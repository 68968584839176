import { Component, OnInit, Input } from "@angular/core";
import { animate, style, transition, trigger } from "@angular/animations";
import { Quotation, Account, BillingCycle } from "@models/_index";
import { AccountService, TaxService } from "@services/_index";
import { AccountQuery, AccountStore } from "@stores/_index";
import { ControlContainer, NgForm } from "@angular/forms";
import { format } from "date-fns";
import { AlertService } from "@services/alert.service";
import { Options } from "@angular-slider/ngx-slider";
import debounce from "lodash.debounce";
import { compareAsc } from "date-fns";
import { Tax, CompanyType } from "@models/account.model";
import { SaleMode } from "@models/quotation.model";
@Component({
  selector: "app-form-quotation-details",
  templateUrl: "./form-quotation-details.component.html",
  styleUrls: ["./form-quotation-details.component.scss"],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  animations: [
    trigger("inOutPaneAnimation", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateX(-100%)" }),
        animate(
          "400ms ease-in-out",
          style({ opacity: 1, transform: "translateX(0)" })
        ),
      ]),
    ]),
  ],
})
export class FormQuotationDetailsComponent implements OnInit {
  @Input() isShowing: boolean;
  @Input() isSubmitted: boolean;
  @Input() isShowingDetails: boolean;
  @Input() quotation: Quotation;

  options: Options = {
    floor: 1,
    ceil: 60,
    step: 1,
    showTicks: true,
    getLegend: (value: number): string => {
      if([1, 12, 24, 36, 48, 60].includes(value)){
        return `<b>${value}</b>`
      }
    },
    showSelectionBar: true,
    disabled: false,
  };

  step: number = 1;

  public customers: Account[] = []; // fill to Customer field
  public accounts: Account[] = []; // fill to Reseller field
  public modes = ["DIRECT", "WHOLESALER", "RESELLER"];
  public billingCycles = Object.keys(BillingCycle);
  public currencies = ["USD", "SGD", "MYR"];
  public terms = [
    {
      context: 14,
      value: "TERM_14_DAYS"
    }, {
      context: 30,
      value: "TERM_30_DAYS"
    }, {
      context: 60,
      value: "TERM_60_DAYS"
    }
  ]
  public quotationDate: Date;
  public quotationExpiration: Date;
  public subscriptionDate: Date;
  public subscriptionEndDate: Date;

  public isCustomerLoading: boolean = false;
  public isAccountLoading: boolean = false;
  
  private currentCustomerPage: number = 0;
  private currentAccountPage: number = 0;

  private customerSearchTerm: string = "";
  private accountSearchTerm: string = "";
  
  private numberOfCustomers: number = 0;
  private numberOfAccounts: number = 0;
  private currentSaleMode: SaleMode = SaleMode.DIRECT
  private totalCustomerItems: number = 0;
  private totalAccountItems: number = 0;

  private debounceSearchCustomer = debounce((searchTerm: string) => {
    this.customerSearchTerm = searchTerm;
    this.customers = [];
    this.currentCustomerPage = 0;
    this.loadCustomers();
  }, 300);

  private debounceSearchAccount = debounce((searchTerm: string) => {
    this.accountSearchTerm = searchTerm;
    this.accounts = [];
    this.currentAccountPage = 0;
    this.loadAccountsByType();
  }, 300);

  constructor(
    private accountService: AccountService,
    private taxService: TaxService,
    private accountQuery: AccountQuery,
    private accountStore: AccountStore,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.currentSaleMode = this.quotation.salesMode
    this.loadCustomers();
    this.loadAccountsByType();
    this.updateSliderStep(this.quotation.billingCycle);
    this.initData();
  }

  initData() {
    this.quotationDate = new Date(this.quotation.quotationDate);
    this.quotationExpiration = new Date(
      this.quotation.quotationExpiration
    );
    this.subscriptionDate = new Date(this.quotation.subscriptionDate);
    this.getSubscriptionEndDate();
    const newOptions: Options = Object.assign({}, this.options);
    newOptions.disabled = this.isShowingDetails;
    this.options = newOptions;
    if (!this.quotation.accountId) {
      this.quotation.tax = null;
    }
    this.quotation.accountId && this.onChangeCustomer(this.quotation.accountId)
    this.quotation.paymentTerm = this.quotation.paymentTerm ?? this.terms[0].value
  }

  loadCustomers() {
    this.isCustomerLoading = true;
    this.accountService
      .getAccounts({
        params: {
          keySearch: this.customerSearchTerm,
          sort: "company.name,asc",
          size: 12,
          page: this.currentCustomerPage
        },
      })
      .subscribe(
        (res) => {
          this.totalCustomerItems = res?.data.totalSize;
          this.numberOfCustomers = this.numberOfCustomers + res?.data.numberOfElements;
          const customers = this.accountQuery.getAccounts;
          this.customers = this.currentCustomerPage === 0 ? customers : [...this.customers, ...customers];
          this.isCustomerLoading = false;

          if(!this.quotation.accountId) return

          if(!this.customers.find(c => c.id === this.quotation.accountId)){
            this.currentCustomerPage += 1
            this.loadCustomers()
          }
        },
        (err) => {
          this.alertService.error(err?.error?.message);
          this.isCustomerLoading = false;
        }
      );
  }

  loadAccountsByType() {
    if(!(
      this.quotation.salesMode === SaleMode.RESELLER || 
      this.quotation.salesMode === SaleMode.WHOLESALER
    )) return

    const isNewLoading = this.currentSaleMode !== this.quotation.salesMode

    if(isNewLoading) {
      this.currentSaleMode = this.quotation.salesMode
      this.numberOfAccounts = 0
      this.currentAccountPage = 0
      this.quotation.reseller = null
    }

    this.isAccountLoading = true;
    this.accountService
      .getAccountsByType({
        params: {
          page: this.currentAccountPage,
          size: 12,
          keySearch: this.accountSearchTerm,
          accountType: this.quotation.salesMode,
          sort: "company.name,asc",
        },
      })
      .subscribe(
        (res) => {

          this.totalAccountItems = res?.data.totalSize;
          this.numberOfAccounts = this.numberOfAccounts + res?.data.numberOfElements;

          const accounts = this.accountQuery.getAccountsByType;
          this.accounts = this.currentAccountPage === 0 ? accounts : [...this.accounts, ...accounts];

          this.isAccountLoading = false;

          if(!this.quotation.reseller || isNewLoading) return
          if(!this.accounts.find(c => c.id === this.quotation.reseller)){
            this.currentAccountPage += 1
            this.loadAccountsByType()
          }
        },
        (err) => {
          this.alertService.error(err?.error?.message);
          this.isAccountLoading = false;
        }
      );
  }

  onSelectQuotationDate(date: Date) {
    this.quotation.quotationDate = this.formatDate(date);
  }

  onSelectQuotationExpiration(date: Date) {
    this.quotation.quotationExpiration = this.formatDate(date);
  }

  onSelectSubscriptionDate(date: Date) {
    this.quotation.subscriptionDate = this.formatDate(date);
    this.getSubscriptionEndDate();
    this.quotation.accountId && this.onChangeCustomer(this.quotation.accountId)
  }

  onChangeBillingCycle(billingCycle: string) {
    this.updateSliderStep(billingCycle);
  }

  onChangeSalesMode() {
    this.accountStore.update({ accountsByType: [] });
    this.accounts = this.accountQuery.getAccountsByType;
    this.loadAccountsByType();
  }


  onChangeCustomer(customerId: string) {
    const customer = this.customers.find((cus) => cus.id === customerId);
    if(customer){
      this.taxService
      .getCustomerTax({
        params: {
          country: customer.company.mailingAddress.country,
          companyType: customer.companyType ?? CompanyType.GENERAL,
          startDate: this.quotation.subscriptionDate.substring(0, 10)
        },
      })
      .subscribe((res) => {
        const sortedTax = res.data.sort((t1: Tax, t2: Tax) =>
          compareAsc(new Date(t1.startDate), new Date(t2.startDate))
        );
        const taxBeforeNow = sortedTax.filter(
          (tax: Tax) => compareAsc(new Date(tax.startDate), new Date()) === -1
        );
        const length = taxBeforeNow.length;
        this.quotation.tax = taxBeforeNow[length - 1].taxRate;
      });
    }

  }


  updateSliderStep(billingCycle: string) {
    switch (billingCycle) {
      case "MONTHLY":
        this.updateTermLength(1, 1);
        break;
      case "UPFRONT":
        this.updateTermLength(1);
        break;
      case "QUARTERLY":
        this.updateTermLength(3);
        break;
      case "ANNUALLY":
        this.updateTermLength(12);
        break;
      default:
        this.updateTermLength(1, 1);
    }
  }

  updateTermLength(step: number, floor: number = 12, disabled: boolean = false) {
    const newOptions: Options = Object.assign({}, this.options);
    newOptions.step = step;
    newOptions.floor = floor;
    newOptions.disabled = disabled;
    this.options = newOptions;
  }

  formatDate(date: Date) {
    // TODO: Split this function to utils if needed to DRY CODE
    return format(date, "YYYY-MM-DDT00:00:00");
  }

  isPartPriceItemExisted() {
    return this.quotation.quotationItems.length > 0;
  }

  termLengthInvalid() {
    return (
      this?.quotation?.termLength === null ||
      (this.quotation.termLength && this?.quotation.termLength < 0)
    );
  }

  onChangeTermLength() {
    this.getSubscriptionEndDate();
  }

  getSubscriptionEndDate() {
    this.subscriptionEndDate = new Date(this.subscriptionDate);
    this.subscriptionEndDate.setMonth(
      this.subscriptionEndDate.getMonth() + this.quotation.termLength
    );
    this.subscriptionEndDate.setDate(
      this.subscriptionEndDate.getDate() - 1
    );
  }

  onScrollCustomerToEnd({ end }) {
    if (this.isCustomerLoading || this.numberOfCustomers === this.totalCustomerItems) {
      return;
    }
    if (end === this.numberOfCustomers) {
      this.currentCustomerPage += 1;
      this.loadCustomers();
    }
  }

  onSearchCustomer(searchTerm: string) {
    this.debounceSearchCustomer(searchTerm);
  }

  onScrollAccountToEnd({ end }) {
    if (this.isAccountLoading || this.numberOfAccounts === this.totalAccountItems) {
      return;
    }
    if (end === this.numberOfAccounts) {
      this.currentAccountPage += 1;
      this.loadAccountsByType();
    }
  }

  onSearchAccount(searchTerm: string) {
    this.debounceSearchAccount(searchTerm);
  }
}
