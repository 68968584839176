import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "body",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  constructor(
    translate: TranslateService,
    private router: Router
  ) {
    translate.addLangs(["en"]);
    translate.setDefaultLang("en");
    translate.use("en");
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
