import { Component, OnInit } from "@angular/core";
import { Quotation} from "@models/quotation.model";
import { QuotationService } from "@services/_index";
import { QuotationQuery } from "@stores/_index";
import { AlertService } from "@services/alert.service";
import { Observable } from "rxjs";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-edit-quotation",
  templateUrl: "./edit-quotation.component.html",
  styleUrls: ["./edit-quotation.component.scss"],
})
export class EditQuotationComponent implements OnInit {
  public quotation: Observable<Quotation>;
  public isLoading: Boolean = false;

  constructor(
    private quotationService: QuotationService,
    private quotationQuery: QuotationQuery,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.loadQuotation(params.id);
    });
  }

  loadQuotation(quotationId: string) {
    this.isLoading = true;
    this.quotationService.getQuotation({ id: quotationId }).subscribe(() => {
      this.isLoading = false;
      this.quotation = this.quotationQuery.quotation$;
    }, (err) => {
      this.isLoading = false;
      this.alertService.error(err?.error?.message);
    });
  }
}
