import { Component, OnInit } from "@angular/core";
import { Policy } from "@models/_index";
import { PolicyService, AlertService, ConfirmModalService } from "@services/_index";
import { PolicyQuery } from "@stores/_index";
import { BsModalRef } from "ngx-bootstrap/modal";
@Component({
  selector: "app-policy",
  templateUrl: "./policy.component.html",
  styleUrls: ["./policy.component.scss"],
})
export class PolicyComponent implements OnInit {
  public policies: Policy[] = [];
  public isLoading: Boolean = false;
  public bsModalRef?: BsModalRef;

  currentPage: number = 0;
  totalItems: number;
  search: string = "";

  // sort
  sortedField: string;
  isSortedAsc: boolean;

  readonly ITEMS_PER_PAGE: number = 10;
  constructor(
    private policyService: PolicyService,
    private alertService: AlertService,
    private confirmModalService: ConfirmModalService,
    private policyQuery: PolicyQuery
  ) {}

  ngOnInit() {
    this.getPolicies();
  }

  getPolicies() {
    this.isLoading = true;
    this.policyService.getPolicies().subscribe(() => {
      this.isLoading = false;
      this.policies = this.policyQuery.getPolicies;
    }, (err) => {
      this.isLoading = false;
      this.alertService.errors(err?.errors?._embedded?.message);
    });
  }

  onClickDeleteBtn(account) {
    this.bsModalRef = this.confirmModalService.show(
      "Do you want to delete this item?",
      "By clicking on OK, this item will be removed immediately"
    );

    this.bsModalRef.content.eventEmitter.subscribe((isOk) => {
      if (isOk) {
        this.deletePolicy(account);
      } else {
        this.bsModalRef.hide();
      }
    });
  }

  deletePolicy(policy: Policy) {
    this.policyService.deletePolicy({ id: policy.id }).subscribe(
      (res) => {
        this.bsModalRef.hide();
        this.getPolicies();
        this.alertService.success("Policy Successfully Removed.");
      },
      (err) => {
        this.isLoading = false;
        console.log(err);
        this.alertService.error(err?.error?.message);
      }
    );
  }
}
