<div>
  <div class="row justify-content-between mb-3">
    <div class="col-xs-12 col-md-4">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"
            ><i class="fa fa-calendar"></i
          ></span>
        </div>
        <input
          id="search-input"
          type="text"
          bsDatepicker
          [bsConfig]="{
            dateInputFormat: 'MMM YYYY',
            containerClass: 'theme-blue',
            minMode: 'month'
          }"
          class="form-control"
          (ngModelChange)="onChangeDateFilter($event)"
          [ngModel]="dateFilter"
        />
      </div>
    </div>
    <div class="col-xs-12 col-md-4 mt-3 mt-md-0">
      <app-search-bar
        (handleSearchEmitter)="onSearch($event)"
        placeholder="{{ 'search' | translate }}"
      ></app-search-bar>
    </div>
    <div class="col-xs-12 col-md-4 mt-3 mt-md-0">
      <a
        class="btn btn-primary float-right"
        [routerLink]="'/wholesaler/billing/pending_billing/'"
        translate
      >
        buttonTitles.viewPendingBillings
      </a>
    </div>
  </div>
  <div class="card">
    <div class="card-header" translate>billings</div>
    <div class="card-body table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>
              <app-sortable-header
                [title]="'billId' | translate"
                [isSortedAsc]="isSortedAscOnField('id')"
                [isSortedDesc]="isSortedDescOnField('id')"
                (handleSort)="onApplySort('id')"
              >
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'status' | translate"
                [isSortedAsc]="isSortedAscOnField('status')"
                [isSortedDesc]="isSortedDescOnField('status')"
                (handleSort)="onApplySort('status')"
              >
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'invoiceNumber' | translate"
                [isSortedAsc]="isSortedAscOnField('invoiceNumber')"
                [isSortedDesc]="isSortedDescOnField('invoiceNumber')"
                (handleSort)="onApplySort('invoiceNumber')"
              >
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'customer' | translate"
                [isSortedAsc]="isSortedAscOnField('customerId')"
                [isSortedDesc]="isSortedDescOnField('customerId')"
                (handleSort)="onApplySort('customerId')"
              >
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'periodStart' | translate"
                [isSortedAsc]="isSortedAscOnField('periodStart')"
                [isSortedDesc]="isSortedDescOnField('periodStart')"
                (handleSort)="onApplySort('periodStart')"
              >
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'exportedBy' | translate"
                [isSortedAsc]="isSortedAscOnField('exporterName')"
                [isSortedDesc]="isSortedDescOnField('exporterName')"
                (handleSort)="onApplySort('exporterName')"
              >
              </app-sortable-header>
            </th>
            <th translate>actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-template [ngIf]="!isLoading">
            <tr *ngFor="let billing of billings">
              <td>{{ billing.billingCode }}</td>
              <td>
                <app-billing-detail-status
                  [status]="billing.status"
                ></app-billing-detail-status>
              </td>
              <td>{{ billing.invoiceNumber }}</td>
              <td>{{ billing.customerName }}</td>
              <td>
                {{ billing.periodStart | date: "dd MMM yyyy" }}
              </td>
              <td>{{ billing.exporterName }}</td>
              <td>
                <div class="d-flex align-items-center">
                  <a
                    class="text-decoration-none mr-2"
                    [routerLink]="[
                      '/wholesaler/billing/billing/',
                      billing.id
                    ]"
                  >
                    <i
                      class="fa fa-eye"
                      tooltip="{{
                        'tooltip.viewBillingDetail' | translate
                      }}"
                    >
                    </i>
                  </a>

                  <a
                    class="text-primary hoverable mr-2"
                    (click)="onDownloadExcel(billing.id, billing.billingCode)"
                  >
                    <i
                      class="fa fa-file-excel-o"
                      tooltip="{{ 'tooltip.downloadBilling' | translate }}"
                    >
                    </i>
                  </a>
                  <app-billing-edit-status
                    [billItem]="billing"
                    (fetchBillItems)="getBillings()"
                  ></app-billing-edit-status>
                </div>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
      <app-pagination
        [totalItems]="totalItems"
        [currentPage]="currentPage"
        [itemPerPages]="itemPerPage"
        (handleGoToPage)="goToPage($event)"
        (changeItemPerPage)="changeItemPerPage($event)"
      ></app-pagination>
    </div>
  </div>
</div>
