import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServiceUrl } from "./config/service-url";
import { PolicyStore } from "@stores/_index";
import { tap } from "rxjs/operators";
import { environment } from "environments/environment";
import { Payload } from "@models/_index";

@Injectable({
  providedIn: "root",
})
export class PolicyService {
  serviceUrl = new ServiceUrl();
  baseUrl = environment.iamBaseUrl;

  constructor(
    private store: PolicyStore,
    private http: HttpClient
  ) {}

  getPolicies(payload?: Payload) {
    return this.http
      .get<any>(`${this.baseUrl}/${this.serviceUrl.GET_POLICIES}`)
      .pipe(
        tap((res) => {
          this.store.update({
            policies: res.data,
          });
        })
      );
  }

  getPolicy(payload?: Payload) {
    return this.http
      .get<any>(`${this.baseUrl}/${this.serviceUrl.GET_POLICIES}/${payload.id}`)
      .pipe(
        tap((res) => {
          this.store.update({
            policy: res.data,
          });
        })
      );
  }

  createPolicy(payload: Payload) {
    return this.http.post(
      `${this.baseUrl}/${this.serviceUrl.GET_POLICIES}`,
      payload.payload
    );
  }

  updatePolicy(payload: Payload) {
    return this.http.put(
      `${this.baseUrl}/${this.serviceUrl.GET_POLICIES}/${payload.id}`,
      payload.payload
    );
  }

  deletePolicy(payload: Payload) {
    return this.http.delete(
      `${this.baseUrl}/${this.serviceUrl.GET_POLICIES}/${payload.id}`
    );
  }
}
