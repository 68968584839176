<div class="card" *ngIf="!isLoading">
  <div class="card-body">
    <div class="row">
      <form
        id="productPartForm"
        class="form-full-width"
        (ngSubmit)="onSubmit(productPartForm)"
        #productPartForm="ngForm"
      >
        <div class="col-12">
          <div class="form-group">
            <label class="required" translate>product</label>
            <select
              [disabled]="isEditing"
              [ngModel]="selectedProductId"
              class="form-control"
              name="productId"
              (change)="onSelectProduct($event.target.value, i)"
            >
              <option
                [value]="product.id"
                *ngFor="let product of products"
              >
                {{ product.productName }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label class="required" translate>name</label>
            <input
              type="text"
              #name="ngModel"
              name="name"
              class="form-control"
              name="name"
              required
              [placeholder]="'placeholder.productPartName' | translate"
              [(ngModel)]="productPart.name"
              [ngClass]="{
                'is-invalid': productPartForm.submitted && name.invalid
              }"
            />

            <div
              *ngIf="productPartForm.submitted && name.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="name.errors.required" translate>
                validateMessages.nameRequired
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="required" translate>uom</label>
            <select
              [(ngModel)]="productPart.uom"
              class="form-control"
              name="uom"
            >
              <option value="COUNT">COUNT</option>
              <option value="DAY">DAY</option>
            </select>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label translate>serviceCode</label>
            <input
              type="text"
              class="form-control"
              #serviceCode="ngModel"
              name="serviceCode"
              [placeholder]="'placeholder.serviceCode' | translate"
              [(ngModel)]="productPart.code"
            />
          </div>
        </div>
      </form>
    </div>
    <div>
      <button
        class="btn btn-primary mr-1"
        [ladda]="isSubmitting"
        type="submit"
        form="productPartForm"
        [translate]="'buttonTitles.save'"
      ></button>
      <button
        class="btn btn-secondary"
        [routerLink]="'/wholesaler/product/part'"
        [translate]="'buttonTitles.cancel'"
      ></button>
    </div>
  </div>
</div>

<div class="text-center" *ngIf="isLoading">
  <div
    class="spinner-border text-primary"
    role="status"
    style="width: 3rem; height: 3rem"
  >
    <span class="sr-only">Loading...</span>
  </div>
</div>
