import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { Subscription } from "@models/subscription.model";

import {
  SubscriptionService,
  AlertService,
  QuotationService,
  AccountService,
} from "@services/_index";

import { AccountQuery, SubscriptionQuery } from "@stores/_index";

import { Account } from "@models/_index";

import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

import debounce from "lodash.debounce";

@Component({
  selector: "app-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["./subscription.component.scss"],
})
export class SubscriptionComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  isLoading: boolean = false;

  currentPage: number = 0;
  totalItems: number = 0;
  itemPerPage: number = 10;

  search: string = "";

  sortedField: string;
  isSortedAsc: boolean;

  accounts: Account[] = [];
  accountId: string;
  defaultAccountId: string = null;


  private debounceSearch = debounce((searchTerm) => {
    this.search = searchTerm;
    this.currentPage = 0;
    this.getSubscriptions();
  }, 500);

  constructor(
    private subscriptionService: SubscriptionService,
    private quotationService: QuotationService,
    private subscriptionQuery: SubscriptionQuery,
    private alertService: AlertService,
    private accountService: AccountService,
    private router: Router,
    private accountQuery: AccountQuery,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params["quotationId"]) {
        this.search = params["quotationId"];
      }
    });
  }

  ngOnInit(): void {
    if (this.accountQuery.getSelectedAccount) {
      this.accountId = this.accountQuery.getSelectedAccount;
      this.onSelect(this.accountId);
      this.defaultAccountId = this.accountId;
    }
    this.getAccounts();
    this.getSubscriptions();
  }

  ngOnDestroy(): void {
    this.accountService.selectAccount(null);
  }

  getSubscriptions() {
    this.isLoading = true;
    this.subscriptionService
      .getSubscriptions({
        params: {
          page: this.currentPage,
          keyword: this.search,
          size: this.itemPerPage,
          sort: this.sortedField,
          direction: this.directionParams(),
          accountId: this.accountId,
        },
      })
      .subscribe(
        (res?) => {
          this.isLoading = false;
          this.totalItems = res?.total_size;
          this.subscriptions = this.subscriptionQuery.subscriptions;
        },
        (err) => {
          this.isLoading = false;
          this.alertService.error(err?.error?.message);
        }
      );
  }

  getAccounts() {
    this.accountService
      .getAccounts({
        params: {},
      })
      .subscribe(
        (res) => {
          this.accounts = res?.data.content;
        },
        (err) => {
          this.alertService.error(err?.error?.message);
        }
      );
  }

  goToPage(page: number) {
    this.currentPage = page - 1;
    this.getSubscriptions();
  }

  changeItemPerPage(itemPerPages: number) {
    this.currentPage = 0;
    this.itemPerPage = itemPerPages;
    this.getSubscriptions();
  }

  onSearch(keySearch: string) {
    this.debounceSearch(keySearch);
  }

  onSelect(accountId: string) {
    this.accountId = accountId;
    this.currentPage = 0;
    this.getSubscriptions();
  }

  // below are sortable function
  isSortedOnField(field: string) {
    return this.sortedField === field;
  }

  isSortedAscOnField(field: string) {
    return this.isSortedOnField(field) && this.isSortedAsc;
  }

  isSortedDescOnField(field: string) {
    return this.isSortedOnField(field) && !this.isSortedAsc;
  }

  directionParams() {
    return this.sortedField ? `${this.isSortedAsc ? "asc" : "desc"}` : "";
  }

  onApplySort(field: string) {
    if (field !== this.sortedField) {
      this.isSortedAsc = true;
      this.sortedField = field;
    } else {
      this.isSortedAsc = !this.isSortedAsc;
    }
    this.getSubscriptions();
  }

  goToQuotation(id: string) {
    this.quotationService.selectQuotation(id);
    this.router.navigate(["/wholesaler/quotation"]);
  }

  showPdf(id, base64String) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${id}.pdf`;
    link.click();
  }

  onDownloadPdf(id: string) {
    this.subscriptionService.getSubscriptionPdf(id).subscribe(
      (res) => {
        this.showPdf(id, res?.file);
      },
      (err) => {}
    );
  }
}
