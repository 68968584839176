import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { UserService, AlertService } from "@services/_index";
import { User } from "@models/user.model";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
  selector: "app-modal-change-password",
  templateUrl: "./modal-change-password.component.html",
  styleUrls: ["./modal-change-password.component.scss"],
})
export class ModalChangePasswordComponent implements OnInit {
  @Input() user: User = new User();

  @ViewChild("changePasswordModal", { static: false })
  public changePasswordModal: ModalDirective;

  public password: string;
  public confirmPassword: string;

  public isSubmitting: Boolean = false;



  constructor(
    private userService: UserService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {}

  onSubmit(form) {
    if (!form.form.valid || !this.checkConfirmPassword()) {
      return;
    }
    this.changePassword();
  }

  changePassword() {
    this.isSubmitting = true;
    this.userService
      .changePassword(this.user.id, { payload: {
        password: this.password,
      }})
      .subscribe(
        () => {
          this.isSubmitting = false;
          this.changePasswordModal.hide();
          this.alertService.success("Password successfully updated.");
        },
        (err) => {
          this.isSubmitting = false;
          this.alertService.error(err?.error.message);
        }
      );
  }

  checkConfirmPassword() {
    return this.password === this.confirmPassword ? true : false;
  }
}
