import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { Product } from "@models/product.model";
import { ProductService } from "@services/product.service";

import { AlertService } from "@services/alert.service";

@Component({
  selector: "app-form-product",
  templateUrl: "./form-product.component.html",
  styleUrls: ["./form-product.component.scss"],
})
export class FormProductComponent implements OnInit {
  product: Product = new Product();
  isLoading: Boolean = true;
  productId: string;
  isSubmitting: Boolean = false;

  constructor(
    private productService: ProductService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.productId = params.id;
        this.productService.getProductDetail({ id: this.productId }).subscribe(
          (res?) => {
            this.isLoading = false;
            const { active, productName, partNumberPrefix } = res?.data;
            this.product = {
              active,
              productName,
              partNumberPrefix,
            };
          },
          (err) => {
            this.alertService.errors(err?.error?._embedded?.errors);
          }
        );
      } else {
        this.isLoading = false;
      }
    });
  }

  onSubmit(form: HTMLFormElement) {
    if (form.form.invalid) {
      return;
    }
    this.product.partNumberPrefix =
      this.product.partNumberPrefix.toLocaleUpperCase();
    this.isSubmitting = true;
    const payload = {
      ...this.product,
    };
    if (this.productId) {
      this.updateProduct(payload);
    } else {
      this.createProduct(payload);
    }
  }

  createProduct(payload: Product) {
    this.productService.addProduct({ payload: payload }).subscribe(
      (res) => {
        this.isSubmitting = false;
        this.router.navigate(["/wholesaler/product/product"]);
        this.alertService.success("Create product successfully!");
      },
      (err?) => {
        this.isSubmitting = false;
        this.alertService.error(err?.error?.message);
      }
    );
  }

  updateProduct(payload) {
    this.productService
      .updateProduct({
        id: this.productId,
        payload: payload,
      })
      .subscribe(
        (res) => {
          this.alertService.success("Update product successfully!");
          this.isSubmitting = false;
          this.router.navigate(["/wholesaler/product/product"]);
        },
        (err?) => {
          this.isSubmitting = false;
          this.alertService.error(err?.error?.message);
        }
      );
  }
}
