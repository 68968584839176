import { Store, StoreConfig } from "@datorama/akita";
import { Injectable } from "@angular/core";

export interface ActionState {
  actions: [];
}
export function createInitialState(): ActionState {
  return {
    actions: [],
  };
}

@StoreConfig({ name: "action" })
@Injectable({ providedIn: "root" })
export class ActionStore extends Store<ActionState> {
  constructor() {
    super(createInitialState());
  }
}
