<div *ngIf="!isPartPriceItemLoading" class="card my-3">
  <div class="card-body">
    <form
      id="productBundleForm"
      (ngSubmit)="onSubmit(productBundleForm)"
      #productBundleForm="ngForm"
      class="row"
    >
      <div class="col-12 col-xl-6">
        <div class="form-group">
          <label translate class="required">internalName</label>
          <div class="row">
            <div class="col-12">
              <div class="input-prepend input-group">
                <input
                  type="text"
                  class="form-control"
                  id="internalName"
                  name="internalName"
                  #internalName="ngModel"
                  required
                  [ngClass]="{
                    'is-invalid':
                      productBundleForm.submitted && internalName.invalid
                  }"
                  [(ngModel)]="productBundle.name"
                  [placeholder]="'placeholder.internalName' | translate"
                />
              </div>
              <div
                *ngIf="productBundleForm.submitted && internalName.invalid"
                class="invalid-feedback d-block"
              >
                <div *ngIf="internalName.errors.required" translate>
                  validateMessages.internalName
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label translate class="required">billDescription</label>
          <div class="row">
            <div class="col-12">
              <div class="input-prepend input-group">
                <input
                  type="text"
                  class="form-control"
                  id="billDescription"
                  name="billDescription"
                  [(ngModel)]="productBundle.billDescription"
                  #billDescription="ngModel"
                  required
                  [ngClass]="{
                    'is-invalid':
                      productBundleForm.submitted &&
                      billDescription.invalid
                  }"
                  [placeholder]="'placeholder.billDescription' | translate"
                />
              </div>
              <div
                *ngIf="
                  productBundleForm.submitted && billDescription.invalid
                "
                class="invalid-feedback d-block"
              >
                <div *ngIf="billDescription.errors.required" translate>
                  validateMessages.billDescription
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label translate class="required">recurrenceType</label>
          <div class="row">
            <div class="col-12">
              <div class="input-prepend input-group">
                <select
                  id="prependedInput"
                  class="form-control"
                  name="recurrenceType"
                  #recurrenceType="ngModel"
                  required
                  [disabled]="productBundleItemList.length > 0"
                  [ngClass]="{
                    'is-invalid':
                      productBundleForm.submitted && recurrenceType.invalid
                  }"
                  [(ngModel)]="productBundle.recurrenceType"
                >
                  <option value="MONTHLY">MONTHLY</option>
                  <option value="QUARTERLY">QUARTERLY</option>
                  <option value="ANNUALLY">ANNUALLY</option>
                  <option value="UPFRONT">UPFRONT</option>
                </select>
              </div>

              <div
                *ngIf="
                  productBundleForm.submitted && recurrenceType.invalid
                "
                class="invalid-feedback d-block"
              >
                <div *ngIf="recurrenceType.errors.required" translate>
                  validateMessages.recurrenceType
                </div>
              </div>
              <div
                *ngIf="productBundleItemList.length > 0"
                class="invalid-feedback d-block"
              >
                <div translate>
                  validateMessages.productBundleRecurrenceType
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label translate class="required">currency</label>
          <div class="row">
            <div class="col-12">
              <div class="input-prepend input-group">
                <select
                  id="prependedInput"
                  class="form-control"
                  name="currency"
                  #currency="ngModel"
                  required
                  [disabled]="productBundleItemList.length > 0"
                  [ngClass]="{
                    'is-invalid':
                      productBundleForm.submitted && currency.invalid
                  }"
                  [(ngModel)]="productBundle.currency"
                >
                  <option value="USD">USD</option>
                  <option value="SGD">SGD</option>
                  <option value="MYR">MYR</option>
                </select>
              </div>

              <div
                *ngIf="productBundleForm.submitted && currency.invalid"
                class="invalid-feedback d-block"
              >
                <div *ngIf="currency.errors.required" translate>
                  validateMessages.currency
                </div>
              </div>
              <div
                *ngIf="productBundleItemList.length > 0"
                class="invalid-feedback d-block"
              >
                <div translate>validateMessages.productBundleCurrency</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="form-group">
          <label translate class="required">effectiveStartDate</label>
          <div class="row">
            <div class="col-12">
              <div class="input-prepend input-group">
                <input
                  type="text"
                  class="form-control"
                  bsDatepicker
                  name="effectDate"
                  [(ngModel)]="effectiveStartDate"
                  #effectDate="ngModel"
                  [bsConfig]="{
                    dateInputFormat: 'DD-MM-YYYY',
                    containerClass: 'theme-blue'
                  }"
                  (ngModelChange)="onSelectEffectiveStartDate($event)"
                  required
                  [ngClass]="{
                    'is-invalid': isSubmitted && effectDate.invalid
                  }"
                />
              </div>
              <div
                  *ngIf="isSubmitted && effectDate.invalid"
                  class="invalid-feedback"
                >
                  <div *ngIf="effectDate.errors.required" translate>
                    validateMessages.effectiveStartDate
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label translate class="required">expiringDate</label>
          <div class="row">
            <div class="col-12">
              <div class="input-prepend input-group">
                <input
                  type="text"
                  class="form-control"
                  bsDatepicker
                  name="expireDate"
                  [(ngModel)]="expiringDate"
                  #expireDate="ngModel"
                  [bsConfig]="{
                    dateInputFormat: 'DD-MM-YYYY',
                    containerClass: 'theme-blue'
                  }"
                  (ngModelChange)="onSelectExpiringDate($event)"
                  required
                  [ngClass]="{
                    'is-invalid': isSubmitted && expireDate.invalid
                  }"
                />
              </div>
              <div
                  *ngIf="isSubmitted && expireDate.invalid"
                  class="invalid-feedback"
                >
                  <div *ngIf="expireDate.errors.required" translate>
                    validateMessages.expireDate
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label translate class="required">maximumDiscount</label>
          <div class="row">
            <div class="col-12">
              <div class="input-prepend input-group">
                <input
                  type="text"
                  class="form-control"
                  id="maximumDiscount"
                  name="maximumDiscount"
                  required
                  [ngClass]="{
                    'is-invalid':
                      productBundleForm.submitted &&
                      maximumDiscount.invalid
                  }"
                  [(ngModel)]="productBundle.maximumDiscount"
                  #maximumDiscount="ngModel"
                  [placeholder]="'placeholder.maximumDiscount' | translate"
                />
              </div>
              <div
                *ngIf="
                  productBundleForm.submitted && maximumDiscount.invalid
                "
                class="invalid-feedback d-block"
              >
                <div *ngIf="maximumDiscount.errors.required" translate>
                  validateMessages.maximumDiscount
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="table-currencies">
      <div class="table-responsive">
        <table class="table align-items-center table-flush mt-3">
          <thead>
            <tr>
              <th width="14%" translate>product</th>
              <th width="20%" translate>partPricing</th>
              <th width="32%" translate>recurrenceType</th>
              <th width="10%" translate>currency</th>
              <th width="5%" translate>minimumQuantity</th>
              <th width="5%" translate>maximumQuantity</th>
              <th width="14%" translate>actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of productBundleItemList; index as i">
              <ng-template [ngIf]="!isThisItemEditing(i)">
                <td>{{ item.product.name }}</td>
                <td>{{ item.partPriceItem.name }}</td>
                <td>{{ formatRec(item.recurrenceType) }}</td>
                <td>{{ item.currency }}</td>
                <td>{{ item.minimumQuantity }}</td>
                <td>{{ item.maximumQuantity }}</td>
                <td>
                  <button
                    class="btn btn-primary mr-1"
                    (click)="onEditSelectedItem(i)"
                    [disabled]="currentEditItem !== -1"
                  >
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button
                    class="btn btn-primary"
                    (click)="onRemoveSelectedItem(i)"
                    [disabled]="currentEditItem !== -1"
                  >
                    <i class="fa fa-trash"> </i>
                  </button>
                </td>
              </ng-template>
              <ng-template [ngIf]="isThisItemEditing(i)">
                <td>
                  <div class="form-group mb-0">
                    <select
                      class="form-control"
                      id="product"
                      name="product"
                      [(ngModel)]="selectedItem.product.id"
                      #product="ngModel"
                      required
                      (change)="onChangeProduct()"
                      [ngClass]="{
                        'is-invalid':
                          !isSelectedProductValid() && isSubmitted
                      }"
                    >
                      <option
                        value=""
                        [translate]="'placeholder.selectProduct'"
                        hidden
                      ></option>
                      <option
                        *ngFor="let pd of productList"
                        [value]="pd.id"
                      >
                        {{ pd.productName }}
                      </option>
                    </select>
                    <div
                      *ngIf="!isSelectedProductValid() && isSubmitted"
                      class="invalid-feedback"
                    >
                      <div *ngIf="product.errors.required" translate>
                        validateMessages.product
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    class="form-group mb-0"
                    *ngIf="selectedItem.product.id.length"
                  >
                    <select
                      id="price"
                      class="form-control"
                      name="partPrice"
                      #partPrice="ngModel"
                      [(ngModel)]="selectedItem.partPriceItem.partPriceItemId"
                      required
                      (change)="onChangePartPriceItem()"
                      [ngClass]="{
                        'is-invalid':
                          !isSelectedPartPriceItemValid() && isSubmitted
                      }"
                    >
                      <option
                        value=""
                        [translate]="
                          'placeholder.selectProductPartPriceItem'
                        "
                        hidden
                      ></option>
                      <option
                        *ngFor="let part of partPriceItemList"
                        [value]="part.id"
                      >
                        {{ part.name }}
                      </option>
                    </select>
                    <div
                      *ngIf="
                        !isSelectedPartPriceItemValid() && isSubmitted
                      "
                      class="invalid-feedback"
                    >
                      <div *ngIf="partPrice.errors.required" translate>
                        validateMessages.partPriceItemRequired
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    class="form-group mb-0"
                    *ngIf="selectedItem.partPriceItem.partPriceItemId.length"
                  >
                    <input
                      class="form-control"
                      [value]="formatRec(selectedItem.recurrenceType)"
                      disabled="true"
                    >
                  </div>
                </td>
                <td>
                  <div
                    class="form-group mb-0"
                    *ngIf="selectedItem.partPriceItem.partPriceItemId.length"
                  >
                    <input
                      class="form-control"
                      disabled="true"
                      [value]="selectedItem.currency"
                    />
                  </div>
                </td>
                <td>
                  <div
                    class="form-group mb-0"
                    *ngIf="selectedItem.partPriceItem.partPriceItemId.length"
                  >
                    <input
                      class="form-control"
                      type="number"
                      id="minimumQuantity"
                      name="minimumQuantity"
                      #minimumQuantity="ngModel"
                      required
                      [(ngModel)]="selectedItem.minimumQuantity"
                      [ngClass]="{
                        'is-invalid':
                          !isMinimumQuantityValid && isSubmitted
                      }"
                      pattern="^(0|[1-9]\d*)$"
                    />
                    <div
                      *ngIf="!isMinimumQuantityValid && isSubmitted"
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="minimumQuantity.errors.required"
                        translate
                      >
                        validateMessages.minimumQuantity
                      </div>
                      <div
                        *ngIf="minimumQuantity.errors.pattern"
                        translate
                      >
                        validateMessages.minimumQuantityValidate
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    class="form-group mb-0"
                    *ngIf="selectedItem.partPriceItem.partPriceItemId.length"
                  >
                    <input
                      class="form-control"
                      type="number"
                      id="maximumQuantity"
                      name="maximumQuantity"
                      #maximumQuantity="ngModel"
                      required
                      [(ngModel)]="selectedItem.maximumQuantity"
                      [ngClass]="{
                        'is-invalid':
                          !isMaximumQuantityValid && isSubmitted
                      }"
                      pattern="^(0|[1-9]\d*)$"
                    />
                    <div
                      *ngIf="!isMaximumQuantityValid && isSubmitted"
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="maximumQuantity.errors.required"
                        translate
                      >
                        validateMessages.maximumQuantity
                      </div>
                      <div
                        *ngIf="maximumQuantity.errors.pattern"
                        translate
                      >
                        validateMessages.maximumQuantityValidate
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <button
                    class="btn btn-primary mr-1"
                    (click)="onSaveSelectedItem()"
                  >
                    <i class="fa fa-check"> </i>
                  </button>
                  <button
                    class="btn btn-primary"
                    (click)="onCancelSelectedItem(i)"
                  >
                    <i class="fa fa-ban"> </i>
                  </button>
                </td>
              </ng-template>
            </tr>
            <tr>
              <td colspan="2">
                <button
                  class="btn btn-primary"
                  (click)="onAddNewProductBundleItem()"
                  [translate]="'buttonTitles.addPrice'"
                  [disabled]="currentEditItem !== -1"
                ></button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div>
      <button
        class="btn btn-primary mr-1"
        [ladda]="isSubmitting"
        [disabled]="currentEditItem !== -1"
        [translate]="'buttonTitles.save'"
        data-style="zoom-in"
        form="productBundleForm"
      ></button>
      <button
        class="btn btn-secondary"
        [translate]="'buttonTitles.cancel'"
        [routerLink]="'/wholesaler/product/product_bundle'"
      ></button>
    </div>
  </div>
</div>
<app-loading-spinner [isLoading]="isPartPriceItemLoading"></app-loading-spinner>
