import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { User } from "@models/user.model";
import { AlertService, UserService } from "@services/_index";

import { AuthQuery } from "@stores/_index";

@Component({
  selector: "app-create-user",
  templateUrl: "./create-user.component.html",
  styleUrls: ["./create-user.component.scss"],
})
export class CreateUserComponent implements OnInit {
  public user: User = new User();
  public isSubmitting: Boolean = false;

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private authQuery: AuthQuery,
    private router: Router
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    const accountId = this.authQuery.currentUserAccountId;
    const displayName = `${this.user.firstName} ${this.user.lastName}`;
    const payload = {
      ...this.user,
      displayName: displayName,
      accountId,
    };
    this.isSubmitting = true;
    this.userService.createUser({ payload: payload }).subscribe(
      () => {
        this.isSubmitting = false;
        this.alertService.success("User Successfully Created.");
        this.router.navigate(["/wholesaler/admin/users"]);
      },
      (err) => {
        this.isSubmitting = false;
        this.alertService.error(err.error.message);
      }
    );
  }
}
