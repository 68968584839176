import { Component, OnInit } from "@angular/core";
import {
  ProductService,
  AccountService,
  SubscriptionService,
  AlertService,
  BillingService,
} from "@services/_index";
import { combineLatest, of } from "rxjs";
import { catchError } from "rxjs/operators";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  products: number;
  customers: number;
  subscriptions: number;
  billings: number;
  isLoading: boolean = false;
  isShowProductsStas: boolean = true;
  isShowCustomersStas: boolean = true;
  isShowSubscriptionsStas: boolean = true;
  isShowBillingsStas: boolean = true;

  constructor(
    private productService: ProductService,
    private accountService: AccountService,
    private billingService: BillingService,
    private subscriptionService: SubscriptionService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.fetchDashboardData();
  }

  async fetchDashboardData() {
    this.isLoading = true;
    combineLatest([
      this.fetchProducts(),
      this.fetchCustomers(),
      this.fetchSubscriptions(),
      this.fetchBillings(),
    ]).subscribe(
      ([productRes, customerRes, subscriptionRes, billingRes]) => {
        this.products = productRes?.data?.totalSize;
        this.customers = customerRes?.data?.totalSize;
        this.billings = billingRes?.data.totalSize;
        this.subscriptions = subscriptionRes.total_size;
        this.isLoading = false;
      }
    );
  }

  fetchProducts() {
    return this.productService.getProductsNoSubscribe().pipe(
      catchError(() => {
        this.isShowProductsStas = false;
        return of(false);
      })
    );
  }

  fetchCustomers() {
    return this.accountService.getAccounts().pipe(
      catchError(() => {
        this.isShowCustomersStas = false;
        return of(false);
      })
    );
  }

  fetchSubscriptions() {
    return this.subscriptionService.getSubscriptions().pipe(
      catchError(() => {
        this.isShowSubscriptionsStas = false;
        return of(false);
      })
    );
  }

  fetchBillings() {
    return this.billingService.getBillings().pipe(
      catchError(() => {
        this.isShowBillingsStas = false;
        return of(false);
      })
    );
  }
}
