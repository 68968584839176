import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import {
  WholesalerComponent,
  DashboardComponent,
  ProductComponent,
  CustomerComponent,
  CreateCustomerComponent,
  EditCustomerComponent,
  PartPricingComponent,
  CreatePartPricingComponent,
  EditPartPricingComponent,
  CreateProductComponent,
  ProductPartComponent,
  CreateProductPartComponent,
  SubscriptionComponent,
  CreateSubscriptionComponent,
  QuotationComponent,
  CreateQuotationComponent,
  FormQuotationDetailsComponent,
  FormContactDetailsComponent,
  BillingComponent,
  EditProductComponent,
  EditProductPartComponent,
  EditQuotationComponent,
  PendingBillingComponent,
  BillingDetailsComponent,
  ShowQuotationComponent,
  UserComponent,
  UserGroupComponent,
  PolicyComponent,
  EditPolicyComponent,
  CreatePolicyComponent,
  CreateUserComponent,
  EditUserComponent,
  CreateUserGroupComponent,
  EditUserGroupComponent,
  ActionComponent,
  HomeComponent,
  CustomerTaxComponent,
  ProductBundleComponent,
  CreateProductBundleComponent,
  EditProductBundleComponent
} from "./index";

import { AuthGuard } from "app/auth/auth.guard";
import { DeactivateGuard } from "app/guards/deactivate.guard"
const routes: Routes = [
  {
    path: "wholesaler",
    component: WholesalerComponent,
    canActivateChild: [AuthGuard],
    children: [
      { path: "", redirectTo: "home", pathMatch: "full" },
      {
        path: "home",
        component: HomeComponent,
        data: { title: "Home" },
      },
      {
        path: "dashboard",
        component: DashboardComponent,
        data: { title: "Dashboard" },
      },
      {
        path: "admin",
        children: [
          {
            path: "users",
            children: [
              {
                path: "",
                component: UserComponent,
                data: { title: "Users" },
              },
              {
                path: "new",
                component: CreateUserComponent,
                data: { title: "Create New User" },
              },
              {
                path: ":id",
                component: EditUserComponent,
                data: { title: "Edit User" },
              },
            ],
          },
          {
            path: "user_groups",
            children: [
              {
                path: "",
                component: UserGroupComponent,
                data: { title: "User Groups" },
              },
              {
                path: "new",
                component: CreateUserGroupComponent,
                data: { title: "Create New User Group" },
              },
              {
                path: ":id",
                component: EditUserGroupComponent,
                data: { title: "Edit User Group" },
              },
            ],
          },
          {
            path: "policy",
            data: { title: "Policies" },
            children: [
              {
                path: "",
                component: PolicyComponent,
                data: { title: "Policies" },
              },
              {
                path: "new",
                component: CreatePolicyComponent,
                data: { title: "Create Policy" },
              },
              {
                path: ":id",
                component: EditPolicyComponent,
                data: { title: "Edit Policy" },
              },
            ],
          },
          {
            path: "action",
            data: { title: "Actions" },
            children: [
              {
                path: "",
                component: ActionComponent,
                data: { title: "Actions" },
              }
            ],
          },
        ],
      },
      {
        path: "product",
        children: [
          {
            path: "part",
            children: [
              {
                path: "",
                component: ProductPartComponent,
                data: { title: "Product Parts" },
              },
              {
                path: "new",
                component: CreateProductPartComponent,
                data: { title: "New Product Part" },
              },
              {
                path: "edit",
                children: [
                  {
                    path: ":idProductPart",
                    component: EditProductPartComponent,
                    data: { title: "Edit Product Part" },
                  },
                ],
              },
              {
                path: ":id",
                children: [
                  {
                    path: "",
                    component: ProductPartComponent,
                    data: { title: "Product Parts" },
                  },
                ],
              },
            ],
          },
          {
            path: "part_pricing",
            children: [
              {
                path: "",
                component: PartPricingComponent,
                data: { title: "Part Pricings" },
              },
              {
                path: "new",
                component: CreatePartPricingComponent,
                data: { title: "New Part Pricing" },
              },
              {
                path: ":id",
                component: EditPartPricingComponent,
                data: { title: "Edit Part Pricing" },
              },
            ],
          },
          {
            path: "product_bundle",
            children: [
              {
                path: "",
                component: ProductBundleComponent,
                data: { title: "Product Bundle" }
              },
              {
                path: "new",
                component: CreateProductBundleComponent,
                data: { title: "Create New Product Bundle" }
              },
              {
                path: ":id",
                component: EditProductBundleComponent,
                data: { title: "Edit Product Bundle" }
              }
            ]
          },
          {
            path: "product",
            children: [
              {
                path: "",
                component: ProductComponent,
                data: { title: "Products" },
              },
              {
                path: "new",
                component: CreateProductComponent,
                data: { title: "Create New Product" },
              },
              {
                path: ":id",
                component: EditProductComponent,
                data: { title: "Edit Product" },
              },
            ],
          },
        ],
      },
      {
        path: "customer",
        children: [
          {
            path: "customers",
            data: { title: "Customers" },
            children: [
              {
                path: "",
                component: CustomerComponent,
                data: { title: "Customers" },
              },
              {
                path: "new",
                component: CreateCustomerComponent,
                data: { title: "Create Customer" },
              },
              {
                path: ":id",
                component: EditCustomerComponent,
                data: { title: "Edit Customer" },
                runGuardsAndResolvers: 'always',
                canDeactivate: [DeactivateGuard]
              },
            ]
          },
          {
            path: "customer_tax",
            data: { title: "Customer Tax" },
            children: [
              {
                path: "",
                component: CustomerTaxComponent,
                data: { title: "Customer Tax" },
              }
            ]
          }
        ],
      },
      {
        path: "subscription",
        children: [
          {
            path: "",
            component: SubscriptionComponent,
            data: { title: "Subscriptions" },
          },
          {
            path: "new",
            component: CreateSubscriptionComponent,
            data: { title: "Create Subscription" },
          },
          // {
          //   path: ":id",
          //   component: EditCustomerComponent,
          //   data: { title: "Edit Customer" },
          // },
        ],
      },
      {
        path: "quotation",
        children: [
          {
            path: "",
            component: QuotationComponent,
            data: { title: "Quotations" },
          },
          {
            path: "new",
            component: CreateQuotationComponent,
            data: { title: "Create Quotation" },
          },
          {
            path: ":id",
            component: ShowQuotationComponent,
            data: { title: "Quotation Details" },
          },
          {
            path: ":id/edit",
            component: EditQuotationComponent,
            data: { title: "Edit Quotation" },
          },
        ],
      },
      {
        path: "billing",
        children: [
          {
            path: "billing",
            children: [
              {
                path: "",
                component: BillingComponent,
                data: { title: "Billings" },
              },
              {
                path: ":id",
                component: BillingDetailsComponent,
                data: { title: "Billing Details" },
              },
            ],
          },
          {
            path: "pending_billing",
            component: PendingBillingComponent,
            data: { title: "Pending Billings" },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, DeactivateGuard],
})
export class WholesalerRoutingModule {}
