<div class="card">
  <div class="card-body p-5">
    <h3 class="text-center mb-0">
      Welcome to the subscription management platform.
    </h3>
    <h5 class="text-center mt-1" *ngIf="lastLoggedIn">
      Your last successful login was on
      {{ lastLoggedIn | date : "dd MMM yyyy": "GMT+9" }} at
      {{ lastLoggedIn | date : "shortTime": "GMT+9" }}
    </h5>
  </div>
</div>
