<div>
  <div class="card">
    <div class="card-body">
      <div class="row justify-content-between">
        <div class="col-sm-12 col-lg-10 mb-2 d-flex justify-content-start align-items-start">
          <div class="form-group w-100">
            <select
              [(ngModel)]="countrySelect"
              [disabled]="isDisable"
              class="form-control w-100"
            >
              <option
                value=""
                [translate]="
                  'placeholder.selectCountry'
                "
                hidden
              >
              </option>
              <option
                [value]="country.code"
                *ngFor="let country of countries"
              >
                {{ country.name }} / {{ country.code }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-12 col-lg-2 mb-2 d-flex justify-content-end align-items-start">
          <button
            class="btn btn-primary float-right"
            [translate]="'buttonTitles.generate'"
            [disabled]="isDisable"
            (click)="onGenerateTaxList()"
          ></button>
        </div>
        <div class="col-sm-12 col-lg-8 mb-2 d-flex justify-content-start align-items-start">
          <app-search-bar
            (handleSearchEmitter)="onSearch($event)"
            [placeholder]="'search' | translate"
            [disabled]="!isGenerated || isDisable"
            class="w-100"
          ></app-search-bar>
        </div>
        <div class="col-sm-6 col-lg-2 mb-2 d-flex justify-content-start align-items-center">
          <div class="custom-control custom-checkbox mb-0">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customCheck1"
              [disabled]="countrySelect === '' || isDisable"
              [(ngModel)]="isDeletedCheck"
            />
            <label class="custom-control-label" for="customCheck1" translate
              >showDeletedTaxes</label
            >
          </div>
        </div>
        <div class="col-sm-6 col-lg-2 mb-2 d-flex justify-content-end align-items-start">
          <button
            class="btn btn-primary float-right"
            [disabled]="!isGenerated || isDisable || isDeleted || companyType === 'CERTIS_BASED'"
            [translate]="'buttonTitles.newCustomerTax'"
            (click)="onClickNewCustomerTax()"
          >
          </button>
        </div>
      </div>
    </div>
  </div>

  <tabset>
    <tab
      heading="GENERAL Company"
      (selectTab)="onSelectedTabs(companyTypeTabSelection.GENERAL)"
      [disabled]="isDisable"
      customClass="fc-tab"
    >
      <app-form-customer-tax-list
        [isLoading]="isLoading"
        [isDeleted]="isDeleted"
        [isDisable]="isDisable"
        [isEdit]="isEdit"
        [isGenerated]="isGenerated"
        [isSubmitting]="isSubmitting"
        [taxList]="taxList"
        [pagination]="pagination"
        [currentEditItem]="currentEditItem"
        (handleTax)="onConfirmation($event)"
        (handleEdit)="editTax($event)"
      ></app-form-customer-tax-list>
    </tab>
    <tab
      heading="CERTIS BASED Company"
      (selectTab)="onSelectedTabs(companyTypeTabSelection.CERTIS_BASED)"
      [disabled]="isDisable"
      customClass="fc-tab"
    >
      <app-form-customer-tax-list
        [isLoading]="isLoading"
        [isDeleted]="isDeleted"
        [isDisable]="isDisable"
        [companyType]="companyType"
        [isGenerated]="isGenerated"
        [isEdit]="isEdit"
        [isSubmitting]="isSubmitting"
        [taxList]="taxList"
        [pagination]="pagination"
        [currentEditItem]="currentEditItem"
        (handleTax)="onConfirmation($event)"
        (handleEdit)="editTax($event)"
      ></app-form-customer-tax-list>
    </tab>
  </tabset>
</div>
