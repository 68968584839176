import { Injectable } from "@angular/core";
import { ServiceUrl } from "./config/service-url";
import { ApiService } from "./config/api.service";
import { catchError, tap } from "rxjs/operators";
import { QuotationStore } from "@stores/_index";
import { Quotation } from "@models/quotation.model";
import { Payload } from "@models/_index";
import { throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class QuotationService {
  serviceUrl = new ServiceUrl();
  url = this.serviceUrl.GET_QUOTATIONS;
  url2 = this.serviceUrl.GET_QUOTATIONS_PRODUCT_BUNDLE

  constructor(
    private quotationStore: QuotationStore,
    private apiService: ApiService
  ) {}

  getQuotations(payload: Payload) {
    return this.apiService.get(this.url, payload).pipe(
      tap((res) => {
        this.quotationStore.update({
          quotations: Quotation.fromArray(res.content),
        });
      })
    );
  }

  getQuotation(payload: Payload) {
    return this.apiService.get(`${this.url}/${payload.id}`, {}).pipe(
      tap((res?) => {
        this.quotationStore.update((state) => ({
          quotation: Object.assign(
            new Quotation(),
            Quotation.fromObject(res)
          ),
        }));
      })
    );
  }

  activateDraftQuotation(payload: Payload) {
    return this.apiService
      .post(`${this.url}/${payload.id}/activate`, {})
      .pipe(tap((res) => {}));
  }

  generatePdfQuotation(payload: Payload) {
    return this.apiService.post(`${this.url}/${payload.id}/pdf`, payload);
  }

  createQuotation(payload?: Payload) {
    return this.apiService.post(this.url, {}).pipe(
      tap((res) => {
        this.quotationStore.update({
          quotation: Object.assign(
            new Quotation(),
            Quotation.fromObject(res)
          ),
        });
      })
    );
  }

  addQuotationItem(payload?: Payload) {
    return this.apiService
      .post(`${this.url}/${payload?.id}/quotation_items`, payload)
      .pipe(
        tap((res) => {
          this.quotationStore.update({
            quotation: Object.assign(
              new Quotation(),
              Quotation.fromObject(res)
            ),
          });
        })
      );
  }

  updateQuotationItem(payload: Payload, quotationItemId: string) {
    return this.apiService
      .putCustomUrl(
        `${this.url}/${payload?.id}/quotation_items/${quotationItemId}`,
        payload
      )
      .pipe(
        tap((res) => {
          this.quotationStore.update({
            quotation: Object.assign(
              new Quotation(),
              Quotation.fromObject(res)
            ),
          });
        })
      );
  }

  deleteQuotationItem(payload: Payload, quotationItemId: string) {
    return this.apiService
      .deleteCustomUrl(
        `${this.url}/${payload?.id}/quotation_items/${quotationItemId}`
      )
      .pipe(
        tap((res) => {
          this.quotationStore.update({
            quotation: Object.assign(
              new Quotation(),
              Quotation.fromObject(res)
            ),
          });
        })
      );
  }

  createContactDetails(payload: Payload) {
    return this.apiService
      .post(`${this.url}/${payload?.id}/contact_details`, payload)
      .pipe(
        tap((res) => {
          this.quotationStore.update({
            quotation: Object.assign(
              new Quotation(),
              Quotation.fromObject(res)
            ),
          });
        })
      );
  }

  createQuotationStep(payload: Payload) {
    return this.apiService
      .post(
        `${this.url}/${payload?.id}/quotation_details`,
        payload
      )
      .pipe(
        tap((res) => {
          this.quotationStore.update({
            quotation: Object.assign(
              new Quotation(),
              Quotation.fromObject(res)
            ),
          });
        })
      );
  }

  addProductBundleQuotationItem(payload: Payload) {
    return this.apiService
      .put(`${this.url2}`, payload)
      .pipe(
        tap((res: any) => {})
      );
  }

  deleteQuotation(payload: Payload) {
    return this.apiService.delete(`${this.url}/${payload.id}`);
  }

  selectQuotation(id: string) {
    this.quotationStore.update({
      selectedQuotation: id,
    });
  }

  createQuotationProductBundle(payload: Payload) {
    return this.apiService.post(`${this.url2}/quotation/${payload.id}`, payload).pipe(
      tap(
        (res: any) => {}, 
        catchError((error) => {
          return throwError(error);
        })
      )
    )
  }

  getProductBundleByQuotationId(payload: Payload) {
    return this.apiService.get(`${this.url2}/quotation`, payload).pipe(
      tap(
        res => {}, 
        catchError(error => {
          return throwError(error);
        })
      )
    )
  }

  deleteQuotationProductBundle(payload: Payload) {
    return this.apiService.delete(`${this.url2}`, payload).pipe(
      tap(
        res => {},
        catchError(error => {
          return throwError(error)
        })
      )
    )
  }

  updateQuotationProductBundle(payload: Payload) {
    return this.apiService.put(`${this.url2}`, payload).pipe(
      tap(
        (res: any) => {},
        catchError(error => {
          return throwError(error)
        })
      )
    )
  }
}
