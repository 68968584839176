import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  QuotationItem,
  PartPriceItem,
  Product,
  CurrencyPriceItem,
  Quotation,
  ProductBundle,
  ProductBundlePriceItem,
} from "@models/_index";
import { QuotationService } from "@services/_index";
import { ProductBundleService } from "@services/product-bundle.service";

@Component({
  selector: "app-form-product-detail",
  templateUrl: "./form-product-detail.component.html",
  styleUrls: ["./form-product-detail.component.scss"],
})
export class FormProductDetailComponent implements OnInit {
  @Input() item: QuotationItem;
  @Input() partPriceItems: PartPriceItem[];
  @Input() quotation: Quotation;
  @Input() products: Product[];
  @Input() isShowingDetails: boolean;
  @Input() isLoading: boolean = false;
  @Input() productBundleSelection: ProductBundle[] = [];
  @Input() quotationItemIdInProductBundle: string[] = []
  @Output() cancelEmitter: EventEmitter<Boolean> = new EventEmitter();
  @Output() saveEmitter: EventEmitter<QuotationItem> = new EventEmitter();
  @Output() deleteEmitter: EventEmitter<any> = new EventEmitter();
  public productBundleList: ProductBundle[] = []
  ngOnInit(): void {
    this.loadOriginalProductBundle()
  }

  loadOriginalProductBundle(): void {
    this.productBundleList = this.productBundleSelection.filter(pb => {
      const bundleItem = this.quotation.bundleItems.find(bi => bi.productBundleId === pb.id)
      return bundleItem ? true : false
    })
  }

  onCancelQuotationItem(): void {
    this.cancelEmitter.emit();
  }

  onSaveQuotationItem(item: QuotationItem): void {
    this.saveEmitter.emit(item);
  }

  onSelectProduct(productId: string, quotationItem: QuotationItem): void{
    const partPriceItem = this.getAvailablePartPriceItems(productId)[0];
    quotationItem.partPricingId = partPriceItem.id;
    this.onSelectPartPriceItem(quotationItem.partPricingId, quotationItem);
  }

  onSelectPartPriceItem(partPricingId: string, quotationItem: QuotationItem): void {
    quotationItem.quantity =
      this.getPartPriceItem(quotationItem).minimumQuantity;
    quotationItem.recurrenceType =
      this.getAvailableRecurrenceTypes(partPricingId)[0];
    this.updatePrice(quotationItem);
  }

  updatePrice(quotationItem: QuotationItem): void {
    if (!quotationItem.partPricingId.length) {
      return;
    }
    const priceItem = this.getSameCurrencyPrice(quotationItem);
    quotationItem.price = priceItem?.price;
    quotationItem.amount = parseFloat(
      this.getAmountOfQuotationItem(quotationItem)
    );
  }

  getSameCurrencyPrice(quotationItem: QuotationItem): CurrencyPriceItem  {
    const partPriceItem = this.getPartPriceItem(quotationItem);
    return partPriceItem?.priceItems.find(
      (price) =>
        price?.currency === this.quotation?.currency &&
        price?.recurrenceType === quotationItem?.recurrenceType
    );
  }

  getAvailablePartPriceItems(productId: string): PartPriceItem[] {
    return [
      ...this.partPriceItems.filter(
        (item) => item?.productPart?.product?.id === productId
      ),
    ];
  }

  getAmountOfQuotationItem(quotationItem: QuotationItem): string {
    return (
      quotationItem.quantity *
      (quotationItem.price - quotationItem.discountAmount)
    ).toFixed(2);
  }

  getAvailableRecurrenceTypes(partPricingId: string): ("UPFRONT" | "QUARTERLY" | "ANNUALLY" | "MONTHLY")[] {
    if (partPricingId) {
      const types = [
        ...this.partPriceItems
          .find((item) => item.id === partPricingId)
          ?.priceItems.map((price) => price?.recurrenceType),
      ];
      return types.filter(
        (type) =>
          type === this.quotation.billingCycle || type === "UPFRONT"
      );
    }
  }

  isQuantityValid(item: QuotationItem): Boolean {
    if(this.quotationItemIdInProductBundle.includes(item.id)){
      return this.isQuantityProductBundleValid(item)
    }else{
      const minimumQuantity = this.getMinimumQuantity(item);
      return item?.partPricingId ? item?.quantity >= minimumQuantity : true;
    }
  }

  isQuantityProductBundleValid(item: QuotationItem): Boolean {
    if(this.productBundleList.length === 0) return false

    const minimumQuantity = this.getMinimumProductBundleQuantity(item)
    const maximumQuantity = this.getMaximumProductBundleQuantity(item)
    return minimumQuantity >= item.quantity && item.quantity >= maximumQuantity
  }

  getFilterPartPriceItem(item: QuotationItem): ProductBundlePriceItem {
    const filterBundleItems = this.quotation.bundleItems.find(i => {
      const ppi = i.quotationItems.find(qi => qi.id === item.id)
      return ppi ? true : false
    })
    const pb = this.productBundleList.find(i => i.id === filterBundleItems.productBundleId)
    return pb.partPriceItems.find(i => i.partPriceItemId === item.partPricingId)
  }

  getMinimumProductBundleQuantity(item: QuotationItem): number {
    const partPriceItem = this.getFilterPartPriceItem(item)
    if(!partPriceItem) return
    return partPriceItem.minimumQuantity
  }

  getMaximumProductBundleQuantity(item: QuotationItem): number {
    const partPriceItem = this.getFilterPartPriceItem(item)
    if(!partPriceItem) return
    return partPriceItem.maximumQuantity
  }
  
  getMinimumQuantity(item: QuotationItem): number {
    const partPriceItem = this.partPriceItems.find(
      (i) => i.id === item?.partPricingId
    );
    return partPriceItem?.minimumQuantity;
  }

  isDiscountAmountInvalid(item: QuotationItem): Boolean {
    return (
      this.isDiscountLargerThanSetting(item) ||
      this.isDiscountFormatInvalid(item)
    );
  }

  isDiscountLargerThanSetting(item: QuotationItem): Boolean {
    const sameCurrencyItem = this.getSameCurrencyPrice(item);
    return item?.discountAmount > sameCurrencyItem?.maxPriceDiscount;
  }

  isDiscountFormatInvalid(item: QuotationItem): Boolean {
    const discount = item.discountAmount.toString();
    const regex = new RegExp("^\\d*(\\.\\d[0]?)?$");
    return !regex.test(discount);
  }

  isQuotationItemInvalid(quotationItem: QuotationItem): Boolean {
    return quotationItem.partPricingId.length === 0;
  }

  getPartPriceItem(item: QuotationItem): PartPriceItem {
    return this.partPriceItems.find(
      (_item) => _item.id === item?.partPricingId
    );
  }

  getDiscountAmount(item: QuotationItem): number {
    return this.getSameCurrencyPrice(item)?.maxPriceDiscount;
  }
}
