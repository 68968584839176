<div class="row" *ngIf="!isLoading">
  <div class="col-6 col-lg-3">
    <app-dashboard-widget
      [amount]="products"
      title="Products"
      icon="icon-social-dropbox"
      bgColor="bg-primary"
      linkTo="product/product"
      textColor="text-primary"
      *ngIf="isShowProductsStas"
    ></app-dashboard-widget>
  </div>
  <div class="col-6 col-lg-3">
    <app-dashboard-widget
      [amount]="customers"
      title="Customers"
      icon="icon-people"
      bgColor="bg-danger"
      linkTo="customer"
      textColor="text-danger"
      *ngIf="isShowCustomersStas"
    ></app-dashboard-widget>
  </div>
  <div class="col-6 col-lg-3">
    <app-dashboard-widget
      [amount]="subscriptions"
      title="Subscriptions"
      icon="icon-credit-card"
      bgColor="bg-warning"
      linkTo="subscription"
      textColor="text-warning"
      *ngIf="isShowSubscriptionsStas"
    ></app-dashboard-widget>
  </div>
  <div class="col-6 col-lg-3">
    <app-dashboard-widget
      [amount]="billings"
      title="Exported Billings"
      icon="icon-paper-clip"
      bgColor="bg-info"
      linkTo="billing/billing"
      textColor="text-info"
      *ngIf="isShowBillingsStas"
    ></app-dashboard-widget>
  </div>
</div>
