<div
  class="card card-quotation border-0 mb-0 border-radius-bottom-0"
  *ngIf="isShowing"
  @inOutPaneAnimation
>
  <div class="card-body">
    <div class="row justify-content-between">
      <div class="col-xs-12 col-lg-6">
        <div class="form-group">
          <label class="required" translate>quotationId</label>
          <input
            type="text"
            class="form-control"
            name="quotationCode"
            #quotationId
            [(ngModel)]="quotation.quotationCode"
            disabled="true"
            required
          />
        </div>

        <div class="form-group">
          <label class="required" translate>currency</label><br />
          <select
            [disabled]="isPartPriceItemExisted() || isShowingDetails"
            class="form-control"
            name="currency"
            #currency
            [(ngModel)]="quotation.currency"
          >
            <option
              value=""
              [translate]="'placeholder.selectCurrency'"
              hidden
            ></option>
            <option *ngFor="let currency of currencies" [value]="currency">
              {{ currency }}
            </option>
          </select>
          <small
            *ngIf="isPartPriceItemExisted() || isShowingDetails"
            class="text-danger"
            translate
          >
            validateMessages.isPartPriceItemExisted</small
          >
        </div>

        <div class="form-group">
          <label class="required" translate>customer</label>
          <ng-select
            [items]="customers"
            [loading]="isCustomerLoading"
            [(ngModel)]="quotation.accountId"
            name="accountId"
            #accountId="ngModel"
            bindLabel="company.name"
            bindValue="id"
            placeholder="{{ 'placeholder.selectCustomer' | translate }}"
            appendTo="body"
            virtualScroll="true"
            (scroll)="onScrollCustomerToEnd($event)"
            (search)="onSearchCustomer($event.term)"
            required
            [ngClass]="{
              'is-invalid': isSubmitted && accountId.invalid
            }"
            [disabled]="isShowingDetails"
            (change)="onChangeCustomer(accountId.value)"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ item?.company?.name }}
            </ng-template>
          </ng-select>
          <div
            *ngIf="isSubmitted && accountId.invalid"
            class="d-block invalid-feedback"
          >
            <div *ngIf="accountId.errors.required" translate>
              validateMessages.accountId
            </div>
          </div>
        </div>

        <div class="form-group">
          <label translate>tax</label><br />
          <input
            type="number"
            class="form-control"
            name="tax"
            #tax="ngModel"
            [(ngModel)]="quotation.tax"
            [ngClass]="{
              'is-invalid': isSubmitted && tax.invalid
            }"
            disabled
          />
        </div>

        <div class="form-group">
          <label translate>salesMode</label>
          <select
            class="form-control"
            name="salesMode"
            #salesMode
            [(ngModel)]="quotation.salesMode"
            [disabled]="isShowingDetails"
            (change)="onChangeSalesMode($event)"
          >
            <option
              value=""
              [translate]="'placeholder.salesMode'"
              hidden
            ></option>
            <option [value]="mode" *ngFor="let mode of modes">
              {{ mode }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label translate>paymentTerm</label>
          <select
            class="form-control" 
            name="paymentTerm"
            #paymentTerm
            [(ngModel)]="quotation.paymentTerm"
            [disabled]="isShowingDetails"
          >
            <option [ngValue]="term.value" *ngFor="let term of terms">
              {{ term.context }}
            </option>
          </select>
        </div>

        <div class="form-group" *ngIf="quotation.salesMode !== 'DIRECT'">
          <label *ngIf="quotation.salesMode === 'WHOLESALER'" translate
            >wholesaler</label
          >
          <label *ngIf="quotation.salesMode === 'RESELLER'" translate
            >reseller</label
          >
          <ng-select
            [items]="accounts"
            [loading]="isAccountLoading"
            [(ngModel)]="quotation.reseller"
            name="reseller"
            #reseller="ngModel"
            bindLabel="company.name"
            bindValue="id"
            [placeholder]="
              quotation.salesMode === 'WHOLESALER'
                ? 'Please select Wholesaler'
                : 'Please select Reseller'
            "
            appendTo="body"
            virtualScroll="true"
            (scroll)="onScrollAccountToEnd($event)"
            (search)="onSearchAccount($event.term)"
            [disabled]="isShowingDetails"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ item?.company?.name }}
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col-xs-12 col-lg-6">
        <div class="form-group">
          <label class="required" translate>date</label>
          <input
            type="text"
            class="form-control"
            bsDatepicker
            name="quotationDate"
            [(ngModel)]="quotationDate"
            [bsConfig]="{
              dateInputFormat: 'DD-MM-YYYY',
              containerClass: 'theme-blue'
            }"
            (ngModelChange)="onSelectQuotationDate($event)"
            required
            [ngClass]="{
              'is-invalid': isSubmitted && quotationDate.invalid
            }"
            [disabled]="isShowingDetails"
          />
          <div
            *ngIf="isSubmitted && quotationDate.invalid"
            class="invalid-feedback"
          >
            <div *ngIf="quotationDate.errors.required" translate>
              validateMessages.quotationDate
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="required" translate>quotationExpiration</label>
          <input
            type="text"
            class="form-control"
            bsDatepicker
            name="quotationExpiration"
            [(ngModel)]="quotationExpiration"
            [bsConfig]="{
              dateInputFormat: 'DD-MM-YYYY',
              containerClass: 'theme-blue'
            }"
            (bsValueChange)="onSelectQuotationExpiration($event)"
            required
            [ngClass]="{
              'is-invalid': isSubmitted && quotationExpiration.invalid
            }"
            [disabled]="isShowingDetails"
          />
          <div
            *ngIf="isSubmitted && quotationExpiration.invalid"
            class="invalid-feedback"
          >
            <div *ngIf="quotationExpiration.errors.required" translate>
              validateMessages.quotationExpiration
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="required" translate
                >subscriptionStartDate</label
              >
              <input
                type="text"
                class="form-control"
                bsDatepicker
                name="subscriptionDate"
                [(ngModel)]="subscriptionDate"
                [bsConfig]="{
                  dateInputFormat: 'DD-MM-YYYY',
                  containerClass: 'theme-blue'
                }"
                (bsValueChange)="onSelectSubscriptionDate($event)"
                required
                [ngClass]="{
                  'is-invalid': isSubmitted && subscriptionDate.invalid
                }"
                [disabled]="isShowingDetails"
              />
              <div
                *ngIf="isSubmitted && subscriptionDate.invalid"
                class="invalid-feedback"
              >
                <div *ngIf="subscriptionDate.errors.required" translate>
                  validateMessages.subscriptionDate
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="required" translate>subscriptionEndDate</label>
              <input
                type="text"
                class="form-control"
                bsDatepicker
                name="subscriptionEndDate"
                [ngModel]="subscriptionEndDate"
                [bsConfig]="{
                  dateInputFormat: 'DD-MM-YYYY',
                  containerClass: 'theme-blue'
                }"
                [disabled]="true"
              />
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="required" translate>billingCycle</label>
          <select
            class="form-control"
            name="billingCycle"
            #billingCycle="ngModel"
            required
            [(ngModel)]="quotation.billingCycle"
            [ngClass]="{
              'is-invalid': isSubmitted && billingCycle.invalid
            }"
            (change)="onChangeBillingCycle($event.target.value)"
            [disabled]="isPartPriceItemExisted() || isShowingDetails"
          >
            <option
              value="null"
              [translate]="'placeholder.selectBillingCycle'"
              hidden
            ></option>
            <option [value]="cycle" *ngFor="let cycle of billingCycles">
              {{ cycle }}
            </option>
          </select>
          <div
            *ngIf="isSubmitted && billingCycle.invalid"
            class="invalid-feedback"
          >
            <div *ngIf="billingCycle.errors.required" translate>
              Billing Cycle is required
            </div>
          </div>
          <small
            *ngIf="isPartPriceItemExisted() || isShowingDetails"
            class="text-danger"
            translate
          >
            validateMessages.billingCycleCantChange</small
          >
        </div>

        <div class="form-group">
          <label class="required" translate>termLength</label>
          <div class="custom-slider">
            <ngx-slider
              [(value)]="quotation.termLength"
              [options]="options"
              (mouseup)="onChangeTermLength()"
            ></ngx-slider>
          </div>
        </div>

        <div class="form-group">
          <label translate class="required">name</label>
          <input
            type="text"
            class="form-control"
            name="salespersonName"
            #salespersonName="ngModel"
            placeholder="{{ 'placeholder.salesPersonName' | translate }}"
            required
            [ngClass]="{
              'is-invalid': isSubmitted && salespersonName.invalid
            }"
            [(ngModel)]="quotation.salespersonName"
            [disabled]="isShowingDetails"
          />
          <div
            *ngIf="isSubmitted && salespersonName.invalid"
            class="invalid-feedback"
          >
            <div *ngIf="salespersonName.errors.required" translate>
              validateMessages.salespersonName
            </div>
          </div>
        </div>
        <div class="form-group">
          <label translate class="required">contactNumber</label>
          <input
            type="text"
            pattern="[- +()0-9]+"
            class="form-control"
            name="salespersonContactNumber"
            #salespersonContactNumber="ngModel"
            required
            placeholder="{{
              'placeholder.salesPersonContactNumber' | translate
            }}"
            [ngClass]="{
              'is-invalid': isSubmitted && salespersonContactNumber.invalid
            }"
            [(ngModel)]="quotation.salespersonContactNumber"
            [disabled]="isShowingDetails"
          />
          <div
            *ngIf="isSubmitted && salespersonContactNumber.invalid"
            class="invalid-feedback"
          >
            <div
              *ngIf="salespersonContactNumber.errors.required"
              translate
            >
              validateMessages.salespersonContactNumber
            </div>
            <div *ngIf="salespersonContactNumber.errors.pattern" translate>
              validateMessages.phoneNumberFormat
            </div>
          </div>
        </div>
        <div class="form-group">
          <label translate class="required">email</label>
          <input
            type="email"
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
            class="form-control"
            name="salespersonEmail"
            #salespersonEmail="ngModel"
            required
            placeholder="{{ 'placeholder.salesPersonEmail' | translate }}"
            [ngClass]="{
              'is-invalid': isSubmitted && salespersonEmail.invalid
            }"
            [(ngModel)]="quotation.salespersonEmail"
            [disabled]="isShowingDetails"
          />
          <div
            *ngIf="isSubmitted && salespersonEmail.invalid"
            class="invalid-feedback"
          >
            <div *ngIf="salespersonEmail.errors.required" translate>
              validateMessages.salespersonEmail
            </div>
            <div *ngIf="salespersonEmail.errors.pattern" translate>
              validateMessages.authorizedEmailFormat
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
