import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";

import { SubscriptionService, AlertService } from "@services/_index";
import { NgxSpinnerService } from "ngx-bootstrap-spinner";

@Component({
  selector: "app-edit-attachment-subscription",
  templateUrl: "./edit-attachment-subscription.component.html",
  styleUrls: ["./edit-attachment-subscription.component.scss"],
})
export class EditAttachmentSubscriptionComponent implements OnInit {
  @Input() subscriptionId: string = "";

  @ViewChild("editAttachmentSubscriptionModal", { static: false })
  public editAttachmentSubscriptionModal: ModalDirective;
  public isLoading: Boolean = false;
  files = [];
  filesAdd: File[] = [];

  constructor(
    private subscriptionService: SubscriptionService,
    private alertService: AlertService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {}

  onShowModal() {
    this.editAttachmentSubscriptionModal.show();
    this.getListFiles();
  }

  getListFiles() {
    this.isLoading = true;
    this.subscriptionService
      .getSubscriptionAttachments(this.subscriptionId)
      .subscribe(
        (res) => {
          this.files = res;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.alertService.error(err?.error?.message);
        }
      );
  }

  base64Generate(id, index, base64String) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${this.files[index].filename}`;
    link.click();
  }

  onDownloadFile(id: string, index: number) {
    this.spinner.show();
    this.subscriptionService.downloadFileAttachment(id).subscribe(
      (res: any) => {
        this.base64Generate(id, index, res?.file);
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.alertService.errors(err?.error?._embedded?.errors);
      }
    );
  }

  onDeleteFile(id: string) {
    this.spinner.show();
    this.subscriptionService.deleteFileAttachment(id).subscribe(
      (res) => {
        this.alertService.success("Deleted file successfully");
        this.getListFiles();
        this.spinner.hide();
      },
      (err) => {
        this.alertService.error(err?.error.message);
        this.spinner.hide();
      }
    );
  }

  onSelect(event) {
    if (event.addedFiles.length + this.files.length > 5) {
      this.alertService.error("Only up to 5 files allowed at once.");
    } else {
      this.spinner.show();
      this.filesAdd.push(...event.addedFiles);
      const payload = {
        files: this.filesAdd,
      };
      this.subscriptionService
        .addNewAttachment(this.subscriptionId, { payload: payload })
        .subscribe(
          (res) => {
            this.alertService.success("Added files successfully");
            this.getListFiles();
            this.filesAdd = [];
            this.spinner.hide();
          },
          (err) => {
            this.alertService.error(err?.error?.message);
            this.filesAdd = [];
            this.spinner.hide();
          }
        );
    }
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }
}
