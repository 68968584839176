import { Injectable } from "@angular/core";
import { ServiceUrl } from "./config/service-url";
import { ApiService } from "./config/api.service";
import { environment } from "environments/environment";
import { Payload } from "@models/_index"
@Injectable({
  providedIn: "root",
})
export class UserGroupService {
  serviceUrl = new ServiceUrl();

  url = `${environment.iamBaseUrl}/smp-iam-svc/usergroup`;

  constructor(private apiService: ApiService) {}

  getUserGroups(payload?: Payload) {
    return this.apiService.getWithoutBaseUrl(`${this.url}`, payload);
  }

  deleteUserGroup(id: string) {
    return this.apiService.deleteWithoutBaseUrl(`${this.url}/${id}`, {});
  }

  getUserGroup(id: string) {
    return this.apiService.getWithoutBaseUrl(`${this.url}/${id}`, {});
  }

  createUserGroup(payload: Payload) {
    return this.apiService.postWithoutBaseUrl(`${this.url}`, payload);
  }

  updateUserGroup(id: string, payload: Payload) {
    return this.apiService.putWithoutBaseUrl(`${this.url}/${id}`, payload);
  }
}
