import { UserGroup } from "./user-group.model";
import { Role } from "./role.model";
export class User {
  accountId?: string;
  avatarUrl?: string;
  displayName?: string;
  email?: string;
  expiry?: number;
  firstName?: string;
  id: string;
  lastName?: string;
  phoneNumber?: string;
  roles: Role[] = [];
  role?: Role[] = [];
  userGroupIds?: Array<Object>;
  username?: string;
  password?: string;
  userGroups?: UserGroup[] = [];
  confirmPassword?: string;
  lastLoggedIn?: string;
}
