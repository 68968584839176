<div class="modal-body text-center">
  <p>{{ content }}</p>
  <button
    type="button"
    class="btn btn-default"
    (click)="onOk()"
    [ladda]="isLoading"
    data-style="zoom-in"
  >
    OK
  </button>
  <button type="button" class="btn btn-primary" (click)="onClose()">
    Cancel
  </button>
</div>
