import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServiceUrl } from "./config/service-url";
import { ActionStore } from "@stores/_index";
import { tap } from "rxjs/operators";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class ActionService {
  private serviceUrl = new ServiceUrl();
  private baseUrl = environment.iamBaseUrl;

  constructor(
    private store: ActionStore,
    private http: HttpClient
  ) {}

  getActions() {
    return this.http
      .get<any>(`${this.baseUrl}/${this.serviceUrl.GET_ACTIONS}`)
      .pipe(
        tap((res) => {
          this.store.update({
            actions: res.data,
          });
        })
      );
  }
}
