import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-sortable-header",
  templateUrl: "./sortable-header.component.html",
  styleUrls: ["./sortable-header.component.scss"],
})
export class SortableHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() isSortedAsc: boolean;
  @Input() isSortedDesc: boolean;
  @Output() handleSort: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClickListener() {
    this.handleSort.emit();
  }
}
