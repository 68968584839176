import { Component, OnInit } from "@angular/core";
import { CustomerTax } from "@models/_index";
import { Country } from "country-state-city";
import { CompanyType } from "@models/account.model";
import { TaxService } from "@services/tax.service";
import { AlertService } from "@services/alert.service";
import {
  BsModalRef,
  BsModalService,
  ModalOptions
} from "ngx-bootstrap/modal";
import { ModalComponent } from "@shared/components/modal/modal.component";
import { format } from "date-fns";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-customer-tax",
  templateUrl: "./customer-tax.component.html",
  styleUrls: ["./customer-tax.component.scss"],
})
export class CustomerTaxComponent implements OnInit {
  public taxList: CustomerTax[] = [];
  public countries: { name: string; code: string }[] = [];
  public companyType: CompanyType = CompanyType.GENERAL;
  public companyTypeTabSelection: typeof CompanyType = CompanyType;

  public countrySelect: string = "";

  public isEdit: Boolean = false
  public isGenerated: Boolean = false
  public isDisable: Boolean = false
  public isLoading: Boolean = false;
  public isSubmitting: Boolean = false
  public currentChoosingCountry: string = ""
  public currentEditItem: number = -1
  public isDeletedCheck: Boolean = false;
  public isDeleted: Boolean = false;

  public searchKey: string = "";
  public pagination: {
    sortDirection: "ASC" | "DESC";
    sortBy: string
  } = {
    sortDirection: "ASC",
    sortBy: ""
  }
  public bsModalRef?: BsModalRef;

  constructor(
    private taxService: TaxService,
    private alertService: AlertService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.countries = Country.getAllCountries().map(
      country => { return {
        name: country.name,
        code: country.isoCode,
      }}
    );
  }

  onSearch(search: string): void {
    this.searchKey = search;
    this.setTaxList();
  }

  onGenerateTaxList(): void {
    if(this.countrySelect.length === 0) return
    this.isGenerated = true
    this.setTaxList();
  }

  setTaxList(): void {
    this.taxList = [];
    this.getTaxList();
  }

  onConfirmation(data: {
    tax: CustomerTax;
    confirmType: "RELOAD_TYPE" | "DELETE_TYPE" | "REVERT_TYPE" | "REMOVE_TYPE" | "SAVE_TYPE" | "UPDATE_TYPE";
  }): void {
    switch(data.confirmType) {
      case "DELETE_TYPE": {
        this.isConfirmation("delete", () => this.onDeleteTax(data.tax))
        break
      }
      case "REMOVE_TYPE": {
        this.isConfirmation("delete", () => this.onRemoveTax(data.tax))
        break
      }
      case "REVERT_TYPE": {
        this.isConfirmation("revert", () => this.onRevertTax(data.tax))
        break
      }
      case "UPDATE_TYPE": {
        this.onUpdateTax()
        break
      }
      case "SAVE_TYPE": {
        this.onSaveTax()
        break
      }
      case "RELOAD_TYPE": {
        this.isLoading = true;
        this.taxList = []
        this.getTaxList()
        break
      }
    }
  }

  onUpdateTax(): void {
    this.isSubmitting = true
    this.taxService.updateTax({
      id: this.taxList[this.currentEditItem].id,
      payload: this.taxList[this.currentEditItem]
    }).subscribe(
      (res: any) => {
        if(res.statusCode === 200){
          this.alertService.success("Successfully creating new tax!")
          this.taxList[this.currentEditItem] = res.data
          this.resetToDefault()
          return
        }
        this.isSubmitting = false
        this.alertService.error("Something went wrong");
      },
      (err: HttpErrorResponse) => {
        if(err.error){
          this.alertService.error(err.error.message)
        }else {
          this.alertService.error("Tax rate could not be updated/added. Each tax rate must have an unique effective date and tax code. Please enter valid value.")
        }
        this.isSubmitting = false
      }
    )
  }

  onSaveTax(): void{
    this.isSubmitting = true
    this.taxService.createTax({
      payload: this.taxList[this.currentEditItem]
    }).subscribe(
      (res: any) => {
        if(res.statusCode === 200){
          this.alertService.success("Successfully creating new tax!")
          this.taxList[this.currentEditItem] = res.data
          this.resetToDefault()
          return
        }
        this.isSubmitting = false
        this.alertService.error("Something went wrong");
      },
      (err: any) => {
        if(err.error){
          this.alertService.error(err.error.message)
        }else {
          this.alertService.error("Tax rate could not be updated/added. Each tax rate must have an unique effective date and tax code. Please enter valid value.")
        }
        this.isSubmitting = false
      }
    )
  }

  onRemoveTax(tax: CustomerTax): void {
    this.taxList.splice(this.taxList.indexOf(tax), 1)
    this.bsModalRef.hide();
    this.currentEditItem = -1
    this.isDisable = false
  }

  onDeleteTax(tax: CustomerTax): void {
    this.taxService.deleteTax({ id: tax.id }).subscribe(() => {
      this.bsModalRef.hide();
      this.alertService.success("Successfully deleted tax");
      this.setTaxList();
    });
  }

  onRevertTax(tax: CustomerTax): void {
    this.taxService.revertTax({ id: tax.id }).subscribe(() => {
      this.bsModalRef.hide();
      this.alertService.success("Successfully deleted tax");
      this.setTaxList();
    });
  }

  resetToDefault() {
    this.isSubmitting = false
    this.currentEditItem = -1
    this.isDisable = false
    this.isEdit = false
  }

  isConfirmation(action: string, callBackFunction: () => void): void {
    const initialState: ModalOptions = {
      initialState: {
        title: `Do you want to ${action} this item?`,
        content: `Are you sure you want to ${action} this tax rate?`,
      },
      animated: false,
    };
    this.bsModalRef = this.modalService.show(ModalComponent, initialState);
    this.bsModalRef.content.eventEmitter.subscribe(
      (isOk: Boolean) =>  isOk ? callBackFunction() : this.bsModalRef.hide()
    );
  }

  onClickNewCustomerTax(): void {
    const newTax = new CustomerTax()
    newTax.country = this.currentChoosingCountry
    newTax.startDate = format(new Date(), "YYYY-MM-DD")
    newTax.taxRate = 0
    newTax.taxCode = ""
    this.taxList.unshift(newTax)
    this.currentEditItem = 0
    this.isDisable = true
  }

  taxListFilter(taxList: CustomerTax[]): CustomerTax[] {
    if (this.searchKey.length <= 0) return taxList;
    taxList = taxList.filter(
      (tax) =>
        tax.country.toLowerCase().includes(this.searchKey.toLowerCase()) ||
        tax.taxCode.toLowerCase().includes(this.searchKey.toLowerCase())
    );
    return taxList.length === 0 ? [] : taxList
  }

  onSelectedTabs(value: CompanyType): void {
    this.companyType = value;
    this.setTaxList();
  }

  getTaxList(): void {
    if(this.countrySelect.length === 0 && this.companyType === CompanyType.GENERAL) return
    this.isLoading = true;
    this.taxService.getTaxList({ params: {
      country: this.countrySelect,
      companyType: this.companyType,
      isDelete: this.isDeletedCheck,
      sort: this.pagination.sortBy + "," + this.pagination.sortDirection,
    }}).subscribe(
      res => {
        this.isLoading = false;

        if (!(res || res.data || res.data.content )) return

        this.currentChoosingCountry = this.countrySelect
        this.taxList = this.taxListFilter(res.data.content);
        this.isDeleted = this.isDeletedCheck
    },
      err => {
        console.log(err)
        this.alertService.error("Something went wrong!")
      }
    );
  }

  isShowTable(): Boolean {
    return this.countrySelect !== '' && this.isGenerated
  }

  editTax(index: number): void {
    this.currentEditItem = index
    if(this.currentEditItem === -1){
      this.resetToDefault()
    }else{
      this.isDisable = true
      this.isEdit = true
    }
  }
}
