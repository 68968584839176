import { Query } from "@datorama/akita";
import { PolicyState, PolicyStore } from "./policy.store";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PolicyQuery extends Query<PolicyState> {
  constructor(protected store: PolicyStore) {
    super(store);
  }

  get getPolicies() {
    return this.getValue().policies;
  }

  get getPolicy() {
    return this.getValue().policy;
  }
}
