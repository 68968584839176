<div>
  <div class="btn-group" dropdown [insideClick]="true" placement="bottom right" style="margin-left: 0.7rem">
    <button
      id="button-alignment"
      dropdownToggle
      type="button"
      class="btn btn-primary btn-dropdown-toggle"
      aria-controls="dropdown-alignment"
    >
      <i class="fa fa-filter"></i>
    </button>
    <ul
      id="dropdown-alignment"
      *dropdownMenu
      class="dropdown-menu p-2 dropdown-menu-right"
      role="menu"
      aria-labelledby="button-alighment"
    >
      <li role="menuitem" class="mb-1">
        <div class="custom-control custom-checkbox">
          <input
            class="custom-control-input"
            type="checkbox"
            id="includeExpired"
            [(ngModel)]="includedExpired"
            (change)="onFilterSubscription($event)"
          />
          <label class="custom-control-label" for="includeExpired"
            >Include Expired</label
          >
        </div>
      </li>
      <li role="menuItem">
        <div class="custom-control custom-checkbox">
          <input
            class="custom-control-input"
            type="checkbox"
            id="includeFuture"
            [(ngModel)]="includedFuture"
            (change)="onFilterSubscription($event)"
          />
          <label class="custom-control-label" for="includeFuture"
            >Include Future</label
          >
        </div>
      </li>
    </ul>
  </div>
</div>