<div
  class="card border-0 mb-0 border-radius-bottom-0"
  *ngIf="isShowing"
  @inOutPaneAnimation
>
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-lg-6">
        <h5><strong translate>salesContact</strong></h5>
        <div class="form-group">
          <label translate for="personInCharge">personInCharge</label>
          <input
            placeholder="{{
              'placeholder.salesPersonInCharge' | translate
            }}"
            type="text"
            id=""
            class="form-control"
            name=""
            required
            [(ngModel)]="quotation.salesContact.personInCharge"
            [disabled]="isShowingDetails"
          />
        </div>

        <div class="form-group">
          <label translate for="contactNumber">contactNumber</label>
          <input
            placeholder="{{
              'placeholder.salesContactNumber' | translate
            }}"
            type="text"
            id=""
            class="form-control"
            name=""
            required
            [(ngModel)]="quotation.salesContact.contactNumber"
            [disabled]="isShowingDetails"
          />
        </div>

        <div class="form-group">
          <label translate for="Email">Email</label>
          <input
            placeholder="{{ 'placeholder.salesEmail' | translate }}"
            type="text"
            id=""
            class="form-control"
            name=""
            required
            [(ngModel)]="quotation.salesContact.email"
            [disabled]="isShowingDetails"
          />
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <h5>
          <strong translate for="billingContact">billingContact</strong>
        </h5>
        <div class="form-group">
          <label translate for="personInCharge">personInCharge</label>
          <input
            placeholder="{{
              'placeholder.billingPersonInCharge' | translate
            }}"
            type="text"
            id=""
            class="form-control"
            name=""
            required
            [(ngModel)]="quotation.billingContact.personInCharge"
            [disabled]="isShowingDetails"
          />
        </div>

        <div class="form-group">
          <label translate for="contactNumber">contactNumber</label>
          <input
            placeholder="{{
              'placeholder.billingContactNumber' | translate
            }}"
            type="text"
            id=""
            class="form-control"
            name=""
            required
            [(ngModel)]="quotation.billingContact.contactNumber"
            [disabled]="isShowingDetails"
          />
        </div>

        <div class="form-group">
          <label translate for="email">email</label>
          <input
            placeholder="{{ 'placeholder.billingEmail' | translate }}"
            type="text"
            id=""
            class="form-control"
            name=""
            required
            [(ngModel)]="quotation.billingContact.email"
            [disabled]="isShowingDetails"
          />
        </div>
      </div>
    </div>
  </div>
</div>
