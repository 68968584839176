export class Billing {
  id?: string;
  exportBy?: string;
  exportAt?: string;
  exporterName?: string;
  customerName?: string;
  customerId?: string | number;
  invoiceNumber?: string;
  remark?: string;
  status?: string;
  billingCode: string;
}

export class PendingBilling {
  id: string;
  amount: number;
  periodStart: string;
  periodEnd: string;
  subscriptionId: string;
  productName: string;
  companyName: string;
  currency: string;
  billingDate: string;
  status: string;
  subscriptionCode?: string;
  quotationcode?: string;
}
