import { Injectable } from "@angular/core";
import { ToasterService } from "angular2-toaster";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(private toasterService: ToasterService) {}

  success(message: string = "Successfully!", title: string = "Success") {
    this.toasterService.pop("success", title, message);
  }

  error(message = "Something went wrong!", title = "Error") {
    this.toasterService.pop("error", title, message);
  }

  errors(errors: any[]) {
    errors?.forEach((err) => {
      this.error(err?.message);
    });
  }
}
