import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { UserGroup } from "@models/_index";

import { AlertService, UserGroupService } from "@services/_index";

@Component({
  selector: "app-create-user-group",
  templateUrl: "./create-user-group.component.html",
  styleUrls: ["./create-user-group.component.scss"],
})
export class CreateUserGroupComponent implements OnInit {
  public userGroup: UserGroup = new UserGroup();
  public isSubmitting: Boolean = false;

  constructor(
    private userGroupService: UserGroupService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    this.isSubmitting = true;
    this.userGroupService.createUserGroup({ payload: this.userGroup }).subscribe(
      () => {
        this.isSubmitting = false;
        this.alertService.success("User Group Successfully Created.");
        this.router.navigate(["/wholesaler/admin/user_groups"]);
      },
      (err) => {
        this.isSubmitting = false;
        this.alertService.error(err.error.message);
      }
    );
  }
}
