import { Store, StoreConfig } from "@datorama/akita";
import { Quotation } from "@models/_index";
import { ProductPart } from "@models/product-part.model";
import { Injectable } from "@angular/core";

export interface QuotationState {
  quotations: Quotation[];
  quotation: Quotation;
  selectedQuotation: string;
}
export function createInitialState(): QuotationState {
  return {
    quotations: [],
    quotation: new Quotation(),
    selectedQuotation: null,
  };
}

@StoreConfig({ name: "quotations" })
@Injectable({ providedIn: "root" })
export class QuotationStore extends Store<QuotationState> {
  constructor() {
    super(createInitialState());
  }
}
