<div>
  <div class="row justify-content-between mb-3 align-items-end">
    <div class="col-6 col-lg-3">
      <label class="mb-0" translate>timeRangePendingBilling</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"
            ><i class="fa fa-calendar"></i
          ></span>
        </div>
        <input
          id="search-input"
          type="text"
          bsDatepicker
          [bsConfig]="{
            dateInputFormat: 'MMM YYYY',
            containerClass: 'theme-blue',
            minMode: 'month'
          }"
          class="form-control"
          (ngModelChange)="onChangeDateFilter($event)"
          [ngModel]="dateFilter"
        />
      </div>
    </div>
    <div class="col-6 text-right">
      <a
        class="btn btn-success mr-1"
        [routerLink]="'/wholesaler/billing/billing/'"
        translate
      >
        buttonTitles.viewBillings
      </a>
      <app-export-ssc-by-month
        [totalItems]="totalItems"
        [dateFilter]="dateFilter"
        (exportEmitter)="onExportAllSSC()"
      ></app-export-ssc-by-month>
      <app-export-ssc
        [selectedBillingIds]="selectedBillingIds"
        [dateFilter]="dateFilter"
        (exportEmitter)="onExportSSC()"
      ></app-export-ssc>
    </div>
  </div>
  <div class="card">
    <div class="card-header" translate>pendingBillings</div>
    <div class="card-body table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>
              <app-sortable-header
                [title]="'subscriptionId' | translate"
                [isSortedAsc]="isSortedAscOnField('subscriptionId')"
                [isSortedDesc]="isSortedDescOnField('subscriptionId')"
                (handleSort)="onApplySort('subscriptionId')"
                >>
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'product' | translate"
                [isSortedAsc]="isSortedAscOnField('productName')"
                [isSortedDesc]="isSortedDescOnField('productName')"
                (handleSort)="onApplySort('productName')"
                >>
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'partPricing' | translate"
                [isSortedAsc]="isSortedAscOnField('partPricingName')"
                [isSortedDesc]="isSortedDescOnField('partPricingName')"
                (handleSort)="onApplySort('partPricingName')"
                >>
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'customer' | translate"
                [isSortedAsc]="isSortedAscOnField('companyName')"
                [isSortedDesc]="isSortedDescOnField('companyName')"
                (handleSort)="onApplySort('companyName')"
                >>
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'currency' | translate"
                [isSortedAsc]="isSortedAscOnField('currency')"
                [isSortedDesc]="isSortedDescOnField('currency')"
                (handleSort)="onApplySort('currency')"
                >>
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'amount' | translate"
                [isSortedAsc]="isSortedAscOnField('amount')"
                [isSortedDesc]="isSortedDescOnField('amount')"
                (handleSort)="onApplySort('amount')"
                >>
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'periodStart' | translate"
                [isSortedAsc]="isSortedAscOnField('periodStart')"
                [isSortedDesc]="isSortedDescOnField('periodStart')"
                (handleSort)="onApplySort('periodStart')"
                >>
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'periodEnd' | translate"
                [isSortedAsc]="isSortedAscOnField('periodEnd')"
                [isSortedDesc]="isSortedDescOnField('periodEnd')"
                (handleSort)="onApplySort('periodEnd')"
                >>
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'billingDate' | translate"
                [isSortedAsc]="isSortedAscOnField('billingDate')"
                [isSortedDesc]="isSortedDescOnField('billingDate')"
                (handleSort)="onApplySort('billingDate')"
              >
              </app-sortable-header>
            </th>
            <th translate>
              toExport
              <input
                type="checkbox"
                [checked]="isCheckedAll()"
                (change)="onCheckedAllChange($event.target.checked)"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-template [ngIf]="!isLoading">
            <tr *ngFor="let pendingBilling of pendingBillings">
              <td>
                <div class="text-break-all">
                  {{ pendingBilling.subscriptionCode }}
                </div>
                <span *ngIf="pendingBilling.quotationCode">
                  <small>
                    From Quotation ID:
                    <span
                      class="link-to-quotation"
                      (click)="goToQuotation(pendingBilling.quotationCode)"
                    >
                      {{ pendingBilling.quotationCode }}
                    </span>
                  </small>
                </span>
              </td>
              <td>{{ pendingBilling?.productName }}</td>
              <td>{{ pendingBilling.partPricingName }}</td>
              <td>{{ pendingBilling?.companyName }}</td>
              <td>{{ pendingBilling?.currency }}</td>
              <td>
                {{ pendingBilling?.amountIncludeTax | currency: "":"" }}
              </td>
              <td>
                {{ pendingBilling.periodStart | date: "dd MMM yyyy" }}
              </td>
              <td>{{ pendingBilling.periodEnd | date: "dd MMM yyyy" }}</td>
              <td>
                {{ pendingBilling.billingDate | date: "dd MMM yyyy" }}
              </td>
              <td class="custom-checkbox">
                <input
                  type="checkbox"
                  [checked]="isChecked(pendingBilling.id)"
                  (change)="
                    onChange(pendingBilling.id, $event.target.checked)
                  "
                />
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
      <app-pagination
        [totalItems]="totalItems"
        [currentPage]="currentPage"
        [itemPerPages]="itemPerPage"
        (handleGoToPage)="goToPage($event)"
        (changeItemPerPage)="changeItemPerPage($event)"
      ></app-pagination>
    </div>
  </div>
</div>
