import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { ToasterService } from "angular2-toaster";

import { ProductPart } from "@models/product-part.model";
import { Product } from "@models/product.model";
import { ProductService } from "@services/product.service";
import { ProductQuery } from "app/@core/stores/products/product.query";
import { AlertService } from "@services/alert.service";
import {
  BsModalService,
  BsModalRef,
  ModalOptions,
} from "ngx-bootstrap/modal";
import { ModalComponent } from "@shared/components/modal/modal.component";

@Component({
  selector: "app-product-part",
  templateUrl: "./product-part.component.html",
  styleUrls: ["./product-part.component.scss"],
})
export class ProductPartComponent implements OnInit {
  public bsModalRef?: BsModalRef;
  productParts$: Observable<ProductPart[]>;
  products: Product[] = [];
  productId: string;
  isLoading: boolean = false;
  defaultProductId: string = null;

  currentPage: number = 0;
  totalItems: number = 0;
  itemPerPage: number = 10;

  search: string = "";

  sortedField: string;
  isSortedAsc: boolean;

  readonly ITEMS_PER_PAGE: number = 10;

  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private productQuery: ProductQuery,
    private modalService: BsModalService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.loadProducts();
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.onSelect(params.id);
        this.defaultProductId = params.id;
      }
    });
    this.initProductPart();
  }

  initProductPart() {
    this.isLoading = true;
    this.productService
      .getAllProductPart({
        params: {
          page: this.currentPage,
          keySearch: this.search,
          productId: this.productId,
          sort: this.sortParams(),
          size: this.itemPerPage,
        },
      })
      .subscribe(
        (res?) => {
          this.isLoading = false;
          this.totalItems = res.data?.totalSize;
        },
        (err?) => {
          this.isLoading = false;
          this.alertService.error(err?.error?.message);
        }
      );
    this.productParts$ = this.productQuery.productParts$;
  }

  loadProducts() {
    this.productService
      .getProductsNoSubscribe({
        params: {},
      })
      .subscribe(
        (res?) => {
          this.products = res?.data.content;
        },
        (err?) => {
          this.alertService.error(err?.error?.message);
        }
      );
  }

  onDelete(id) {
    const initialState: ModalOptions = {
      initialState: {
        title: "Do you want to remove this item?",
        content:
          "By clicking on OK, this item will be removed immediately",
      },
      animated: false,
    };
    this.bsModalRef = this.modalService.show(ModalComponent, initialState);
    this.bsModalRef.content.eventEmitter.subscribe((isOk) => {
      if (isOk) {
        this.deleteProductPart(id);
      } else {
        this.bsModalRef.hide();
      }
    });
  }

  deleteProductPart(productPartId: string) {
    this.productService.deleteProductPart({ id: productPartId }).subscribe(
      (res) => {
        this.alertService.success("Delete product part successfully!");
        this.bsModalRef.hide();
        this.initProductPart();
      },
      (err) => {
        this.alertService.error(err?.error?.message);
        this.bsModalRef.hide();
      }
    );
  }

  onSearch(keySearch: string) {
    this.search = keySearch;
    this.currentPage = 0;
    this.initProductPart();
  }

  onSelect(productId: string) {
    this.productId = productId;
    this.currentPage = 0;
    this.productService.selectProduct(productId);
    this.initProductPart();
  }

  goToPage(page: number) {
    this.currentPage = page - 1;
    this.initProductPart();
  }

  changeItemPerPage(itemPerPages: number) {
    this.currentPage = 0;
    this.itemPerPage = itemPerPages;
    this.initProductPart();
  }

  // below are sortable function
  isSortedOnField(field: string) {
    return this.sortedField === field;
  }

  isSortedAscOnField(field: string) {
    return this.isSortedOnField(field) && this.isSortedAsc;
  }

  isSortedDescOnField(field: string) {
    return this.isSortedOnField(field) && !this.isSortedAsc;
  }

  sortParams() {
    return this.sortedField
      ? `${this.sortedField},${this.isSortedAsc ? "asc" : "desc"}`
      : "";
  }

  onApplySort(field: string) {
    if (field !== this.sortedField) {
      this.isSortedAsc = true;
      this.sortedField = field;
    } else {
      this.isSortedAsc = !this.isSortedAsc;
    }
    this.initProductPart();
  }
}
