<div
  class="flex-row align-items-center"
  style="display: flex; min-height: calc(100vh - 175px)"
>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">403</h1>
          <h4 class="pt-3">Unauthorized</h4>
          <p class="text-muted">
            Sorry, you do not have permission to view this page.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
