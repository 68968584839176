import { Injectable } from "@angular/core";
import { ServiceUrl } from "./config/service-url";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { AuthStore } from "@stores/_index";
import { BehaviorSubject } from "rxjs";
import { Payload } from "@models/_index";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private isAuthorized = new BehaviorSubject<boolean>(true);
  private baseUrl: string = environment.iamBaseUrl
  private serviceUrl = new ServiceUrl()
  constructor(
    private httpClient: HttpClient,
    private authStore: AuthStore,
  ) {}

  login(payload: Payload) {
    console.log(payload)
    return this.httpClient.post(`${this.baseUrl}/${this.serviceUrl.IAM_SERVICE}/login`, payload.payload);
  }

  logout() {
    this.authStore.update({
      currentUser: undefined,
    });
    return this.httpClient.get(`${this.baseUrl}/${this.serviceUrl.IAM_SERVICE}/logout`);
  }

  getCurrentUser() {
    return this.httpClient.get(`${this.baseUrl}/${this.serviceUrl.IAM_SERVICE}/user/info`);
  }

  // is Authorized getter and setter
  setIsAuthorized(value: boolean) {
    this.isAuthorized.next(value);
  }

  getIsAuthorized() {
    return this.isAuthorized.asObservable();
  }
}
