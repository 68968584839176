import { Component, OnInit } from "@angular/core";
import { Billing } from "@models/billing.model";

import { BillingService, AlertService } from "@services/_index";
import { BillingQuery } from "@stores/_index";
import { NgxSpinnerService } from "ngx-bootstrap-spinner";
import { addDays, format, startOfMonth } from "date-fns";

@Component({
  selector: "app-billing",
  templateUrl: "./billing.component.html",
  styleUrls: ["./billing.component.scss"],
})
export class BillingComponent implements OnInit {
  billings: Billing[] = [];

  isLoading: boolean = false;

  // pagination
  currentPage: number = 0;
  totalItems: number = 0;
  itemPerPage: number = 10;

  // search
  search: string = "";
  // sort
  sortedField: string = "exportAt,desc";
  isSortedAsc: boolean;

  readonly ITEMS_PER_PAGE: number = 10;

  // date filter
  today = new Date();
  dateFilter: Date = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    1
  );

  constructor(
    private billingService: BillingService,
    private billingQuery: BillingQuery,
    private alertService: AlertService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getBillings();
  }

  getBillings() {
    this.isLoading = true;
    this.billingService
      .getBillings({
        params: {
          page: this.currentPage,
          keySearch: this.search,
          sort: this.sortParams(),
          size: this.itemPerPage,
          monthFilter: format(this.dateFilter, "YYYY-MM-DD"),
        },
      })
      .subscribe(
        (res?) => {
          this.isLoading = false;
          this.totalItems = res.data?.totalSize;
          this.billings = this.billingQuery.billings;
        },
        (err?) => {
          this.isLoading = false;
          this.alertService.errors(err?.error?._embedded?.errors);
        }
      );
  }

  goToPage(page: number) {
    this.currentPage = page - 1;
    this.getBillings();
  }

  onSearch(keySearch: string) {
    this.search = keySearch;
    this.currentPage = 0;
    this.getBillings();
  }

  onChangeDateFilter(date) {
    this.dateFilter = date;
    this.currentPage = 0;
    this.getBillings();
  }

  onDownloadExcel(billingId, billingCode) {
    this.spinner.show();
    this.billingService.getBillingExportedExcel(billingId).subscribe(
      (res?: any) => {
        this.spinner.hide();
        this.alertService.success("Successfully Downloaded.");
        this.getExcelFile(res, billingId, billingCode);
      },
      (err?) => {
        this.spinner.hide();

        this.alertService.errors(err?.error?._embedded?.errors);
      }
    );
  }

  getExcelFile(res, billingId, billingCode) {
    const downloadLink = window.document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(
      new Blob([res.body], {
        // eslint-disable-next-line max-len
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    downloadLink.download = `${billingCode}.xlsx`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  isSortedOnField(field: string) {
    return this.sortedField === field;
  }

  isSortedAscOnField(field: string) {
    return this.isSortedOnField(field) && this.isSortedAsc;
  }

  isSortedDescOnField(field: string) {
    return this.isSortedOnField(field) && !this.isSortedAsc;
  }

  sortParams() {
    return this.sortedField
      ? `${this.sortedField},${this.isSortedAsc ? "asc" : "desc"}`
      : "";
  }

  changeItemPerPage(itemPerPages: number) {
    this.currentPage = 0;
    this.itemPerPage = itemPerPages;
    this.getBillings();
  }

  onApplySort(field: string) {
    if (field !== this.sortedField) {
      this.isSortedAsc = true;
      this.sortedField = field;
    } else {
      this.isSortedAsc = !this.isSortedAsc;
    }
    this.getBillings();
  }
}
