import { Injectable } from "@angular/core";
import {
  BsModalService,
  BsModalRef,
  ModalOptions,
} from "ngx-bootstrap/modal";
import { ModalComponent } from "@shared/components/modal/modal.component";

@Injectable({
  providedIn: "root",
})
export class ConfirmModalService {
  constructor(private modalService: BsModalService) {}

  show(title: string, content: string) {
    const initialState: ModalOptions = {
      initialState: {
        title: title,
        content: content,
      },
      animated: false,
    };
    return this.modalService.show(ModalComponent, initialState);
  }
}
