import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";

import { UserGroup } from "@models/_index";
import { UserGroupService, AlertService } from "@services/_index";

@Component({
  selector: "app-edit-user-group",
  templateUrl: "./edit-user-group.component.html",
  styleUrls: ["./edit-user-group.component.scss"],
})
export class EditUserGroupComponent implements OnInit {
  public userGroup: UserGroup = {};
  public isSubmitting: Boolean = false;
  public isLoading: boolean = false;

  constructor(
    private userGroupService: UserGroupService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.isLoading = true;
      this.userGroupService.getUserGroup(params.id).subscribe(
        (res) => {
          const policyId = res?.data.policies.map((p) => p.id);

          this.userGroup = { ...res?.data, policies: policyId };
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
    });
  }

  onSubmit() {
    this.isSubmitting = true;
    this.userGroupService
      .updateUserGroup(this.userGroup.id, {
        payload: {
          ...this.userGroup,
          users: this.userGroup.users.map((user) => user.id),
        },
      })
      .subscribe(
        () => {
          this.isSubmitting = false;
          this.alertService.success("User Group Successfully Updated.");
          this.router.navigate(["/wholesaler/admin/user_groups"]);
        },
        (err) => {
          this.isSubmitting = false;
          this.alertService.error(err?.error.message);
        }
      );
  }
}
