import { ProductPart } from "./product-part.model";
import { CurrencyPriceItem } from "./currency-price-item.model";
export class PartPriceItem {
  id: string;
  name: string;
  price?: number;
  minimumQuantity?: number;
  currency?: string;
  productPartId?: string;
  productPart?: ProductPart = new ProductPart();
  priceItems?: CurrencyPriceItem[] = [];
  billingCycle: string;
  description: string;
  billDescription: string;
}
