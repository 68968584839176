import { Component, OnInit } from "@angular/core";
import { Account } from "@models/_index";
import { AccountService } from "@services/_index";
import { AlertService } from "@services/alert.service";
import { Router } from "@angular/router";
@Component({
  selector: "app-create-customer",
  templateUrl: "./create-customer.component.html",
  styleUrls: ["./create-customer.component.scss"],
})
export class CreateCustomerComponent implements OnInit {
  public account: Account = new Account();
  public isSubmitting: Boolean = false;

  constructor(
    private accountService: AccountService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit() {}

  onSubmit() {
    this.isSubmitting = true;
    this.accountService
      .createAccount({ payload: { ...this.account, status: "ACTIVE" }})
      .subscribe(
        () => {
          this.isSubmitting = false;
          this.alertService.success("Customer Successfully Created.");
          this.router.navigate(["/wholesaler/customer/customers"]);
        },
        (err) => {
          console.log(err);
          this.isSubmitting = false;
          this.alertService.errors(err?.error?._embedded?.errors);
        }
      );
  }
}
