import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { Policy, Action } from "@models/_index";
import { PolicyQuery, ActionQuery } from "@stores/_index";
import {
  PolicyService,
  ActionService,
  AlertService,
} from "@services/_index";

@Component({
  selector: "app-edit-policy",
  templateUrl: "./edit-policy.component.html",
  styleUrls: ["./edit-policy.component.scss"],
})
export class EditPolicyComponent implements OnInit {
  public policy: Policy = new Policy();
  public actions: Action[] = [];
  public isSubmitting: Boolean = false;
  public isLoading: Boolean = false;

  constructor(
    private policyService: PolicyService,
    private policyQuery: PolicyQuery,
    private actionService: ActionService,
    private actionQuery: ActionQuery,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.loadPolicy(params.id);
    });
  }

  loadPolicy(policyId: string) {
    this.isLoading = true;
    this.policyService.getPolicy({ id: policyId }).subscribe(
      () => {
        this.policy = this.policyQuery.getPolicy;
        this.loadActions();
      },
      (err) => {
        this.isLoading = false;
        console.log(err);
        this.alertService.errors(err?.error?._embedded?.message);
      }
    );
  }

  loadActions() {
    this.isLoading = true;
    this.actionService.getActions().subscribe(
      () => {
        this.isLoading = false;
        this.actions = this.actionQuery.getActions;
      },
      (err) => {
        this.isLoading = false;
        console.log(err);
        this.alertService.errors(err?.error?._embedded?.message);
      }
    );
  }

  onSubmit(actionIds) {
    this.isSubmitting = true;
    this.policyService
      .updatePolicy({
        id: this.policy.id,
        payload: {
          policyName: this.policy.policyName,
          actions: actionIds,
        },
      })
      .subscribe(
        () => {
          this.isSubmitting = false;
          this.alertService.success("Policy Successfully Updated.");
          this.router.navigate(["/wholesaler/admin/policy"]);
        },
        (err) => {
          this.isSubmitting = false;
          console.log(err);
          this.alertService.errors(err?.error?._embedded?.message);
        }
      );
  }
}
