<ng-template [ngIf]="quotation">
  <app-form-header [currentStep]="currentStep"></app-form-header>
  <form
    id="quotationForm"
    (ngSubmit)="onNextStep(quotationForm)"
    #quotationForm="ngForm"
    (keydown.enter)="$event.preventDefault()"
  >
    <app-form-quotation-details
      [isShowing]="isOnQuotationStep()"
      *ngIf="isOnQuotationStep()"
      [quotation]="quotation"
      [isSubmitted]="isSubmitted"
      [isShowingDetails]="isShowingDetails"
    ></app-form-quotation-details>

    <app-form-contact-details
      [isShowing]="isOnContactStep()"
      *ngIf="isOnContactStep()"
      [quotation]="quotation"
      [isShowingDetails]="isShowingDetails"
    ></app-form-contact-details>

    <app-form-product-details
      [isShowing]="isOnProductStep()"
      *ngIf="isOnProductStep()"
      [quotation]="quotation"
      [isShowingDetails]="isShowingDetails"
      (editProductItemEmitter)="isEditingProductItem = $event"
    ></app-form-product-details>

    <app-form-preview
      [quotation]="quotation"
      [isShowing]="isOnPreviewStep()"
      *ngIf="isOnPreviewStep()"
    >
    </app-form-preview>
  </form>
  <div class="card border-0 border-radius-top-0">
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <button
          class="btn btn-secondary"
          (click)="onCancel()"
          [translate]="'buttonTitles.cancel'"
          *ngIf="!isOnPreviewStep()"
        ></button>
        <button
          class="btn btn-secondary"
          [routerLink]="'/wholesaler/quotation'"
          [translate]="'buttonTitles.backToQuotationList'"
          *ngIf="isOnPreviewStep()"
        ></button>
        <div>
          <button
            class="btn bg-none border-primary text-primary mr-1"
            [translate]="'buttonTitles.backToPreviousStep'"
            (click)="onBackStep()"
            *ngIf="!isOnQuotationStep()"
          ></button>
          <button
            class="btn btn-primary"
            [translate]="'buttonTitles.nextStep'"
            [ladda]="isSubmitting"
            data-style="zoom-in"
            *ngIf="!isOnPreviewStep()"
            form="quotationForm"
          ></button>
          <button
            class="btn btn-primary"
            *ngIf="isOnPreviewStep()"
            (click)="downloadPDF()"
            [translate]="'buttonTitles.download'"
          ></button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
