import { Component, OnInit } from "@angular/core";

import { UserGroupService, AlertService } from "@services/_index";
import { UserGroup } from "@models/_index";

import {
  BsModalService,
  BsModalRef,
  ModalOptions,
} from "ngx-bootstrap/modal";
import { ModalComponent } from "@shared/components/modal/modal.component";

@Component({
  selector: "app-user-group",
  templateUrl: "./user-group.component.html",
  styleUrls: ["./user-group.component.scss"],
})
export class UserGroupComponent implements OnInit {
  userGroups: UserGroup[] = [];
  isLoading: boolean = false;
  public bsModalRef?: BsModalRef;

  constructor(
    private userGroupService: UserGroupService,
    private modalService: BsModalService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.getUserGroups();
  }

  getUserGroups() {
    this.isLoading = true;
    this.userGroupService.getUserGroups().subscribe(
      (res) => {
        this.userGroups = res?.data;
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  onDelete(id: string) {
    const initialState: ModalOptions = {
      initialState: {
        title: "Do you want to remove this item?",
        content:
          "By clicking on OK, this item will be removed immediately",
      },
      animated: false,
    };
    this.bsModalRef = this.modalService.show(ModalComponent, initialState);

    this.bsModalRef.content.eventEmitter.subscribe((isOk) => {
      if (isOk) {
        this.deleteUserGroup(id);
      } else {
        this.bsModalRef.hide();
      }
    });
  }

  deleteUserGroup(id: string) {
    this.userGroupService.deleteUserGroup(id).subscribe(
      (res) => {
        this.alertService.success("Delete user group successfully!");
        this.bsModalRef.hide();
        this.getUserGroups();
      },
      (err) => {
        this.alertService.error(err?.error?.message);
        this.bsModalRef.hide();
      }
    );
  }

  onSearch(keySearch: string) {}
}
