import { Query } from "@datorama/akita";
import { ProductState, ProductStore } from "./product.store";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ProductQuery extends Query<ProductState> {
  products$ = this.select("products");
  productParts$ = this.select("productParts");

  constructor(protected store: ProductStore) {
    super(store);
  }

  get getProducts() {
    return this.getValue().products;
  }

  get getSelectedProduct() {
    return this.getValue().selectedProduct;
  }
}
