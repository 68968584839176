<div class="card border-0">
  <div class="card-body">
    <div class="row">
      <div class="col-xs-12 col-lg-12 col-xl-10">
        <form
          id="customerForm"
          (ngSubmit)="onSubmit(customerForm)"
          #customerForm="ngForm"
        >
          <div class="row justify-content-between">
            <div class="col-xs-12 col-md-5">
              <div class="form-part">
                <h5>
                  <strong translate>customerDetail</strong>
                </h5>
                <div class="form-group">
                  <label translate class="required">customerTypes</label>
                  <ng-select
                    [multiple]="true"
                    [closeOnSelect]="false"
                    name="accountTypes"
                    [(ngModel)]="account.accountTypes"
                    [disabled]="!isEditing"
                  >
                    <ng-option
                      *ngFor="let type of customerTypes"
                      [value]="type"
                      >{{ type }}</ng-option
                    >
                  </ng-select>
                </div>
                <div class="form-group">
                  <label translate for="companyName" class="required"
                    >companyName</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    name="companyName"
                    #companyName="ngModel"
                    placeholder="{{
                      'placeholder.companyName' | translate
                    }}"
                    [(ngModel)]="account.company.name"
                    [ngClass]="{
                      'is-invalid':
                        customerForm.submitted && companyName.invalid
                    }"
                    [disabled]="!isEditing"
                    required
                  />
                  <div
                    *ngIf="customerForm.submitted && companyName.invalid"
                    class="invalid-feedback"
                  >
                    <div *ngIf="companyName.errors.required" translate>
                      validateMessages.companyName
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label translate for="atCustomerCode" class="required"
                    >atCustomerCode</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    name="atCustomerCode"
                    #atCustomerCode="ngModel"
                    placeholder="{{
                      'placeholder.atCustomerCode' | translate
                    }}"
                    [ngClass]="{
                      'is-invalid':
                        customerForm.submitted && atCustomerCode.invalid
                    }"
                    [(ngModel)]="account.atCustomerCode"
                    [disabled]="!isEditing"
                    required
                  />
                  <div
                    *ngIf="
                      customerForm.submitted && atCustomerCode.invalid
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="atCustomerCode.errors.required" translate>
                      validateMessages.atCustomerCode
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label translate for="sapCustomerCode" class="required"
                    >sapCustomerCode</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    name="sapCustomerCode"
                    #sapCustomerCode="ngModel"
                    placeholder="{{
                      'placeholder.sapCustomerCode' | translate
                    }}"
                    [ngClass]="{
                      'is-invalid':
                        customerForm.submitted && sapCustomerCode.invalid
                    }"
                    [(ngModel)]="account.sapCustomerCode"
                    [disabled]="!isEditing"
                    required
                  />
                  <div
                    *ngIf="
                      customerForm.submitted && sapCustomerCode.invalid
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="sapCustomerCode.errors.required" translate>
                      validateMessages.sapCustomerCode
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label translate>company/OrganizationID</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="{{
                      'placeholder.company/OrganizationID' | translate
                    }}"
                    name="brn"
                    #brn="ngModel"
                    [ngClass]="{
                      'is-invalid': customerForm.submitted && brn.invalid
                    }"
                    [(ngModel)]="account.company.brn"
                    [disabled]="!isEditing"
                  />
                  <div
                    *ngIf="customerForm.submitted && brn.invalid"
                    class="invalid-feedback"
                  >
                    <div *ngIf="brn.errors.required" translate>
                      validateMessages.brn
                    </div>
                  </div>
                </div>
                <div class="form-group d-flex align-items-center mb-0">
                  <div class="custom-control custom-checkbox mb-0">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customCheck1"
                      [(ngModel)]="account.isCertisTaxGroup"
                      name="isCertisTaxGroup"
                      #isCertisTaxGroup="ngModel"
                      [disabled]="!isEditing"
                    />
                    <label
                      class="custom-control-label"
                      for="customCheck1"
                      translate
                      >certisTaxGroup</label
                    >
                  </div>
                </div>
              </div>
              <div class="form-part mt-5">
                <div>
                  <tabset>
                    <tab
                      heading="Registered Address"
                      id="tab1"
                      customClass="fc-tab"
                    >
                      <div class="form-group">
                        <label translate>unitNumber</label>
                        <input
                          type="text"
                          class="form-control"
                          name="mailingUnit"
                          placeholder="{{
                            'placeholder.unitNumber' | translate
                          }}"
                          [(ngModel)]="account.company.mailingAddress.unit"
                          [disabled]="!isEditing"
                        />
                      </div>
                      <div class="form-group">
                        <label translate>address</label>
                        <input
                          type="text"
                          class="form-control"
                          name="mailingAddress"
                          placeholder="{{
                            'placeholder.address' | translate
                          }}"
                          [(ngModel)]="
                            account.company.mailingAddress.address
                          "
                          [disabled]="!isEditing"
                        />
                      </div>
                      <div class="form-group">
                        <label translate>postalCode</label>
                        <input
                          type="text"
                          class="form-control"
                          name="mailingPostalCode"
                          placeholder="{{
                            'placeholder.postalCode' | translate
                          }}"
                          [(ngModel)]="
                            account.company.mailingAddress.postalCode
                          "
                          [disabled]="!isEditing"
                        />
                      </div>
                      <div class="form-group">
                        <label translate>country</label>
                        <select
                          class="form-control"
                          name="mailingCountry"
                          #mailingCountry
                          [(ngModel)]="
                            account.company.mailingAddress.country
                          "
                          (change)="onSelectCountry(mailingCountry.value, 'mailingAddress')"
                          [disabled]="!isEditing"
                        >
                          <option
                            value=""
                            [translate]="'placeholder.country'"
                            hidden
                          ></option>
                          <option
                            [value]="country.code"
                            *ngFor="let country of countries"
                          >
                            {{ country.name }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="form-group"
                        *ngIf="
                          account.company.mailingAddress.country !== 'SG'
                        "
                      >
                        <label translate>state</label>
                        <select
                          class="form-control"
                          [(ngModel)]="
                            account.company.mailingAddress.state
                          "
                          #mailingState
                          name="mailingState"
                          (change)="onSelectState(mailingState.value, 'mailingAddress')"
                          [disabled]="!isEditing"
                        >
                          <option
                            value=""
                            [translate]="'placeholder.state'"
                            hidden
                          ></option>
                          <option
                            [value]="state.code"
                            *ngFor="let state of getStates(account.company.mailingAddress.country)"
                          >
                            {{ state.name }}
                          </option>
                        </select>
                      </div>
                    </tab>
                    <tab heading="Billing Address" customClass="fc-tab">
                      <div class="form-group">
                        <label translate>unitNumber</label>
                        <input
                          type="text"
                          class="form-control"
                          name="billingUnit"
                          placeholder="{{
                            'placeholder.unitNumber' | translate
                          }}"
                          [(ngModel)]="account.company.billingAddress.unit"
                          [disabled]="!isEditing"
                        />
                      </div>
                      <div class="form-group">
                        <label translate>address</label>
                        <input
                          type="text"
                          class="form-control"
                          name="billingAddress"
                          placeholder="{{
                            'placeholder.address' | translate
                          }}"
                          [(ngModel)]="
                            account.company.billingAddress.address
                          "
                          [disabled]="!isEditing"
                        />
                      </div>
                      <div class="form-group">
                        <label translate>postalCode</label>
                        <input
                          type="text"
                          class="form-control"
                          name="billingPostalCode"
                          placeholder="{{
                            'placeholder.postalCode' | translate
                          }}"
                          [(ngModel)]="
                            account.company.billingAddress.postalCode
                          "
                          [disabled]="!isEditing"
                        />
                      </div>
                      <div class="form-group">
                        <label translate>country</label>
                        <select
                          class="form-control"
                          name="billingCountry"
                          #billingCountry
                          [(ngModel)]="
                            account.company.billingAddress.country
                          "
                          [disabled]="!isEditing"
                          (change)="onSelectCountry(billingCountry.value, 'billingAddress')"
                        >
                          <option
                            value=""
                            [translate]="'placeholder.country'"
                            hidden
                          ></option>
                          <option
                            [value]="country.code"
                            *ngFor="let country of countries"
                          >
                            {{ country.name }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="form-group"
                        *ngIf="
                          account.company.billingAddress.country !== 'SG'
                        "
                      >
                        <label translate>state</label>
                        <select
                          class="form-control"
                          [(ngModel)]="
                            account.company.billingAddress.state
                          "
                          [disabled]="!isEditing"
                          #billingState
                          name="billingState"
                          (change)="onSelectState(billingState.value, 'billingAddress')"
                        >
                          <option
                            value=""
                            [translate]="'placeholder.state'"
                            hidden
                          ></option>
                          <option
                            [value]="state.code"
                            *ngFor="let state of getStates(account.company.billingAddress.country)"
                          >
                            {{ state.name }}
                          </option>
                        </select>
                      </div>
                    </tab>
                  </tabset>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-md-5 ml-md-3">
              <div class="form-part">
                <h5>
                  <strong translate>authorizedContact</strong>
                </h5>
                <div class="form-group">
                  <label translate>personInCharge</label>
                  <input
                    type="text"
                    class="form-control"
                    name="authorizedPersonInCharge"
                    placeholder="{{
                      'placeholder.authorizedPersonInCharge' | translate
                    }}"
                    [(ngModel)]="account.authorizedContact.personInCharge"
                    [disabled]="!isEditing"
                  />
                </div>
                <div class="form-group">
                  <label translate>contactNumber</label>
                  <input
                    type="text"
                    class="form-control"
                    name="authorizedContactNumber"
                    placeholder="{{
                      'placeholder.authorizedContactNumber' | translate
                    }}"
                    [(ngModel)]="account.authorizedContact.contactNumber"
                    [disabled]="!isEditing"
                  />
                </div>
                <div class="form-group">
                  <label translate>email</label>
                  <input
                    type="email"
                    class="form-control"
                    name="authorizedEmail"
                    #authorizedEmail="ngModel"
                    placeholder="{{
                      'placeholder.authorizedEmail' | translate
                    }}"
                    [(ngModel)]="account.authorizedContact.email"
                    [disabled]="!isEditing"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    [ngClass]="{
                      'is-invalid':
                        customerForm.submitted && authorizedEmail.invalid
                    }"
                  />
                  <div
                    *ngIf="
                      customerForm.submitted && authorizedEmail.invalid
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="authorizedEmail.errors.pattern" translate>
                      validateMessages.authorizedEmailFormat
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-part mt-5">
                <h5>
                  <strong translate>billingContact</strong>
                </h5>
                <div class="form-group">
                  <label translate>personInCharge</label>
                  <input
                    type="text"
                    class="form-control"
                    name="billingPersonInCharge"
                    placeholder="{{
                      'placeholder.billingPersonInCharge' | translate
                    }}"
                    [(ngModel)]="account.billingContact.personInCharge"
                    [disabled]="!isEditing"
                  />
                </div>
                <div class="form-group">
                  <label translate>contactNumber</label>
                  <input
                    type="text"
                    class="form-control"
                    name="billingContactNumber"
                    placeholder="{{
                      'placeholder.billingContactNumber' | translate
                    }}"
                    [(ngModel)]="account.billingContact.contactNumber"
                    [disabled]="!isEditing"
                  />
                </div>
                <div class="form-group">
                  <label translate>email</label>
                  <input
                    type="text"
                    class="form-control"
                    name="billingEmail"
                    #billingEmail="ngModel"
                    placeholder="{{
                      'placeholder.billingEmail' | translate
                    }}"
                    [(ngModel)]="account.billingContact.email"
                    [disabled]="!isEditing"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    [ngClass]="{
                      'is-invalid':
                        customerForm.submitted && billingEmail.invalid
                    }"
                  />
                  <div
                    *ngIf="customerForm.submitted && billingEmail.invalid"
                    class="invalid-feedback"
                  >
                    <div *ngIf="billingEmail.errors.pattern" translate>
                      validateMessages.authorizedEmailFormat
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-part mt-5">
                <h5><strong translate>salesContact</strong></h5>
                <div class="form-group">
                  <label translate>personInCharge</label>
                  <input
                    type="text"
                    class="form-control"
                    name="salesPersonInCharge"
                    #salesPersonInCharge="ngModel"
                    placeholder="{{
                      'placeholder.salesPersonInCharge' | translate
                    }}"
                    [(ngModel)]="account.salesContact.personInCharge"
                    [disabled]="!isEditing"
                  />
                </div>
                <div class="form-group">
                  <label translate>contactNumber</label>
                  <input
                    type="text"
                    class="form-control"
                    name="salesContactNumber"
                    #salesContactNumber="ngModel"
                    placeholder="{{
                      'placeholder.salesContactNumber' | translate
                    }}"
                    [(ngModel)]="account.salesContact.contactNumber"
                    [disabled]="!isEditing"
                  />
                </div>
                <div class="form-group">
                  <label translate>email</label>
                  <input
                    type="text"
                    class="form-control"
                    name="salesEmail"
                    #salesEmail="ngModel"
                    placeholder="{{
                      'placeholder.salesEmail' | translate
                    }}"
                    [(ngModel)]="account.salesContact.email"
                    [disabled]="!isEditing"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    [ngClass]="{
                      'is-invalid':
                        customerForm.submitted && salesEmail.invalid
                    }"
                  />
                  <div
                    *ngIf="customerForm.submitted && salesEmail.invalid"
                    class="invalid-feedback"
                  >
                    <div *ngIf="salesEmail.errors.pattern" translate>
                      validateMessages.authorizedEmailFormat
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <a
              class="btn btn-primary text-white mr-1"
            
              [translate]="'buttonTitles.edit'"
              (click)="onEdit()"
              *ngIf="!isEditing"
            ></a>
            <button
              class="btn btn-primary mr-1"
              [ladda]="isSubmitting"
              data-style="zoom-in"
              [translate]="'buttonTitles.save'"
              type="submit"
              *ngIf="isEditing"
            ></button>
            <a
              class="btn btn-secondary"
              (click)="onCancel()"
              >Cancel</a
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
