import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
} from "@angular/core";

@Component({
  selector: "app-search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.scss"],
})
export class SearchBarComponent implements OnInit {
  @Input() placeholder: string;

  @Input() showFilter: Boolean = false;
  @Input() items: string[] = [];
  @Input() label: string = "";
  @Input() value: string = "";
  @Input() preSelect: string = "";
  @Input() selectedItem: string = "";
  @Input() defaultValue: string = null;
  @Input() type: string = null;
  @Input() disabled: Boolean = false;

  @Output() handleSearchEmitter: EventEmitter<any> = new EventEmitter();
  @Output() handleSelectEmitter: EventEmitter<any> = new EventEmitter();

  search: string = "";

  timerId: any;

  constructor() {}

  ngOnInit(): void {
    if (this.defaultValue) {
      this.onSelect(this.defaultValue);
    }
  }

  onSearch() {
    if (this.timerId !== null) {
      clearTimeout(this.timerId);
    }
    this.timerId = setTimeout(() => {
      this.handleSearchEmitter.emit(this.search);
      this.timerId = null;
    }, 400);
  }

  onSelect(item: string) {
    this.selectedItem = item;
    this.handleSelectEmitter.emit(item);
  }
}
