<div>
  <div class="row mb-3">
    <div class="col-xs-12 col-lg-4 mb-2">
      <app-search-bar
        (handleSearchEmitter)="onSearch($event)"
        [placeholder]="'search' | translate"
      >
      </app-search-bar>
    </div>

    <div class="col-xs-12 col-lg-3 pr-3 mb-2">
      <app-search-bar
        (handleSelectEmitter)="onSelect($event)"
        [showFilter]="true"
        [items]="products"
        preSelect="{{ 'allProducts' | translate }}"
        [defaultValue]="defaultProductId"
        type="product"
      >
      </app-search-bar>
    </div>

    <div class="col-xs-12 col-lg-5 align-items-end mb-2">
      <a
        class="btn btn-primary float-right"
        routerLink="/wholesaler/product/part/new"
        translate
      >
        buttonTitles.newProductPart
      </a>
    </div>
  </div>
  <div class="card">
    <div class="card-header" translate>productParts</div>
    <div class="card-body table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>
              <app-sortable-header
                [title]="'product' | translate"
                [isSortedAsc]="isSortedAscOnField('product.productName')"
                [isSortedDesc]="isSortedDescOnField('product.productName')"
                (handleSort)="onApplySort('product.productName')"
                >>
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'productPartName' | translate"
                [isSortedAsc]="isSortedAscOnField('name')"
                [isSortedDesc]="isSortedDescOnField('name')"
                (handleSort)="onApplySort('name')"
              >
                >
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'uom' | translate"
                [isSortedAsc]="isSortedAscOnField('uom')"
                [isSortedDesc]="isSortedDescOnField('uom')"
                (handleSort)="onApplySort('uom')"
                >>
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'partNumberPrefix' | translate"
                [isSortedAsc]="isSortedAscOnField('partNumber')"
                [isSortedDesc]="isSortedDescOnField('partNumber')"
                (handleSort)="onApplySort('partNumber')"
                >>
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'serviceCode' | translate"
                [isSortedAsc]="isSortedAscOnField('code')"
                [isSortedDesc]="isSortedDescOnField('code')"
                (handleSort)="onApplySort('code')"
                >>
              </app-sortable-header>
            </th>

            <th translate>actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-template [ngIf]="!isLoading">
            <tr *ngFor="let productPart of productParts$ | async">
              <td>{{ productPart?.product?.productName }}</td>
              <td>{{ productPart?.name }}</td>
              <td>{{ productPart?.uom }}</td>
              <td>{{ productPart?.product?.partNumberPrefix }}</td>
              <td>{{ productPart?.code }}</td>
              <td>
                <a
                  class="text-decoration-none mr-1"
                  [routerLink]="[
                    '/wholesaler/product/part/edit',
                    productPart?.id
                  ]"
                >
                  <i
                    class="fa fa-pencil"
                    tooltip="{{ 'tooltip.edit' | translate }}"
                  >
                  </i>
                </a>
                <a
                  class="text-primary hoverable"
                  (click)="onDelete(productPart?.id)"
                >
                  <i
                    class="fa fa-trash"
                    tooltip="{{ 'tooltip.delete' | translate }}"
                  >
                  </i>
                </a>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
      <app-pagination
        [totalItems]="totalItems"
        [currentPage]="currentPage"
        [itemPerPages]="itemPerPage"
        (handleGoToPage)="goToPage($event)"
        (changeItemPerPage)="changeItemPerPage($event)"
      ></app-pagination>
    </div>
  </div>
</div>
