/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */

import { format, addYears } from "date-fns";

export class QuotationItem {
  id: string;
  quotationId: string;
  partPricingId: string;
  price: number;
  discount: number;
  amount: number;
  discountAmount: number;
  discountType: string;
  quantity: number;
  recurrenceType: string;
  productId?: string;
  productName?: string;
  partPricingName?: string;

  constructor(item?: QuotationItem) {
    this.id = item?.id || "",
    this.quotationId = item?.quotationId || "",
    this.partPricingId = item?.partPricingId || "",
    this.price = item?.price || 0,
    this.discount = item?.discount || 0,
    this.amount = item?.amount || 0,
    // this.tax = item?.tax || 7,
    this.discountAmount = item?.discountAmount || 0,
    this.discountType = item?.discountType || "AMOUNT",
    this.quantity = item?.quantity || 1;
  }
}
