import { Component, OnInit } from "@angular/core";
import { Quotation } from "@models/quotation.model";
import { QuotationService } from "@services/_index";
import { QuotationQuery } from "@stores/_index";
import { AlertService } from "@services/alert.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-create-quotation",
  templateUrl: "./create-quotation.component.html",
  styleUrls: ["./create-quotation.component.scss"],
})
export class CreateQuotationComponent implements OnInit {
  public quotation: Observable<Quotation>;
  public isLoading: Boolean = false;

  constructor(
    private quotationService: QuotationService,
    private quotationQuery: QuotationQuery,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.createQuotation();
  }

  createQuotation() {
    this.isLoading = true;
    this.quotationService.createQuotation().subscribe(
      (res) => {
        this.isLoading = false;
        this.quotation = this.quotationQuery.quotation$;
      },
      (err) => {
        this.isLoading = false;
        this.alertService.error(err?.error?.message);
      }
    );
  }
}
