<div>
  <div class="row justify-content-between mb-3">
    <div class="col-xs-12 col-lg-4 mb-2"></div>
    <div class="col-xs-12 col-lg-6 align-items-end">
      <a
        class="btn btn-primary float-right"
        [routerLink]="'/wholesaler/admin/user_groups/new'"
        translate
      >
        buttonTitles.newUserGroup
      </a>
    </div>
  </div>
  <div class="card">
    <div class="card-header" translate>userGroups</div>
    <div class="card-body table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th translate>name</th>
            <th translate>policies</th>
            <th translate>actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-template [ngIf]="!isLoading">
            <tr *ngFor="let userGroup of userGroups; index as i">
              <td>{{ userGroup?.name }}</td>
              <td>
                <p *ngFor="let policy of userGroup?.policies">
                  {{ policy.policyName }}
                </p>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <a
                    class="hoverable text-primary mr-1"
                    [routerLink]="[
                      '/wholesaler/admin/user_groups',
                      userGroup.id
                    ]"
                  >
                    <i
                      class="fa fa-pencil"
                      tooltip="{{ 'tooltip.edit' | translate }}"
                    >
                    </i>
                  </a>
                  <a
                    class="hoverable text-primary mr-3"
                    (click)="onDelete(userGroup.id)"
                  >
                    <i
                      class="fa fa-trash"
                      tooltip="{{ 'tooltip.delete' | translate }}"
                    >
                    </i>
                  </a>
                </div>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
    </div>
  </div>
</div>
