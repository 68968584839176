<div class="input-group" *ngIf="!showFilter">
  <div class="input-group-prepend">
    <span class="input-group-text" id="basic-addon1"
      ><i class="fa fa-search"></i
    ></span>
  </div>
  <input
    id="search-input"
    type="search"
    [disabled]="disabled"
    class="form-control"
    placeholder="{{ placeholder }}"
    (input)="onSearch()"
    [(ngModel)]="search"
  />
</div>

<div class="input-group" *ngIf="showFilter">
  <div class="input-group-prepend">
    <span class="input-group-text" id="basic-addon1"
      ><i class="fa fa-filter"></i
    ></span>
  </div>
  <select
    class="form-control"
    (change)="onSelect(selectedItem)"
    [disabled]="disabled"
    [(ngModel)]="selectedItem"
  >
    <option value="">{{ preSelect }}</option>
    <option [value]="item.id" *ngFor="let item of items">
      <p *ngIf="type === 'product'">
        {{ item.productName }}
      </p>
      <p *ngIf="type === 'account'">
        {{ item.company.name }}
      </p>
    </option>
  </select>
</div>
