import { Store, StoreConfig } from "@datorama/akita";
import { User } from "@models/user.model";
import { Injectable } from "@angular/core";

export interface AuthState {
  currentUser: User;
}
export function createInitialState(): AuthState {
  return {
    currentUser: undefined,
  };
}

@StoreConfig({ name: "auths" })
@Injectable({ providedIn: "root" })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }
}
