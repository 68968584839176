export const wholesalerNavItems: any[] = [
  {
    name: "navItems.dashboard",
    url: "/wholesaler/dashboard",
    icon: "icon-home",
    requireAdmin: false
  },
  {
    name: "navItems.admin",
    url: "/wholesaler/admin",
    icon: "icon-user",
    requireAdmin: true,
    children: [
      {
        name: "navItems.users",
        url: "/wholesaler/admin/users",
        icon: "icon-user-female",
      },
      {
        name: "navItems.userGroups",
        url: "/wholesaler/admin/user_groups",
        icon: "icon-user-following",
      },
      {
        name: "navItems.policy",
        url: "/wholesaler/admin/policy",
        icon: "icon-shield",
      },
      {
        name: "navItems.action",
        url: "/wholesaler/admin/action",
        icon: "icon-cursor",
      },
    ],
  },
  {
    name: "navItems.product",
    url: "/wholesaler/product",
    icon: "icon-drawer",
    requireAdmin: false,
    children: [
      {
        name: "navItems.products",
        url: "/wholesaler/product/product",
        icon: "icon-social-dropbox",
      },
      {
        name: "navItems.productPart",
        url: "/wholesaler/product/part",
        icon: "icon-grid",
      },
      {
        name: "navItems.partPricing",
        url: "/wholesaler/product/part_pricing",
        icon: "icon-tag",
      },
      {
        name: "navItems.productBundle",
        url: "/wholesaler/product/product_bundle",
        icon: "icon-bag"
      }
    ],
  },
  {
    name: "navItems.customer",
    url: "/wholesaler/customer",
    icon: "icon-user",
    requireAdmin: false,
    children: [
      {
        name: "navItems.customers",
        url: "/wholesaler/customer/customers",
        icon: "icon-people"
      },
      {
        name: "navItems.customerTax",
        url: "/wholesaler/customer/customer_tax",
        icon: "icon-book-open"
      }
    ]
  },
  {
    name: "navItems.quotation",
    url: "/wholesaler/quotation",
    icon: "icon-calculator",
    requireAdmin: false,
  },
  {
    name: "navItems.subscription",
    url: "/wholesaler/subscription",
    icon: "icon-credit-card",
    requireAdmin: false
  },
  {
    name: "navItems.billing",
    url: "/wholesaler/billing",
    icon: "icon-paper-clip",
    requireAdmin: false,
    children: [
      {
        name: "navItems.pendingBilling",
        url: "/wholesaler/billing/pending_billing",
        icon: "icon-layers",
      },
      {
        name: "navItems.billing",
        url: "/wholesaler/billing/billing",
        icon: "icon-wallet",
      },
    ],
  },
];
