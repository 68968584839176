import { PartPriceItem } from '@models/_index';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductBundle, ProductBundleItem } from '@models/product-bundle.model';
import { AlertService } from '@services/alert.service';
import { ProductBundleService } from '@services/product-bundle.service';

@Component({
  selector: 'app-edit-product-bundle',
  templateUrl: './edit-product-bundle.component.html',
  styleUrls: ['./edit-product-bundle.component.scss']
})
export class EditProductBundleComponent implements OnInit {
  public isEditing: Boolean = true
  public isLoading: Boolean = true
  public isPartPriceItemLoading: Boolean = true
  public productBundle: ProductBundle = new ProductBundle()
  public productBundleItem: ProductBundleItem[] = []
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productBundleService: ProductBundleService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.findById(params.id)
    })
  }

  findById(id: string): void {
    this.productBundleService.getProductBundleById({ id: id }).subscribe(
      res => {
        if(!(res || res.data)) {
          this.alertService.error("Something went wrong")
          this.router.navigate(['wholesaler/product/product_bundle'])
        }
        this.productBundle = res.data
        this.isLoading = false
      },
      err => {
        console.log(err)
        this.alertService.error("Something went wrong")
        this.router.navigate(['wholesaler/product/product_bundle'])
      }
    )
  }
}


