import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  Router,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { User } from "@models/_index";
import { AlertService } from "@services/alert.service";
import { AuthQuery, AuthStore } from "@stores/_index";
import { AuthService } from "@services/auth.service";
import { catchError, map } from "rxjs/operators";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private authQuery: AuthQuery,
    private authStore: AuthStore,
    private authService: AuthService
  ) {}
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    if (!this.isAuth()) {
      return this.authService.getCurrentUser().pipe(
        map((res: any) => {
          this.authStore.update({ currentUser: res.data });
          return true;
        }),
        catchError((err) => {
          this.router.navigate(["/auth/login"]);
          return of(false);
        })
      );
    } else {
      return true;
    }
  }

  isAuth() {
    const accountId = this.authQuery.currentUserAccountId;
    if (!accountId || accountId === "") {
      return false;
    }
    return true;
  }
}
