import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PartPriceItemQuery } from "@stores/_index";
import { PartPriceItemService } from "@services/part-price-item.service";
import { Observable } from "rxjs";
import { PartPriceItem } from "@models/part-price-item.model";
import { Router } from "@angular/router";
import { AlertService } from "@services/alert.service";
@Component({
  selector: "app-edit-part-pricing",
  templateUrl: "./edit-part-pricing.component.html",
  styleUrls: ["./edit-part-pricing.component.scss"],
})
export class EditPartPricingComponent implements OnInit {
  public partPriceItem: PartPriceItem;
  public isSubmitting: Boolean = false;

  constructor(
    private route: ActivatedRoute,
    private partPriceItemQuery: PartPriceItemQuery,
    private partPriceItemService: PartPriceItemService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.partPriceItemService.getPartPriceItem({ id: params.id }).subscribe(
        (res) => {
          this.partPriceItem = this.partPriceItemQuery.getPartPriceItem;
          this.partPriceItem.productPartId =
            this.partPriceItem.productPart.id;
        },
        (err) => {
          this.alertService.error(err?.error?.message);
        }
      );
    });
  }

  onSubmit() {
    // billingCycle, description is using fake data
    // should discuss later and remove if not be used
    this.isSubmitting = true;
    this.partPriceItemService
      .updatePartPriceItem({
        id: this.partPriceItem.id,
        payload: {
          ...this.partPriceItem,
          billingCycle: "start",
          description: "Test",
          priceItems: this.populatePriceItems(),
        },
      })
      .subscribe(
        (res) => {
          this.isSubmitting = false;
          this.alertService.success(
            "Part Price Item Successfully Updated."
          );
          this.router.navigate(["/wholesaler/product/part_pricing/"]);
        },
        (err) => {
          console.log(err);
          this.isSubmitting = false;
          this.alertService.error(err);
        }
      );
  }

  populatePriceItems() {
    return this.partPriceItem.priceItems.map((p) => ({
      id: p?.id || undefined,
      currency: p.currency,
      maxPriceDiscount: p.maxPriceDiscount,
      price: p.price,
      recurrenceType: p.recurrenceType,
      isDeleted: p.isDeleted || false,
    }));
  }
}
