import { Product } from "./product.model";
import { Status } from "./status.model";
export enum Uom {
  COUNT = "count",
  DAY = "day",
  SWITCH = "switch",
}
export class ProductPart {
  id: string;
  name: string;
  uom: Uom;
  code: string;
  recurring: boolean;
  sku: string;
  productId: string;
  product?: Product = new Product();
  status: Status;
}

export class ProductPartForm {
  id: string;
  name: string;
  uom: Uom;
  code: string;
  recurring: boolean;
  sku: string;
  productId: string;
  status: Status;
}
