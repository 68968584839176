<div class="card" *ngIf="!isLoading">
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <form class="form-full-width" id="productForm" (ngSubmit)="onSubmit(productForm)" #productForm="ngForm">

          <div class="form-group">
            <label translate class="required">productName</label>

            <input [placeholder]="'placeholder.productName' | translate" type="text" #productName="ngModel"
              class="form-control" name="productName" required [(ngModel)]="product.productName" [ngClass]="{
                'is-invalid':
                  productForm.submitted && productName.invalid
              }" />

            <div *ngIf="productForm.submitted && productName.invalid" class="invalid-feedback">
              <div *ngIf="productName.errors.required" translate>
                validateMessages.productNameRequired
              </div>
            </div>
          </div>

          <div class="form-group">
            <label translate class="required">partNumberPrefix</label>

            <input [placeholder]="'placeholder.partNumberPrefix' | translate" type=" text" #partNumberPrefix="ngModel"
              class="form-control" name="partNumberPrefix" required [(ngModel)]="product.partNumberPrefix" [ngClass]="{
                'is-invalid':
                  productForm.submitted && partNumberPrefix.invalid
              }" maxlength="3" style="text-transform:uppercase" />

            <div *ngIf="productForm.submitted && partNumberPrefix.invalid" class="invalid-feedback">
              <div *ngIf="partNumberPrefix.errors.required" translate>
                validateMessages.partNumberPrefix
              </div>
            </div>
          </div>

          <div class="form-group d-flex align-items-center">
            <label translate>active</label>
            <label class="switch switch-label switch-pill switch-success switch-sm float-right ml-2">
              <input #active="ngModel" type="checkbox" class="switch-input" name="active"
                [(ngModel)]="product.active" />
              <span class="switch-slider" data-checked="Yes" data-unchecked="No"></span>
            </label>
          </div>
        </form>
      </div>
    </div>
    <div>
      <button class="btn btn-primary mr-1" [ladda]="isSubmitting" type="submit" [translate]="'buttonTitles.save'"
        form="productForm"></button>
      <button class="btn btn-secondary" [routerLink]="'/wholesaler/product/product'"
        [translate]="'buttonTitles.cancel'"></button>
    </div>
  </div>
</div>

<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>