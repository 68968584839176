import { Component, OnInit } from "@angular/core";

import { UserService } from "@services/user.service";
import { AlertService } from "@services/alert.service";

import { User } from "@models/_index";

import {
  BsModalService,
  BsModalRef,
  ModalOptions,
} from "ngx-bootstrap/modal";
import { ModalComponent } from "@shared/components/modal/modal.component";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit {
  users: User[] = [];
  isLoading: boolean = false;
  public bsModalRef?: BsModalRef;

  constructor(
    private userService: UserService,
    private modalService: BsModalService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    this.isLoading = true;
    this.userService.getUsers().subscribe(
      (res) => {
        this.users = res?.data;
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  onDelete(id: string) {
    const initialState: ModalOptions = {
      initialState: {
        title: "Do you want to remove this item?",
        content:
          "By clicking on OK, this item will be removed immediately",
      },
      animated: false,
    };
    this.bsModalRef = this.modalService.show(ModalComponent, initialState);

    this.bsModalRef.content.eventEmitter.subscribe((isOk) => {
      if (isOk) {
        this.deleteUser(id);
      } else {
        this.bsModalRef.hide();
      }
    });
  }

  deleteUser(id: string) {
    this.userService.deleteUser(id).subscribe(
      (res) => {
        this.alertService.success("Delete user successfully!");
        this.bsModalRef.hide();
        this.getUsers();
      },
      (err) => {
        this.alertService.error(err?.error?.message);
        this.bsModalRef.hide();
      }
    );
  }

  onSearch(keySearch: string) {}
}
