import { Company } from "./company.model";
import { Contact } from "./contact.model";
import { BaseModel } from "./base.model";
export enum AccountStatus {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  DELETED = "DELETED",
}

export enum AccountType {
  SYSTEM = "SYSTEM",
  DIRECT = "DIRECT",
  WHOLESALE = "WHOLESALE",
  RESELLER = "RESELLER",
}

export enum CompanyType {
  GENERAL = "GENERAL",
  CERTIS_BASED = "CERTIS_BASED",
}

export class TaxCode {
  id: string;
  country: string;
  taxCode: string;
  modifier: string;
}
export class Tax {
  id: string;
  taxCodeId: string;
  country: string;
  taxRate: number;
  startDate: string;
}
export class Account extends BaseModel {
  id: string;
  name: string;
  status: AccountStatus;
  atCustomerCode: string;
  sapCustomerCode: string;
  accountTypes: AccountType[] = [AccountType.DIRECT];
  companyId: string;
  company: Company = new Company();
  authorizedContact?: Contact = new Contact();
  billingContact?: Contact = new Contact();
  companyType: CompanyType;
  salesContact?: Contact = new Contact();
  isCertisTaxGroup: boolean = false;
  taxCode: TaxCode;
}
