import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() totalItems: number = 0;
  @Input() currentPage: number = 0;
  @Input() itemPerPages: number = 10;
  @Output() handleGoToPage: EventEmitter<number> = new EventEmitter();
  @Output() changeItemPerPage: EventEmitter<number> = new EventEmitter();

  readonly MAX_DISPLAY_PAGES: number = 7;

  totalPages: number = 0;
  availablePages: number[];
  totalCount: string;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    if (!this.itemPerPages || this.itemPerPages === 0) {
      this.itemPerPages = 10;
    }

    this.updateAvailablePages();
  }

  updateAvailablePages() {
    let fromPage: number;
    let toPage: number;

    this.totalPages = Math.trunc(this.totalItems / this.itemPerPages);
    if (this.totalItems % this.itemPerPages > 0) {
      this.totalPages += 1;
    }

    if (this.totalPages < this.MAX_DISPLAY_PAGES) {
      fromPage = 1;
    } else {
      if (this.currentPage <= 1) {
        fromPage = 1;
      } else {
        fromPage = this.currentPage - 1;
      }
    }

    if (fromPage + this.MAX_DISPLAY_PAGES - 1 > this.totalPages) {
      toPage = this.totalPages;
    } else {
      toPage = fromPage + this.MAX_DISPLAY_PAGES - 1;
    }

    this.availablePages = [];
    for (let i = fromPage; i <= toPage; i++) {
      this.availablePages.push(i);
    }
  }

  shouldEnableFirstPrev() {
    return this.currentPage > 0;
  }

  shouldEnableNextLast() {
    return this.currentPage < this.totalPages - 1;
  }

  goToPage(page: number) {
    this.currentPage = page;
    this.updateAvailablePages();
    this.handleGoToPage.emit(this.currentPage);
  }

  goToFirstPage() {
    if (this.shouldEnableFirstPrev()) {
      this.goToPage(1);
    }
  }

  goToNextPage() {
    if (this.currentPage < this.totalPages - 1) {
      this.goToPage(this.currentPage + 1);
    }
  }

  goToPreviousPage() {
    if (this.currentPage - 1 >= 0 && this.shouldEnableFirstPrev()) {
      this.goToPage(this.currentPage - 1);
    }
  }

  goToLastPage() {
    if (this.shouldEnableNextLast()) {
      this.goToPage(this.totalPages);
    }
  }

  onChangeItemPerPage(itemPerPage: number) {
    const value = Number(itemPerPage);
    this.itemPerPages = value;

    this.changeItemPerPage.emit(itemPerPage);
  }

  calculateEnd(start) {
    const itemPerPage = Number(this.itemPerPages);
    return start + itemPerPage - 1;
  }

  getTotalCount() {
    let start =
      this.currentPage !== 0
        ? this.currentPage * this.itemPerPages + 1
        : 1;
    let end =
      this.currentPage === this.totalPages - 1
        ? this.totalItems
        : this.calculateEnd(start);
    start = this.totalItems === 0 ? 0 : start;
    end = this.totalItems === 0 ? 0 : end;
    this.totalCount =
      "" + start + "-" + end + " of " + this.totalItems + " items";
    return this.totalCount;
  }
}
