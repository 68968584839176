import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

import { UserGroup, Policy } from "@models/_index";
import { UserGroupService, PolicyService } from "@services/_index";

@Component({
  selector: "app-form-user-group",
  templateUrl: "./form-user-group.component.html",
  styleUrls: ["./form-user-group.component.scss"],
})
export class FormUserGroupComponent implements OnInit {
  @Input() userGroup: UserGroup = new UserGroup();
  @Input() isSubmitting: Boolean = false;
  @Output() submitEmitter: EventEmitter<any> = new EventEmitter();

  policies: Policy[] = [];

  constructor(private policyService: PolicyService) {}

  ngOnInit(): void {
    this.getPolicies();
  }

  onSubmit(form) {
    if (!form.form.valid) {
      return;
    }
    this.submitEmitter.emit();
  }

  getPolicies() {
    this.policyService.getPolicies().subscribe(
      (res) => {
        this.policies = res?.data;
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
