import { AlertService } from "@services/_index";
import { ProductBundleService } from "@services/product-bundle.service";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  CurrencyPriceItem,
  PartPriceItem,
  Product,
  ProductBundle,
  ProductBundleItem,
} from "@models/_index";
import { ProductService } from "../../../@core/services/product.service";
import { PartPriceItemService } from "@services/part-price-item.service";
import { format } from "date-fns";
import { Router } from "@angular/router";
import {
  PartPricingItem,
  ProductBundlePriceItem,
} from "@models/product-bundle.model";
import { PartPriceItemQuery } from "@stores/_index";
@Component({
  selector: "app-form-product-bundle",
  templateUrl: "./form-product-bundle.component.html",
  styleUrls: ["./form-product-bundle.component.scss"],
})
export class FormProductBundleComponent implements OnInit {
  @Input() public isEditing: Boolean = false;
  @Input() public productBundle: ProductBundle = new ProductBundle();
  @Input() public isPartPriceItemLoading: Boolean = false;

  public productList: Product[] = [];
  public partPriceItemList: PartPriceItem[] = [];
  public productBundleItemList: ProductBundleItem[] = [];

  public isSubmitting: Boolean = false;
  public isSubmitted: Boolean = false;
  public currentEditItem: number = -1;

  public selectedItem: ProductBundleItem = new ProductBundleItem();
  public effectiveStartDate: Date = new Date();
  public expiringDate: Date = new Date();

  constructor(
    private productService: ProductService,
    private productBundleService: ProductBundleService,
    private partPriceItemService: PartPriceItemService,
    private partPriceItemQuery: PartPriceItemQuery,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (!this.isEditing) {
      this.onSelectEffectiveStartDate(new Date());
      this.onSelectExpiringDate(new Date());
    } else {
      this.effectiveStartDate = new Date(this.productBundle.effectiveDate);
      this.expiringDate = new Date(this.productBundle.expiringDate);
    }
    this.onLoadProductList();
  }

  setProductBundleItemList() {
    this.partPriceItemService.getAllPartPriceItems({}).subscribe((res) => {
      if (!(res || res.data)) return;
      this.productBundleItemList = this.productBundle.partPriceItems.map(
        (item) => {
          const result = res.data.find(
            (p: PartPriceItem) => p.id === item.partPriceItemId
          );
          return {
            product: {
              id: result.productPart.product.id,
              name: result.productPart.product.productName,
            },
            partPriceItem: {
              id: item.id,
              partPriceItemId: item.partPriceItemId,
              name: item.partPriceItemName,
            },
            recurrenceType: this.loadingRecurrenceType(item),
            currency: this.productBundle.currency,
            minimumQuantity: item.minimumQuantity,
            maximumQuantity: item.maximumQuantity,
          };
        }
      );
      this.isPartPriceItemLoading = false;
    });
  }

  loadingRecurrenceType(partPriceItems: ProductBundlePriceItem): string[] {
    const recurrenceType: string[] = [];
    partPriceItems.priceItems.forEach((i: PartPricingItem) => {
      if (recurrenceType.includes(i.recurrenceType)) return;
      recurrenceType.push(i.recurrenceType);
    });
    return recurrenceType;
  }

  onSubmit(form: HTMLFormElement): void {
    if (form.form.invalid) return;
    this.isSubmitting = true;
    this.isEditing ? this.onSubmitEdit() : this.onSubmitCreate();
  }

  onSubmitEdit(): void {
    const payload = {
      name: this.productBundle.name,
      recurrenceType: this.productBundle.recurrenceType,
      effectiveDate: this.productBundle.effectiveDate,
      expiringDate: this.productBundle.expiringDate,
      currency: this.productBundle.currency,
      billDescription: this.productBundle.billDescription,
      maximumDiscount: this.productBundle.maximumDiscount,
      partPriceItems: this.productBundleItemList.map((item) => {
        return {
          id: item.partPriceItem.id,
          partPriceItemId: item.partPriceItem.partPriceItemId,
          maximumQuantity: item.maximumQuantity,
          minimumQuantity: item.minimumQuantity,
        };
      }),
    };

    this.productBundleService
      .updateProductBundle({
        id: this.productBundle.id,
        payload: payload,
      })
      .subscribe(
        () => {
          this.alertService.success(
            "Successfully updated product bundle!"
          );
          this.isSubmitting = false;
          this.router.navigate(["/wholesaler/product/product_bundle"]);
        },
        (err) => {
          this.alertService.error("Something went wrong!");
          console.log(err);
          this.isSubmitting = false;
        }
      );
  }

  onSubmitCreate(): void {
    const payload = {
      name: this.productBundle.name,
      recurrenceType: this.productBundle.recurrenceType,
      effectiveDate: this.productBundle.effectiveDate,
      expiringDate: this.productBundle.expiringDate,
      currency: this.productBundle.currency,
      billDescription: this.productBundle.billDescription,
      maximumDiscount: this.productBundle.maximumDiscount,
      partPriceItems: this.productBundleItemList.map((item) => {
        return {
          partPriceItemId: item.partPriceItem.partPriceItemId,
          maximumQuantity: item.maximumQuantity,
          minimumQuantity: item.minimumQuantity,
        };
      }),
    };

    this.productBundleService
      .createProductBundle({
        payload: payload,
      })
      .subscribe(
        () => {
          this.alertService.success(
            "Successfully created a new product bundle!"
          );
          this.isSubmitting = false;
          this.router.navigate(["/wholesaler/product/product_bundle"]);
        },
        (err) => {
          this.alertService.error("Something went wrong!");
          console.log(err);
          this.isSubmitting = false;
        }
      );
  }

  onLoadProductList(): void {
    this.productService.getProductsNoSubscribe().subscribe((res) => {
      if (!(res || res.data || res.data.content)) return;
      this.productList = res.data.content;
      this.isEditing && this.setProductBundleItemList();
    });
  }

  isThisItemEditing(index: number): Boolean {
    return index === this.currentEditItem;
  }

  onAddNewProductBundleItem(): void {
    this.productBundleItemList.push(new ProductBundleItem());
    this.currentEditItem = this.productBundleItemList.length - 1;
  }

  onChangePartPriceItem(): void {
    this.selectedItem.recurrenceType = [];
    this.selectedItem.currency = "";
    this.selectedItem.partPriceItem.name = this.partPriceItemList.find(
      (p) => p.id === this.selectedItem.partPriceItem.partPriceItemId
    ).name;
    this.generateRecurrenceType();
  }

  onSelectEffectiveStartDate(date: Date) {
    this.productBundle.effectiveDate = format(date, "YYYY-MM-DDTHH:mm:ss");
  }

  onSelectExpiringDate(date: Date) {
    this.productBundle.expiringDate = format(date, "YYYY-MM-DDTHH:mm:ss");
  }

  onChangeProduct(): void {
    this.selectedItem.partPriceItem.partPriceItemId = "";
    this.selectedItem.product.name = this.productList.find(
      (p) => p.id === this.selectedItem.product.id
    ).productName;
    this.loadPartPriceItem();
  }


  getAvailablePriceItem() {
    return this.partPriceItemQuery.getAvailablePriceItem(
      this.productBundle.currency,
      this.productBundle.recurrenceType,
      this.selectedItem.product.id
    );
  }

  loadPartPriceItem(): void {
    this.partPriceItemService.getAllPartPriceItems({}).subscribe((res) => {
      if (!(res || res.data)) return;
      this.partPriceItemList = this.getAvailablePriceItem()
    });
  }

  generateRecurrenceType(isEdit: Boolean = false): void {
    if (isEdit) return;

    this.partPriceItemService
      .getPartPriceItems({ id: this.selectedItem.partPriceItem.partPriceItemId })
      .subscribe((res) => {
        if (!(res || res.data)) return;
        res.data.priceItems.forEach((i: CurrencyPriceItem) =>
          this.validateRecurrenceType(i)
        );
      });
  }

  formatRec(rec: string[]): string {
    const val: string[] =
      this.productBundle.recurrenceType !== "UPFRONT"
        ? ["UPFRONT", this.productBundle.recurrenceType]
        : ["UPFRONT"];

    const filterRec = rec.filter((s: string) => val.includes(s));
    return filterRec.join(" | ");
  }

  validateRecurrenceType(item: CurrencyPriceItem): void {
    if (
      !this.productBundle.currency.includes(item.currency) ||
      this.selectedItem.recurrenceType.includes(item.recurrenceType)
    )
      return;

    this.selectedItem.recurrenceType.push(item.recurrenceType);
    this.selectedItem.currency = item.currency;
  }

  isSelectedProductValid(): Boolean {
    return this.selectedItem.product.id !== "";
  }

  isSelectedPartPriceItemValid(): Boolean {
    return this.selectedItem.partPriceItem.partPriceItemId !== "";
  }

  isSelectedRecurrenceTypeValid(): Boolean {
    return this.selectedItem.recurrenceType.length !== 0;
  }

  isSelectedCurrencyValid(): Boolean {
    return this.selectedItem.currency !== "";
  }

  isMinimumQuantityValid(): Boolean {
    return this.selectedItem.minimumQuantity >= 0;
  }

  isMaximumQuantityValid(): Boolean {
    return this.selectedItem.maximumQuantity >= 0;
  }

  onSaveSelectedItem(): void {
    this.isSubmitted = true;
    const validateChecking =
      this.isSelectedProductValid() &&
      this.isSelectedPartPriceItemValid() &&
      this.isSelectedRecurrenceTypeValid() &&
      this.isSelectedCurrencyValid() &&
      this.isMinimumQuantityValid() &&
      this.isMaximumQuantityValid();

    if (!validateChecking) return;

    this.productBundleItemList[this.currentEditItem] = {
      product: {
        id: this.selectedItem.product.id,
        name: this.selectedItem.product.name,
      },
      partPriceItem: {
        partPriceItemId: this.selectedItem.partPriceItem.partPriceItemId,
        name: this.selectedItem.partPriceItem.name,
      },
      recurrenceType: this.selectedItem.recurrenceType,
      currency: this.selectedItem.currency,
      minimumQuantity: this.selectedItem.minimumQuantity,
      maximumQuantity: this.selectedItem.maximumQuantity,
    };
    this.reset();
  }

  onCancelSelectedItem(index: number): void {
    this.productBundleItemList.splice(index, 1);
    this.reset();
  }

  onRemoveSelectedItem(index: number): void {
    this.productBundleItemList.splice(index, 1);
  }

  onEditSelectedItem(index: number): void {
    this.currentEditItem = index;
    this.selectedItem = {
      product: {
        id: this.productBundleItemList[this.currentEditItem].product.id,
        name: this.productBundleItemList[this.currentEditItem].product
          .name,
      },
      partPriceItem: {
        partPriceItemId: this.productBundleItemList[this.currentEditItem].partPriceItem
          .partPriceItemId,
        name: this.productBundleItemList[this.currentEditItem]
          .partPriceItem.name,
      },
      recurrenceType:
        this.productBundleItemList[this.currentEditItem].recurrenceType,
      currency: this.productBundleItemList[this.currentEditItem].currency,
      minimumQuantity:
        this.productBundleItemList[this.currentEditItem].minimumQuantity,
      maximumQuantity:
        this.productBundleItemList[this.currentEditItem].maximumQuantity,
    };
    this.loadPartPriceItem();
    this.generateRecurrenceType(true);
  }

  reset(): void {
    this.selectedItem = new ProductBundleItem();
    this.isSubmitted = false;
    this.currentEditItem = -1;
  }
}
