export class QuotationProductBundleItem{
    id?: string
    part_pricing_id: string
    part_pricing_name: string
    product_part_id: string
    product_part_name: string
    product_id: string
    product_name: string
    quantity: number
    discount_type: "PERCENTAGE" | "AMOUNT"
    discount_amount: number
    discount_percentage: number
    price: number
    amount: number
    recurrence_type: "QUARTERLY" | "UPFRONT" | "MONTHLY" | "ANNUALLY"
    billing_description: string
    total_price_whole_term: number
}