import { throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { ServiceUrl } from "./config/service-url";
import { ApiService } from "./config/api.service";
import { Payload } from "@models/_index";
import { catchError, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ProductBundleService {
  serviceUrl = new ServiceUrl();
  url = this.serviceUrl.GET_PRODUCT_BUNDLE;

  constructor(private apiService: ApiService) {}

  getAllProductBundle(payload: Payload) {
    return this.apiService.get(`${this.url}`, payload).pipe(
      tap(
        (res) => {},
        catchError((error) => {
          return throwError(error);
        })
      )
    );
  }

  getAllProjects(payload: Payload) {
    return this.apiService.get(`${this.url}/paged`, payload).pipe(
      tap(
        (res) => {},
        catchError((error) => {
          return throwError(error);
        })
      )
    );
  }

  getListProductBundle(payload: Payload) {
    return this.apiService.get(`${this.url}/paged`, payload).pipe(
      tap(
        (res) => {},
        catchError((error) => {
          return throwError(error);
        })
      )
    );
  }

  getProductBundleById(payload: Payload) {
    return this.apiService.get(this.url, payload).pipe(
      tap(
        (res) => {},
        catchError((error) => {
          return throwError(error);
        })
      )
    );
  }

  createProductBundle(payload: Payload) {
    return this.apiService.post(this.url, payload).pipe(
      tap((res) => {}),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  updateProductBundle(payload: Payload) {
    return this.apiService.put(this.url, payload).pipe(
      tap(
        (res) => {},
        catchError((error) => {
          return throwError(error);
        })
      )
    );
  }

  deleteProductBundle(payload: Payload) {
    return this.apiService.delete(this.url, payload).pipe(
      tap(
        (res) => {},
        catchError((error) => {
          return throwError(error);
        })
      )
    );
  }
}
