import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-customer-subscription-filter",
  templateUrl: "./customer-subscription-filter.component.html",
  styleUrls: ["./customer-subscription-filter.component.scss"],
})
export class CustomerSubscriptionFilterComponent implements OnInit {
  // @Input() includedFuture: Boolean = false;
  // @Input() includedExpired: Boolean = false;
  public includedFuture: Boolean = false;
  public includedExpired: Boolean = false;

  @Output() filterChangeEmitter: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  onFilterSubscription(): void {
    this.filterChangeEmitter.emit({
      includedExpired: this.includedExpired,
      includedFuture: this.includedFuture,
    });
  }
}
