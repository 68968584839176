import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";

import { Subscription } from "@models/_index";
import { AlertService, SubscriptionService } from "@services/_index";

@Component({
  selector: "app-void-subscription",
  templateUrl: "./void-subscription.component.html",
  styleUrls: ["./void-subscription.component.scss"],
})
export class VoidSubscriptionComponent implements OnInit {
  @Input() subscription: Subscription = {};

  @Output() getSubscriptions: EventEmitter<any> = new EventEmitter();

  @ViewChild("voidSubscriptionModal", { static: false })
  public voidSubscriptionModal: ModalDirective;
  public isLoading: boolean = false;
  public subscriptionItem: Subscription = {};
  public isInvalidRemark: boolean = false;

  constructor(
    private alertService: AlertService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {}

  onShowModal() {
    this.voidSubscriptionModal.show();
    this.subscriptionItem = { ...this.subscription };
    this.checkValidRemark();
  }

  onCloseModel() {
    this.voidSubscriptionModal.hide();
    this.isInvalidRemark = false;
  }

  onSubmit() {
    this.isLoading = true;
    const subscriptionId = this.subscriptionItem.id;
    const payload = {
      status: "VOID",
      remark: this.subscriptionItem.remark,
    };
    this.subscriptionService
      .voidSubscription(subscriptionId, { payload: payload })
      .subscribe(
        (res) => {
          this.alertService.success(
            "Subscription has been update successfully"
          );
          this.getSubscriptions.emit();
        },
        (err) => {
          this.alertService.error(err?.error?.message);
        }
      );
  }

  onChangeRemark(v) {
    this.checkValidRemark();
  }

  checkValidRemark() {
    if (!this.subscriptionItem.remark) {
      this.isInvalidRemark = true;
      return;
    }
    this.isInvalidRemark = false;
  }
}
