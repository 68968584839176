import { Component, OnInit, Input } from "@angular/core";
import { AlertService } from "@services/alert.service";
import { Quotation, Product, PartPriceItem } from "@models/_index";
import { PartPriceItemService } from "@services/_index";
import { PartPriceItemQuery } from "@stores/_index";
import { animate, style, transition, trigger } from "@angular/animations";
import { format } from "date-fns";

@Component({
  selector: "app-form-preview",
  templateUrl: "./form-preview.component.html",
  styleUrls: ["./form-preview.component.scss"],
  animations: [
    trigger("inOutPaneAnimation", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateX(-100%)" }),
        animate(
          "400ms ease-in-out",
          style({ opacity: 1, transform: "translateX(0)" })
        ),
      ]),
    ]),
  ],
})
export class FormPreviewComponent implements OnInit {
  @Input() isShowing: boolean;
  @Input() quotation: Quotation;

  public partPriceItems: PartPriceItem[];

  constructor(
    private alertService: AlertService,
    private partPriceItemService: PartPriceItemService,
    private partPriceItemQuery: PartPriceItemQuery
  ) {}

  ngOnInit(): void {
    this.loadPartPricing();
  }

  loadPartPricing() {
    this.partPriceItemService
      .getPartPriceItems({ params: { size: 100 } })
      .subscribe(() => {
        this.partPriceItems = this.partPriceItemQuery.getPartPriceItems;
        this.mappingData();
      });
  }

  mappingData() {
    this.quotation.quotationItems.forEach((item) => {
      const priceItem = this.partPriceItems.find(
        (price) => price.id === item.partPricingId
      );
      item.productName = priceItem?.productPart?.product?.productName;
      const productPartName = priceItem?.productPart?.name;
      const priceItemName = priceItem?.name;
      item.partPricingName = `${productPartName} - ${priceItemName}`;
    });
  }

  getQuotationDate() {
    return this.formatDate(new Date(this.quotation.quotationDate));
  }

  getQuotationExpiration() {
    return this.formatDate(new Date(this.quotation.quotationExpiration));
  }

  getSubscriptionStartDate() {
    return this.formatDate(new Date(this.quotation.subscriptionDate));
  }

  getSubscriptionEndDate() {
    return this.formatDate(new Date(this.quotation.subscriptionEndDate));
  }

  formatBillingCycle() {
    const text = this.quotation.billingCycle;
    return `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
  }


  formatDate(date: Date) {
    // TODO: Split this function to utils if needed to DRY CODE
    return format(date, "DD MMM YYYY");
  }
}
