import { Injectable } from "@angular/core";
import { ServiceUrl } from "./config/service-url";
import { ApiService } from "./config/api.service";
import { environment } from "environments/environment";
import { Payload } from "@models/_index"
@Injectable({
  providedIn: "root",
})
export class RoleService {
  serviceUrl = new ServiceUrl();

  url = environment.iamBaseUrl;

  constructor(private apiService: ApiService) {}

  getRoles(payload?: Payload) {
    return this.apiService.getWithoutBaseUrl(`${this.url}`, {});
  }

  deleteRole(payload?: Payload) {
    return this.apiService.deleteWithoutBaseUrl(`${this.url}/${payload.id}`, {});
  }

  createRole(payload?: Payload) {
    return this.apiService.postWithoutBaseUrl(`${this.url}`, payload);
  }
}
