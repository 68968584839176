import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Params } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private baseUrl: string = environment.iamBaseUrl

  constructor(private http: HttpClient) {}

  public get(url: string, payload?: any) {
    let queryParams: HttpParams;
    const id: string = payload?.id || "";
    if (payload?.params) {
      queryParams = this.setParameter(payload.params);
    }
    return this.http.get<any>(`${this.baseUrl}/${url}/${id}`, {
      params: queryParams,
    });
  }

  public postFormData(url: string, payload?: any) {
    const data = new FormData();
    payload = payload.payload
    data.append("quotation_id", payload?.quotation_id);
    data.append("start_date", payload?.start_date);
    for (const file of payload?.files) {
      data.append("files", file);
    }
    data.append(
      "customer_reference_number",
      payload?.customer_reference_number
    );
    return this.http.post(`${this.baseUrl}/${url}`, data);
  }

  public post(url: string, payload?: any, options?: any) {
    return this.http.post(`${this.baseUrl}/${url}`, payload.payload, options);
  }

  public put(url: string, payload?: any) {
    const id: string = payload?.id || "";
    return this.http.put(`${this.baseUrl}/${url}/${id}`, payload.payload);
  }

  public delete(url: string, payload?: any) {
    const id: string = payload?.id || "";
    return this.http.delete(`${this.baseUrl}/${url}/${id}`, {
      params: payload?.payload,
    });
  }

  public deleteCustomUrl(url: string) {
    return this.http.delete(`${this.baseUrl}/${url}`);
  }

  public putCustomUrl(url: string, payload?: any) {
    return this.http.put(`${this.baseUrl}/${url}`, payload.payload);
  }

  // just a testing function, will delete this when everything's ok
  public getWithoutBaseUrl(url: string, payload?: any) {
    let queryParams: HttpParams;
    const id: string = payload?.id || "";
    if (payload?.params) {
      queryParams = this.setParameter(payload.params);
    }

    return this.http.get<any>(`${url}/${id}`, {
      params: queryParams,
    });
  }

  public postWithoutBaseUrl(url: string, payload?: any, options?: any) {
    return this.http.post(`${url}`, payload.payload, options);
  }

  public putWithoutBaseUrl(url: string, payload?: any) {
    return this.http.put(`${url}`, payload.payload);
  }

  public deleteWithoutBaseUrl(url: string, payload?: any) {
    const id: string = payload?.id || "";
    return this.http.delete(`${url}/${id}`, {
      params: payload?.payload,
    });
  }
  // the end of testing func

  private setParameter(routerParams: Params): HttpParams {
    let queryParams = new HttpParams();
    for (const key in routerParams) {
      if (routerParams.hasOwnProperty(key)) {
        queryParams = queryParams.set(key, routerParams[key]);
      }
    }
    return queryParams;
  }
}
