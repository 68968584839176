import { Store, StoreConfig } from "@datorama/akita";
import { Account } from "@models/_index";
import { Injectable } from "@angular/core";

export interface AccountState {
  accounts: Account[];
  accountsByType: Account[];
  account: Account;
  selectedAccount: string;
  selectedReseller: Account;
}
export function createInitialState(): AccountState {
  return {
    accounts: [],
    accountsByType: [],
    account: new Account(),
    selectedAccount: "",
    selectedReseller: new Account(),
  };
}

@StoreConfig({ name: "accounts" })
@Injectable({ providedIn: "root" })
export class AccountStore extends Store<AccountState> {
  constructor() {
    super(createInitialState());
  }
}
