import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-dashboard-widget",
  templateUrl: "./dashboard-widget.component.html",
  styleUrls: ["./dashboard-widget.component.scss"],
})
export class DashboardWidgetComponent implements OnInit {
  @Input() amount: number = 0;
  @Input() title: string = "";
  @Input() icon: string = "";
  @Input() bgColor: string = "";
  @Input() textColor: string = "";
  @Input() linkTo: string = "";

  constructor(private router: Router) {}

  ngOnInit(): void {}

  iconClass() {
    return `${this.icon} ${this.bgColor} p-4 font-2xl mr-3 float-left`;
  }

  textClass() {
    return `h4 ${this.textColor} mb-0 pt-3`;
  }

  goToPage() {
    this.router.navigate([`/wholesaler/${this.linkTo}`]);
  }
}
