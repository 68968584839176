import { Query } from "@datorama/akita";
import { ActionState, ActionStore } from "./action.store";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ActionQuery extends Query<ActionState> {
  constructor(protected store: ActionStore) {
    super(store);
  }

  get getActions() {
    return this.getValue().actions;
  }
}
