import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BillingService } from "@services/billing.service";
import { BillingQuery } from "@stores/_index";
import { PendingBilling } from "@models/billing.model";
import { AlertService } from "@services/alert.service";

@Component({
  selector: "app-billing-details",
  templateUrl: "./billing-details.component.html",
  styleUrls: ["./billing-details.component.scss"],
})
export class BillingDetailsComponent implements OnInit {
  billingId: string = "";
  billingItems: PendingBilling[] = [];
  isLoading: boolean = false;

  // pagination
  currentPage: number = 0;
  totalItems: number = 0;

  // sort
  sortedField: string = "";
  isSortedAsc: boolean;

  readonly ITEMS_PER_PAGE: number = 10;

  constructor(
    private route: ActivatedRoute,
    private billingService: BillingService,
    private billingQuery: BillingQuery,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.billingId = params.id;
      this.getBillingItems();
    });
  }

  getBillingItems() {
    this.isLoading = true;
    this.billingService
      .getBillingItems({
        params: {
          billingId: this.billingId,
          page: this.currentPage,
          sort: this.sortParams(),
        },
      })
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.totalItems = res.data?.totalSize;
          this.billingItems = this.billingQuery.billingItems;
        },
        (err) => {
          this.alertService.errors(err?.error?._embedded?.errors);
        }
      );
  }

  goToPage(page: number) {
    this.currentPage = page - 1;
    this.getBillingItems();
  }

  isSortedOnField(field: string) {
    return this.sortedField === field;
  }

  isSortedAscOnField(field: string) {
    return this.isSortedOnField(field) && this.isSortedAsc;
  }

  isSortedDescOnField(field: string) {
    return this.isSortedOnField(field) && !this.isSortedAsc;
  }

  sortParams() {
    return this.sortedField
      ? `${this.sortedField},${this.isSortedAsc ? "asc" : "desc"}`
      : "";
  }

  onApplySort(field: string) {
    if (field !== this.sortedField) {
      this.isSortedAsc = true;
      this.sortedField = field;
    } else {
      this.isSortedAsc = !this.isSortedAsc;
    }
    this.getBillingItems();
  }
}
