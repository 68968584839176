import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

import { User, UserGroup } from "@models/_index";
import { UserGroupService } from "@services/_index";

@Component({
  selector: "app-form-user",
  templateUrl: "./form-user.component.html",
  styleUrls: ["./form-user.component.scss"],
})
export class FormUserComponent implements OnInit {
  @Input() user: User = new User();
  @Input() isEditing: boolean = false;
  @Input() isSubmitting: Boolean = false;
  @Output() submitEmitter: EventEmitter<any> = new EventEmitter();
  userGroups: UserGroup[] = [];

  constructor(private userGroupService: UserGroupService) {}

  ngOnInit(): void {
    this.getUserGroups();
  }

  onSubmit(form) {
    if (
      !form.form.valid ||
      !this.checkConfirmPassword() ||
      this.isUserGroupsBlank()
    ) {
      return;
    }
    this.submitEmitter.emit();
  }

  checkConfirmPassword() {
    if (this.user.password !== this.user.confirmPassword) {
      return false;
    } else {
      return true;
    }
  }

  getUserGroups() {
    this.userGroupService.getUserGroups().subscribe(
      (res) => {
        this.userGroups = res?.data;
      },
      (err) => {}
    );
  }

  isUserGroupsBlank() {
    return this.user?.userGroups?.length <= 0;
  }
}
