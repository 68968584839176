<div class="d-flex justify-content-between mt-3">
  <div class="d-flex">
    <div class="font-italic pt-2">Showing {{ getTotalCount() }}</div>
    <div>
      <select
        class="form-control selector"
        (change)="onChangeItemPerPage($event.target.value)"
      >
        <option [value]="10">10</option>
        <option [value]="50">50</option>
        <option [value]="100">100</option>
      </select>
    </div>
  </div>

  <div>
    <ul class="pagination" *ngIf="totalItems > 0">
      <li class="page-item">
        <a class="page-link" (click)="goToFirstPage()">«</a>
      </li>
      <li
        *ngFor="let page of availablePages"
        [ngClass]="{
          'page-item': true,
          'active': page == currentPage + 1
        }"
      >
        <a class="page-link" (click)="goToPage(page)">{{ page }}</a>
      </li>
      <li class="page-item">
        <a class="page-link" (click)="goToLastPage()">»</a>
      </li>
    </ul>
  </div>
</div>
