import { Store, StoreConfig } from "@datorama/akita";
import { ProductPart } from "@models/_index";
import { Injectable } from "@angular/core";

export interface ProductPartState {
  productParts: ProductPart[];
}
export function createInitialState(): ProductPartState {
  return {
    productParts: [],
  };
}

@StoreConfig({ name: "productParts" })
@Injectable({ providedIn: "root" })
export class ProductPartStore extends Store<ProductPartState> {
  constructor() {
    super(createInitialState());
  }
}
