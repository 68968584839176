<div class="card my-3">
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <form
          id="userGroupForm"
          (ngSubmit)="onSubmit(userGroupForm)"
          #userGroupForm="ngForm"
        >
          <div class="form-group">
            <label translate class="required">name</label>
            <input
              type="text"
              class="form-control"
              name="name"
              #name="ngModel"
              placeholder="{{ 'placeholder.userGroup' | translate }}"
              [(ngModel)]="userGroup.name"
              [ngClass]="{
                'is-invalid': userGroupForm.submitted && name.invalid
              }"
              required
            />
            <div
              *ngIf="userGroupForm.submitted && name.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="name.errors.required" translate>
                validateMessages.nameRequired
              </div>
            </div>
          </div>

          <div class="form-group">
            <label translate>policies</label>
            <ng-select
              [multiple]="true"
              [closeOnSelect]="false"
              name="policies"
              [(ngModel)]="userGroup.policies"
              placeholder="{{ 'placeholder.policySelect' | translate }}"
            >
              <ng-option
                *ngFor="let policy of policies"
                [value]="policy.id"
                >{{ policy.policyName }}</ng-option
              >
            </ng-select>
          </div>
        </form>
      </div>
    </div>

    <div class="mt-3">
      <button
        class="btn btn-primary mr-1"
        [ladda]="isSubmitting"
        [translate]="'buttonTitles.save'"
        data-style="zoom-in"
        form="userGroupForm"
      ></button>
      <button
        class="btn btn-secondary"
        [translate]="'buttonTitles.cancel'"
        [routerLink]="'/wholesaler/admin/user_groups'"
      ></button>
    </div>
  </div>
</div>
