import { Injectable } from "@angular/core";
import { ServiceUrl } from "./config/service-url";
import { ApiService } from "./config/api.service";
import { BillingStore } from "@stores/_index";
import { tap } from "rxjs/operators";
import { Payload } from "@models/payload.model";

@Injectable({
  providedIn: "root",
})
export class BillingService {
  serviceUrl = new ServiceUrl();

  constructor(
    private store: BillingStore,
    private apiService: ApiService
  ) {}

  getBillings(payload?: Payload) {
    return this.apiService
      .get(this.serviceUrl.GET_BILLINGS, payload)
      .pipe(
        tap((res) => {
          this.store.update({
            billings: res?.data.content,
          });
        })
      );
  }

  updateBillStatus(payload: Payload) {
    return this.apiService.putCustomUrl(
      `${this.serviceUrl.GET_BILLINGS}/${payload.id}/status`, payload
    );
  }

  getBillingItems(payload: Payload) {
    return this.apiService
      .get(this.serviceUrl.GET_BILLING_ITEMS, payload)
      .pipe(
        tap((res) => {
          this.store.update({
            billingItems: res?.data.content,
          });
        })
      );
  }

  exportSSC(payload: Payload) {
    return this.apiService.post(this.serviceUrl.EXPORT_BILLING_ITEMS, payload)
  }

  exportSscByMonth(payload: Payload) {
    return this.apiService.post(
      this.serviceUrl.EXPORT_BILLING_ITEMS_BY_MONTH, payload)
  }

  getBillingExportedExcel(id: string) {
    return this.apiService.post(
      `${this.serviceUrl.GET_BILLING_EXCEL}/${id}`,
      {},
      { observe: "response", responseType: "blob" }
    );
  }
}
