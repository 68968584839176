import { Injectable } from "@angular/core";
import { ServiceUrl } from "./config/service-url";
import { ApiService } from "./config/api.service";
import { tap } from "rxjs/operators";
import { AccountStore } from "../stores/_index";
import { Payload} from "@models/_index";

// import pipe
@Injectable({
  providedIn: "root",
})
export class AccountService {
  private serviceUrl = new ServiceUrl();

  constructor(
    private apiService: ApiService,
    private store: AccountStore
  ) {}

  getAccounts(payload?: Payload) {
    return this.apiService
      .get(this.serviceUrl.GET_ACCOUNTS, payload)
      .pipe(
        tap((res) => {
          this.store.update({ accounts: res?.data?.content || [] });
        })
      );
  }

  getAccountsByType(payload: Payload) {
    return this.apiService
      .get(this.serviceUrl.GET_ACCOUNTS, payload)
      .pipe(
        tap((res) => {
          this.store.update({ accountsByType: res?.data?.content || [] });
        })
      );
  }

  getAccount(id: string) {
    return this.apiService
      .get(this.serviceUrl.GET_ACCOUNTS, { id: id })
      .pipe(
        tap((res) => {
          this.store.update({
            account: res.data,
          });
        })
      );
  }

  getSelectedReseller(id: string) {
    return this.apiService
      .get(this.serviceUrl.GET_ACCOUNTS, { id: id })
      .pipe(
        tap((res) => {
          this.store.update({
            selectedReseller: res.data,
          });
        })
      );
  }

  createAccount(payload: Payload) {
    return this.apiService.post(`${this.serviceUrl.GET_ACCOUNTS}`, payload);
  }

  updateAccount(payload: Payload) {
    return this.apiService.put(`${this.serviceUrl.GET_ACCOUNTS}`, payload);
  }

  deleteAccount(id: string) {
    return this.apiService.delete(`${this.serviceUrl.GET_ACCOUNTS}/${id}`);
  }

  selectAccount(id: string) {
    this.store.update({
      selectedAccount: id,
    });
  }
}
