import { Store, StoreConfig } from "@datorama/akita";
import { Injectable } from "@angular/core";

export interface BillingState {
  billings: [];
  billingItems: [];
}
export function createInitialState(): BillingState {
  return {
    billings: [],
    billingItems: [],
  };
}

@StoreConfig({ name: "billing" })
@Injectable({ providedIn: "root" })
export class BillingStore extends Store<BillingState> {
  constructor() {
    super(createInitialState());
  }
}
