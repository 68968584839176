<div>
  <div class="row justify-content-between mb-3">
    <div class="col-xs-12 col-lg-4 mb-2"></div>
    <div class="col-xs-12 col-lg-6 align-items-end">
      <a
        class="btn btn-primary float-right"
        [routerLink]="'/wholesaler/admin/policy/new'"
        [translate]="'buttonTitles.newPolicy'"
      >
      </a>
    </div>
  </div>
  <div class="card">
    <div class="card-header" translate>policies</div>
    <div class="card-body table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th translate>name</th>
            <th translate>actions</th>
            <th translate>actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-template [ngIf]="!isLoading">
            <tr *ngFor="let policy of policies">
              <td>{{ policy?.policyName }}</td>
              <td>
                <div *ngFor="let action of policy.actions">
                  {{ action?.service }}
                  :
                  {{ action?.operation }}
                </div>
              </td>
              <td>
                <a
                  class="text-decoration-none mr-1"
                  [routerLink]="['/wholesaler/admin/policy/', policy.id]"
                >
                  <i
                    class="fa fa-pencil"
                    tooltip="{{ 'tooltip.edit' | translate }}"
                  ></i>
                </a>
                <a
                  class="text-primary hoverable"
                  (click)="onClickDeleteBtn(policy)"
                >
                  <i
                    class="fa fa-trash"
                    tooltip="{{ 'tooltip.delete' | translate }}"
                  ></i>
                </a>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
    </div>
  </div>
</div>
