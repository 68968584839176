import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Account } from "@models/_index";
import { AlertService } from "@services/alert.service";
import { ConfirmModalService } from "@services/confirm-modal.service";
import { AccountService } from "@services/_index";
import { AccountQuery } from "@stores/_index";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { TabsetComponent } from "ngx-bootstrap/tabs";
import { DeactivateGuard } from "app/guards/deactivate.guard";
@Component({
  selector: "app-edit-customer",
  templateUrl: "./edit-customer.component.html",
  styleUrls: ["./edit-customer.component.scss"],
})
export class EditCustomerComponent implements OnInit {
  @ViewChild("customerTab") customerTab: TabsetComponent;
  @ViewChild("template") modal: ElementRef;
  modalRef: BsModalRef;

  public account: Account;
  public isSubmitting: Boolean = false;
  public isLoading: boolean = false;
  public isEditing: boolean = true;
  public bsModalRef?: BsModalRef;
  public currentTab: string = "form";
  public currentAction: string = "";
  private _account: Account;

  private backSubscription;

  constructor(
    private accountQuery: AccountQuery,
    private accountService: AccountService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private confirmModalService: ConfirmModalService,
    private modalService: BsModalService,
    private deactivateGuard: DeactivateGuard
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.isLoading = true;
      this.accountService.getAccount(params.id).subscribe(
        (res) => {
          this.isLoading = false;
          this.account = this.accountQuery.getAccount;
          this._account = JSON.parse(JSON.stringify(this.account));
        },
        (err) => {
          this.isLoading = false;
          this.alertService.error(err?.error.message);
        }
      );
    });
    this.route.fragment.subscribe((fragment: string) => {
      this.isEditing = fragment === "view" ? false : true;
    });

    this.backSubscription = this.router.events.subscribe((event: any) => {
      if (event.navigationTrigger === "popstate") {
        this.currentAction = "back";
        if (this.isMakingChanges()) {
          this.deactivateGuard.canDeact = false;
          this.openModal();
        } else {
          this.deactivateGuard.canDeact = true;
          this.router.navigate(["/wholesaler/customer/customers"]);
        }
      }
    });
  }

  ngOnDestroy() {
    this.backSubscription.unsubscribe();
  }

  onSelectTab(tabName: string) {
    this.currentAction = "change_tab";
    if (tabName === "form") {
      this.customerTab.tabs[0].active = true;
      this.currentTab = "form";
    } else {
      if (this.isEditing && this.isMakingChanges()) {
        this.openModal();
      } else {
        this.customerTab.tabs[1].active = true;
        this.currentTab = "subscription";
      }
    }
  }

  onSubmit() {
    this.isSubmitting = true;
    this.accountService
      .updateAccount({
        id: this.account.id,
        payload: { ...this.account, status: "ACTIVE" },
      })
      .subscribe(
        () => {
          this.isSubmitting = false;
          this.alertService.success("Customer Successfully Updated.");
          this._account = JSON.parse(JSON.stringify(this.account));
        },
        (err) => {
          console.log(err);
          this.isSubmitting = false;
          this.alertService.errors(err?.error?._embedded?.errors);
        }
      );
  }

  onCancel() {
    this.currentAction = "cancel";
    this.isMakingChanges()
      ? this.openModal()
      : this.router.navigate(["/wholesaler/customer/customers"]);
  }

  isMakingChanges() {
    return JSON.stringify(this._account) !== JSON.stringify(this.account);
  }

  //modal
  openModal() {
    this.modalRef = this.modalService.show(
      this.modal,
      Object.assign({}, { class: "modal-lg" })
    );
  }

  hideModal() {
    this.modalRef.hide();
  }

  saveExit() {
    this.onSubmit();
    switch (this.currentAction) {
      case "change_tab":
        this.customerTab.tabs[1].active = true;
        this.currentTab = "subscription";
        this.hideModal();
        return;
      case "back":
        this.deactivateGuard.canDeact = true;
        this.hideModal();
        this.router.navigate(["/wholesaler/customer/customers"]);
        return;
      case "cancel":
        this.hideModal();
        this.router.navigate(["/wholesaler/customer/customers"]);
        return;
    }
  }

  unsaveExit() {
    switch (this.currentAction) {
      case "change_tab":
        this.hideModal();
        this.account = JSON.parse(JSON.stringify(this._account));
        this.customerTab.tabs[1].active = true;
        this.currentTab = "subscription";
        return;
      case "back":
        this.hideModal();
        this.deactivateGuard.canDeact = true;
        this.router.navigate(["/wholesaler/customer/customers"]);
        return;
      case "cancel":
        this.hideModal();
        this.router.navigate(["/wholesaler/customer/customers"]);
        return;
    }
  }

  exit() {
    // this.account = JSON.parse(JSON.stringify(this._account));
    this.customerTab.tabs[0].active = true;
    this.currentTab = "form";
    this.hideModal();
  }
}
