import { Query } from "@datorama/akita";
import {
  SubscriptionState,
  SubscriptionStore,
} from "./subscription.store";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SubscriptionQuery extends Query<SubscriptionState> {
  constructor(protected store: SubscriptionStore) {
    super(store);
  }

  get subscriptions() {
    return this.getValue().subscriptions;
  }

  get customerSubscriptions() {
    return this.getValue().customerSubscriptions;
  }
}
