import { Query } from "@datorama/akita";
import { ProductPartState, ProductPartStore } from "./product-part.store";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ProductPartQuery extends Query<ProductPartState> {

  constructor(protected store: ProductPartStore) {
    super(store);
  }

  get productParts() {
    return this.getValue().productParts;
  };
}
