import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";

// import { LocationStrategy, HashLocationStrategy }
// from '@angular/common';

import { AppComponent } from "./app.component";

// Import containers
import { P404Component } from "./shared/components/error/404.component";
import { P500Component } from "./shared/components/error/500.component";

import { WholesalerModule } from "./wholesaler/wholesaler.module";
// Import routing module
import { AppRoutingModule } from "./app-routing.module";

// Import 3rd party components
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ChartsModule } from "ng2-charts";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ToasterModule } from "angular2-toaster";

import { ModalModule, BsModalService } from "ngx-bootstrap/modal";
import { NgxSpinnerModule } from "ngx-bootstrap-spinner";
import { AuthModule } from "./auth/auth.module";

import { AuthInterceptor } from "@interceptors/auth.interceptor";

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    ChartsModule,
    WholesalerModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToasterModule.forRoot(),
    ModalModule.forRoot(),
    NgxSpinnerModule,
    AuthModule,
  ],
  declarations: [AppComponent, P404Component, P500Component],
  providers: [
    BsModalService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
