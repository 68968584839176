import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { format, addMonths } from "date-fns";
import { BillingService, AlertService } from "@services/_index";
import { AuthQuery } from "@stores/_index";
import { Billing } from "@models/billing.model";

@Component({
  selector: "app-export-ssc-by-month",
  templateUrl: "./export-ssc-by-month.component.html",
  styleUrls: ["./export-ssc-by-month.component.scss"],
})
export class ExportSscByMonthComponent implements OnInit {
  @Input() isDisabled: Boolean = true;
  @Input() dateFilter: Date = new Date();
  @Input() totalItems: Number = 0;
  @Output() exportEmitter: EventEmitter<any> = new EventEmitter();

  @ViewChild("exportSscByMonthModal", { static: false })
  public exportSscByMonthModal: ModalDirective;
  public isLoading: Boolean = false;
  public isSubmitted: Boolean = false;
  public postingDate: Date = new Date();

  constructor(
    private billingService: BillingService,
    private alertService: AlertService,
    private authQuery: AuthQuery
  ) {}

  ngOnInit(): void {}

  onExportSsc() {
    this.isSubmitted = true;
    const info = this.authQuery.getCurrentUser;

    if (!this.invalidPostingDate()) {
      this.isLoading = true;
      this.billingService
        .exportSscByMonth({
          payload: {
            billingDate: format(this.dateFilter, "YYYY-MM-DD"),
            userId: info.id,
            invoiceDate: format(this.postingDate, "YYYY-MM-DD"),
          }
        })
        .subscribe(
          (res?: any) => {
            this.isLoading = false;
            this.downloadSSCFile(res);
            this.exportSscByMonthModal.hide();
            this.alertService.success(
              `${this.totalItems} subscription rows
              successfully exported.
              New Billing: ${res.data.billingId} created.`
            );
            this.exportEmitter.emit();
          },
          (err?) => {
            this.isLoading = false;
            this.alertService.error(
              err?.error?._embedded?.errors?.message
            );
          }
        );
    }
  }

  downloadSSCFile(res) {
    // eslint-disable-next-line max-len
    const source = `data:application/data:application/zip;base64,${res.data.base64}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${format(this.dateFilter, "MMM YYYY")}.zip`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  invalidPostingDate() {
    return (
      this.isSubmitted &&
      (this.postingDate === undefined || this.postingDate === null)
    );
  }
}
