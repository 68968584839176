import { Query } from "@datorama/akita";
import { AuthState, AuthStore } from "./auth.store";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AuthQuery extends Query<AuthState> {
  currentUser$ = this.select("currentUser");

  constructor(protected store: AuthStore) {
    super(store);
  }

  get getCurrentUser() {
    return this.getValue().currentUser;
  }

  get currentUserAccountId() {
    return this.getValue().currentUser?.accountId;
  }

  isAdmin() {
    return this.getCurrentUser?.userGroupIds?.filter(
      (group: any) => group?.name === "Administrator"
    ).length > 0;
  }
}
