export class Subscription {
  id?: string;
  subscriptionCode?: string;
  account_id?: string;
  company_name?: string;
  billing_type?: string;
  start_date?: string;
  end_date?: string;
  total_price?: number;
  currency?: string;
  status?: string;
  signed_document?: string;
  customer_reference_number?: string;
  billing_description?: string;
  files?: FormData;
  remark?: string;
}
