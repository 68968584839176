<div>
  <div class="row justify-content-between mb-3">
    <div class="col-xs-12 col-lg-4 mb-2 d-flex justify-content-start align-items-start">
      <app-search-bar (handleSearchEmitter)="onSearch($event)" [placeholder]="'search' | translate"
        class="w-100"></app-search-bar>
    </div>
    <div class="col-xs-6 col-lg-8 mb-2 d-flex justify-content-end align-items-start">
      <a class="btn btn-primary float-right" [routerLink]="'/wholesaler/product/product_bundle/new'"
        [translate]="'buttonTitles.newProductBundle'">
      </a>
    </div>
  </div>
  <div class="card">
    <div class="card-header" translate>productBundle</div>
    <div class="card-body table-responsive">
      <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
      <div *ngIf="productBundleList.length === 0 && !isLoading" id="blank-notification" class="h-100 mt-5 mb-5">
        <span class="mr-2 font-weight-bold">No data</span><i class="fa fa-window-close-o" aria-hidden="true"></i>
      </div>
      <table class="table table-striped" *ngIf="productBundleList.length > 0 && !isLoading">
        <thead>
          <tr>
            <th>
              <app-sortable-header [title]="'bundleName' | translate" [isSortedAsc]="isSortedAscOnField('name')"
                [isSortedDesc]="isSortedDescOnField('name')" (handleSort)="onApplySort('name')">
              </app-sortable-header>
            </th>
            <th translate>recurrenceType</th>
            <th translate>currency</th>
            <th>
              <app-sortable-header [title]="'effectiveStartDate' | translate"
                [isSortedAsc]="isSortedAscOnField('effectiveDate')"
                [isSortedDesc]="isSortedDescOnField('effectiveDate')"
                (handleSort)="onApplySort('effectiveDate')"></app-sortable-header>
            </th>
            <th>
              <app-sortable-header [title]="'expiringDate' | translate"
                [isSortedAsc]="isSortedAscOnField('expiringDate')" [isSortedDesc]="isSortedDescOnField('expiringDate')"
                (handleSort)="onApplySort('expiringDate')"></app-sortable-header>
            </th>
            <th translate>actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let pb of productBundleList">
            <td>
              {{ pb.name }}
            </td>
            <td>
              {{ pb.recurrenceType }} 
            </td>
            <td>{{ pb.currency }}</td>
            <td>
              {{ dateFormat(pb.effectiveDate) }}
            </td>
            <td>
              {{ dateFormat(pb.expiringDate) }}
              <span *ngIf="!isDateInRange(pb.expiringDate)" class="badge badge-pill badge-secondary">Expired</span>
            </td>
            <td>
              <a class="text-decoration-none text-primary mr-1" [routerLink]="[
                  '/wholesaler/product/product_bundle',
                  pb.id
                ]">
                <i class="fa fa-pencil" tooltip="{{ 'tooltip.edit' | translate }}"></i>
              </a>
              <a class="hoverable mr-1 text-primary" (click)="onConfirmation(pb)">
                <i class="fa fa-trash" tooltip="{{ 'tooltip.delete' | translate }}"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <app-pagination *ngIf="productBundleList.length > 0" [totalItems]="pagination.totalItems"
        [currentPage]="pagination.currentPage" [itemPerPages]="pagination.itemPerPage"
        (handleGoToPage)="goToPage($event)" (changeItemPerPage)="changeItemPerPage($event)"></app-pagination>
    </div>
  </div>
</div>