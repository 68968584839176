<div>
  <div class="row justify-content-between mb-2">
    <div class="col-xs-12 col-lg-4 mb-2">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"
            ><i class="fa fa-search"></i
          ></span>
        </div>
        <input
          id="search-input"
          type="search"
          class="form-control"
          [placeholder]="'search' | translate"
          (input)="onSearch(search)"
          [(ngModel)]="search"
        />
      </div>
    </div>

    <div class="d-flex col-xs-12 col-lg-6 mb-2">
      <div class="form-group d-flex align-items-center mb-0 ml-2">
        <div class="custom-control custom-checkbox mb-0">
          <input
            type="checkbox"
            class="custom-control-input"
            id="customCheck1"
            [(ngModel)]="isShowDraft"
            name="isShowDraft"
            #isCertisTaxGroup="ngModel"
            (change)="onChangeCheckBoxes()"
          />
          <label class="custom-control-label" for="customCheck1" translate
            >showDraftQuotations</label
          >
        </div>
      </div>
      <div class="form-group d-flex align-items-center mb-0 ml-2">
        <div class="custom-control custom-checkbox mb-0">
          <input
            type="checkbox"
            class="custom-control-input"
            id="isShowGenerated"
            [(ngModel)]="isShowGenerated"
            name="isShowGenerated"
            (change)="onChangeCheckBoxes()"
          />
          <label
            class="custom-control-label"
            for="isShowGenerated"
            translate
            >showGeneratedQuotations</label
          >
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-lg-2 align-items-end">
      <a
        class="btn btn-primary float-right"
        [routerLink]="'/wholesaler/quotation/new'"
        translate
      >
        buttonTitles.newQuotation
      </a>
    </div>
  </div>
  <div class="card">
    <div class="card-header" translate>quotations</div>
    <div class="card-body table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>
              <app-sortable-header
                [title]="'quotationId' | translate"
                [isSortedAsc]="isSortedAscOnField('created_at')"
                [isSortedDesc]="isSortedDescOnField('created_at')"
                (handleSort)="onApplySort('created_at')"
                >>
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'customer' | translate"
                [isSortedAsc]="isSortedAscOnField('account_id')"
                [isSortedDesc]="isSortedDescOnField('account_id')"
                (handleSort)="onApplySort('account_id')"
                >>
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'quotationDate' | translate"
                [isSortedAsc]="isSortedAscOnField('quotation_date')"
                [isSortedDesc]="isSortedDescOnField('quotation_date')"
                (handleSort)="onApplySort('quotation_date')"
                >>
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'quotationExpiration' | translate"
                [isSortedAsc]="isSortedAscOnField('quotation_expiration')"
                [isSortedDesc]="
                  isSortedDescOnField('quotation_expiration')
                "
                (handleSort)="onApplySort('quotation_expiration')"
                >>
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'totalPrice' | translate"
                [isSortedAsc]="isSortedAscOnField('total_price')"
                [isSortedDesc]="isSortedDescOnField('total_price')"
                (handleSort)="onApplySort('total_price')"
                >>
              </app-sortable-header>
            </th>
            <th translate>actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-template [ngIf]="!isLoading">
            <tr *ngFor="let quotation of quotations">
              <td>
                {{ quotation?.quotationCode }}
                <span
                  class="badge badge-pill badge-warning text-white ml-1"
                  *ngIf="isDraftQuotation(quotation)"
                  translate
                >
                  draft
                </span>
                <span
                  class="badge badge-pill badge-success text-white ml-1"
                  *ngIf="isGenerated(quotation)"
                  translate
                >
                  subscriptionGenerated
                </span>
              </td>
              <td>{{ quotation?.companyName }}</td>
              <td>{{ quotation?.quotationDate | date: "dd MMM yyyy" }}</td>
              <td>
                {{ quotation?.quotationExpiration | date: "dd MMM yyyy" }}
              </td>
              <td>{{ quotation?.totalPrice }}</td>
              <td>
                <a
                  class="text-decoration-none mr-1"
                  [routerLink]="['/wholesaler/quotation/', quotation.id]"
                  *ngIf="!isDraftQuotation(quotation)"
                >
                  <i
                    class="fa fa-eye"
                    tooltip="{{ 'tooltip.viewQuotation' | translate }}"
                  >
                  </i>
                </a>
                <a
                  class="text-decoration-none mr-1"
                  [routerLink]="[
                    '/wholesaler/quotation',
                    quotation.id,
                    'edit'
                  ]"
                  *ngIf="isDraftQuotation(quotation)"
                >
                  <i
                    class="fa fa-pencil"
                    tooltip="{{ 'tooltip.edit' | translate }}"
                  >
                  </i>
                </a>
                <a
                  class="text-primary hoverable mr-1"
                  (click)="onDelete(quotation.id)"
                >
                  <i
                    class="fa fa-trash"
                    tooltip="{{ 'tooltip.delete' | translate }}"
                  >
                  </i>
                </a>
                <a
                  class="text-primary hoverable mr-1"
                  *ngIf="!isDraftQuotation(quotation)"
                  (click)="onDownloadPdf(quotation.id)"
                >
                  <i
                    class="fa fa-file-pdf-o"
                    tooltip="{{ 'tooltip.downloadQuotation' | translate }}"
                  ></i>
                </a>
                <a
                  class="text-decoration-none mr-1"
                  *ngIf="
                    !isDraftQuotation(quotation) && !isGenerated(quotation)
                  "
                  [routerLink]="['/wholesaler/subscription/new']"
                  [queryParams]="{ quotationId: quotation.id }"
                >
                  <i
                    class="fa fa-location-arrow"
                    aria-hidden="true"
                    tooltip="{{
                      'tooltip.generateSubscription' | translate
                    }}"
                  ></i>
                </a>
                <a
                  class="text-decoration-none mr-1"
                  *ngIf="
                    !isDraftQuotation(quotation) && isGenerated(quotation)
                  "
                  [routerLink]="['/wholesaler/subscription/']"
                  [queryParams]="{ quotationId: quotation.quotationCode }"
                >
                  <i
                    class="fa fa-search"
                    aria-hidden="true"
                    tooltip="{{ 'tooltip.viewSubscription' | translate }}"
                  ></i>
                </a>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
      <app-pagination
        [totalItems]="totalItems"
        [currentPage]="currentPage"
        [itemPerPages]="itemPerPage"
        (handleGoToPage)="goToPage($event)"
        (changeItemPerPage)="changeItemPerPage($event)"
      ></app-pagination>
    </div>
  </div>
</div>
