import { Component, Input, OnInit } from "@angular/core";
import { Quotation } from "@models/_index";
import { QuotationService } from "@services/_index";
import { QuotationQuery, AuthQuery } from "@stores/_index";
import { AlertService } from "@services/alert.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";

import { BsModalRef } from "ngx-bootstrap/modal";
import { ConfirmModalService } from "@services/confirm-modal.service";

enum STEPS {
  QUOTATION_STEP = 1,
  CONTACT_STEP = 2,
  PRODUCT_STEP = 3,
  PREVIEW_STEP = 4,
}

@Component({
  selector: "app-form-quotation",
  templateUrl: "./form-quotation.component.html",
  styleUrls: ["./form-quotation.component.scss"],
})
export class FormQuotationComponent implements OnInit {
  @Input() quotation: Quotation;
  @Input() isShowingDetails: Boolean = false;
  @Input() isEditing: Boolean = true;

  public isSubmitting: Boolean = false;
  public isSubmitted: Boolean = false;

  public currentStep: number;

  public bsModalRef?: BsModalRef;

  private steps = STEPS;
  private isEditingProductItem: boolean = false;

  constructor(
    private quotationService: QuotationService,
    private quotationQuery: QuotationQuery,
    private authQuery: AuthQuery,
    private alertService: AlertService,
    private confirmModalService: ConfirmModalService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.currentStep = this.steps.QUOTATION_STEP;
  }

  ngOnInit() {
    if (!this.isEditing) {
      this.initSalesPersonInfo();
    }
  }

  initSalesPersonInfo() {
    const infoUser = this.authQuery.getCurrentUser;
    this.quotation.salespersonName = infoUser.displayName;
    this.quotation.salespersonContactNumber = infoUser.phoneNumber;
    this.quotation.salespersonEmail = infoUser.email;
  }

  onNextStep(form: HTMLFormElement) {
    this.isSubmitted = true;

    if (form.form.invalid) {
      return;
    }

    if (this.isShowingDetails) {
      this.currentStep += 1;
    } else {
      this.editingNextStepHandler();
    }
  }

  editingNextStepHandler() {
    switch (this.currentStep) {
      case this.steps.QUOTATION_STEP:
        this.createQuotationStep();
        break;
      case this.steps.CONTACT_STEP:
        this.createContactStep();
        break;
      case this.steps.PRODUCT_STEP:
        this.checkProductStep();
        break;
      default:
        this.createQuotationStep();
        break;
    }
  }

  createQuotationStep() {
    this.isSubmitting = true;
    if (
      this?.quotation?.termLength === null ||
      (this.quotation.termLength && this?.quotation.termLength < 0)
    ) {
      return;
    }

    this.quotationService
      .createQuotationStep({
        id: this.quotationQuery.quotation.id,
        payload: {
          currency: this.quotation.currency,
          sales_mode: this.quotation.salesMode,
          payment_term: this.quotation.paymentTerm,
          quotation_date: this.quotation.quotationDate,
          subscription_date: this.quotation.subscriptionDate,
          termLength: this.quotation.termLength,
          quotation_expiration: this.quotation.quotationExpiration,
          account_id: this.quotation.accountId,
          reseller: this.quotation.reseller,
          salesperson_name: this.quotation.salespersonName,
          tax: this.quotation.tax,
          // eslint-disable-next-line max-len
          salesperson_contact_number:
            this.quotation.salespersonContactNumber,
          salesperson_email: this.quotation.salespersonEmail,
          billing_cycle: this.quotation.billingCycle,
        },
      })
      .subscribe(
        () => {
          this.handleSuccess();
        },
        (err) => {
          this.handleError(err);
        }
      );
  }

  createContactStep() {
    this.isSubmitting = true;
    this.quotationService
      .createContactDetails({
        id: this.quotationQuery.quotation.id,
        payload: {
          sales_contact: {
            person_in_charge: this.quotation.salesContact.personInCharge,
            contact_number: this.quotation.salesContact.contactNumber,
            email: this.quotation.salesContact.email,
          },
          billing_contact: {
            person_in_charge: this.quotation.billingContact.personInCharge,
            contact_number: this.quotation.billingContact.contactNumber,
            email: this.quotation.billingContact.email,
          },
        }
      })
      .subscribe(
        (res) => {
          this.handleSuccess();
        },
        (err) => {
          this.handleError(err);
        }
      );
  }

  checkProductStep() {
    if (this.isEditingProductItem) {
      this.alertService.error("There is unconfirmed part pricing");
    } else {
      this.handleSuccess();
    }
  }

  activateDraftQuotation() {
    this.route.params.subscribe((params) => {
      if (params.id && !this.isDraftQuotation()) {
        this.handleSuccess();
      } else {
        this.quotationService
          .activateDraftQuotation({ id: this.quotation.id })
          .subscribe(
            (res) => {
              this.handleSuccess();
            },
            (err) => {
              this.handleError(err);
            }
          );
      }
    });
  }

  downloadPDF() {
    if (this.isShowingDetails) {
      this.quotationService
        .generatePdfQuotation({
          id: this.quotation.id,
          payload: {
            notes: this.quotation.notes,
          },
        })
        .subscribe(
          (res?: any) => {
            this.showPDF(this.quotation.quotationCode, res?.file);
          },
          (err) => {
            this.handleError(err);
          }
        );
    } else {
      this.bsModalRef = this.confirmModalService.show(
        "Confirmation?",
        `By clicking on OK, this Draft Quotation
        will be generated and activated.
        You can no longer edit this Quotation.`
      );

      this.bsModalRef.content.eventEmitter.subscribe((isOk) => {
        if (isOk) {
          this.handleOk();
        } else {
          this.bsModalRef.hide();
        }
      });
    }
  }

  handleOk() {
    this.quotationService
      .activateDraftQuotation({ id: this.quotation.id })
      .subscribe(
        (activateRes) => {
          this.quotationService
            .generatePdfQuotation({
              id: this.quotation.id,
              payload: {
                notes: this.quotation.notes,
              }
            })
            .subscribe(
              (res?: any) => {
                this.bsModalRef.hide();
                this.showPDF(this.quotation.quotationCode, res?.file);
              },
              (err) => {
                this.bsModalRef.hide();
                this.handleError(err);
              }
            );
        },
        (err) => {
          this.bsModalRef.hide();
          this.handleError(err);
        }
      );
  }

  showPDF(fileName, base64String) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`;
    link.click();
  }

  onBackStep() {
    this.currentStep -= 1;
  }

  onCancel() {
    if (this.isDraftQuotation()) {
      this.alertService.success(
        `Quotation ID: ${this.quotation.id} saved as draft`
      );
    }
    this.router.navigate(["/wholesaler/quotation"]);
  }

  isOnQuotationStep() {
    return this.currentStep === this.steps.QUOTATION_STEP;
  }

  isOnContactStep() {
    return this.currentStep === this.steps.CONTACT_STEP;
  }

  isOnProductStep() {
    return this.currentStep === this.steps.PRODUCT_STEP;
  }

  isOnPreviewStep() {
    return this.currentStep === this.steps.PREVIEW_STEP;
  }

  onSubmitContactDetailsForm(payload) { }

  handleSuccess() {
    this.isSubmitting = false;
    this.isSubmitted = false;
    this.currentStep += 1;
  }

  handleError(err) {
    this.isSubmitting = false;
    this.alertService.errors(err?.error?._embedded?.errors);
  }

  isDraftQuotation() {
    return this, this.quotation.status === "DRAFT";
  }
}
