import { Component, OnInit } from "@angular/core";
import { AuthQuery } from "@stores/_index";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  public lastLoggedIn: Date;
  
  constructor(private authQuery: AuthQuery) {}

  ngOnInit(): void {
    if (this.authQuery.getCurrentUser?.lastLoggedIn) {
      this.lastLoggedIn = new Date(this.authQuery.getCurrentUser?.lastLoggedIn?.split("[")[0]);
    }
  }
}
