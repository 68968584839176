<a
  tooltip="{{ 'tooltip.viewSignedDocument' | translate }}"
  class="text-primary hoverable mr-1"
  (click)="onShowModal()"
>
  <i class="fa fa-eye"> </i>
</a>

<div
  bsModal
  #editAttachmentSubscriptionModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" translate>editSignedDocument</h4>
        <button
          type="button"
          class="close"
          (click)="editAttachmentSubscriptionModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="padding: 0">
        <div class="card-body table-responsive">
          <div class="row mb-3">
            <div class="col-12 justify-content-end">
              <ngx-dropzone
                #drop
                style="display: none"
                [multiple]="true"
                accept="*"
                (change)="onSelect($event)"
              ></ngx-dropzone>
              <button
                class="btn btn-primary float-right"
                (click)="drop.showFileSelector()"
                translate
              >
                tooltip.uploadNewFiles
              </button>
            </div>
          </div>
          <table class="table">
            <thead>
              <tr style="background-color: #fff">
                <th translate>fileName</th>
                <th translate>actions</th>
              </tr>
            </thead>
            <tbody>
              <ng-template [ngIf]="!isLoading">
                <tr *ngFor="let file of files; index as i">
                  <td>{{ file.filename }}</td>
                  <td>
                    <a
                      class="text-primary hoverable mr-1"
                      (click)="onDownloadFile(file.id, i)"
                      tooltip="{{ 'tooltip.downloadFile' | translate }}"
                    >
                      <i class="fa fa-download"></i>
                    </a>
                    <a
                      class="text-primary hoverable"
                      (click)="onDeleteFile(file.id)"
                    >
                      <i
                        class="fa fa-trash"
                        tooltip="{{ 'tooltip.delete' | translate }}"
                      ></i>
                    </a>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
          <app-loading-spinner
            [isLoading]="isLoading"
          ></app-loading-spinner>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="editAttachmentSubscriptionModal.hide()"
            class="btn btn-primary"
            translate
          >
            tooltip.done
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
