import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-billing-detail-status",
  templateUrl: "./billing-detail-status.component.html",
  styleUrls: ["./billing-detail-status.component.scss"],
})
export class BillingDetailStatusComponent implements OnInit {
  @Input() status: string = "";

  constructor() {}

  ngOnInit(): void {}
}
