<!--
  Tried to split header to another component to reduce number of code
  lines but got some error
  TODO: Split header to another component
-->
<div class="wrapper">
  <app-header
    [navbarBrandRouterLink]="['dashboard']"
    [fixed]="true"
    [navbarBrandFull]="{
      src: 'assets/img/brand/augment_logo.png',
      width: 89,
      height: 25,
      alt: 'Augment+ Logo'
    }"
    [navbarBrandMinimized]="{
      src: 'assets/img/brand/augment_mini_logo.png',
      width: 30,
      height: 30,
      alt: 'Augment+ Logo'
    }"
    [sidebarToggler]="'lg'"
    [asideMenuToggler]="false"
  >
    <ul class="nav navbar-nav ml-auto">
      <li class="nav-item dropdown" dropdown placement="bottom right">
        <a
          class="nav-link"
          data-toggle="dropdown"
          href="#"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          dropdownToggle
          (click)="(false)"
        >
          <span class="pr-4">
            {{ displayUserInfo() }}
            <i
              class="fa fa-sort-desc"
              style="transform: translateY(-3px)"
            ></i>
          </span>
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          *dropdownMenu
          aria-labelledby="simple-dropdown"
        >
          <a class="dropdown-item" [routerLink]="'/auth/logout'"
            ><i class="fa fa-lock"></i> Logout</a
          >
        </div>
      </li>
    </ul>
  </app-header>

  <div class="app-body">
    <app-layout-sidebar></app-layout-sidebar>

    <main class="main">
      <app-403 [hidden]="isAuthorized"></app-403>

      <div [hidden]="!isAuthorized">
        <cui-breadcrumb></cui-breadcrumb>

        <div class="container-fluid">
          <router-outlet></router-outlet>
        </div>
      </div>
    </main>
  </div>

  <app-footer [hidden]="!isAuthorized">
    <span
      ><a href="https://augmenttech.sg/">&copy; Augmen+ Technologies</a>
    </span>
  </app-footer>
</div>
