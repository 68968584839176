import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-form-header",
  templateUrl: "./form-header.component.html",
  styleUrls: ["./form-header.component.scss"],
})
export class FormHeaderComponent implements OnInit {
  @Input() currentStep: number;

  public headers: any[] = [
    {
      id: "quotation",
      translateKey: "quotationDetails",
      title: "Quotation Details",
      step: 1,
    },
    {
      id: "contact",
      translateKey: "contactDetails",
      title: "Contact Details",
      step: 2,
    },
    {
      id: "product",
      translateKey: "productDetails",
      title: "Product Details",
      step: 3,
    },
    {
      id: "preview_download",
      translateKey: "previewAndDownload",
      title: "Preview and Download",
      step: 4,
    },
  ];;

  constructor() {
  }

  ngOnInit(): void {}

  isSelected(header) {
    return header.step === this.currentStep;
  }
}
