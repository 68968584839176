import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Account } from "@models/_index";
import { AccountQuery } from "@stores/_index";
import { AccountService } from "@services/_index";
import { AlertService } from "@services/alert.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ConfirmModalService } from "@services/confirm-modal.service";

@Component({
  selector: "app-customer",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"],
})
export class CustomerComponent implements OnInit {
  public accounts: Account[] = [];
  public isLoading: Boolean = false;
  public bsModalRef?: BsModalRef;

  currentPage: number = 0;
  totalItems: number;
  itemPerPage: number = 10;

  search: string = "";

  // sort
  sortedField: string;
  isSortedAsc: boolean;

  readonly ITEMS_PER_PAGE: number = 10;

  constructor(
    private accountQuery: AccountQuery,
    private accountService: AccountService,
    private alertService: AlertService,
    private confirmModalService: ConfirmModalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadAccount();
  }

  loadAccount() {
    this.isLoading = true;
    this.accountService
      .getAccounts({
        params: {
          page: this.currentPage,
          keySearch: this.search,
          sort: this.sortParams(),
          size: this.itemPerPage,
        },
      })
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.accounts = this.accountQuery.getAccounts;
          this.totalItems = res.data?.totalSize;
        },
        (err) => {
          this.isLoading = false;
          this.alertService.error(err?.error?.message);
        }
      );
  }

  onViewDetail(accountId: string) {
    this.router.navigate(["wholesaler/customer/customers", accountId], { fragment:"view"});
  }

  onClickDeleteBtn(account: Account) {
    this.bsModalRef = this.confirmModalService.show(
      "Do you want to delete this item?",
      "By clicking on OK, this item will be removed immediately"
    );

    this.bsModalRef.content.eventEmitter.subscribe((isOk) => {
      if (isOk) {
        this.deleteAccount(account);
      } else {
        this.bsModalRef.hide();
      }
    });
  }

  deleteAccount(account: Account) {
    this.accountService.deleteAccount(account.id).subscribe(
      (res) => {
        this.bsModalRef.hide();
        this.loadAccount();
        this.alertService.success("Customer Successfully Removed.");
      },
      (err) => {
        this.isLoading = false;

        this.alertService.error(err?.error?.message);
      }
    );
  }

  goToSubscription(accountId: string) {
    this.accountService.selectAccount(accountId);
    this.router.navigate(["wholesaler/subscription"]);
  }

  goToPage(page: number) {
    this.currentPage = page - 1;
    this.loadAccount();
  }

  onSearch(keySearch: string) {
    this.search = keySearch;
    this.currentPage = 0;
    this.loadAccount();
  }

  changeItemPerPage(itemPerPages: number) {
    this.currentPage = 0;
    this.itemPerPage = itemPerPages;
    this.loadAccount();
  }

  // below are sortable function
  isSortedOnField(field: string) {
    return this.sortedField === field;
  }

  isSortedAscOnField(field: string) {
    return this.isSortedOnField(field) && this.isSortedAsc;
  }

  isSortedDescOnField(field: string) {
    return this.isSortedOnField(field) && !this.isSortedAsc;
  }

  sortParams() {
    return this.sortedField
      ? `${this.sortedField},${this.isSortedAsc ? "asc" : "desc"}`
      : "";
  }

  onApplySort(field: string) {
    if (field !== this.sortedField) {
      this.isSortedAsc = true;
      this.sortedField = field;
    } else {
      this.isSortedAsc = !this.isSortedAsc;
    }
    this.loadAccount();
  }
}
