<div class="card my-3">
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-xl-9">
        <form
          id="partPricingForm"
          (ngSubmit)="onSubmit(partPricingForm)"
          #partPricingForm="ngForm"
        >
          <div class="form-group">
            <label translate class="required">product</label>
            <div class="row">
              <div class="col-12 col-lg-8 col-xl-8">
                <select
                  class="form-control"
                  id="productId"
                  #productId="ngModel"
                  [ngModel]="selectedProductId"
                  name="productId"
                  [disabled]="isEditing"
                  (change)="onSelectProduct(productId.value)"
                  required
                  [ngClass]="{
                    'is-invalid':
                      partPricingForm.submitted && productId.invalid
                  }"
                >
                  <option
                    value=""
                    [translate]="'placeholder.selectProduct'"
                    hidden
                  ></option>
                  <option
                    [value]="product.id"
                    *ngFor="let product of products"
                  >
                    {{ product.productName }}
                  </option>
                </select>
                <div
                  *ngIf="partPricingForm.submitted && productId.invalid"
                  class="invalid-feedback"
                >
                  <div *ngIf="productId.errors.required" translate>
                    validateMessages.product
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4 col-xl-3 mt-3 mt-lg-0">
                <a
                  [routerLink]="'/wholesaler/product/product'"
                  translate
                  class="btn btn-primary btn-block"
                >
                  buttonTitles.manageProducts
                </a>
              </div>
            </div>
          </div>

          <ng-template [ngIf]="selectedProductId?.length">
            <div class="form-group">
              <label translate class="required">productPart</label>
              <div class="row">
                <div class="col-12 col-lg-8 col-xl-8">
                  <select
                    class="form-control"
                    id="productPart"
                    name="productPartId"
                    #productPartId="ngModel"
                    required
                    [ngClass]="{
                      'is-invalid':
                        partPricingForm.submitted && productPartId.invalid
                    }"
                    [disabled]="isEditing"
                    [(ngModel)]="selectedProductPartId"
                    (change)="onSelectProductPart(productPartId.value)"
                  >
                    <option
                      value=""
                      [translate]="'placeholder.selectProductPart'"
                      hidden
                    ></option>
                    <option
                      [value]="part.id"
                      *ngFor="let part of productParts"
                    >
                      {{ part.name }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      partPricingForm.submitted && productPartId.invalid
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="productPartId.errors.required" translate>
                      validateMessages.productPart
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-4 col-xl-3 mt-3 mt-lg-0">
                  <a
                    [routerLink]="'/wholesaler/product/part'"
                    translate
                    class="btn btn-block btn-primary"
                  >
                    buttonTitles.manageProductParts
                  </a>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label translate class="required">internalName</label>
              <div class="row">
                <div class="col-12 col-xl-8">
                  <div class="input-prepend input-group">
                    <input
                      id="prependedInput"
                      type="text"
                      class="form-control"
                      name="internalName"
                      #internalName="ngModel"
                      required
                      [ngClass]="{
                        'is-invalid':
                          partPricingForm.submitted && internalName.invalid
                      }"
                      [(ngModel)]="partPriceItem.name"
                      [placeholder]="
                        'placeholder.internalName' | translate
                      "
                    />
                  </div>
                  <div
                    *ngIf="
                      partPricingForm.submitted && internalName.invalid
                    "
                    class="invalid-feedback d-block"
                  >
                    <div *ngIf="internalName.errors.required" translate>
                      validateMessages.internalName
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label translate class="required">billDescription</label>
              <ng-template #popTemplate><div [innerHTML]="showTooltip()"></div></ng-template>
              <i
                class="ml-1 icon-info"
                [tooltip]="popTemplate"
                placement="top"
              ></i>
              <span class="character-left-italic">
                Characters left: {{ charactersLeft }}
              </span>
              <div class="row">
                <div class="col-12 col-xl-8">
                  <div class="input-prepend input-group">
                    <input
                      id="prependedInput"
                      type="text"
                      class="form-control"
                      name="billDescription"
                      #billDescription="ngModel"
                      [maxlength]="maxLength"
                      required
                      [ngClass]="{
                        'is-invalid':
                          partPricingForm.submitted &&
                          billDescription.invalid
                      }"
                      [(ngModel)]="partPriceItem.billDescription"
                      [placeholder]="
                        'placeholder.billDescription' | translate
                      "
                      (input)="
                        onChangeBillDescription($event.target.value)
                      "
                    />
                  </div>

                  <div
                    *ngIf="
                      partPricingForm.submitted && billDescription.invalid
                    "
                    class="invalid-feedback d-block"
                  >
                    <div *ngIf="billDescription.errors.required" translate>
                      validateMessages.billDescription
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label translate class="required">minimumQuantity</label>
              <div class="row">
                <div class="col-12 col-xl-8">
                  <div class="input-prepend input-group">
                    <input
                      id="prependedInput"
                      type="number"
                      class="form-control"
                      name="minimumQuantity"
                      #minimumQuantity="ngModel"
                      required
                      [ngClass]="{
                        'is-invalid':
                          partPricingForm.submitted &&
                          minimumQuantity.invalid
                      }"
                      [(ngModel)]="partPriceItem.minimumQuantity"
                      [placeholder]="
                        'placeholder.minimumQuantity' | translate
                      "
                    />
                  </div>

                  <div
                    *ngIf="
                      partPricingForm.submitted && minimumQuantity.invalid
                    "
                    class="invalid-feedback d-block"
                  >
                    <div *ngIf="minimumQuantity.errors.required" translate>
                      validateMessages.minimumQuantity
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </form>
      </div>
    </div>
    <div class="table-currencies">
      <div class="table-responsive">
        <table
          class="table align-items-center table-flush mt-3"
          *ngIf="selectedProductId?.length"
        >
          <thead>
            <tr>
              <th width="20%" translate>currency</th>
              <th width="20%" translate>price</th>
              <th width="20%" translate>maxPriceDiscount</th>
              <th width="20%" translate>recurrence</th>
              <th width="20%" translate>actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let currencyPriceItem of partPriceItem.priceItems;
                index as i
              "
            >
              <ng-template [ngIf]="isShowPriceItem(currencyPriceItem)">
                <ng-template [ngIf]="!isCurrencyPriceItemEditing(i)">
                  <td>{{ currencyPriceItem.currency }}</td>
                  <td>
                    {{ currencyPriceItem.price | currency: "":"" }}
                  </td>
                  <td>
                    {{
                      currencyPriceItem.maxPriceDiscount | currency: "":""
                    }}
                  </td>
                  <td>{{ currencyPriceItem.recurrenceType }}</td>

                  <td>
                    <button
                      class="btn btn-primary mr-1"
                      (click)="onEditCurrencyPriceItem(i)"
                      [disabled]="isEditingCurrencyPriceItem"
                    >
                      <i class="fa fa-pencil"></i>
                    </button>
                    <button
                      class="btn btn-primary"
                      (click)="onRemoveCurrencyPriceItem(i)"
                      [disabled]="isEditingCurrencyPriceItem"
                    >
                      <i class="fa fa-trash"> </i>
                    </button>
                  </td>
                </ng-template>
                <ng-template [ngIf]="isCurrencyPriceItemEditing(i)">
                  <td>
                    <div
                      class="form-group mb-0"
                      *ngIf="selectedProductId.length"
                    >
                      <select
                        class="form-control"
                        id="currency"
                        name="currency"
                        #currency="ngModel"
                        required
                        [(ngModel)]="currencyPriceItem.currency"
                        [ngClass]="{
                          'is-invalid':
                            !isCurrencyPriceValid && currency.invalid
                        }"
                      >
                        <option
                          value=""
                          [translate]="'placeholder.selectCurrency'"
                          hidden
                        ></option>
                        <option value="USD">USD</option>
                        <option value="SGD">SGD</option>
                        <option value="MYR">MYR</option>
                      </select>
                      <div
                        *ngIf="!isCurrencyPriceValid && currency.invalid"
                        class="invalid-feedback"
                      >
                        <div *ngIf="currency.errors.required" translate>
                          validateMessages.currency
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      class="form-group mb-0"
                      *ngIf="selectedProductId.length"
                    >
                      <input
                        type="number"
                        id="price"
                        class="form-control"
                        name="price"
                        #price="ngModel"
                        [(ngModel)]="currencyPriceItem.price"
                        required
                        [ngClass]="{
                          'is-invalid':
                            !isCurrencyPriceValid && price.invalid
                        }"
                        pattern="^\d*(\.\d{0,2})?$"
                        [placeholder]="'placeholder.price' | translate"
                      />
                      <div
                        *ngIf="!isCurrencyPriceValid && price.invalid"
                        class="invalid-feedback"
                      >
                        <div *ngIf="price.errors.required" translate>
                          validateMessages.priceRequired
                        </div>
                        <div *ngIf="price.errors.pattern" translate>
                          validateMessages.priceDecimalRequired
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="form-group mb-0">
                      <input
                        type="number"
                        step="0.1"
                        class="form-control"
                        name="maxPriceDiscount"
                        #maxPriceDiscount="ngModel"
                        [(ngModel)]="currencyPriceItem.maxPriceDiscount"
                        required
                        [placeholder]="
                          'placeholder.maxPriceDiscount' | translate
                        "
                        [ngClass]="{
                          'is-invalid':
                            !isCurrencyPriceValid &&
                            (maxPriceDiscount.invalid ||
                              !isMaxPriceDiscountValid)
                        }"
                        pattern="^\d*(\.\d{0,1})?$"
                      />
                      <div
                        *ngIf="
                          !isCurrencyPriceValid &&
                          (maxPriceDiscount.invalid ||
                            !isMaxPriceDiscountValid)
                        "
                        class="invalid-feedback"
                      >
                        <div
                          *ngIf="maxPriceDiscount.errors.required"
                          translate
                        >
                          validateMessages.maxPriceDiscount
                        </div>
                        <div
                          *ngIf="maxPriceDiscount.errors.pattern"
                          translate
                        >
                          validateMessages.maxPriceDiscountCase
                        </div>
                      </div>
                      <div
                        *ngIf="
                          !checkMaxPriceDiscountValid(currencyPriceItem)
                        "
                        class="invalid-feedback"
                        translate
                      >
                        validateMessages.maxPriceDiscountSmallerThanPrice
                      </div>
                    </div>
                  </td>

                  <td>
                    <div
                      class="form-group mb-0"
                      *ngIf="selectedProductId.length"
                    >
                      <select
                        class="form-control"
                        id="recurrenceType"
                        name="recurrenceType"
                        #recurrenceType="ngModel"
                        required
                        [(ngModel)]="currencyPriceItem.recurrenceType"
                        [ngClass]="{
                          'is-invalid':
                            !isCurrencyPriceValid && currency.invalid
                        }"
                      >
                        <option
                          value=""
                          [translate]="'placeholder.selectProduct'"
                          hidden
                        ></option>
                        <option value="UPFRONT">UPFRONT</option>
                        <option value="MONTHLY">MONTHLY</option>
                        <option value="QUARTERLY">QUARTERLY</option>
                        <option value="ANNUALLY">ANNUALLY</option>
                      </select>
                      <div
                        *ngIf="!isCurrencyPriceValid && currency.invalid"
                        class="invalid-feedback"
                      >
                        <div *ngIf="currency.errors.required" translate>
                          validateMessages.currency
                        </div>
                      </div>
                    </div>
                  </td>

                  <td>
                    <button
                      class="btn btn-primary mr-1"
                      (click)="onSaveCurrencyPriceItem(currencyPriceItem)"
                    >
                      <i class="fa fa-check"> </i>
                    </button>
                    <button
                      class="btn btn-primary"
                      (click)="onCancelCurrencyPriceItem(i)"
                    >
                      <i class="fa fa-ban"> </i>
                    </button>
                  </td>
                </ng-template>
              </ng-template>
            </tr>
            <tr>
              <td>
                <button
                  class="btn btn-primary"
                  (click)="onAddCurrencyPriceItem()"
                  [translate]="'buttonTitles.addPrice'"
                  [disabled]="isEditingCurrencyPriceItem"
                ></button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div>
      <button
        class="btn btn-primary mr-1"
        [ladda]="isSubmitting"
        [disabled]="isEditingCurrencyPriceItem"
        [translate]="'buttonTitles.save'"
        data-style="zoom-in"
        form="partPricingForm"
      ></button>
      <button
        class="btn btn-secondary"
        [translate]="'buttonTitles.cancel'"
        [routerLink]="'/wholesaler/product/part_pricing'"
      ></button>
    </div>
  </div>
</div>
