<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
<tabset
  #customerTab
  *ngIf="!isLoading && account"
  class="customer-tab"
>
  <tab
    heading="Customer Details"
    id="form"
    customClass="fc-tab"
    (selectTab)="onSelectTab('form')"
  >
    <app-form-customer
      [account]="account"
      (submitEmitter)="onSubmit()"
      (cancelEmitter)="onCancel()"
      [isSubmitting]="isSubmitting"
      [isEditing]="isEditing"
      *ngIf="currentTab === 'form'"
    ></app-form-customer>
  </tab>
  <tab
    heading="Customer Subscriptions"
    id="subscription"
    customClass="fc-tab"
    (selectTab)="onSelectTab('subscription')"
  >
    <app-customer-subscription
      *ngIf="currentTab === 'subscription'"
      [account]="account"
    ></app-customer-subscription>
  </tab>
</tabset>

<ng-template #template>
  <div class="modal-body text-center">
    <p>This page has unsaved changes. Do you want to save them before exiting the page?</p>
    <button type="button" class="btn btn-primary mr-5" (click)="saveExit()" >Save and exit</button>
    <button type="button" class="btn btn-secondary ml-5" (click)="unsaveExit()" >Exit without saving</button>
    <button type="button" class="btn btn-secondary" (click)="exit()" >Cancel</button>
  </div>
</ng-template>