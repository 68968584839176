import { Injectable } from "@angular/core";
import { ServiceUrl } from "./config/service-url";
import { ApiService } from "./config/api.service";
import { Payload } from "@models/_index";
import { catchError, tap } from "rxjs/operators";
import { throwError } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class TaxService {
  serviceUrl = new ServiceUrl();

  constructor(private apiService: ApiService) {}

  getCustomerTax(payload: Payload) {
    return this.apiService.get(`${this.serviceUrl.GET_TAX}`, payload).pipe(
      tap(
        (res) => {},
        catchError((error) => {
          return throwError(error);
        })
      )
    );
  }

  getTaxById(payload: Payload) {
    return this.apiService.get(`${this.serviceUrl.GET_TAX}`, payload).pipe(
      tap(
        (res) => {},
        catchError((error) => {
          return throwError(error);
        })
      )
    );
  }

  getTaxList(payload: Payload) {
    return this.apiService
      .get(`${this.serviceUrl.GET_TAX}/with-pagination`, payload)
      .pipe(
        tap(
          (res) => {},
          catchError((error) => {
            return throwError(error);
          })
        )
      );
  }

  createTax(payload: Payload) {
    return this.apiService
      .post(`${this.serviceUrl.GET_TAX}`, payload)
      .pipe(
        tap(
          (res) => {},
          catchError((error) => {
            return throwError(error);
          })
        )
      );
  }

  updateTax(payload: Payload) {
    return this.apiService.put(`${this.serviceUrl.GET_TAX}`, payload).pipe(
      tap(
        (res) => {},
        catchError((error) => {
          return throwError(error);
        })
      )
    );
  }

  deleteTax(payload: Payload) {
    return this.apiService
      .delete(`${this.serviceUrl.GET_TAX}`, payload)
      .pipe(
        tap(
          (res) => {},
          catchError((error) => {
            return throwError(error);
          })
        )
      );
  }

  revertTax(payload: Payload) {
    return this.apiService
      .put(`${this.serviceUrl.GET_TAX}/revert-delete`, payload)
      .pipe(
        tap(
          (res) => {},
          catchError((error) => {
            return throwError(error);
          })
        )
      );
  }
}
