import { Component, OnInit } from "@angular/core";

import { Observable } from "rxjs";

import { Product } from "../../@core/models/product.model";
import { ProductService } from "@services/product.service";
import { ProductQuery } from "app/@core/stores/products/product.query";
import { AlertService } from "@services/alert.service";
import {
  BsModalService,
  BsModalRef,
  ModalOptions,
} from "ngx-bootstrap/modal";
import { ModalComponent } from "@shared/components/modal/modal.component";

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.css"],
})
export class ProductComponent implements OnInit {
  products$: Observable<Product[]>;
  isLoading: boolean = false;
  public bsModalRef?: BsModalRef;

  // pagination
  currentPage: number = 0;
  totalItems: number = 0;
  itemPerPage: number = 10;
  // search
  search: string = "";
  // sort
  sortedField: string;
  isSortedAsc: boolean;

  readonly ITEMS_PER_PAGE: number = 10;

  constructor(
    private productService: ProductService,
    private productQuery: ProductQuery,
    private modalService: BsModalService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.getProductsList();
  }

  getProductsList() {
    this.isLoading = true;
    this.productService
      .getProductsNoSubscribe({
        params: {
          page: this.currentPage,
          keySearch: this.search,
          sort: this.sortParams(),
          size: this.itemPerPage,
        },
      })
      .subscribe(
        (res?) => {
          this.isLoading = false;
          this.totalItems = res.data?.totalSize;
        },
        (err?) => {
          this.isLoading = false;
          this.alertService.error(err?.error?.message);
        }
      );
    this.products$ = this.productQuery.products$;
  }

  onDelete(id: string) {
    const initialState: ModalOptions = {
      initialState: {
        title: "Do you want to remove this item?",
        content:
          "By clicking on OK, this item will be removed immediately",
      },
      animated: false,
    };
    this.bsModalRef = this.modalService.show(ModalComponent, initialState);

    this.bsModalRef.content.eventEmitter.subscribe((isOk) => {
      if (isOk) {
        this.deleteProduct(id);
      } else {
        this.bsModalRef.hide();
      }
    });
  }

  deleteProduct(id: string) {
    this.productService.deleteProduct({ id: id }).subscribe(
      (res) => {
        this.alertService.success("Delete product successfully!");
        this.bsModalRef.hide();
        this.getProductsList();
      },
      (err) => {
        this.alertService.error(err?.error?.message);
        this.bsModalRef.hide();
      }
    );
  }

  goToPage(page: number) {
    this.currentPage = page - 1;
    this.getProductsList();
  }

  changeItemPerPage(itemPerPages: number) {
    this.currentPage = 0;
    this.itemPerPage = itemPerPages;
    this.getProductsList();
  }

  onSearch(keySearch: string) {
    this.search = keySearch;
    this.currentPage = 0;
    this.getProductsList();
  }

  // below are sortable function
  isSortedOnField(field: string) {
    return this.sortedField === field;
  }

  isSortedAscOnField(field: string) {
    return this.isSortedOnField(field) && this.isSortedAsc;
  }

  isSortedDescOnField(field: string) {
    return this.isSortedOnField(field) && !this.isSortedAsc;
  }

  sortParams() {
    return this.sortedField
      ? `${this.sortedField},${this.isSortedAsc ? "asc" : "desc"}`
      : "";
  }

  onApplySort(field: string) {
    if (field !== this.sortedField) {
      this.isSortedAsc = true;
      this.sortedField = field;
    } else {
      this.isSortedAsc = !this.isSortedAsc;
    }
    this.getProductsList();
  }
}
