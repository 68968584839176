import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { Policy, Action } from "@models/_index";
import { AlertService, ActionService } from "@services/_index";
import { ActionQuery } from "@stores/_index";

@Component({
  selector: "app-form-policy",
  templateUrl: "./form-policy.component.html",
  styleUrls: ["./form-policy.component.scss"],
})
export class FormPolicyComponent implements OnInit {
  @Input() policy: Policy;
  @Input() actions: Action[] = [];
  @Input() isSubmitting: Boolean = false;
  @Output() submitEmitter: EventEmitter<any> = new EventEmitter();

  public selectedActions: string[] = [];

  constructor(
    private actionService: ActionService,
    private actionQuery: ActionQuery,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.policy?.id) {
      this.selectedActions = this.policy.actions.map(
        (action) => action.id
      );
    }
  }

  isChecked(actionId: string) {
    return this.selectedActions.find((id) => id === actionId)?.length;
  }

  onChange(actionId: string, isChecked: Boolean) {
    if (isChecked) {
      this.selectedActions.push(actionId);
    } else {
      const index = this.selectedActions.indexOf(actionId);
      this.selectedActions.splice(index, 1);
    }
  }

  onSubmit(form) {
    if (!form.form.valid) {
      return;
    }
    this.submitEmitter.emit(this.selectedActions);
  }
}
