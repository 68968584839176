import {
  Component,
  EventEmitter,
  OnInit,
  Output
} from "@angular/core";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
})
export class ModalComponent implements OnInit {
  @Output() eventEmitter: EventEmitter<boolean> = new EventEmitter();

  title?: string;
  content?: string;
  isLoading: Boolean = false;

  constructor() {}

  ngOnInit(): void {

  }

  onOk() {
    this.isLoading = true;
    this.eventEmitter.emit(true);
  }

  onClose() {
    this.eventEmitter.emit(false);
  }
}
