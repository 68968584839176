<div>
  <div class="row justify-content-between mb-3">
    <div class="col-xs-12 col-lg-4 mb-2">
      <app-search-bar
        (handleSearchEmitter)="onSearch($event)"
        [placeholder]="'search' | translate"
      ></app-search-bar>
    </div>
    <div class="col-xs-12 col-lg-6 align-items-end">
      <a
        class="btn btn-primary float-right"
        [routerLink]="'/wholesaler/product/product/new'"
        translate
      >
        buttonTitles.newProduct
      </a>
    </div>
  </div>
  <div class="card">
    <div class="card-header" translate>products</div>
    <div class="card-body table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>
              <app-sortable-header
                [title]="'name' | translate"
                [isSortedAsc]="isSortedAscOnField('productName')"
                [isSortedDesc]="isSortedDescOnField('productName')"
                (handleSort)="onApplySort('productName')"
                >>
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'partNumberPrefix' | translate"
                [isSortedAsc]="isSortedAscOnField('partNumberPrefix')"
                [isSortedDesc]="isSortedDescOnField('partNumberPrefix')"
                (handleSort)="onApplySort('partNumberPrefix')"
              >
                >
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'status' | translate"
                [isSortedAsc]="isSortedAscOnField('active')"
                [isSortedDesc]="isSortedDescOnField('active')"
                (handleSort)="onApplySort('active')"
                >>
              </app-sortable-header>
            </th>
            <th translate>actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-template [ngIf]="!isLoading">
            <tr *ngFor="let product of products$ | async">
              <td>{{ product?.productName }}</td>
              <td>{{ product?.partNumberPrefix }}</td>
              <td *ngIf="product?.active">
                <span class="badge badge-pill badge-success">Active</span>
              </td>
              <td *ngIf="!product?.active">
                <span class="badge badge-pill badge-secondary"
                  >Inactive</span
                >
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <a
                    class="text-primary hoverable mr-1"
                    [routerLink]="[
                      '/wholesaler/product/product',
                      product.id
                    ]"
                  >
                    <i
                      class="fa fa-pencil"
                      tooltip="{{ 'tooltip.edit' | translate }}"
                    >
                    </i>
                  </a>

                  <a class="text-primary hoverable mr-3">
                    <i
                      class="fa fa-trash"
                      (click)="onDelete(product.id)"
                      tooltip="{{ 'tooltip.delete' | translate }}"
                    >
                    </i>
                  </a>

                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    [translate]="'buttonTitles.viewProductParts'"
                    [routerLink]="[
                      '/wholesaler/product/part/',
                      product.id
                    ]"
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
      <app-pagination
        [totalItems]="totalItems"
        [currentPage]="currentPage"
        [itemPerPages]="itemPerPage"
        (handleGoToPage)="goToPage($event)"
        (changeItemPerPage)="changeItemPerPage($event)"
      ></app-pagination>
    </div>
  </div>
</div>
