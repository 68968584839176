import { Component, OnInit, Input } from "@angular/core";
import { Quotation } from "@models/_index";
@Component({
  selector: "app-quotation-amount-detail",
  templateUrl: "./quotation-amount-detail.component.html",
  styleUrls: ["./quotation-amount-detail.component.scss"],
})
export class QuotationAmountDetailComponent implements OnInit {
  @Input() quotation: Quotation;

  constructor() {}

  ngOnInit(): void {}
  displayTaxAmount() {
    // eslint-disable-next-line max-len
    return `Inclusive of (${this.quotation.tax}%) Tax: ${parseFloat(
      this.quotation.taxAmount.toString()
    ).toFixed(2)}`;
  }

  formatBillingCycle() {
    const text = this.quotation.billingCycle;
    return `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
  }

  displayRecurrenceAmount() {
    // eslint-disable-next-line max-len
    return `${this.formatBillingCycle()} Amount (${
      this.quotation.currency
    }): ${parseFloat(this.quotation.recurrenceAmount).toFixed(2)}`;
  }

  displayTotalSubscriptionAmount() {
    return `Total Subscription Amount (excluding Upfront Amount)
        (${this.quotation.currency}):
        ${parseFloat(this.quotation.subscriptionAmount).toFixed(2)}`;
  }

  displayUpfrontAmount() {
    return `Upfront Amount (${this.quotation.currency}):
       ${parseFloat(this.quotation.upfrontAmount).toFixed(2)}`;
  }

  displaySubtotal() {
    return `SubTotal: ${parseFloat(
      this.quotation.subtotal.toString()
    ).toFixed(2)}`;
  }

  displayTotal() {
    // eslint-disable-next-line max-len
    return `Total (${this.quotation.currency}): ${this.quotation.totalPrice}`;
  }
}
