import { Injectable } from "@angular/core";
import { ServiceUrl } from "./config/service-url";
import { ApiService } from "./config/api.service";
import { environment } from "environments/environment";
import { AuthQuery } from "@stores/_index";
import { Payload } from "@models/_index";

@Injectable({
  providedIn: "root",
})
export class UserService {
  serviceUrl = new ServiceUrl();

  url = `${environment.iamBaseUrl}/smp-iam-svc/account`;
  urlUser = `${environment.iamBaseUrl}/smp-iam-svc/user`;

  constructor(
    private apiService: ApiService,
    private authQuery: AuthQuery
  ) {}

  getUsers(payload?: Payload) {
    const accountId = this.authQuery.currentUserAccountId;
    return this.apiService.getWithoutBaseUrl(
      `${this.url}/${accountId}/users`, payload
    );
  }

  deleteUser(id: string) {
    return this.apiService.deleteWithoutBaseUrl(
      `${this.urlUser}/${id}`,
      {}
    );
  }

  getUser(id: string) {
    return this.apiService.getWithoutBaseUrl(`${this.urlUser}/${id}`, {});
  }

  createUser(payload: Payload) {
    return this.apiService.postWithoutBaseUrl(`${this.urlUser}`, payload);
  }

  updateUser(id: string, payload: Payload) {
    return this.apiService.putWithoutBaseUrl(`${this.urlUser}/${id}`, payload);
  }

  changePassword(id: string, payload: Payload) {
    return this.apiService.putWithoutBaseUrl(
      `${this.urlUser}/password/${id}`,
      payload
    );
  }
}
