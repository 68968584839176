import { Query } from "@datorama/akita";
import {
  PartPriceItemState,
  PartPriceItemStore,
} from "./part-price-item.store";
import { Injectable } from "@angular/core";
import { PartPriceItem } from "@models/part-price-item.model";

@Injectable({
  providedIn: "root",
})
export class PartPriceItemQuery extends Query<PartPriceItemState> {
  partPriceItems$ = this.select("partPriceItems");
  partPriceItem$ = this.select("partPriceItem");

  constructor(protected store: PartPriceItemStore) {
    super(store);
  }

  get getPartPriceItem() {
    return this.getValue().partPriceItem;
  }

  get getPartPriceItems() {
    return this.getValue().partPriceItems;
  }

  getAvailablePriceItem(currency: "USD" | "SGD" | "MYR" , recurrenceType: "MONTHLY" | "ANNUALLY" | "QUARTERLY" | "UPFRONT", productID?: string) {
    const items = this.getValue().partPriceItems.filter(
      (item) =>
        this.isActive(item) &&
        this.isSameProductID(item, productID) &&
        this.isSameCurrency(item, currency) &&
        this.isRecurrenceTypeAvailable(item, recurrenceType)
    );
    const results = items.map((item) => ({
      ...item,
      priceItems: item.priceItems.filter(
        (price) =>
          price.currency === currency &&
          (
            price.recurrenceType === recurrenceType ||
            price.recurrenceType === "UPFRONT"
          )
      ),
    }));
    return results;
  }

  private isSameProductID(item: PartPriceItem, productID: string) {
    if(!productID) return true
    return item.productPart.product.id === productID
  }

  private isActive(item: PartPriceItem) {
    return item?.productPart?.product?.active;
  }

  private isSameCurrency(item: PartPriceItem, currency: "USD" | "SGD" | "MYR") {
    return item?.priceItems?.find((price) => price?.currency === currency);
  }

  private isRecurrenceTypeAvailable(item: PartPriceItem, recurrenceType: "MONTHLY" | "ANNUALLY" | "QUARTERLY" | "UPFRONT") {
    return item?.priceItems?.find(
      (price) =>
        price.recurrenceType === recurrenceType ||
        price.recurrenceType === "UPFRONT"
    );
  }
}
