<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<app-form-user
  [user]="user"
  [isEditing]="true"
  *ngIf="!isLoading && user"
  [isSubmitting]="isSubmitting"
  (submitEmitter)="onSubmit()"
>
</app-form-user>
