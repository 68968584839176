<div
  class="card card-quotation border-0 mb-0 border-radius-bottom-0"
  *ngIf="isShowing"
  @inOutPaneAnimation
>
  <div class="card-body">
    <h5><strong translate>quotationDetails</strong></h5>
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label translate>quotationId</label>
          <input
            type="text"
            class="form-control"
            readonly
            [ngModel]="quotation?.quotationCode"
          />
        </div>

        <div class="form-group">
          <label translate>currency</label>
          <input
            type="text"
            class="form-control"
            readonly
            [ngModel]="quotation?.currency"
          />
        </div>

        <div class="form-group">
          <label translate>customer</label>
          <input
            type="text"
            class="form-control"
            readonly
            [ngModel]="quotation?.companyName"
          />
        </div>
      </div>

      <div class="col-12 col-lg-6 h-100 align-self-start">
        <div class="form-group">
          <label translate>date</label>
          <input
            type="text"
            class="form-control"
            readonly
            [ngModel]="getQuotationDate()"
          />
        </div>

        <div class="form-group">
          <label translate>expiresOn</label>
          <input
            type="text"
            class="form-control"
            readonly
            [ngModel]="getQuotationExpiration()"
          />
        </div>

        <div class="form-group">
          <label translate>subscriptionStartDate</label>
          <input
            type="text"
            class="form-control"
            readonly
            [ngModel]="getSubscriptionStartDate()"
          />
        </div>
        <div class="form-group">
          <label translate>subscriptionEndDate</label>
          <input
            type="text"
            class="form-control"
            readonly
            [ngModel]="getSubscriptionEndDate()"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 mt-3">
        <h5><strong translate>soldTo</strong></h5>
        <div class="form-group">
          <label translate>personInCharge</label>
          <input
            type="text"
            class="form-control"
            readonly
            [ngModel]="quotation?.salesContact?.personInCharge"
          />
        </div>
        <div class="form-group">
          <label translate>contactNumber</label>
          <input
            type="text"
            class="form-control"
            readonly
            [ngModel]="quotation?.salesContact?.contactNumber"
          />
        </div>
        <div class="form-group">
          <label translate>email</label>
          <input
            type="text"
            class="form-control"
            readonly
            [ngModel]="quotation?.salesContact?.email"
          />
        </div>
      </div>
      <div class="col-lg-6 mt-3">
        <h5><strong translate>billTo</strong></h5>
        <div class="form-group">
          <label translate>personInCharge</label>
          <input
            type="text"
            class="form-control"
            readonly
            [ngModel]="quotation?.billingContact?.personInCharge"
          />
        </div>
        <div class="form-group">
          <label translate>contactNumber</label>
          <input
            type="text"
            class="form-control"
            readonly
            [ngModel]="quotation?.billingContact?.contactNumber"
          />
        </div>
        <div class="form-group">
          <label translate>email</label>
          <input
            type="text"
            class="form-control"
            readonly
            [ngModel]="quotation?.billingContact?.email"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-3">
        <h5><strong translate>details</strong></h5>
        <div class="table-responsive mt-3">
          <table class="table table-striped">
            <thead>
              <tr>
                <th translate>product</th>
                <th translate>partPricing</th>
                <th translate>recurrenceType</th>
                <th translate>quantity</th>
                <th translate>price</th>
                <th translate>discountAmount</th>
                <th translate>amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of quotation.quotationItems">
                <td>{{ item?.productName }}</td>
                <td>{{ item?.partPricingName }}</td>
                <td>{{ item?.recurrenceType }}</td>
                <td>{{ item?.quantity }}</td>
                <td>{{ item?.price }}</td>
                <td>{{ item?.discountAmount }}</td>
                <td>{{ item?.amount }}</td>
              </tr>
            </tbody>
          </table>
          <app-quotation-amount-detail [quotation]="quotation"></app-quotation-amount-detail>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label translate>notes</label>
      <textarea
        class="form-control"
        [(ngModel)]="quotation.notes"
      ></textarea>
    </div>
  </div>
</div>
