import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { format } from "date-fns";

import { Quotation } from "@models/quotation.model";

import { QuotationService } from "@services/quotation.service";
import { SubscriptionService } from "@services/subscription.service";
import { AlertService } from "@services/alert.service";

import { QuotationQuery } from "@stores/_index";
import { Options } from "@angular-slider/ngx-slider";

import { Observable } from "rxjs";
import { NgSelectComponent } from "@ng-select/ng-select";

@Component({
  selector: "app-form-subscription",
  templateUrl: "./form-subscription.component.html",
  styleUrls: ["./form-subscription.component.scss"],
})
export class FormSubscriptionComponent implements OnInit {
  public quotationId: string;
  public quotationDetail;
  public quotationItemsTable = [];
  public quotationItems = [];
  public billingCycles = ["MONTHLY", "QUARTERLY", "ANNUALLY", "UPFRONT"];

  public isLoading: Boolean = false;
  public isSubmitting: Boolean = false;
  public isDateValid: Boolean = true;
  public subscriptionDate: Date;
  public subscriptionEndDate: Date;
  public isUploadValid: Boolean = true;
  public customer_reference_number: string = "";

  public files: File[] = [];

  options: Options = {
    floor: 1,
    ceil: 60,
    step: 1,
    showTicks: true,
    getLegend: (value: number): string => {
      if([1, 12, 24, 36, 48, 60].includes(value)){
        return `<b>${value}</b>`
      }
    },
    showSelectionBar: true,
    disabled: true,
  };

  constructor(
    private quotationService: QuotationService,
    private quotationQuery: QuotationQuery,
    private alertService: AlertService,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.quotationId = params["quotationId"];
      this.loadQuotation();
    });
  }

  ngOnInit(): void {}

  loadQuotation() {
    this.isLoading = true;
    this.quotationService.getQuotation({ id: this.quotationId }).subscribe(
      (res) => {
        this.isLoading = false;
        this.quotationItemsTable = res?.quotation_items;
        this.quotationDetail = res;
        this.subscriptionDate = new Date(res?.subscription_date);
        this.getSubscriptionEndDate();
        this.quotationItems = this.quotationItemsTable.map(
          ({ id, recurrence_type }) => ({
            id,
            billing_cycle: recurrence_type,
          })
        );
      },
      (err) => {
        this.isLoading = false;
        this.alertService.error(err.error.message);
      }
    );
  }

  formatDate(date: Date) {
    return format(date, "YYYY-MM-DDT00:00:00");
  }

  onSaveSubscription() {
    this.isDateValid = true;

    const payload = {
      quotation_id: this.quotationId,
      start_date: this.formatDate(this.subscriptionDate),
      files: this.files,
      customer_reference_number: this.customer_reference_number,
    };

    if (this.isDateValid) {
      this.isSubmitting = true;
      this.subscriptionService.createSubscription({ payload: payload }).subscribe(
        () => {
          this.isSubmitting = false;
          this.alertService.success("Create subscriptions successfully!");
          this.router.navigate(["/wholesaler/subscription"]);
        },
        (err) => {
          this.isSubmitting = false;
          this.alertService.error(err.error.message);
        }
      );
    } else {
      return;
    }
  }

  onSelectSubscriptionDate(date) {
    this.getSubscriptionEndDate();
  }

  getSubscriptionEndDate() {
    this.subscriptionEndDate = new Date(this.subscriptionDate);
    this.subscriptionEndDate.setMonth(
      this.subscriptionEndDate.getMonth() +
        this.quotationDetail.term_length
    );
    this.subscriptionEndDate.setDate(
      this.subscriptionEndDate.getDate() - 1
    );
  }

  onSelect(event) {
    this.files.push(...event.addedFiles);
    if (this.files.length > 5) {
      this.alertService.error("Only up to 5 files allowed at once.");
      this.isUploadValid = false;
    } else {
      this.isUploadValid = true;
    }
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    if (this.files.length > 5) {
      this.isUploadValid = false;
    } else {
      this.isUploadValid = true;
    }
  }
}
