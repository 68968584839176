<div class="table-responsive mt-3">
    <table class="table table-quotation-items" width="100%">
      <thead>
        <tr>
          <th width="150" translate *ngIf="!isShowingDetails">actions</th>
          <th width="200" translate>bundleName</th>
          <th width="200" translate>recurrenceType</th>
          <th width="200" translate>currency</th>
          <th width="130" translate>discountAmount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of productBundleList; index as i">
          <ng-template [ngIf]="operation.currentEditItem !== i">
            <td width="100" *ngIf="!isShowingDetails">
              <button
                style="width: 40px"
                class="btn btn-primary mb-1 mr-1"
                type="button"
                [disabled]="operation.currentEditItem !== -1"
                (click)="onClickEditProductBundle(i)"
              >
                <i class="fa fa-pencil"> </i>
              </button>
              <button
                style="width: 40px"
                class="btn btn-primary mb-1"
                type="button"
                [disabled]="operation.currentEditItem !== -1"
                (click)="onClickDeleteProductBundle(item.id)"
              >
                <i class="fa fa-trash"> </i>
              </button>
            </td>
            <td>{{ item?.name }}</td>
            <td>{{ item?.recurrenceType }}</td>
            <td>{{ item?.currency }}</td>
            <td>{{ item?.discountAmount }}</td>
          </ng-template>
          <ng-template [ngIf]="operation.currentEditItem === i">
            <td width="100">
              <button
                style="width: 40px"
                class="btn btn-primary mb-1 mr-1"
                [ladda]="operation.isLoading"
                data-style="zoom-in"
                (click)="operation.isEditing ? onClickUpdateProductBundle() : onClickSaveProductBundle()"
                type="button"
                [disabled]="operation.selectedItem.productBundleId.length === 0 || !isDiscountAmountValid()"
              >
                <i class="fa fa-check"> </i>
              </button>
              <button
                style="width: 40px"
                class="btn btn-primary mb-1"
                (click)="operation.isEditing ? onCancelEditProductBundle() : onCancelProductBundle()"
                type="button"
              >
                <i class="fa fa-ban"> </i>
              </button>
            </td>
            <td>
              <select class="form-control" name="bundleName" #bundleName="ngModel" [(ngModel)]="operation.selectedItem.productBundleId" (change)="onChangeProductBundle()">
                <option value="" [translate]="'placeholder.selectProductBundle'"></option>
                <option [value]="bundle.id" *ngFor="let bundle of productBundleSelection" >{{ bundle.name }}</option>
              </select>
            </td>
            <td>
              <input type="text" disabled="true" class="form-control" [(ngModel)]="operation.selectedItem.recurrenceType"> 
            </td>
            <td>
              <input type="text" disabled="true" class="form-control" [(ngModel)]="operation.selectedItem.currency"> 
            </td>
            <td>
              <input type="number" class="form-control" [(ngModel)]="operation.selectedItem.discountAmount"> 
            </td>
          </ng-template>
        </tr>
        <tr *ngIf="!isShowingDetails">
          <td colspan="2">
            <p
              class="font-weight-bold text-primary mt-2 hoverable d-inline-block"
              translate
              (click)="operation.currentEditItem !== -1 || isProductBundleMode() ? return : onAddProductBundle()"
              [ngClass]="{ 'btn-disabled': operation.currentEditItem !== -1 || isProductBundleMode()}"
            >
              <i class="icon-plus"> </i>
                buttonTitles.addProductBundle
            </p>
          </td>
          <td colspan="3"></td>
        </tr>
      </tbody>
    </table>
  </div>