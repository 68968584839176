import { Component, OnInit } from "@angular/core";
import { PartPriceItem, CurrencyPriceItem } from "@models/_index";
import { PartPriceItemService } from "@services/part-price-item.service";
import { Router } from "@angular/router";
import { AlertService } from "@services/alert.service";
@Component({
  selector: "app-create-part-pricing",
  templateUrl: "./create-part-pricing.component.html",
  styleUrls: ["./create-part-pricing.component.scss"],
})
export class CreatePartPricingComponent implements OnInit {
  partPriceItem: PartPriceItem = new PartPriceItem();
  isSubmitting: Boolean = false;
  constructor(
    private partPriceItemService: PartPriceItemService,
    private router: Router,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    this.isSubmitting = true;
    this.partPriceItem = {
      ...this.partPriceItem,
      billingCycle: "start",
      description: "Test",
    };

    this.partPriceItemService
      .createPartPriceItem({ payload: this.partPriceItem })
      .subscribe(
        (res) => {
          this.isSubmitting = false;
          this.alertService.success(
            "Part Price Item Successfully Created."
          );
          this.router.navigate(["/wholesaler/product/part_pricing/"]);
        },
        (err) => {
          this.isSubmitting = false;
          this.alertService.error(err);
        }
      );
  }
}
