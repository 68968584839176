<div class="row justify-content-between mb-3">
  <div class="col-4"></div>
  <div class="col-6 text-right">
    <a
      class="btn btn-success"
      [routerLink]="'/wholesaler/billing/billing/'"
      translate
    >
      buttonTitles.backBillings
    </a>
  </div>
</div>
<div class="card">
  <div class="card-header">{{ billingId }}</div>
  <div class="card-body table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>
            <app-sortable-header
              [title]="'subscriptionId' | translate"
              [isSortedAsc]="isSortedAscOnField('subscriptionId')"
              [isSortedDesc]="isSortedDescOnField('subscriptionId')"
              (handleSort)="onApplySort('subscriptionId')"
              >>
            </app-sortable-header>
          </th>
          <th>
            <app-sortable-header
              [title]="'product' | translate"
              [isSortedAsc]="isSortedAscOnField('productName')"
              [isSortedDesc]="isSortedDescOnField('productName')"
              (handleSort)="onApplySort('productName')"
              >>
            </app-sortable-header>
          </th>
          <th>
            <app-sortable-header
              [title]="'customer' | translate"
              [isSortedAsc]="isSortedAscOnField('companyName')"
              [isSortedDesc]="isSortedDescOnField('companyName')"
              (handleSort)="onApplySort('companyName')"
              >>
            </app-sortable-header>
          </th>
          <th>
            <app-sortable-header
              [title]="'currency' | translate"
              [isSortedAsc]="isSortedAscOnField('currency')"
              [isSortedDesc]="isSortedDescOnField('currency')"
              (handleSort)="onApplySort('currency')"
              >>
            </app-sortable-header>
          </th>
          <th>
            <app-sortable-header
              [title]="'amount' | translate"
              [isSortedAsc]="isSortedAscOnField('amount')"
              [isSortedDesc]="isSortedDescOnField('amount')"
              (handleSort)="onApplySort('amount')"
              >>
            </app-sortable-header>
          </th>
          <th>
            <app-sortable-header
              [title]="'periodStart' | translate"
              [isSortedAsc]="isSortedAscOnField('periodStart')"
              [isSortedDesc]="isSortedDescOnField('periodStart')"
              (handleSort)="onApplySort('periodStart')"
              >>
            </app-sortable-header>
          </th>
          <th>
            <app-sortable-header
              [title]="'periodEnd' | translate"
              [isSortedAsc]="isSortedAscOnField('periodEnd')"
              [isSortedDesc]="isSortedDescOnField('periodEnd')"
              (handleSort)="onApplySort('periodEnd')"
              >>
            </app-sortable-header>
          </th>
          <th>
            <app-sortable-header
              [title]="'billingDate' | translate"
              [isSortedAsc]="isSortedAscOnField('billingDate')"
              [isSortedDesc]="isSortedDescOnField('billingDate')"
              (handleSort)="onApplySort('billingDate')"
            >
            </app-sortable-header>
          </th>
          <th>
            <app-sortable-header
              [title]="'status' | translate"
              [isSortedAsc]="isSortedAscOnField('status')"
              [isSortedDesc]="isSortedDescOnField('status')"
              (handleSort)="onApplySort('status')"
            >
            </app-sortable-header>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-template [ngIf]="!isLoading">
          <tr *ngFor="let item of billingItems">
            <td>{{ item.subscriptionCode }}</td>
            <td>{{ item.productName }}</td>
            <td>{{ item.companyName }}</td>
            <td>{{ item.currency }}</td>
            <td>{{ item.amount | currency: "":"" }}</td>
            <td>
              {{ item.periodStart | date: "dd MMM yyyy" }}
            </td>
            <td>{{ item.periodEnd | date: "dd MMM yyyy" }}</td>
            <td>
              {{ item.billingDate | date: "dd MMM yyyy" }}
            </td>
            <td>
              {{
                item.status === "INVOICED_AND_PAID"
                  ? "Invoiced and Paid"
                  : item.status === "INVOICED_AND_VOID"
                  ? "Invoiced and Void"
                  : "Invoiced and Outstanding"
              }}
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
    <app-pagination
      [totalItems]="totalItems"
      [currentPage]="currentPage"
      [itemPerPages]="ITEMS_PER_PAGE"
      (handleGoToPage)="goToPage($event)"
    ></app-pagination>
  </div>
</div>
