import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { Subscription } from "@models/_index";
import {
  SubscriptionService,
  AlertService,
  ConfirmModalService,
} from "@services/_index";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-update-customer-reference-number",
  templateUrl: "./update-customer-reference-number.component.html",
  styleUrls: ["./update-customer-reference-number.component.scss"],
})
export class UpdateCustomerReferenceNumberComponent implements OnInit {
  @Input() subscription: Subscription;

  @ViewChild("editRefNumberModal", { static: false })
  public editRefNumberModal: ModalDirective;
  public isLoading: Boolean = false;
  public isSubmitting: Boolean = false;

  public bsModalRef?: BsModalRef;

  constructor(
    private subscriptionService: SubscriptionService,
    private confirmModalService: ConfirmModalService,
    private alertService: AlertService
  ) {}

  ngOnInit() {}

  onShowModal() {
    this.editRefNumberModal.show();
  }

  onSubmit() {
    if (this.subscription.customer_reference_number === "") {
      this.bsModalRef = this.confirmModalService.show(
        "Confirmation",
        "Are you sure you want to leave Customer Reference Number blank?"
      );

      this.bsModalRef.content.eventEmitter.subscribe((isOk) => {
        if (isOk) {
          this.updateCustomerRefNumber();
        }
        this.bsModalRef.hide();
      });
    } else {

      this.updateCustomerRefNumber();
    }
  }

  updateCustomerRefNumber() {
    this.isSubmitting = true;
    this.subscriptionService
      .updateSubscription({
        id: this.subscription.id,
        payload: {
          customerReferenceNumber:
            this.subscription.customer_reference_number,
        },
      })
      .subscribe(
        () => {
          this.isSubmitting = false;
          this.alertService.success(
            "Customer Reference Number successfuly updated"
          );
          this.editRefNumberModal.hide();
        },
        (err) => {
          console.log(err);
          this.isSubmitting = false;
          this.alertService.error(err?.error?.message);
        }
      );
  }
}
