import { Component, OnInit } from "@angular/core";
import { Action } from "@models/_index";
import { ActionService, AlertService, ConfirmModalService } from "@services/_index";
import { ActionQuery } from "@stores/_index";
import { BsModalRef } from "ngx-bootstrap/modal";
@Component({
  selector: "app-action",
  templateUrl: "./action.component.html",
  styleUrls: ["./action.component.scss"],
})
export class ActionComponent implements OnInit {
  public actions: Action[] = [];
  public isLoading: Boolean = false;
  public bsModalRef?: BsModalRef;

  currentPage: number = 0;
  totalItems: number;
  search: string = "";

  // sort
  sortedField: string;
  isSortedAsc: boolean;

  readonly ITEMS_PER_PAGE: number = 10;
  constructor(
    private actionService: ActionService,
    private alertService: AlertService,
    private confirmModalService: ConfirmModalService,
    private actionQuery: ActionQuery
  ) {}

  ngOnInit() {
    this.getPolicies();
  }

  getPolicies() {
    this.isLoading = true;
    this.actionService.getActions().subscribe(
      () => {
        this.isLoading = false;
        this.actions = this.actionQuery.getActions;
      },
      (err) => {
        this.isLoading = false;
        this.alertService.errors(err?.errors?._embedded?.message);
      }
    );
  }
}
