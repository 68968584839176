import { Injectable } from "@angular/core";
import { ServiceUrl } from "./config/service-url";
import { ApiService } from "./config/api.service";
import { Observable, pipe } from "rxjs";
import { tap } from "rxjs/operators";
import { ProductStore } from "../stores/products/product.store";
import { Product } from "@models/product.model";
import { ProductPart } from "@models/product-part.model";
import { Payload } from "@models/_index";
@Injectable({
  providedIn: "root",
})
export class ProductService {
  serviceUrl = new ServiceUrl();
  constructor(
    private apiService: ApiService,
    private store: ProductStore
  ) {}

  getProducts() {
    this.store.setLoading(true);
    this.apiService
      .get(this.serviceUrl.GET_PRODUCTS, {})
      .pipe(
        tap((res) => {
          this.store.update({ products: res.data.content });
          this.store.setLoading(false);
        })
      )
      .subscribe();
  }

  selectProduct(productId: string) {
    this.store.update({
      selectedProduct: productId,
    });
  }

  // this one will be used instead
  // the above func() to follow convention of this prj
  // when I (Bao) deploy and Linh fix
  getProductsNoSubscribe(payload?: Payload) {
    return this.apiService
      .get(this.serviceUrl.GET_PRODUCTS, payload )
      .pipe(
        tap((res) => {
          this.store.update({ products: res.data.content });
        })
      );
  }

  getProductDetail(payload: Payload) {
    return this.apiService.get(
      `${this.serviceUrl.GET_PRODUCTS}/${payload.id}`,
      {}
    );
  }

  deleteProduct(payload: Payload) {
    return this.apiService.delete(
      `${this.serviceUrl.GET_PRODUCTS}/${payload.id}`,
      {}
    );
  }

  addProduct(payload: Payload) {
    return this.apiService.post(this.serviceUrl.GET_PRODUCTS, payload);
  }

  updateProduct(payload: Payload) {
    return this.apiService.put(`${this.serviceUrl.GET_PRODUCTS}`, payload);
  }

  getAllProductPart(payload: Payload) {
    return this.apiService
      .get(this.serviceUrl.GET_PRODUCT_PARTS, payload)
      .pipe(
        tap((res) => {
          this.store.update({ productParts: res.data.content });
        })
      );
  }

  getProductParts(payload: Payload) {
    return this.apiService
      .get(
        `${this.serviceUrl.GET_PRODUCT_PARTS_BY_PRODUCT_ID}/${payload.id}`,
        {}
      )
      .pipe(
        tap((res) => {
          this.store.update({ productParts: res["data"] });
        })
      );
  }

  deleteProductPart(payload: Payload) {
    return this.apiService.delete(
      `${this.serviceUrl.GET_PRODUCT_PARTS}/${payload.id}`,
      {}
    );
  }

  addProductPart(payload: Payload) {
    return this.apiService.post(this.serviceUrl.GET_PRODUCT_PARTS, payload);
  }

  getProductPartDetail(payload: Payload) {
    return this.apiService.get(
      `${this.serviceUrl.GET_PRODUCT_PARTS}/${payload.id}`,
      {}
    );
  }

  updateProductPart(payload: Payload) {
    return this.apiService.put(`${this.serviceUrl.GET_PRODUCT_PARTS}`, payload);
  }
}
