import { Store, StoreConfig } from "@datorama/akita";
import { PartPriceItem } from "@models/part-price-item.model";
import { Injectable } from "@angular/core";

export interface PartPriceItemState {
  partPriceItems: PartPriceItem[];
  partPriceItem: PartPriceItem;
}
export function createInitialState(): PartPriceItemState {
  return {
    partPriceItems: [],
    partPriceItem: new PartPriceItem(),
  };
}

@StoreConfig({ name: "partPriceItems" })
@Injectable({providedIn: "root"})
export class PartPriceItemStore extends Store<PartPriceItemState> {
  constructor() {
    super(createInitialState());
  }
}
