import { Store, StoreConfig } from "@datorama/akita";
import { Injectable } from "@angular/core";
import { Policy } from "@models/policy.model";

export interface PolicyState {
  policies: [];
  policy: Policy;
}
export function createInitialState(): PolicyState {
  return {
    policies: [],
    policy: new Policy()
  };
}

@StoreConfig({ name: "policy" })
@Injectable({ providedIn: "root" })
export class PolicyStore extends Store<PolicyState> {
  constructor() {
    super(createInitialState());
  }
}
