import { BaseModel } from "./base.model";

export class CustomerTax extends BaseModel {
  id: string;
  country: string;
  taxRate: number;
  startDate: string;
  taxCode: string;
  modifier?: string = "1";
}
