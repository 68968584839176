<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
        <form
          class="form-full-width"
          id="subscriptionForm"
          subscriptionForm="ngForm"
          #subscriptionForm="ngForm"
          *ngIf="!isLoading"
        >
          <div>
            <div class="form-group">
              <label translate class="required">quotationId</label>
              <input
                readonly
                type="text"
                class="form-control"
                required
                name="quotationCode"
                [(ngModel)]="quotationDetail.quotation_code"
              />
            </div>
            <div class="form-group">
              <label translate>customer</label>
              <input
                readonly
                placeholder="Customer name"
                type="text"
                name="company_name"
                class="form-control"
                required
                [(ngModel)]="quotationDetail.company_name"
              />
            </div>

            <div class="form-group">
              <label translate>startDate</label>
              <input
                type="text"
                class="form-control"
                bsDatepicker
                name="subscriptionDate"
                [(ngModel)]="subscriptionDate"
                [bsConfig]="{
                  dateInputFormat: 'DD-MM-YYYY',
                  containerClass: 'theme-blue'
                }"
                (ngModelChange)="onSelectSubscriptionDate($event)"
                required
              />
            </div>

            <div class="form-group">
              <label translate>termLength</label>
              <div class="custom-slider">
                <ngx-slider
                  [(value)]="quotationDetail.term_length"
                  [options]="options"
                ></ngx-slider>
              </div>
            </div>

            <div class="form-group">
              <label translate>endDate</label>
              <input
                type="text"
                class="form-control"
                bsDatepicker
                name="subscriptionEndDate"
                [ngModel]="subscriptionEndDate"
                [bsConfig]="{
                  dateInputFormat: 'DD-MM-YYYY',
                  containerClass: 'theme-blue'
                }"
                [disabled]="true"
              />
            </div>

            <div class="row">
              <div class="col-12 mt-3">
                <label
                  style="font-size: 0.9rem; font-weight: 600"
                  translate
                  >details</label
                >
                <div class="table-responsive mt-3">
                  <table class="table">
                    <thead>
                      <tr>
                        <th translate>productPart</th>
                        <th translate>partPricing</th>
                        <th translate>quantity</th>
                        <th translate>price</th>
                        <th translate>billingCycle</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let quotation of quotationItemsTable;
                          index as i
                        "
                      >
                        <td>{{ quotation?.product_part_name }}</td>
                        <td>{{ quotation?.part_pricing_name }}</td>
                        <td>{{ quotation?.quantity }}</td>
                        <td>{{ quotation?.amount }}</td>
                        <td>
                          {{ quotation?.recurrence_type }}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b
                            >Total ({{ quotationDetail?.currency }}):
                            {{ quotationDetail?.total_price }}</b
                          >
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label translate>customerReferenceNumber</label>
              <input
                placeholder="{{
                  'placeholder.customerReferenceNumber' | translate
                }}"
                type="text"
                name="customer_reference_number"
                class="form-control"
                [(ngModel)]="customer_reference_number"
              />
            </div>

            <!-- file drop zone -->
            <div class="form-group">
              <label translate>uploadFile</label>
              <div
                class="custom-dropzone"
                ngx-dropzone
                [multiple]="true"
                accept="*"
                (change)="onSelect($event)"
              >
                <ngx-dropzone-label>
                  <p>Drag & drop a file here or click</p>
                  <p>
                    <span class="icon-cloud-upload"></span>
                  </p>
                </ngx-dropzone-label>

                <ngx-dropzone-preview
                  *ngFor="let f of files"
                  [removable]="true"
                  (removed)="onRemove(f)"
                >
                  <ngx-dropzone-label
                    >{{ f.name }} ({{ f.type }})</ngx-dropzone-label
                  >
                </ngx-dropzone-preview>
              </div>
            </div>

            <!--  -->
          </div>
        </form>
      </div>
    </div>
    <div>
      <button
        class="btn btn-primary mr-1"
        [ladda]="isSubmitting"
        type="submit"
        (click)="onSaveSubscription()"
        [translate]="'buttonTitles.save'"
        [disabled]="!isUploadValid"
      ></button>
      <button
        class="btn btn-secondary"
        [routerLink]="'/wholesaler/subscription'"
        [translate]="'buttonTitles.cancel'"
      ></button>
    </div>
  </div>
</div>
