// @ts-nocheck
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { ProductService } from "@services/product.service";
import { ToasterService } from "angular2-toaster";
import { AlertService } from "@services/alert.service";
import { ProductQuery } from "@stores/_index";

import { Product, ProductPart } from "@models/_index";

@Component({
  selector: "app-form-product-part",
  templateUrl: "./form-product-part.component.html",
  styleUrls: ["./form-product-part.component.scss"],
})
export class FormProductPartComponent implements OnInit {
  productPart: ProductPartForm = new ProductPart();
  isLoading: Boolean = false;
  productId: string;
  productPartId: string;
  isSubmitting: Boolean = false;
  products: Product[] = [];
  selectedProductId: string;
  isEditing: boolean = false;

  constructor(
    private productService: ProductService,
    private productQuery: ProductQuery,
    private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.loadProduct();
  }

  loadProduct() {
    this.isLoading = true;
    const defaultProduct = this.productQuery.getSelectedProduct;
    this.productService.getProductsNoSubscribe().subscribe(
      (res?) => {
        this.products = res?.data?.content;
        this.selectedProductId =
          defaultProduct !== "" ? defaultProduct : res.data?.content[0].id;
        this.productPart.uom = "COUNT";
        this.route.params.subscribe((params) => {
          if (params.idProductPart) {
            this.productPartId = params.idProductPart;
            this.isEditing = true;
            this.productService
              .getProductPartDetail({ id: this.productPartId })
              .subscribe((res) => {
                this.onSelectProduct(res?.data?.product?.id);
                this.productPart.name = res?.data?.name;
                this.productPart.uom = res?.data?.uom;
                this.productPart.productId = res?.data?.product?.id;
                this.productPart.code = res?.data?.code;
                // this.productPart.recurring = res?.data?.recurring;
                this.isLoading = false;
              });
          }else{
            this.isLoading = false;
          }
        });
      },
      (err?) => {
        this.alertService.error(err?.error?.message);
      }
    );
  }

  updateProductPart(payload: any) {
    this.productService
      .updateProductPart({ id: this.productPartId, payload: payload })
      .subscribe(
        (res) => {
          this.isSubmitting = false;
          this.router.navigate(["/wholesaler/product/part"]);
          this.alertService.success("Update product part successfully!");
        },
        (err) => {
          this.isSubmitting = false;
          this.toasterService.pop("error", "Error", err.error.message);
        }
      );
  }

  createProductPart(payload: any) {
    this.productService.addProductPart({ payload: payload }).subscribe(
      (res) => {
        this.isSubmitting = false;
        this.router.navigate(["/wholesaler/product/part"]);
        this.alertService.success("Create product part successfully!");
      },
      (err) => {
        this.isSubmitting = false;
        this.toasterService.pop("error", "Error", err.error.message);
      }
    );
  }

  onSelectProduct(productId: string) {
    this.selectedProductId = productId;
  }

  onSubmit(form: HTMLFormElement) {
    if (form.form.invalid) {
      return;
    }

    this.isSubmitting = true;
    const payload = {
      recurring: false,
      productId: this.selectedProductId,
      ...this.productPart,
    };
    if (this.productPartId) {
      this.updateProductPart(payload);
    } else {
      this.createProductPart(payload);
    }
  }
}
