<a
  class="text-primary hoverable mr-1"
  *ngIf="subscription.status !== 'VOID'"
  (click)="onShowModal()"
>
  <i
    class="fa fa-ban"
    tooltip="{{ 'tooltip.voidSubscription' | translate }}"
  ></i>
</a>

<a
  *ngIf="subscription.status === 'VOID'"
  class="hoverable badge badge-secondary mr-1"
  translate
  (click)="onShowModal()"
  >void</a
>

<div
  bsModal
  #voidSubscriptionModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" translate>voidSubscription</h4>
        <button
          type="button"
          class="close"
          (click)="onCloseModel()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-full-width">
          <div class="form-group">
            <label translate class="required">remarks</label>
            <textarea
              name="remark"
              class="form-control"
              rows="4"
              #remark="ngModel"
              class="form-control"
              name="remark"
              [(ngModel)]="subscriptionItem.remark"
              (input)="onChangeRemark($event.target.value)"
            ></textarea>
          </div>
          <i *ngIf="subscriptionItem.status !== 'VOID'" translate>
            subscriptionVoid
          </i>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="onCloseModel()"
          translate
        >
          buttonTitles.cancel
        </button>
        <button
          [ladda]="isLoading"
          type="button"
          (click)="onSubmit()"
          class="btn btn-primary"
          [disabled]="isInvalidRemark"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
