import { Query } from "@datorama/akita";
import { AccountState, AccountStore } from "./account.store";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AccountQuery extends Query<AccountState> {
  constructor(protected store: AccountStore) {
    super(store);
  }

  get getAccount() {
    return this.getValue().account;
  }

  get getAccounts() {
    return this.getValue().accounts;
  }

  get getAccountsByType() {
    return this.getValue().accountsByType;
  }

  get getSelectedAccount() {
    return this.getValue().selectedAccount;
  }

  get getSelectedReseller() {
    return this.getValue().selectedReseller;
  }
}
