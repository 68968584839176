import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { PendingBilling } from "@models/billing.model";

import {
  BillingService,
  AlertService,
  QuotationService,
} from "@services/_index";
import { BillingQuery, AuthQuery } from "@stores/_index";
import { NgxSpinnerService } from "ngx-bootstrap-spinner";

import { addDays, format, startOfMonth } from "date-fns";

@Component({
  selector: "app-pending-billing",
  templateUrl: "./pending-billing.component.html",
  styleUrls: ["./pending-billing.component.scss"],
})
export class PendingBillingComponent implements OnInit {
  pendingBillings: PendingBilling[] = [];
  selectedBillingIds: string[] = [];

  isLoading: boolean = false;

  // pagination
  currentPage: number = 0;
  totalItems: number = 0;
  itemPerPage: number = 10;

  // search
  search: string = "";
  // sort
  sortedField: string = "billingDate";
  isSortedAsc: boolean;

  // date filter
  today = new Date();
  dateFilter: Date = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    1
  );
  readonly ITEMS_PER_PAGE: number = 10;

  constructor(
    private billingService: BillingService,
    private quotationService: QuotationService,
    private billingQuery: BillingQuery,
    private authQuery: AuthQuery,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getBillings();
  }

  getBillings() {
    this.isLoading = true;
    this.billingService
      .getBillingItems({
        params: {
          page: this.currentPage,
          size: this.itemPerPage,
          keySearch: this.search,
          sort: this.sortParams(),
          billingDate: format(this.dateFilter, "YYYY-MM-DD"),
        },
      })
      .subscribe(
        (res?) => {
          this.isLoading = false;
          this.totalItems = res.data?.totalSize;
          this.pendingBillings = this.billingQuery.billingItems;
        },
        (err?) => {
          this.isLoading = false;
          this.alertService.error(err?.error?.message);
        }
      );
  }

  onChangeDateFilter(date) {
    this.dateFilter = date;
    this.currentPage = 0;
    this.getBillings();
  }

  onExportSSC() {
    this.currentPage = 0;
    this.getBillings();
    this.selectedBillingIds = [];
  }

  onExportAllSSC() {
    this.currentPage = 0;
    this.getBillings();
    this.selectedBillingIds = [];
  }


  isCheckedAll() {
    return (
      this.pendingBillings.length !== 0 &&
      this.pendingBillings.filter((billing) =>
        this.isBillingSelected(billing)
      ).length === this.pendingBillings.length
    );
  }

  onCheckedAllChange(isCheckedAll: boolean) {
    if (isCheckedAll) {
      this.handleSelectAll();
    } else {
      this.handleDeselectAll();
    }
  }

  handleSelectAll() {
    this.pendingBillings.forEach((billing) => {
      if (!this.isBillingSelected(billing)) {
        this.selectedBillingIds.push(billing.id);
      }
    });
  }

  handleDeselectAll() {
    this.pendingBillings.forEach((billing) => {
      const index = this.selectedBillingIds.indexOf(billing.id);
      if (index !== -1) {
        this.selectedBillingIds.splice(index, 1);
      }
    });
  }

  isBillingSelected(billing) {
    return this.selectedBillingIds.indexOf(billing.id) !== -1;
  }

  isChecked(billingId: string) {
    return this.selectedBillingIds.find((id) => id === billingId)?.length;
  }

  onChange(billingId: string, isChecked: boolean) {
    if (isChecked) {
      this.addItemToExportingList(billingId);
    } else {
      this.removeItemFromExportingList(billingId);
    }
  }

  addItemToExportingList(billingId: string) {
    this.selectedBillingIds.push(billingId);
  }

  removeItemFromExportingList(billingId: string) {
    const index = this.selectedBillingIds.indexOf(billingId);
    this.selectedBillingIds.splice(index, 1);
  }

  goToPage(page: number) {
    this.currentPage = page - 1;
    this.getBillings();
  }

  changeItemPerPage(itemPerPages: number) {
    this.currentPage = 0;
    this.itemPerPage = itemPerPages;
    this.getBillings();
  }

  isSortedOnField(field: string) {
    return this.sortedField === field;
  }

  isSortedAscOnField(field: string) {
    return this.isSortedOnField(field) && this.isSortedAsc;
  }

  isSortedDescOnField(field: string) {
    return this.isSortedOnField(field) && !this.isSortedAsc;
  }

  sortParams() {
    return this.sortedField
      ? `${this.sortedField},${this.isSortedAsc ? "asc" : "desc"}`
      : "";
  }

  onApplySort(field: string) {
    if (field !== this.sortedField) {
      this.isSortedAsc = true;
      this.sortedField = field;
    } else {
      this.isSortedAsc = !this.isSortedAsc;
    }
    this.getBillings();
  }

  goToQuotation(id: string) {
    this.quotationService.selectQuotation(id);
    this.router.navigate(["/wholesaler/quotation"]);
  }
}
