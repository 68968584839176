import { Component, Input, OnInit } from "@angular/core";
import { SubscriptionQuery } from "@stores/_index";
import { SubscriptionService } from "@services/_index";
import { AlertService } from "@services/alert.service";
import { Account } from "@models/account.model";
import { controllers } from "chart.js";
@Component({
  selector: "app-customer-subscription",
  templateUrl: "./customer-subscription.component.html",
  styleUrls: ["./customer-subscription.component.scss"],
})
export class CustomerSubscriptionComponent implements OnInit {
  @Input() account: Account;
  public data: Object = {};
  public isLoading: Boolean = false;
  public payload = { dateFilter: ["CURRENT"] };

  constructor(
    private subscriptionService: SubscriptionService,
    private subscriptionQuery: SubscriptionQuery,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData(payload = {}) {
    this.isLoading = true;
    this.subscriptionService
      .getCustomerSubscriptions(this.account.id, {
        params: this.payload,
      })
      .subscribe(
        (res) => {
          this.data = this.subscriptionQuery.customerSubscriptions;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.alertService.errors(err?.errors?._embedded?.message);
        }
      );
  }
  getProducts() {
    return [...Object.keys(this.data)];
  }
  getPartsOfProduct(product: string) {
    const res = [];
    Object.keys(this.data[product]).map((partName) => {
      res.push(([partName] = this.data[product][partName]));
    });
    return res;
  }
  getSubscriptions(product, part) {
    return this.getPartsOfProduct(product);
  }
  getPartSummary(product: string) {
    const res = [];
    Object.keys(this.data[product]).map((partName) => {
      res.push({
        key: partName,
        value: this.getTotalOfPart(this.data[product][partName]),
      });
    });
    return res;
  }
  getTotalOfPart(part) {
    const test = Object.values(part).reduce(
      (acc, obj: any) => acc + obj.quantity,
      0
    );
    return test;
  }
  onChangeFilter(filterValue) {
    if (
      filterValue.includedExpired &&
      !this.payload.dateFilter.includes("EXPIRED")
    ) {
      this.payload.dateFilter.push("EXPIRED");
    } else if (
      !filterValue.includedExpired &&
      this.payload.dateFilter.includes("EXPIRED")
    ) {
      const index = this.payload.dateFilter.indexOf("EXPIRED");
      this.payload.dateFilter.splice(index, 1);
    }
    if (
      filterValue.includedFuture &&
      !this.payload.dateFilter.includes("FUTURE")
    ) {
      this.payload.dateFilter.push("FUTURE");
    } else if (
      !filterValue.includedFuture &&
      this.payload.dateFilter.includes("FUTURE")
    ) {
      const index = this.payload.dateFilter.indexOf("FUTURE");
      this.payload.dateFilter.splice(index, 1);
    }
    this.loadData()
  }
}
