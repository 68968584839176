<td *ngIf="!isShowingDetails">
  <button
    style="width: 40px"
    class="btn btn-primary mb-1 mr-1"
    type="button"
    (click)="onSaveQuotationItem(item)"
    [ladda]="isLoading"
    data-style="zoom-in"
    [disabled]="isQuotationItemInvalid(item) || !isQuantityValid(item)"
  >
    <i class="fa fa-check"> </i>
  </button>
  <button
    style="width: 40px"
    class="btn btn-primary mb-1"
    type="button"
    (click)="onCancelQuotationItem(i)"
    [ladda]="isLoading"
    data-style="zoom-in"
  >
    <i class="fa fa-ban"> </i>
  </button>
</td>
<td>
  <select
    class="form-control"
    id="type"
    name="product"
    [(ngModel)]="item.productId"
    [disabled]="quotationItemIdInProductBundle.includes(item.id)"
    (change)="onSelectProduct($event.target.value, item)"
  >
    <option value="undefined" hidden>Please select a Product</option>
    <option *ngFor="let p of products" [value]="p.id">
      {{ p.productName }}
    </option>
  </select>
</td>
<td>
  <select
    class="form-control"
    id="type"
    name="partPriceItem"
    [disabled]="quotationItemIdInProductBundle.includes(item.id)"
    [(ngModel)]="item.partPricingId"
    (change)="onSelectPartPriceItem($event.target.value, item)"
  >
    <option value="" hidden>Please select a Part Price</option>
    <option
      *ngFor="let priceItem of getAvailablePartPriceItems(item.productId)"
      [value]="priceItem.id"
    >
      {{ priceItem.name }}
    </option>
  </select>
</td>
<td>
  <select
    class="form-control"
    id="type"
    name="recurrenceType"
    [(ngModel)]="item.recurrenceType"
    [disabled]="
      getAvailableRecurrenceTypes(item.partPricingId)?.length === 1
    "
    (change)="updatePrice(item)"
  >
    <option value="" hidden>Please select Recurrency Type</option>
    <option
      *ngFor="let type of getAvailableRecurrenceTypes(item.partPricingId)"
      [value]="type"
    >
      {{ type }}
    </option>
  </select>
</td>
<td>
  <input
    class="form-control"
    name="tax"
    type="price"
    [(ngModel)]="item.price"
    disabled
  />
</td>
<td>
  <input
    class="form-control"
    name="discountAmount"
    #discountAmount="ngModel"
    [ngClass]="{
      'is-invalid': isDiscountAmountInvalid(item)
    }"
    type="number"
    [(ngModel)]="item.discountAmount"
    (change)="updatePrice(item)"
  />
  <div *ngIf="isDiscountAmountInvalid(item)">
    <div
      *ngIf="isDiscountLargerThanSetting(item)"
      class="invalid-feedback d-block"
    >
      <span translate>
        validateMessages.discountAmountLargerThanSetting
      </span>
      {{ getDiscountAmount(item) }}
    </div>
    <div
      *ngIf="isDiscountFormatInvalid(item)"
      class="invalid-feedback d-block"
      translate
    >
      validateMessages.discountAmount
    </div>
  </div>
</td>
<td>
  <input
    class="form-control"
    name="quantity"
    type="number"
    [(ngModel)]="item.quantity"
    [ngClass]="{
      'is-invalid': !isQuantityValid(item)
    }"
    (change)="updatePrice(item)"
  />
  <div *ngIf="!this.quotationItemIdInProductBundle.includes(item.id) && !isQuantityValid(item)">
    <div *ngIf="!this.quotationItemIdInProductBundle.includes(item.id) && !isQuantityValid(item)" class="invalid-feedback d-block">
      <span translate> validateMessages.quantity </span>
      {{ getMinimumQuantity(item) }}
    </div>
  </div>
  <div *ngIf="this.quotationItemIdInProductBundle.includes(item.id) && !isQuantityProductBundleValid(item)">
    <div *ngIf="this.quotationItemIdInProductBundle.includes(item.id) && !isQuantityProductBundleValid(item)" class="invalid-feedback d-block">
      <span translate> validateMessages.productBundleQuantity </span>
      {{ getMinimumProductBundleQuantity(item) }} and {{ getMaximumProductBundleQuantity(item) }}
    </div>
  </div>
</td>
<td>
  <input
    class="form-control"
    name="tax"
    type="amount"
    [(ngModel)]="item.amount"
    disabled
  />
</td>
