import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { Router } from "@angular/router";
import { Account, AddressType } from "@models/_index";
import { Country, State } from "country-state-city";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ConfirmModalService } from "@services/confirm-modal.service";
@Component({
  selector: "app-form-customer",
  templateUrl: "./form-customer.component.html",
  styleUrls: ["./form-customer.component.scss"],
})
export class FormCustomerComponent implements OnInit {
  @Input() account: Account = new Account();
  @Input() isSubmitting: Boolean = false;
  @Input() isEditing: Boolean = true;
  @Output() submitEmitter: EventEmitter<string> = new EventEmitter();
  @Output() cancelEmitter: EventEmitter<string> = new EventEmitter();

  public addressTypes = Object.keys(AddressType);
  public countries = [];
  public states = [];
  public customerTypes = ["DIRECT", "WHOLESALER", "RESELLER"];

  public bsModalRef?: BsModalRef;
  private _account: Account;
  constructor(
    private router: Router,
    private confirmModalService: ConfirmModalService
  ) {}

  ngOnInit() {
    this.countries = this.getCountries();
    this._account = JSON.parse(JSON.stringify(this.account));
  }

  onSubmit(form: HTMLFormElement) {
    if (form.form.invalid) {
      return;
    }
    this.submitEmitter.emit();
  }

  onCancel() {
    this.cancelEmitter.emit("");
  }

  onEdit() {
    this.router.navigate(["wholesaler/customer/customers", this.account.id], {
      fragment: "edit",
    });
  }

  onSelectCountry(country: string, addressType: string) {
    this.states = this.getStates(country);
    this.account.company[addressType].countryName = 1
      Country.getCountryByCode(country).name;
  }

  onSelectState(state: string, addressType: string) {
    const stateName = State.getStatesOfCountry(
      this.account.company[addressType].country
    ).find((s) => s.isoCode === state).name;
    this.account.company[addressType].stateName = stateName;
  }

  getCountries() {
    return Country.getAllCountries().map((country) => ({
      name: country.name,
      code: country.isoCode,
    }));
  }

  getStates(country: string) {
    return State.getStatesOfCountry(country).map((state) => ({
      name: state.name,
      code: state.isoCode,
    }));
  }
}
