import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { BillingService, AlertService } from "@services/_index";
import { Billing } from "@models/_index";

@Component({
  selector: "app-billing-edit-status",
  templateUrl: "./billing-edit-status.component.html",
  styleUrls: ["./billing-edit-status.component.scss"],
})
export class BillingEditStatusComponent implements OnInit {
  @Input() billItem: Billing = new Billing();
  @Output() fetchBillItems: EventEmitter<any> = new EventEmitter();

  @ViewChild("billingEditStatusModal", { static: false })
  public billingEditStatusModal: ModalDirective;
  public isLoading: Boolean = false;
  public bill: Billing = new Billing();
  public isInvalidRemark: boolean = false;
  public VOID: string = "VOID";

  constructor(
    private billingService: BillingService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {}

  onShowModal() {
    this.billingEditStatusModal.show();
    this.bill = { ...this.billItem };
    this.checkValidRemark();
  }

  onCloseModal() {
    this.billingEditStatusModal.hide();
    this.isInvalidRemark = false;
  }

  onSubmit() {
    this.isLoading = true;
    const payload = {
      status: this.bill.status,
      invoiceNumber: this.bill.invoiceNumber,
      remark: this.bill.remark,
    };
    this.billingService.updateBillStatus({ id: this.bill.id, payload: payload }).subscribe(
      (res) => {
        this.alertService.success("Update Bill Status Successfully!");
        this.isLoading = false;
        this.billingEditStatusModal.hide();
        this.fetchBillItems.emit();
      },
      (err) => {
        this.isLoading = false;
        this.alertService.error(err?.error.message);
      }
    );
  }

  onChange() {
    this.checkValidRemark();
  }

  onChangeRemark() {
    this.checkValidRemark();
  }

  checkValidRemark() {
    if (this.bill.status === this.VOID && !this.bill.remark) {
      this.isInvalidRemark = true;
      return;
    }
    this.isInvalidRemark = false;
  }
}
