import { Quotation } from "@models/_index";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { animate, style, transition, trigger } from "@angular/animations";
import { ActivatedRoute } from "@angular/router";
import { QuotationQuery } from "@stores/_index";

@Component({
  selector: "app-form-contact-details",
  templateUrl: "./form-contact-details.component.html",
  styleUrls: ["./form-contact-details.component.scss"],
  animations: [
    trigger("inOutPaneAnimation", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateX(-100%)" }),
        animate(
          "400ms ease-in-out",
          style({ opacity: 1, transform: "translateX(0)" })
        ),
      ]),
    ]),
  ],
})
export class FormContactDetailsComponent implements OnInit {
  @Input() isShowing: boolean;

  @Input() isShowingDetails: boolean;

  @Input() quotation: Quotation;

  @Output() handleSubmit: EventEmitter<any> = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private quotationQuery: QuotationQuery
  ) {}

  ngOnInit(): void {
  }
}
