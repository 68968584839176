<div class="card my-3">
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-md-6 col-xl-5">
        <form
          id="policyForm"
          (ngSubmit)="onSubmit(policyForm)"
          #policyForm="ngForm"
        >
          <div class="form-group">
            <label translate class="required">policyName</label>
            <input
              type="text"
              class="form-control"
              name="policyName"
              #policyName="ngModel"
              placeholder="{{ 'placeholder.policyName' | translate }}"
              [(ngModel)]="policy.policyName"
              [ngClass]="{
                'is-invalid': policyForm.submitted && policyName.invalid
              }"
              required
            />
            <div
              *ngIf="policyForm.submitted && policyName.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="policyName.errors.required" translate>
                validateMessages.policyName
              </div>
            </div>
          </div>

          <div>
            <h5 translate>actions</h5>
            <div class="mb-2" *ngFor="let action of actions">
              <input
                type="checkbox"
                style="transform: translateY(2px); margin-right: 5px"
                [checked]="isChecked(action.id)"
                (change)="
                  onChange(action.id, $event.target.checked)
                "
              />
              {{ action.service }}:{{ action.operation }}
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="mt-3">
      <button
        class="btn btn-primary mr-1"
        [ladda]="isSubmitting"
        [translate]="'buttonTitles.save'"
        data-style="zoom-in"
        form="policyForm"
      ></button>
      <button
        class="btn btn-secondary"
        [translate]="'buttonTitles.cancel'"
        [routerLink]="'/wholesaler/admin/policy'"
      ></button>
    </div>
  </div>
</div>