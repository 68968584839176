<div class="form-quotation-header">
  <div class="row">
    <div
      class="col-3 form-quotation-header--item"
      *ngFor="let header of headers"
      [ngClass]="{'active': isSelected(header)}"
    >
      {{ header.translateKey | translate }}
    </div>
  </div>
  <!-- </div> -->
</div>
