import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { WholesalerRoutingModule } from "./wholesaler-routing.module";

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from "@coreui/angular";
import { LayoutSidebarComponent } from "@shared/components/layout/layout-sidebar/layout-sidebar.component";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import { LaddaModule } from "angular2-ladda";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgSelectModule } from "@ng-select/ng-select";
import { TabsModule } from "ngx-bootstrap/tabs";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { NgxDropzoneModule } from "ngx-dropzone";
import { ModalModule } from "ngx-bootstrap/modal";
//  import components
import {
  WholesalerComponent,
  DashboardComponent,
  ProductComponent,
  CustomerComponent,
  CreateCustomerComponent,
  FormCustomerComponent,
  EditCustomerComponent,
  PartPricingComponent,
  CreatePartPricingComponent,
  FormPartPricingComponent,
  SearchBarComponent,
  CreateProductComponent,
  FormProductComponent,
  ProductPartComponent,
  EditPartPricingComponent,
  CreateProductPartComponent,
  FormProductPartComponent,
  SubscriptionComponent,
  FormSubscriptionComponent,
  CreateSubscriptionComponent,
  QuotationComponent,
  FormQuotationDetailsComponent,
  CreateQuotationComponent,
  FormContactDetailsComponent,
  FormHeaderComponent,
  FormProductDetailsComponent,
  BillingComponent,
  FormPreviewComponent,
  EditProductComponent,
  EditProductPartComponent,
  LoadingSpinnerComponent,
  PaginationComponent,
  ModalComponent,
  SortableHeaderComponent,
  FilterProductPartComponent,
  FormQuotationComponent,
  EditQuotationComponent,
  PendingBillingComponent,
  BillingDetailsComponent,
  UserComponent,
  UserGroupComponent,
  PolicyComponent,
  EditPolicyComponent,
  CreatePolicyComponent,
  FormPolicyComponent,
  DashboardWidgetComponent,
  ShowQuotationComponent,
  CreateUserComponent,
  EditUserComponent,
  FormUserComponent,
  ActionComponent,
  FormUserGroupComponent,
  CreateUserGroupComponent,
  EditUserGroupComponent,
  FormProductDetailComponent,
  QuotationAmountDetailComponent,
  BillingDetailStatusComponent,
  BillingEditStatusComponent,
  ModalChangePasswordComponent,
  EditAttachmentSubscriptionComponent,
  HomeComponent,
  VoidSubscriptionComponent,
  ExportSscComponent,
  ExportSscByMonthComponent,
  UpdateCustomerReferenceNumberComponent,
  CustomerSubscriptionComponent,
  FormCustomerTaxListComponent,
  CreateProductBundleComponent,
  EditProductBundleComponent,
  FormProductBundleComponent,
  ProductBundleComponent,
  FormQuotationProductBundleComponent,
} from "./index";

import { P403Component } from "@shared/components/error/403.component";
import { CustomerSubscriptionFilterComponent } from './customer/customer-subscription/customer-subscription-filter/customer-subscription-filter.component';
import { CustomerTaxComponent } from './customer-tax/customer-tax.component';

@NgModule({
  declarations: [
    WholesalerComponent,
    DashboardComponent,
    ProductComponent,
    CustomerComponent,
    CreateCustomerComponent,
    FormCustomerComponent,
    EditCustomerComponent,
    PartPricingComponent,
    CreatePartPricingComponent,
    FormPartPricingComponent,
    SearchBarComponent,
    CreateProductComponent,
    FormProductComponent,
    ProductPartComponent,
    EditPartPricingComponent,
    CreateProductPartComponent,
    FormProductPartComponent,
    SubscriptionComponent,
    FormSubscriptionComponent,
    CreateSubscriptionComponent,
    QuotationComponent,
    FormQuotationDetailsComponent,
    CreateQuotationComponent,
    FormContactDetailsComponent,
    FormHeaderComponent,
    FormProductDetailsComponent,
    BillingComponent,
    FormPreviewComponent,
    EditProductComponent,
    EditProductPartComponent,
    LoadingSpinnerComponent,
    PaginationComponent,
    ModalComponent,
    FilterProductPartComponent,
    FormQuotationComponent,
    EditQuotationComponent,
    SortableHeaderComponent,
    PendingBillingComponent,
    BillingDetailsComponent,
    LayoutSidebarComponent,
    DashboardWidgetComponent,
    ShowQuotationComponent,
    UserComponent,
    UserGroupComponent,
    PolicyComponent,
    EditPolicyComponent,
    CreatePolicyComponent,
    FormPolicyComponent,
    FormUserComponent,
    CreateUserComponent,
    EditUserComponent,
    FormUserGroupComponent,
    CreateUserGroupComponent,
    EditUserGroupComponent,
    ActionComponent,
    FormProductDetailComponent,
    QuotationAmountDetailComponent,
    BillingDetailStatusComponent,
    BillingEditStatusComponent,
    EditAttachmentSubscriptionComponent,
    ModalChangePasswordComponent,
    P403Component,
    HomeComponent,
    VoidSubscriptionComponent,
    ExportSscComponent,
    ExportSscByMonthComponent,
    UpdateCustomerReferenceNumberComponent,
    CustomerSubscriptionComponent,
    CustomerSubscriptionFilterComponent,
    CustomerTaxComponent,
    FormCustomerTaxListComponent,
    ProductBundleComponent,
    CreateProductBundleComponent,
    EditProductBundleComponent,
    FormProductBundleComponent,
    FormQuotationProductBundleComponent
  ],
  imports: [
    CommonModule,
    WholesalerRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule,
    TranslateModule.forChild({ extend: true }),
    FormsModule,
    LaddaModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    NgxSliderModule,
    TooltipModule.forRoot(),
    NgxDropzoneModule,
    ModalModule.forRoot(),
  ],
  entryComponents: [ModalComponent],
})
export class WholesalerModule {}
