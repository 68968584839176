<div class="card my-3">
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <form
          id="userForm"
          (ngSubmit)="onSubmit(userForm)"
          #userForm="ngForm"
        >
          <div class="form-group">
            <label translate class="required">firstName</label>
            <input
              type="text"
              class="form-control"
              name="firstName"
              #firstName="ngModel"
              placeholder="{{ 'placeholder.firstName' | translate }}"
              [(ngModel)]="user.firstName"
              [ngClass]="{
                'is-invalid': userForm.submitted && firstName.invalid
              }"
              required
            />
            <div
              *ngIf="userForm.submitted && firstName.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="firstName.errors.required" translate>
                validateMessages.firstName
              </div>
            </div>
          </div>

          <div class="form-group">
            <label translate class="required">lastName</label>
            <input
              type="text"
              class="form-control"
              name="lastName"
              #lastName="ngModel"
              placeholder="{{ 'placeholder.lastName' | translate }}"
              [(ngModel)]="user.lastName"
              [ngClass]="{
                'is-invalid': userForm.submitted && lastName.invalid
              }"
              required
            />
            <div
              *ngIf="userForm.submitted && lastName.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="lastName.errors.required" translate>
                validateMessages.lastName
              </div>
            </div>
          </div>

          <div class="form-group">
            <label translate class="required">email</label>
            <input
              type="text"
              class="form-control"
              name="email"
              #email="ngModel"
              placeholder="{{ 'placeholder.userEmail' | translate }}"
              [(ngModel)]="user.email"
              [ngClass]="{
                'is-invalid': userForm.submitted && email.invalid
              }"
              required
            />
            <div
              *ngIf="userForm.submitted && email.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="email.errors.required" translate>
                validateMessages.userEmail
              </div>
            </div>
          </div>

          <div class="form-group">
            <label translate class="required">userGroup</label>
            <ng-select
              [multiple]="true"
              [closeOnSelect]="false"
              name="userGroups"
              [(ngModel)]="user.userGroups"
              placeholder="{{ 'placeholder.userGroups' | translate }}"
            >
              <ng-option
                *ngFor="let userGroup of userGroups"
                [value]="userGroup.id"
                >{{ userGroup.name }}</ng-option
              >
            </ng-select>
          </div>

          <div class="form-group">
            <label translate class="required">staffId</label>
            <input
              type="text"
              class="form-control"
              name="username"
              #username="ngModel"
              placeholder="{{ 'placeholder.staffId' | translate }}"
              [(ngModel)]="user.username"
              [ngClass]="{
                'is-invalid': userForm.submitted && username.invalid
              }"
              required
            />
            <div
              *ngIf="userForm.submitted && username.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="username.errors.required" translate>
                validateMessages.username
              </div>
            </div>
          </div>

          <div class="form-group">
            <label translate>phoneNumber</label>
            <input
              type="text"
              class="form-control"
              name="phoneNumber"
              #phoneNumber="ngModel"
              placeholder="{{ 'placeholder.phoneNumber' | translate }}"
              [(ngModel)]="user.phoneNumber"
            />
          </div>

          <div class="form-group" *ngIf="!isEditing">
            <label translate class="required">password</label>
            <input
              type="password"
              class="form-control"
              name="password"
              #password="ngModel"
              placeholder="{{
                'placeholder.password' | translate
              }}"
              [(ngModel)]="user.password"
              [ngClass]="{
                'is-invalid':
                  userForm.submitted && password.invalid
              }"
              required
            />
            <div
              *ngIf="userForm.submitted && password.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="password.errors.required" translate>
                validateMessages.password
              </div>
            </div>
          </div>

          <div class="form-group" *ngIf="!isEditing">
            <label translate class="required"
              >confirmPassword</label
            >
            <input
              type="password"
              class="form-control"
              name="confirmPassword"
              #confirmPassword="ngModel"
              placeholder="{{
                'placeholder.password' | translate
              }}"
              [(ngModel)]="user.confirmPassword"
              [ngClass]="{
                'is-invalid':
                  userForm.submitted && !checkConfirmPassword()
              }"
              required
            />
            <div
              *ngIf="userForm.submitted && !checkConfirmPassword()"
              class="invalid-feedback"
            >
              <div
                *ngIf="
                  userForm.submitted && !checkConfirmPassword()
                "
                translate
              >
                validateMessages.confirmPassword
              </div>
            </div>
          </div>

          <app-modal-change-password [user]="user" *ngIf="isEditing"></app-modal-change-password>
        </form>
      </div>
    </div>

    <div class="mt-3">
      <button
        class="btn btn-primary mr-1"
        [ladda]="isSubmitting"
        [translate]="'buttonTitles.save'"
        data-style="zoom-in"
        form="userForm"
      ></button>
      <button
        class="btn btn-secondary"
        [translate]="'buttonTitles.cancel'"
        [routerLink]="'/wholesaler/admin/users'"
      ></button>
    </div>
  </div>
</div>
