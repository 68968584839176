import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  Product,
  ProductPart,
  PartPriceItem,
  CurrencyPriceItem,
} from "@models/_index";
import { ProductService, ProductPartService } from "@services/_index";
import { ProductQuery, ProductPartQuery } from "@stores/_index";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ConfirmModalService } from "@services/confirm-modal.service";

@Component({
  selector: "app-form-part-pricing",
  templateUrl: "./form-part-pricing.component.html",
  styleUrls: ["./form-part-pricing.component.scss"],
})
export class FormPartPricingComponent implements OnInit {
  @Input() partPriceItem: PartPriceItem = new PartPriceItem();
  @Input() isSubmitting: Boolean = false;
  @Input() isEditing: Boolean = false;
  @Output() submitEmitter: EventEmitter<string> = new EventEmitter();

  public products: Product[];
  public _currencyPriceItems: CurrencyPriceItem[];
  public productParts: ProductPart[];
  public currencies = ["USD", "SGD", "MYR"];

  public isEditingCurrencyPriceItem: boolean = false;
  public editingCurrencyPriceItemIndex: number;

  public selectedProductId: string;
  public selectedProductPartId: string;

  public productName: string = "[Product Name]";
  public productPartName: any = "[Product Part Name]";

  public bsModalRef?: BsModalRef;
  public isLoading: Boolean = false;
  public isCurrencyPriceValid: Boolean = true;
  public isMaxPriceDiscountValid: boolean = true;

  public maxLength: number = 107;
  public billDesLength: number = 0;
  public charactersLeft: number = 0;

  constructor(
    private productService: ProductService,
    private productQuery: ProductQuery,
    private productPartQuery: ProductPartQuery,
    private productPartService: ProductPartService,
    private confirmModalService: ConfirmModalService
  ) {}

  ngOnInit(): void {
    this.initData();
    this.loadData();
  }

  initData() {
    this.selectedProductId =
      this.partPriceItem?.productPart?.product?.id || "";

    this.selectedProductPartId = this.partPriceItem?.productPart?.id || "";

    this._currencyPriceItems = JSON.parse(
      JSON.stringify(this.partPriceItem?.priceItems)
    ); 
  }

  loadData() {
    this.loadProduct();
    if (this.selectedProductId) {
      this.onChangeBillDescription(this.partPriceItem.billDescription)
      this.loadProductPartByProductId();
    }
  }

  loadProduct() {
    this.productService.getProductsNoSubscribe().subscribe(() => {
      this.products = this.productQuery.getProducts;
    });
  }

  loadProductPartByProductId() {
    this.productPartService
      .getProductPartsByProductId({  id: this.selectedProductId })
      .subscribe((res) => {
        this.productParts = this.productPartQuery.productParts;
      });
  }

  onSubmit(form: HTMLFormElement) {
    if (form.form.invalid) {
      return;
    }
    this.submitEmitter.emit("submitted");
  }

  onSelectProduct(productId: string) {
    this.selectedProductId = productId;
    this.productName = this.products.find(
      (x) => x.id === productId
    ).productName;
    this.productPartService
      .getProductPartsByProductId({ id: this.selectedProductId })
      .subscribe(() => {
        this.productParts = this.productPartQuery.productParts;
      });
    this.initBillDescription();
  }

  onSelectProductPart(productPartId: string) {
    this.selectedProductPartId = productPartId;
    this.partPriceItem.productPartId = this.selectedProductPartId;
    this.productPartName = this.productParts.find(
      (x) => x.id === productPartId
    ).name;
    this.initBillDescription();
  }

  // Todo: Must split Currency Form Part to another Component
  // to make code easier to maintain
  onAddCurrencyPriceItem() {
    this.partPriceItem.priceItems.push(new CurrencyPriceItem());
    this.isEditingCurrencyPriceItem = true;
    this.editingCurrencyPriceItemIndex = this.isCurrencyPriceItemExist()
      ? this.partPriceItem.priceItems.length - 1
      : 0;
  }

  isCurrencyPriceItemExist() {
    return this.getPriceItems().length > 0;
  }

  isCurrencyPriceItemEditing(index: number) {
    return this.editingCurrencyPriceItemIndex === index;
  }

  onEditCurrencyPriceItem(index: number) {
    this.editingCurrencyPriceItemIndex = index;
    this.isEditingCurrencyPriceItem = true;
  }

  onRemoveCurrencyPriceItem(index: number) {
    this.bsModalRef = this.confirmModalService.show(
      "Confirmation",
      "Are you sure you want to delete this price?"
    );

    this.bsModalRef.content.eventEmitter.subscribe((isOk) => {
      if (isOk) {
        this.removeCurrencyPriceItem(index);
      }
      this.bsModalRef.hide();
    });
  }

  removeCurrencyPriceItem(index: number) {
    if (!this.getPriceItems()[index].id) {
      this.getPriceItems().splice(index, 1);
    } else {
      this.getPriceItems()[index].isDeleted = true;
    }
  }

  onSaveCurrencyPriceItem(priceItem: CurrencyPriceItem) {
    if (
      !this.isPriceItemValid(priceItem) ||
      !this.checkMaxPriceDiscountValid(priceItem)
    ) {
      this.isCurrencyPriceValid = false;
      return;
    }
    this.isCurrencyPriceValid = true;
    this._currencyPriceItems = JSON.parse(
      JSON.stringify(this.partPriceItem.priceItems)
    );
    this.stopEditingCurrencyPriceItem();
  }

  getCurrency(currency: string) {
    return currency;
  }

  onCancelCurrencyPriceItem(index: number) {
    this.partPriceItem.priceItems.pop();
    this.stopEditingCurrencyPriceItem();
  }

  stopEditingCurrencyPriceItem() {
    this.editingCurrencyPriceItemIndex = null;
    this.isEditingCurrencyPriceItem = false;
  }

  getPriceItems() {
    return this.partPriceItem.priceItems;
  }

  isShowPriceItem(priceItem: CurrencyPriceItem) {
    return priceItem?.isDeleted === undefined || !priceItem.isDeleted;
  }

  getAvailableCurrencies(currency: string): string[] {
    return [
      ...this.currencies.filter(
        (c) =>
          this.getPriceItems()
            .filter((x) => !x.isDeleted)
            .find((currencyPrice) => currencyPrice.currency === c) ===
          undefined
      ),
      currency,
    ];
  }

  isPriceItemValid(item: CurrencyPriceItem) {
    const maxPriceDiscount = item?.maxPriceDiscount?.toString();
    const price = item?.price?.toString();
    const regexMaxPriceDiscount = new RegExp("^\\d*(\\.\\d{0,1})?$");
    const regexPrice = new RegExp("^\\d*(\\.\\d{0,2})?$");
    const condMaxPriceDiscount =
      regexMaxPriceDiscount.test(maxPriceDiscount);
    const condPrice = regexPrice.test(price);
    return item.currency && condMaxPriceDiscount && condPrice;
  }

  checkMaxPriceDiscountValid(item: CurrencyPriceItem) {
    this.isMaxPriceDiscountValid =
      item.maxPriceDiscount <= item.price ? true : false;

    return this.isMaxPriceDiscountValid;
  }

  initBillDescription() {
    // eslint-disable-next-line max-len
    this.partPriceItem.billDescription = `${this.productName} ${this.productPartName}, {{RECURRENCE}}, {{QTY}}`;
    this.billDesLength = this.partPriceItem.billDescription.length;
    this.charactersLeft = this.maxLength - this.billDesLength;
  }

  onChangeBillDescription(val: string) {
    const isRecurrence = val.includes("{{RECURRENCE}}");
    const isQty = val.includes("{{QTY}}");
    this.billDesLength = val.length;
    switch (true) {
      case !isRecurrence && !isQty:
        this.maxLength = 100;
        break;
      case isRecurrence && !isQty:
        this.maxLength = 105;
        break;
      case !isRecurrence && isQty:
        this.maxLength = 102;
        break;
      case isRecurrence && isQty:
        this.maxLength = 107;
        break;
      default:
        break;
    }
    this.charactersLeft = this.maxLength - this.billDesLength;
  }

  showTooltip() {
    return `
      <span class="text-left d-block">
        - {{QTY}} & {{RECURRENCE}} will be replaced with specified quantity and recurrence
        type during quotation. Usage of {{QTY}} & {{RECURRENCE}} is highly encouraged as 
        Bill Description will be displayed in Quotation and FI Invoice.
        <br/> <br/> 
        - Note: In FI invoice, bill description is limited to 50 characters only 
      </span>
    `;
  }
}
