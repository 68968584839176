import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { format, addMonths } from "date-fns";
import { BillingService, AlertService } from "@services/_index";
import { AuthQuery } from "@stores/_index";
import { Billing } from "@models/billing.model";

@Component({
  selector: "app-export-ssc",
  templateUrl: "./export-ssc.component.html",
  styleUrls: ["./export-ssc.component.scss"],
})
export class ExportSscComponent implements OnInit {
  @Input() selectedBillingIds: string[] = [];
  @Input() dateFilter: Date = new Date();
  @Output() exportEmitter: EventEmitter<any> = new EventEmitter();

  @ViewChild("exportSscModal", { static: false })
  public exportSscModal: ModalDirective;
  public isLoading: Boolean = false;
  public isSubmitted: Boolean = false;
  public postingDate: Date = new Date();

  constructor(
    private billingService: BillingService,
    private alertService: AlertService,
    private authQuery: AuthQuery
  ) {}

  ngOnInit(): void {}

  onExportSsc() {
    this.isSubmitted = true;
    const info = this.authQuery.getCurrentUser;

    if (!this.invalidPostingDate()) {
      if (this.selectedBillingIds.length === 0) {
        this.alertService.error(
          "Please select at least one row to export"
        );
        return;
      }
      this.isLoading = true;
      this.billingService
        .exportSSC({
          payload: { 
            listBillItem: this.selectedBillingIds,
            userId: info.id,
            invoiceDate: format(this.postingDate, "YYYY-MM-DD"),
          }
        })
        .subscribe(
          (res?: any) => {
            this.isLoading = false;
            this.downloadSSCFile(res);
            this.exportSscModal.hide();
            this.alertService.success(
              `${this.selectedBillingIds.length} subscription rows
              successfully exported.
              New Billing: ${res.data.billingId} created.`
            );
            this.exportEmitter.emit();
          },
          (err?) => {
            this.isLoading = false;
            this.alertService.error(
              err?.error?._embedded?.errors?.message
            );
          }
        );
    }
  }

  downloadSSCFile(res) {
    // eslint-disable-next-line max-len
    const source = `data:application/data:application/zip;base64,${res.data.base64}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${format(this.dateFilter, "MMM YYYY")}.zip`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  invalidPostingDate() {
    return (
      this.isSubmitted &&
      (this.postingDate === undefined || this.postingDate === null)
    );
  }
}
