import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { CustomerTax } from "@models/_index";
import { CompanyType } from "@models/account.model";
import { format } from "date-fns";

@Component({
  selector: "app-form-customer-tax-list",
  templateUrl: "./form-customer-tax-list.component.html",
  styleUrls: ["./form-customer-tax-list.component.scss"],
})
export class FormCustomerTaxListComponent implements OnInit, OnChanges {
  public startDate: Date = new Date();
  public tempTax: CustomerTax = new CustomerTax()
  @Input() public isSubmitting: Boolean = false;
  @Input() public companyType: CompanyType = CompanyType.GENERAL;
  @Input() public isDisable: Boolean = false;
  @Input() public taxList: CustomerTax[] = null;
  @Input() public isGenerated: Boolean = false
  @Input() public isLoading: Boolean = false;
  @Input() public isEdit: Boolean = false;
  @Input() public isDeleted: Boolean = false;
  @Input() public currentEditItem: number = -1;
  @Input() public pagination: {
    sortDirection: "ASC" | "DESC";
    sortBy: string
  } = {
    sortDirection: "ASC",
    sortBy: ""
  }
  @Output() public onChangeHandlePagination: EventEmitter<any> =
    new EventEmitter();
  @Output() public handleTax: EventEmitter<any> = new EventEmitter();
  @Output() public handleEdit: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.currentEditItem) return;
    this.startDate =
      this.currentEditItem !== -1
        ? new Date(this.taxList[this.currentEditItem].startDate)
        : new Date();
  }

  isCurrentTaxEditing(index: number): boolean {
    return this.currentEditItem === index;
  }

  onInputTaxCode(): void {
    this.taxList[this.currentEditItem].taxCode =
      this.taxList[this.currentEditItem].taxCode.toUpperCase();
  }

  onClickEditBtn(tax: CustomerTax): void {
    this.isDisable = !this.isDisable;
    const index = this.taxList.indexOf(tax)
    this.tempTax = {...this.taxList[index]}
    this.handleEdit.emit(index);
  }

  onClickCancelTaxBtn(): void {
    this.taxList[this.currentEditItem] = this.tempTax
    this.tempTax = new CustomerTax()
    this.handleEdit.emit(-1);
  }

  onClickUpdateTaxBtn(tax: CustomerTax): void {
    if (!this.validate()) return;
    this.handleTax.emit({ tax: null, confirmType: "UPDATE_TYPE" });
  }

  onClickSaveTaxBtn(): void {
    if (!this.validate()) return;
    this.handleTax.emit({ tax: null, confirmType: "SAVE_TYPE" });
  }

  validate(): Boolean {
    return (
      this.isTaxCodeValid(this.taxList[this.currentEditItem].taxCode) &&
      this.isTaxRateValid(this.taxList[this.currentEditItem].taxRate) &&
      this.taxList[this.currentEditItem].startDate.length > 0 &&
      this.taxList[this.currentEditItem].country.length > 0
    );
  }

  onClickRemoveTaxBtn(tax: CustomerTax): void {
    this.handleTax.emit({ tax: tax, confirmType: "REMOVE_TYPE" });
  }

  onClickDeleteTaxBtn(tax: CustomerTax): void {
    this.handleTax.emit({ tax: tax, confirmType: "DELETE_TYPE" });
  }

  onClickRevertTaxBtn(tax: CustomerTax): void {
    this.handleTax.emit({ tax: tax, confirmType: "REVERT_TYPE" });
  }

  onChangeEffectStartDate(date: Date): void {
    this.taxList[this.currentEditItem].startDate = format(
      date,
      "YYYY-MM-DD"
    );
  }

  isTaxCodeValid(text: string): Boolean {
    return text.length > 0;
  }

  isTaxRateValid(text: number): Boolean {
    return text > 0 && text !== null;
  }

  
  isEffectStartDateValid(): Boolean {
    if (this.taxList !== null && this.currentEditItem !== -1) {
      return this.startDate !== null;
    }
    return false;
  }
  
  isSortedAscOnField(colName: string): Boolean {
    return colName === this.pagination.sortBy && this.pagination.sortDirection === "ASC"
  }

  isSortedDescOnField(colName: string): Boolean {
    return colName === this.pagination.sortBy && this.pagination.sortDirection === "DESC"
  }

  onApplySort(colName: string): void {
    if(colName === this.pagination.sortBy)
      this.pagination.sortDirection = this.pagination.sortDirection === "ASC" ? "DESC" : "ASC";
    else this.pagination.sortBy = colName;

    this.handleTax.emit({ tax: null, confirmType: "RELOAD_TYPE"})
  }
}
