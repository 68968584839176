<button
  class="btn btn-primary"
  (click)="exportSscModal.show()"
  translate
  [disabled]="selectedBillingIds.length === 0"
>
  buttonTitles.exportSSC
</button>

<div
  bsModal
  #exportSscModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog text-left" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" translate>selectPostingDate</h4>
        <button
          type="button"
          class="close"
          (click)="exportSscModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="padding: 0">
        <div class="card-body">
          <div class="form-group">
            <label class="required" translate>date</label>
            <input
              type="text"
              class="form-control"
              bsDatepicker
              name="postingDate"
              [(ngModel)]="postingDate"
              [bsConfig]="{
                dateInputFormat: 'DD-MM-YYYY',
                containerClass: 'theme-blue'
              }"
              [ngClass]="{
                'is-invalid': invalidPostingDate()
              }"
              required
            />
            <div *ngIf="invalidPostingDate()" class="invalid-feedback">
              validateMessages.postingDate
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            [ladda]="isLoading"
            data-style="zoom-in"
            type="button"
            (click)="onExportSsc()"
            class="btn btn-primary"
            [translate]="'buttonTitles.export'"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
