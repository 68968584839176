import { Component, OnInit, OnDestroy } from "@angular/core";
import { wholesalerNavItems } from "@utils/_wholesaler_nav.util";
import { SidebarService } from "../../../../@core/services/sidebar.service";
import { Subscription } from "rxjs";
import { AuthQuery } from "@stores/_index";

@Component({
  selector: "app-layout-sidebar",
  templateUrl: "./layout-sidebar.component.html",
  styleUrls: ["./layout-sidebar.component.css"],
})
export class LayoutSidebarComponent implements OnInit, OnDestroy {
  public sidebarMinimized = false;
  public navItems: any[] = [];

  private sidebarMinimizedSubscription: Subscription;
  private navItemsSubscription: Subscription;

  constructor(
    private sidebarService: SidebarService,
    private authQuery: AuthQuery
  ) {}

  ngOnInit(): void {
    this.navItemsSubscription = this.subscribeNavItems();
    this.sidebarMinimizedSubscription = this.subscribeIsSidebarVisible();
  }

  ngOnDestroy() {
    this.navItemsSubscription.unsubscribe();
    this.sidebarMinimizedSubscription.unsubscribe();
  }

  toggleMinimize(e: any) {
    this.sidebarService.toggleSidebarVisible(e);
  }

  subscribeNavItems() {
    return this.sidebarService.getNavItems().subscribe((items) => {
      if (!this.authQuery.isAdmin()) {
        items = items.filter((item: any) => !item.requireAdmin);
      }
      this.navItems = items;
    });
  }

  subscribeIsSidebarVisible() {
    return this.sidebarService
      .getIsSidebarVisible()
      .subscribe((isVisible) => {
        this.sidebarMinimized = isVisible;
      });
  }
}
