<div class="login-wrapper">
  <div class="app-body h-100">
    <main class="main d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-9 col-lg-5 mx-auto">
            <div class="card-group">
              <div class="card p-4">
                <div class="card-body">
                  <form
                    id="loginForm"
                    (ngSubmit)="onSubmit(loginForm)"
                    #loginForm="ngForm"
                  >
                    <div class="text-center mb-3">
                      <img
                        src="assets/img/brand/augment_mini_logo.png"
                        style="width: 30px; height: 30px"
                      />
                      <h4 class="mt-2">
                        <strong>Augmen+ Technologies</strong>
                      </h4>
                    </div>

                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><i class="icon-user"></i
                        ></span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="{{
                          'placeholder.username' | translate
                        }}"
                        name="username"
                        #username="ngModel"
                        [(ngModel)]="login.username"
                        [ngClass]="{
                          'is-invalid':
                            loginForm.submitted && username.invalid
                        }"
                        required
                      />
                      <div
                        *ngIf="loginForm.submitted && username.invalid"
                        class="invalid-feedback"
                      >
                        <div *ngIf="username.errors.required" translate>
                          validateMessages.username
                        </div>
                      </div>
                    </div>
                    <div class="input-group mb-4">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><i class="icon-lock"></i
                        ></span>
                      </div>
                      <input
                        type="password"
                        class="form-control"
                        placeholder="{{
                          'placeholder.password' | translate
                        }}"
                        name="password"
                        #password="ngModel"
                        [(ngModel)]="login.password"
                        [ngClass]="{
                          'is-invalid':
                            loginForm.submitted && password.invalid
                        }"
                        required
                      />
                      <div
                        *ngIf="loginForm.submitted && password.invalid"
                        class="invalid-feedback"
                      >
                        <div *ngIf="password.errors.required" translate>
                          validateMessages.password
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <button
                          [ladda]="isLoading"
                          type="submit"
                          class="btn btn-block btn-primary px-4"
                        >
                          Login
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
