<div class="card">
  <div class="card-header" translate>customerTax</div>
  <div class="card-body table-responsive">
    <table class="table table-striped" *ngIf="taxList.length > 0">
      <thead>
        <tr>
          <th width="20%">
            <app-sortable-header
              [title]="'taxCode' | translate"
              [isSortedAsc]="isSortedAscOnField('taxCode')"
              [isSortedDesc]="isSortedDescOnField('taxCode')"
              (handleSort)="onApplySort('taxCode')"
            >
            </app-sortable-header>
          </th>
          <th translate width="20%">
            country
          </th>
          <th width="20%">
            <app-sortable-header
              [title]="'taxRate' | translate"
              [isSortedAsc]="isSortedAscOnField('taxRate')"
              [isSortedDesc]="isSortedDescOnField('taxRate')"
              (handleSort)="onApplySort('taxRate')"
            >
            </app-sortable-header>
          </th>
          <th width="20%">
            <app-sortable-header
              [title]="'effectiveStartDate' | translate"
              [isSortedAsc]="isSortedAscOnField('startDate')"
              [isSortedDesc]="isSortedDescOnField('startDate')"
              (handleSort)="onApplySort('startDate')"
            >
            </app-sortable-header>
          </th>
          <th translate width="20%" *ngIf="companyType === 'GENERAL'">actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let tax of taxList; index as i">
          <ng-template [ngIf]="isCurrentTaxEditing(i)">
            <td>
              <div class="form-group mb-0">
                <input
                  class="form-control"
                  name="taxCode"
                  [(ngModel)]="tax.taxCode"
                  #taxCode="ngModel"
                  [disabled]="isSubmitting"
                  (input)="onInputTaxCode()"
                  [ngClass]="{
                    'is-invalid': !isTaxCodeValid(tax.taxCode)
                  }"
                />
                <div *ngIf="!isTaxCodeValid(tax.taxCode)" class="invalid-feedback">
                  <div translate>
                    validateMessages.taxCodeRequired
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="form-group mb-0">
                <input
                  class="form-control"
                  [(ngModel)]="tax.country"
                  disabled="true"
                />
              </div>
            </td>
            <td>
              <div class="form-group mb-0">
                <input
                  type="number"
                  class="form-control"
                  name="taxRate"
                  [(ngModel)]="tax.taxRate"
                  #taxRate="ngModel"
                  [disabled]="isSubmitting"
                  [ngClass]="{
                    'is-invalid': !isTaxRateValid(tax.taxRate)
                  }"
                />
                <div *ngIf="!isTaxRateValid(tax.taxRate)" class="invalid-feedback">
                  <div translate>
                    validateMessages.taxRateRequired
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="form-group mb-0">
                <input
                  type="text"
                  class="form-control"
                  bsDatepicker
                  autocomplete="off"
                  name="effectiveStartDateInput"
                  #effectiveStartDateInput="ngModel"
                  [disabled]="isSubmitting"
                  [(ngModel)]="startDate"
                  [bsConfig]="{
                    dateInputFormat: 'YYYY-MM-DD',
                    containerClass: 'theme-blue',
                    adaptivePosition: true
                  }"
                  (bsValueChange)="onChangeEffectStartDate($event)"
                  [ngClass]="{
                    'is-invalid': !isEffectStartDateValid()
                  }"
                />
                <div
                  *ngIf="
                    !isEffectStartDateValid()
                  "
                  class="invalid-feedback"
                >
                  <div
                    translate
                  >
                    validateMessages.effectiveStartDateRequired
                  </div>
                </div>
              </div>
            </td>
            <td>
              <ng-template [ngIf]="isEdit">
                <button
                class="btn btn-primary mr-1 hoverable"
                (click)="onClickUpdateTaxBtn(tax)"
                [ladda]="isSubmitting"
                data-style="zoom-in"
              >
                <i
                  class="fa fa-check"
                ></i>
              </button>
              <button
                [disabled]="isSubmitting"
                class="btn btn-primary hoverable"
                (click)="onClickCancelTaxBtn()"
              >
                <i
                  class="fa fa-window-close"
                  tooltip="{{ 'tooltip.cancel' | translate }}"
                ></i>
              </button>
              </ng-template>
              <ng-template [ngIf]="!isEdit">
                <button
                class="btn btn-primary mr-1 hoverable"
                (click)="onClickSaveTaxBtn()"
                [ladda]="isSubmitting"
                data-style="zoom-in"
              >
                <i
                  class="fa fa-check"
                ></i>
              </button>
              <button
                [disabled]="isSubmitting"
                class="btn btn-primary hoverable"
                (click)="onClickRemoveTaxBtn(tax)"
              >
                <i
                  class="fa fa-trash"
                  tooltip="{{ 'tooltip.delete' | translate }}"
                ></i>
              </button>
              </ng-template>
            </td>
          </ng-template>
          <ng-template [ngIf]="!isCurrentTaxEditing(i)">
            <td>{{ tax?.taxCode }}</td>
            <td>
              {{ tax?.country }}
            </td>
            <td>{{ tax?.taxRate }}</td>
            <td>
              {{ tax?.startDate }}
            </td>
            <td *ngIf="companyType === 'GENERAL'">
              <a
                class="text-decoration-none text-primary mr-1 hoverable"
                (click)="onClickEditBtn(tax)"
                *ngIf="!isDeleted && !isDisable"
              >
                <i
                  class="fa fa-pencil"
                  tooltip="{{ 'tooltip.edit' | translate }}"
                ></i>
              </a>
              <a
                class="text-decoration-none mr-1 text-success hoverable"
                *ngIf="isDeleted"
                (click)="onClickRevertTaxBtn(tax)"
              >
                <i
                  class="fa fa-reply"
                  tooltip="{{ 'tooltip.revert' | translate }}"
                ></i>
              </a>
              <a
                *ngIf="!isDeleted && !isDisable"
                [ngClass]="{
                  'text-danger': isDeleted,
                  'text-primary': !isDeleted
                }"
                class="hoverable mr-1"
                (click)="onClickDeleteTaxBtn(tax)"
              >
                <i
                  class="fa fa-trash"
                  tooltip="{{ 'tooltip.delete' | translate }}"
                ></i>
              </a>
            </td>
          </ng-template>
        </tr>
      </tbody>
    </table>
    <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
    <div
      *ngIf="taxList.length === 0 && !isLoading && isGenerated"
      id="blank-notification"
      class="h-100 mt-2 mb-3"
    >
      <span class="mr-2 text-danger">
        The selected country does not have an existing Tax Rate. Default
        Tax Rate will be 0%.<br />
        Please add a tax rate if required.
      </span>
    </div>
    <div
      *ngIf="taxList.length === 0 && !isLoading && !isGenerated && companyType === 'GENERAL'"
      id="blank-notification"
      class="h-100 mt-2 mb-3"
    >
      <span class="mr-2 text-danger">
        Please select a country to generate data!!
      </span>
    </div>
  </div>
</div>
