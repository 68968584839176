import { Store, StoreConfig } from "@datorama/akita";
import { Injectable } from "@angular/core";

export interface SubscriptionState {
  subscriptions: [];
  customerSubscriptions: {};
}
export function createInitialState(): SubscriptionState {
  return {
    subscriptions: [],
    customerSubscriptions: {},
  };
}

@StoreConfig({ name: "subscription" })
@Injectable({ providedIn: "root" })
export class SubscriptionStore extends Store<SubscriptionState> {
  constructor() {
    super(createInitialState());
  }
}
