import { BaseModel } from "./base.model";

export class ProductBundle extends BaseModel {
  id: string
  name: string
  recurrenceType: "MONTHLY" | "QUARTERLY" | "UPFRONT" | "ANNUALLY" = "MONTHLY"
  currency: "USD" | "SGD" | "MYR" = "USD"
  maximumDiscount: number = 0
  billDescription: string
  effectiveDate: string
  expiringDate: string
  partPriceItems: ProductBundlePriceItem[]
}

export class ProductBundleQuotationItem extends BaseModel {
  id: string
  productBundleId: string
  name: string
  recurrenceType: "MONTHLY" | "QUARTERLY" | "UPFRONT" | "ANNUALLY" = "MONTHLY"
  currency: "USD" | "SGD" | "MYR" = "USD"
  discountAmount: number = 0
}

export class ProductBundlePriceItem{
  id?: string
  partPriceItemId: string
  partPriceItemName: string
  partPriceItemDescription: string
  priceItems: PartPricingItem[]
  minimumQuantity: number
  maximumQuantity: number
}

export class PartPricingItem {
  id: string
  price: number
  maxPriceDiscount: number
  currency: "USD" | "SGD" | "MYR"
  recurrenceType: "MONTHLY" | "QUARTERLY" | "UPFRONT" | "ANNUALLY"
}

export class ProductBundleItem {
  product: { id: string, name: string } = { id: "", name: "" }
  partPriceItem: { 
    id?: string, 
    partPriceItemId: string, 
    name: string 
  } = { partPriceItemId: "", name: "" }
  recurrenceType: string[] = []
  currency: string = ""
  minimumQuantity: number = 1
  maximumQuantity: number = 1
}

