import { Injectable } from "@angular/core";
import { ServiceUrl } from "./config/service-url";
import { ApiService } from "./config/api.service";
import { throwError } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { PartPriceItemStore } from "../stores/part-price-items/part-price-item.store";
import { PartPriceItem } from "@models/part-price-item.model";
import { HttpErrorResponse } from "@angular/common/http";
import { Payload } from "@models/_index";

// import pipe
@Injectable({
  providedIn: "root",
})
export class PartPriceItemService {
  serviceUrl = new ServiceUrl();

  constructor(
    private apiService: ApiService,
    private store: PartPriceItemStore
  ) {}

  getPartPriceItems(payload: Payload) {
    return this.apiService
      .get(this.serviceUrl.GET_PART_PRICE_ITEMS, payload)
      .pipe(
        tap((res) => {
          this.store.update({ partPriceItems: res?.data?.content || [] });
        })
      );
  }

  getAllPartPriceItems(payload?: Payload) {
    return this.apiService
      .get(this.serviceUrl.GET_ALL_PART_PRICE_ITEMS, payload)
      .pipe(
        tap((res) => {
          this.store.update({ partPriceItems: res?.data || [] });
        })
      );
  }

  getPartPriceItemsByProductPart(payload: Payload) {
    return this.apiService
      .get(this.serviceUrl.GET_PART_PRICE_ITEMS_BY_PRODUCT_PART, payload)
      .pipe(
        tap((res) => {
          this.store.update({ partPriceItems: res?.data || [] });
        })
      );
  }

  getPartPriceItem(payload: Payload) {
    return this.apiService
      .get(this.serviceUrl.GET_PART_PRICE_ITEMS, payload)
      .pipe(
        tap((res) => {
          this.store.update({
            partPriceItem: Object.assign(new PartPriceItem(), res.data),
          });
        }),
        catchError((err) => this.errorHandler(err))
      );
  }

  createPartPriceItem(payload: Payload) {
    return this.apiService
      .post(`${this.serviceUrl.GET_PART_PRICE_ITEMS}`, payload)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  updatePartPriceItem(payload: Payload) {
    this.store.setLoading(true);
    return this.apiService
      .put(`${this.serviceUrl.GET_PART_PRICE_ITEMS}`, payload)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  deletePartPriceItem(payload: Payload) {
    return this.apiService
      .delete(`${this.serviceUrl.GET_PART_PRICE_ITEMS}/${payload.id}`)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error.message || "Server error.");
  }
}
