import { Query } from "@datorama/akita";
import { BillingState, BillingStore } from "./billing.store";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class BillingQuery extends Query<BillingState> {
  constructor(protected store: BillingStore) {
    super(store);
  }

  get billings() {
    return this.getValue().billings;
  }

  get billingItems() {
    return this.getValue().billingItems;
  }
}
