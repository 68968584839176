import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@services/auth.service";
import { AlertService } from "@services/alert.service";
import { AuthStore } from "@stores/_index";

@Component({
  selector: "app-logout",
  template: "",
})
export class LogoutComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
    private authStore: AuthStore,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.authService.logout().subscribe(
      (res: any) => {
        this.authStore.update({ currentUser: undefined });
        this.router.navigate(["/auth/login"]);
        this.alertService.success(res?.message);
      },
      (err) => {
        // currently geting CORS so have to hardcode these 3 line
        this.alertService.success("Successfully Logged Out");
        this.router.navigate(["/auth/login"]);
      }
    );
  }
}
