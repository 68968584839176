<button
  type="button"
  class="btn btn-primary"
  (click)="changePasswordModal.show()"
>
  Change Password
</button>
<div
  class="modal fade"
  bsModal
  #changePasswordModal="bs-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-static-name"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h4 id="dialog-static-name" class="modal-title pull-left">
          Change Password
        </h4>
        <button
          type="button"
          class="btn-close close pull-right"
          aria-label="Close"
          (click)="changePasswordModal.hide()"
        >
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form
          id="changePasswordForm"
          (ngSubmit)="onSubmit(changePasswordForm)"
          #changePasswordForm="ngForm"
        >
          <div class="form-group">
            <label translate class="required">password</label>
            <input
              type="password"
              class="form-control"
              name="passwordTemplate"
              placeholder="{{ 'placeholder.password' | translate }}"
              [(ngModel)]="password"
              #passwordTemplate="ngModel"
              [ngClass]="{
                'is-invalid':
                  changePasswordForm.submitted && passwordTemplate.invalid
              }"
              required
            />
            <div
              *ngIf="
                changePasswordForm.submitted && passwordTemplate.invalid
              "
              class="invalid-feedback"
            >
              <div *ngIf="passwordTemplate.errors.required" translate>
                validateMessages.password
              </div>
            </div>
          </div>

          <div class="form-group">
            <label translate class="required">confirmPassword</label>
            <input
              type="password"
              class="form-control"
              name="confirmPasswordTemplate"
              placeholder="{{ 'placeholder.password' | translate }}"
              [(ngModel)]="confirmPassword"
              #confirmPasswordTemplate="ngModel"
              [ngClass]="{
                'is-invalid':
                  (changePasswordForm.submitted && !checkConfirmPassword()) ||
                  (changePasswordForm.submitted && confirmPasswordTemplate.invalid)
              }"
              required
            />
            <div
              *ngIf="
              (changePasswordForm.submitted && !checkConfirmPassword()) ||
              (changePasswordForm.submitted && confirmPasswordTemplate.invalid)
              "
              class="invalid-feedback"
            >
              <div
                *ngIf="
                  changePasswordForm.submitted && !checkConfirmPassword()
                "
                translate
              >
                validateMessages.confirmPassword
              </div>
              <div
                *ngIf="
                  changePasswordForm.submitted && confirmPasswordTemplate.invalid
                "
                translate
              >
                validateMessages.confirmPasswordRequire
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          class="btn btn-primary"
          [ladda]="isSubmitting"
          [translate]="'buttonTitles.save'"
          data-style="zoom-in"
          form="changePasswordForm"
        >
          buttonTitles.save
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="changePasswordModal.hide()"
          translate
        >
          buttonTitles.cancel
        </button>
      </div>
    </div>
  </div>
</div>
