import { Component, OnInit, OnDestroy } from "@angular/core";
import { Quotation } from "@models/quotation.model";
import { QuotationService } from "@services/_index";
import { QuotationQuery } from "@stores/_index";
import { AlertService } from "@services/alert.service";

import {
  BsModalService,
  BsModalRef,
  ModalOptions,
} from "ngx-bootstrap/modal";
import { ModalComponent } from "@shared/components/modal/modal.component";
import debounce from "lodash.debounce";
@Component({
  selector: "app-quotation",
  templateUrl: "./quotation.component.html",
  styleUrls: ["./quotation.component.scss"],
})
export class QuotationComponent implements OnInit, OnDestroy {
  public isLoading: Boolean = false;
  public isShowDraft: Boolean = true;
  public isShowGenerated: Boolean = true;

  public quotations: Quotation[];

  public currentPage: number = 0;
  public totalItems: number;
  itemPerPage: number = 10;

  public search: string = "";
  readonly ITEMS_PER_PAGE: number = 10;

  public bsModalRef?: BsModalRef;

  // sort
  sortedField: string = "created_at";
  isSortedAsc: boolean;

  private debounceSearch = debounce((searchTerm) => {
    this.search = searchTerm;
    this.currentPage = 0;
    this.loadQuotations();
  }, 500);

  constructor(
    private quotationService: QuotationService,
    private quotationQuery: QuotationQuery,
    private alertService: AlertService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    if (this.quotationQuery.selectedQuotation) {
      this.onSearch(this.quotationQuery.selectedQuotation);
    } else {
      this.loadQuotations();
    }
  }

  ngOnDestroy(): void {
    this.quotationService.selectQuotation(null);
  }

  loadQuotations() {
    this.isLoading = true;
    this.quotationService
      .getQuotations({
        params: {
          page: this.currentPage,
          keyword: this.search,
          sort: this.sortedField,
          direction: this.directionParams(),
          size: this.itemPerPage,
          status: this.isShowDraft ? "DRAFT" : "ACTIVE",
          is_generated: this.isShowGenerated ? true : false,
        },
      })
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.quotations = this.quotationQuery.quotations;
          this.totalItems = res?.total_size;
        },
        (err) => {
          this.isLoading = false;
          this.alertService.error(err._embeed);
        }
      );
  }

  onDelete(id: string) {
    const initialState: ModalOptions = {
      initialState: {
        title: "Do you want to remove this item?",
        content:
          "By clicking on OK, this item will be removed immediately",
      },
      animated: false,
    };
    this.bsModalRef = this.modalService.show(ModalComponent, initialState);

    this.bsModalRef.content.eventEmitter.subscribe((isOk) => {
      if (isOk) {
        this.deleteQuotation(id);
      } else {
        this.bsModalRef.hide();
      }
    });
  }

  deleteQuotation(id: string) {
    this.quotationService.deleteQuotation({ id: id }).subscribe(
      (res?: any) => {
        this.alertService.success("Delete quotation successfully!");
        this.bsModalRef.hide();
        this.loadQuotations();
      },
      (err) => {
        this.alertService.error(
          "Can't delete this quotation, please try again!"
        );
      }
    );
  }

  onDownloadPdf(id: string) {
    this.generatePdf(id);
  }

  generatePdf(id: string) {
    const quotation = this.quotations.find((q) => q.id === id);
    const notes = quotation.notes;
    this.quotationService
      .generatePdfQuotation({ id: id, payload: { notes: notes }})
      .subscribe(
        (res?: any) => {
          this.showPdf(quotation.quotationCode, res?.file);
        },
        (err) => {
          this.alertService.errors(err?._embedded?.errors);
        }
      );
  }

  showPdf(fileName, base64String) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`;
    link.click();
  }

  goToPage(page: number) {
    this.currentPage = page - 1;
    this.loadQuotations();
  }

  changeItemPerPage(itemPerPages: number) {
    this.currentPage = 0;
    this.itemPerPage = itemPerPages;
    this.loadQuotations();
  }

  onSearch(keySearch: string) {
    this.debounceSearch(keySearch);
  }

  isDraftQuotation(quotation: Quotation) {
    return quotation?.status === "DRAFT";
  }

  isGenerated(quotation: Quotation) {
    return quotation?.status === "ACTIVE" && quotation?.isGenerated;
  }

  onChangeCheckBoxes() {
    this.currentPage = 0;
    this.loadQuotations();
  }

  // below are sortable function
  isSortedOnField(field: string) {
    return this.sortedField === field;
  }

  isSortedAscOnField(field: string) {
    return this.isSortedOnField(field) && this.isSortedAsc;
  }

  isSortedDescOnField(field: string) {
    return this.isSortedOnField(field) && !this.isSortedAsc;
  }

  directionParams() {
    return this.sortedField ? `${this.isSortedAsc ? "asc" : "desc"}` : "";
  }

  onApplySort(field: string) {
    if (field !== this.sortedField) {
      this.isSortedAsc = true;
      this.sortedField = field;
    } else {
      this.isSortedAsc = !this.isSortedAsc;
    }
    this.loadQuotations();
  }
}
