import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  ProductBundle,
  ProductBundleQuotationItem,
} from "@models/product-bundle.model";
import { BundleItem, Quotation } from "@models/quotation.model";
import { AlertService } from "@services/alert.service";
import { ProductBundleService } from "@services/product-bundle.service";
import { QuotationService } from "@services/quotation.service";
import { format } from "date-fns";
@Component({
  selector: "app-form-quotation-product-bundle",
  templateUrl: "./form-quotation-product-bundle.component.html",
  styleUrls: ["./form-quotation-product-bundle.component.scss"],
})
export class FormQuotationProductBundleComponent
  implements OnInit, OnChanges
{
  @Input() public quotation: Quotation;
  @Input() public isShowingDetails: Boolean;
  @Input() public triggerProductBundleChange: { type: | ""
  | "SUCCESSFULLY_CREATED"
  | "FAILED_TO_CREATE"
  | "SUCCESSFULLY_UPDATED"
  | "FAILED_TO_UPDATE"
  | "SUCCESSFULLY_DELETED"
  | "FAILED_TO_DELETE"}
   =  { type: "" } 
  public operation: {
    isEditing: Boolean;
    isLoading: Boolean;
    selectedItem: ProductBundleQuotationItem;
    currentEditItem: number;
  } = {
    isEditing: false,
    isLoading: false,
    selectedItem: {
      id: "",
      productBundleId: "",
      name: "",
      recurrenceType: null,
      currency: null,
      discountAmount: 0,
    },
    currentEditItem: -1,
  };
  @Output() public onEmitProductBundle: EventEmitter<any> =
    new EventEmitter();
  @Input() public productBundleList: ProductBundleQuotationItem[] = [];
  @Input() public productBundleSelection: ProductBundle[] = [];
  constructor(
    private alertService: AlertService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.triggerProductBundleChange) {
      switch (this.triggerProductBundleChange.type) {
        case "SUCCESSFULLY_CREATED":
          this.onSuccessfullyCreated();
          break;
        case "SUCCESSFULLY_UPDATED":
          this.onSuccessfullyUpdated();
          break;
        case "SUCCESSFULLY_DELETED":
          this.onSuccessfullyDeleted();
          break;
        case "FAILED_TO_CREATE":
          this.onFailedToCreate();
          break;
        case "FAILED_TO_UPDATE":
          this.onFailedToUpdate();
          break;
        case "FAILED_TO_DELETE":
          this.onFailedToDelete();
          break;
        default:
          break;
      }

      this.triggerProductBundleChange.type = ""
    }
  }

  onFailedToCreate(): void {
    this.alertService.error("Something went wrong!");
    this.operation.isLoading = false;
  }

  onFailedToUpdate(): void {
    this.alertService.error("Something went wrong!");
    this.operation.isLoading = false;
  }

  onFailedToDelete(): void {
    this.alertService.error("Something went wrong!");
  }

  onSuccessfullyUpdated(): void {
    this.alertService.success("Successfully updated product bundle");
    this.getProductBundle();
    this.operation.currentEditItem = -1;
    this.operation.isLoading = false;
  }

  onSuccessfullyCreated(): void {
    this.alertService.success("Successfully added product bundle");
    this.getProductBundle();
    this.operation.currentEditItem = -1;
    this.operation.isLoading = false;
  }

  onSuccessfullyDeleted(): void {
    this.alertService.success("Successfully deleted product bundle!");
    this.getProductBundle();
  }

  ngOnInit(): void {
    this.getProductBundle();
  }

  getProductBundle(): void {
    this.productBundleList = this.quotation.bundleItems.map(
      (res: BundleItem) => {
        const product = this.productBundleSelection.find(
          (item) => item.id === res.productBundleId
        );
        return {
          id: res.id,
          productBundleId: res.productBundleId,
          name: product.name,
          recurrenceType: product.recurrenceType,
          currency: product.currency,
          maximumDiscount: 0,
          discountAmount: res.discountAmount,
        };
      }
    );
  }

  isProductBundleMode(): Boolean {
    return (
      this.productBundleList.length === 0 &&
      this.quotation.quotationItems.length > 0
    );
  }

  onAddProductBundle(): void {
    this.setDefaultSelectedItem();
    this.productBundleList.push(this.operation.selectedItem);
    this.operation.currentEditItem = this.productBundleList.length - 1;
  }

  onChangeProductBundle(): void {
    if (!this.operation.selectedItem.productBundleId) return;

    const product = this.productBundleSelection.find(
      (p) => p.id === this.operation.selectedItem.productBundleId
    );

    this.productBundleList[this.operation.currentEditItem].currency =
      product.currency;
    this.productBundleList[this.operation.currentEditItem].recurrenceType =
      product.recurrenceType;
  }

  onClickSaveProductBundle(): void {
    this.operation.isLoading = true;
    const payload = {
      id: this.quotation.id,
      payload: {
        discountAmount: this.operation.selectedItem.discountAmount,
        productBundleId: this.operation.selectedItem.productBundleId,
        quotationItems: [],
      },
    };
    const data = {
      productBundle: this.productBundleSelection.find(
        (p) => p.id === this.operation.selectedItem.productBundleId
      ),
      payload: payload,
    }
    this.sendingProductBundle(
      data,
      "CREATE"
    );
  }

  onClickUpdateProductBundle(): void {
    this.operation.isLoading = true;
    this.operation.isEditing = false;
    const payload = {
      id: this.operation.selectedItem.id,
      payload: {
        discountAmount: this.operation.selectedItem.discountAmount,
        productBundleId: this.operation.selectedItem.productBundleId,
        quotationItems: [],
      },
    };

    this.sendingProductBundle(
      {
        productBundle: this.productBundleSelection.find(
          (p) => p.id === this.operation.selectedItem.productBundleId
        ),
        payload: payload,
      },
      "UPDATE"
    );
  }

  onClickEditProductBundle(index: number): void {
    this.operation.isEditing = true;
    this.operation.currentEditItem = index;
    this.operation.selectedItem =
      this.productBundleList[this.operation.currentEditItem];
  }

  isDiscountAmountValid(): Boolean {
    return (
      this.operation.selectedItem.discountAmount >= 0 &&
      this.operation.selectedItem.discountAmount !== null
    );
  }

  onCancelEditProductBundle(): void {
    this.setDefaultSelectedItem();
    this.operation.currentEditItem = -1;
    this.operation.isEditing = false;
  }

  setDefaultSelectedItem(): void {
    this.operation.selectedItem = {
      id: "",
      productBundleId: "",
      name: "",
      recurrenceType: null,
      currency: null,
      discountAmount: 0,
    };
  }

  onCancelProductBundle(): void {
    this.setDefaultSelectedItem();
    this.productBundleList.splice(this.operation.currentEditItem, 1);
    this.operation.currentEditItem = -1;
  }

  onClickDeleteProductBundle(id: string): void {
    const payload = {
      id: id,
    };

    this.sendingProductBundle(
      {
        productBundle: null,
        payload: payload,
      },
      "DELETE"
    );
  }

  sendingProductBundle(
    data: any,
    type: "CREATE" | "UPDATE" | "DELETE" = "CREATE"
  ): void {
    this.onEmitProductBundle.emit({
      data: data,
      type: type,
    });
  }
}
