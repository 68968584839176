import { Component, OnInit } from "@angular/core";
import { PartPriceItem, ProductPart } from "@models/_index";
import { Router } from "@angular/router";
import { PartPriceItemService } from "@services/part-price-item.service";
import { PartPriceItemQuery } from "@stores/part-price-items/part-price-item.query";
import { AlertService } from "@services/alert.service";
import {
  BsModalService,
  BsModalRef,
  ModalOptions,
} from "ngx-bootstrap/modal";
import { ModalComponent } from "@shared/components/modal/modal.component";

@Component({
  selector: "app-part-pricing",
  templateUrl: "./part-pricing.component.html",
  styleUrls: ["./part-pricing.component.scss"],
})
export class PartPricingComponent implements OnInit {
  public bsModalRef?: BsModalRef;
  public isLoading: Boolean;
  public partPriceItems: PartPriceItem[] = [];

  currentPage: number = 0;
  totalItems: number;
  itemPerPage: number = 10;

  search: string = "";

  // sort
  sortedField: string;
  isSortedAsc: boolean;
  readonly ITEMS_PER_PAGE: number = 10;

  constructor(
    private router: Router,
    private partPriceItemService: PartPriceItemService,
    private partPriceItemQuery: PartPriceItemQuery,
    private modalService: BsModalService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.loadPartPriceItems();
  }

  loadPartPriceItems() {
    this.partPriceItemService
      .getPartPriceItems({
        params: {
          page: this.currentPage,
          keySearch: this.search,
          sort: this.sortParams(),
          size: this.itemPerPage,
        },
      })
      .subscribe(
        (res?) => {
          this.isLoading = false;
          this.partPriceItems = this.partPriceItemQuery.getPartPriceItems;
          this.totalItems = res.data?.totalSize;
        },
        (err) => {
          this.isLoading = false;

          this.alertService.error(err?.error?.message);
        }
      );
  }

  onClickEditBtn(item: PartPriceItem) {
    this.router.navigate(["/wholesaler/product/part_pricing/" + item.id]);
  }
  onClickDeleteBtn(item: PartPriceItem) {
    // should find a way to reuse these code to generate modal
    const initialState: ModalOptions = {
      initialState: {
        title: "Do you want to remove this item?",
        content:
          "By clicking on OK, this item will be removed immediately",
      },
      animated: false,
    };
    this.bsModalRef = this.modalService.show(ModalComponent, initialState);

    this.bsModalRef.content.eventEmitter.subscribe((isOk) => {
      if (isOk) {
        this.deletePartPriceItem(item);
      } else {
        this.bsModalRef.hide();
      }
    });
  }

  deletePartPriceItem(item: PartPriceItem) {
    this.partPriceItemService
      .deletePartPriceItem({ id: item.id })
      .subscribe(
        (res) => {
          this.bsModalRef.hide();
          this.loadPartPriceItems();
          this.alertService.success(
            "Part Price Item Successfully Removed."
          );
        },
        (err) => {
          this.isLoading = false;

          this.alertService.error(err?.error?.message);
        }
      );
  }

  goToPage(page: number) {
    this.currentPage = page - 1;
    this.loadPartPriceItems();
  }

  changeItemPerPage(itemPerPages: number) {
    this.currentPage = 0;
    this.itemPerPage = itemPerPages;
    this.loadPartPriceItems();
  }

  onSearch(keySearch: string) {
    this.search = keySearch;
    this.currentPage = 0;
    this.loadPartPriceItems();
  }
  // below are sortable function
  isSortedOnField(field: string) {
    return this.sortedField === field;
  }

  isSortedAscOnField(field: string) {
    return this.isSortedOnField(field) && this.isSortedAsc;
  }

  isSortedDescOnField(field: string) {
    return this.isSortedOnField(field) && !this.isSortedAsc;
  }

  sortParams() {
    return this.sortedField
      ? `${this.sortedField},${this.isSortedAsc ? "asc" : "desc"}`
      : "";
  }

  onApplySort(field: string) {
    if (field !== this.sortedField) {
      this.isSortedAsc = true;
      this.sortedField = field;
    } else {
      this.isSortedAsc = !this.isSortedAsc;
    }
    this.loadPartPriceItems();
  }
}
