<div
  class="card card-list border-0 mb-0 border-radius-bottom-0"
  *ngIf="isShowing"
  @inOutPaneAnimation
>
  <div class="card-body" *ngIf="!isLoadingPartPricing">
    <div class="table-responsive mt-3">
      <table class="table table-quotation-items" width="100%">
        <thead>
          <tr>
            <th width="150" *ngIf="!isShowingDetails">Actions</th>
            <th width="200" translate>product</th>
            <th width="300" translate>partPricing</th>
            <th width="200" translate>recurrenceType</th>
            <th width="130">
              {{ "price" | translate }} ({{ quotation.currency }})
            </th>
            <th width="130" translate>discountAmount</th>
            <th width="120" translate>quantity</th>
            <th width="250">
              {{ "amount" | translate }} ({{ quotation.currency }})
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of quotation.quotationItems; index as i">
            <ng-template [ngIf]="!isQuotationItemEditing(i)">
              <td width="100" *ngIf="!isShowingDetails">
                <button
                  style="width: 40px"
                  class="btn btn-primary mb-1 mr-1"
                  type="button"
                  (click)="onEditQuotationItem(i)"
                  [disabled]="isEditingQuotationItem"
                >
                  <i class="fa fa-pencil"> </i>
                </button>
                <button
                  style="width: 40px"
                  class="btn btn-primary mb-1"
                  type="button"
                  (click)="onDeleteQuotationItem(item)"
                  [disabled]="isEditingQuotationItem || quotationItemIdInProductBundle.includes(item.id)"
                >
                  <i class="fa fa-trash"> </i>
                </button>
              </td>
              <td>{{ item?.productName }}</td>
              <td>{{ item?.partPricingName }}</td>
              <td>{{ item?.recurrenceType }}</td>
              <td>{{ item?.price }}</td>
              <td>{{ item?.discountAmount }}</td>
              <td>{{ item?.quantity }}</td>
              <td>{{ item?.amount }}</td>
            </ng-template>
            <ng-template [ngIf]="isQuotationItemEditing(i)">
              <app-form-product-detail
                [item]="item"
                [products]="products"
                [productBundleSelection]="productBundleSelection"
                [quotation]="quotation"
                [partPriceItems]="partPriceItems"
                [quotationItemIdInProductBundle]="quotationItemIdInProductBundle"
                [isShowingDetails]="isShowingDetails"
                (cancelEmitter)="onCancelQuotationItem(i)"
                (saveEmitter)="onSaveQuotationItem(item)"
                (deleteEmitter)="deleteQuotationItemHandler(item)"
                [isLoading]="isLoading"
              ></app-form-product-detail>
            </ng-template>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-1">
      <p
        class="font-weight-bold text-primary ml-3 mt-2 hoverable d-inline-block"
        [ngClass]="{
          'btn-disabled': isEditingQuotationItem
        }"
        (click)="
          !isEditingQuotationItem
            ? onAddQuotationItem()
            : return
        "
        translate
        *ngIf="!isShowingDetails"
      >
        <i class="icon-plus"> </i>
        buttonTitles.addPartPricing
      </p>
      <div class="row">
        <app-form-quotation-product-bundle
          *ngIf="productBundleSelection.length !== 0"
          (onEmitProductBundle)="emitProductBundle($event)"
          [triggerProductBundleChange]="triggerProductBundleChange"
          [isShowingDetails]="isShowingDetails"
          [productBundleSelection]="productBundleSelection"
          [quotation]="quotation"
          class="col-lg-7 col-12"
        ></app-form-quotation-product-bundle>
        <app-quotation-amount-detail
          class="col-lg-5 col-12"
          [quotation]="quotation"
        ></app-quotation-amount-detail>
      </div>
    </div>
  </div>
  <app-loading-spinner
    [isLoading]="isLoadingPartPricing"
  ></app-loading-spinner>
</div>
