<div class="d-flex justify-content-between align-items-center">
  <h5>
    <strong class="text-underline">{{ account.company.name }}</strong>
  </h5>
  <app-customer-subscription-filter
    (filterChangeEmitter)="onChangeFilter($event)"
  ></app-customer-subscription-filter>
</div>

<div *ngFor="let product of getProducts()" class="card mt-3">
  <div class="card-header">{{ product }}</div>
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-customer-subscriptions">
        <thead>
          <tr>
            <th>Subscription ID</th>
            <th>Product Part Name</th>
            <th>Service Code</th>
            <th>Quantity</th>
            <th>Start Date</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody>
          <ng-template [ngIf]="!isLoading">
            <ng-container *ngFor="let part of getPartsOfProduct(product)">
              <tr *ngFor="let subscription of part">
                <td>
                  <strong>{{ subscription.subscription_code }}</strong>
                </td>
                <td>{{ subscription.product_part_name }}</td>
                <td>{{ subscription.product_part_service_code }}</td>
                <td>{{ subscription.quantity }}</td>
                <td>
                  {{ subscription.start_date | date: "dd MMM yyyy" }}
                </td>
                <td>{{ subscription.end_date | date: "dd MMM yyyy" }}</td>
              </tr>
            </ng-container>
            <tr class="border-top">
              <td></td>
              <td><strong>Resources</strong></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr *ngFor="let partSummary of getPartSummary(product)">
              <td></td>
              <td><strong>Total</strong></td>
              <td>
                <strong>{{ partSummary.key }}</strong>
              </td>
              <td></td>
              <td>
                <strong>{{ partSummary.value }}</strong>
              </td>
              <td></td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
    </div>
  </div>
</div>
