import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { catchError, map } from "rxjs/operators";
import { throwError, EMPTY } from "rxjs";
import { AlertService, AuthService } from "@services/_index";
import { AuthQuery } from "@stores/_index";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService,
    private authQuery: AuthQuery
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true,
      setHeaders: {
        'Cache-Control': 'no-cache'
      }
    });
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        this.authService.setIsAuthorized(true);
        return event;
      }),
      catchError((x) => this.handleError(x))
    );
  }

  private handleError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      this.handleNoPermission(err);
      return EMPTY;
    } else {
      return throwError(err);
    }
  }

  private handleNoPermission(err) {
    return err.status === 401 ? this.handle401() : this.handle403();
  }

  private handle403() {
    if (this.router.routerState.snapshot.url !== "/wholesaler/dashboard") {
      this.authService.setIsAuthorized(false);
    }
  }
  private handle401() {
    if (this.authQuery.getCurrentUser !== undefined) {
      this.alertService.error("Unauthenticated. Please log in!");
    }
    this.router.navigate(["/auth/login"]);
  }
}
