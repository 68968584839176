<div class="price-summary-wrapper float-right">
  <table class="table table-price">
    <tbody>
      <tr *ngIf="quotation.billingCycle !== 'UPFRONT'">
        <td class="price-label">
          {{ quotation.billingCycle | titlecase }}
          Amount ({{ quotation.currency }}):
        </td>
        <td>{{ quotation.recurrenceAmount | currency }}</td>
      </tr>
      <tr>
        <td class="price-label">Term Length (Months):</td>
        <td>{{ quotation.termLength }}</td>
      </tr>
      <tr>
        <td class="price-label">
          Total Subscription Amount (excluding Upfront Amount) ({{
            quotation.currency
          }}):
        </td>
        <td>{{ quotation.subscriptionAmount | currency }}</td>
      </tr>
      <tr>
        <td class="price-label">
          Upfront Amount ({{ quotation.currency }}):
        </td>
        <td>{{ quotation.upfrontAmount | currency }}</td>
      </tr>
      <tr>
        <td class="price-label">SubTotal ({{ quotation.currency }}):</td>
        <td>{{ quotation.subtotal | currency }}</td>
      </tr>
      <tr>
        <td class="price-label">Total ({{ quotation.currency }}):</td>
        <td>{{ quotation.totalPrice | currency }}</td>
      </tr>
      <tr>
        <td class="price-label">
          Discount Applied ({{ quotation.currency }})
        </td>
        <td>
          {{ quotation.discount | currency }}
        </td>
      </tr>
      <tr *ngIf="quotation.tax > 0">
        <td class="price-label">
          Inclusive of Tax ({{quotation.tax}}%)
        </td>
        <td>
          {{ quotation.taxAmount | currency }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
