import { Component, OnInit } from "@angular/core";
import { ProductBundle } from "@models/_index";
import { ProductBundleService } from "@services/product-bundle.service";
import { format } from "date-fns";
import {
  BsModalService,
  BsModalRef,
  ModalOptions,
} from "ngx-bootstrap/modal";
import { ModalComponent } from "@shared/components/modal/modal.component";
import { AlertService } from "@services/alert.service";
@Component({
  selector: "app-product-bundle",
  templateUrl: "./product-bundle.component.html",
  styleUrls: ["./product-bundle.component.scss"],
})
export class ProductBundleComponent implements OnInit {
  public productBundleList: ProductBundle[] = [];
  public isLoading: Boolean = true
  public bsModalRef?: BsModalRef;
  public pagination: {
    totalItems: number;
    currentPage: number;
    itemPerPage: number;
    search: string;
    sortBy: string;
    sortDirection: "ASC" | "DESC";
  } = {
    totalItems: 10,
    currentPage: 0,
    itemPerPage: 10,
    search: "",
    sortBy: "",
    sortDirection: "ASC",
  }
  
  constructor(
    private productBundleService: ProductBundleService,
    private modalService: BsModalService,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.loadingProductBundleList()
  }

  loadingProductBundleList(): void {
    this.isLoading = true
    this.productBundleService.getListProductBundle({
      params: {
        size: this.pagination.itemPerPage,
        page: this.pagination.currentPage,
        keyword: this.pagination.search,
        sort: this.pagination.sortBy + "," + this.pagination.sortDirection,
      }
    }).subscribe(res => {
      this.productBundleList = res.data.content
      this.pagination = {
        totalItems: res.data.totalSize,
        currentPage: res.data.pageNumber,
        itemPerPage: res.data.size,
        search: this.pagination.search,
        sortBy: this.pagination.sortBy,
        sortDirection: this.pagination.sortDirection,
      }
      this.isLoading = false
    })
  }
  onSearch(text: string): void {
    this.pagination.search = text
    this.loadingProductBundleList()
  }

  changeItemPerPage(size: number): void {
    this.pagination.itemPerPage = size
    this.pagination.currentPage = 0
    this.loadingProductBundleList()
  }

  goToPage(page: number): void {
    this.pagination.currentPage = page
    this.loadingProductBundleList()
  }

  dateFormat(date: string): string {
    return format(date, "YYYY-MM-DD")
  }

  onConfirmation(pb: ProductBundle): void {
    const initialState: ModalOptions = {
      initialState: {
        title: `Do you want to delete this item?`,
        content: `Are you sure you want to delete this product bundle?`,
      },
      animated: false,
    };
    this.bsModalRef = this.modalService.show(ModalComponent, initialState);
    this.bsModalRef.content.eventEmitter.subscribe((isOk: Boolean) => {
      isOk ? this.deleteProductBundle(pb) : this.bsModalRef.hide();
    });
  }

  isDateInRange(exDate: string): boolean {
    const today = new Date();
    
    const expiringDate = new Date(exDate)

    today.setHours(0, 0, 0, 0);
    expiringDate.setHours(0, 0, 0, 0);
    
    return today < expiringDate
  }
  

  deleteProductBundle(pb: ProductBundle) {
    this.productBundleService.deleteProductBundle({ id: pb.id }).subscribe(
      () => {
        this.alertService.success("Successfully deleted product bundle!")
        this.productBundleList = []
        this.loadingProductBundleList()
        this.bsModalRef.hide();
      },
      err => {
        console.log(err)
        this.alertService.error("Something went wrong!")
        this.bsModalRef.hide();
      }
    )
  }

  onApplySort(colName: string): void {
    if (this.pagination.sortBy === colName) {
      this.pagination.sortDirection = this.pagination.sortDirection === "ASC" ? "DESC" : "ASC"
    }else{
      this.pagination.sortBy = colName
    }
    this.loadingProductBundleList()
  }

  isSortedAscOnField(colName: string): Boolean {
    return colName === this.pagination.sortBy && this.pagination.sortDirection === "ASC"
  }

  isSortedDescOnField(colName: string): Boolean {
    return colName === this.pagination.sortBy && this.pagination.sortDirection === "DESC"
  }
}
