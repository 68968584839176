import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";

import { INavData } from "@coreui/angular";

import { TranslateService } from "@ngx-translate/core";
@Injectable({
  providedIn: "root",
})
export class SidebarService {

  private navItems = new BehaviorSubject<INavData[]>([]);
  private isSidebarVisible = new BehaviorSubject<boolean>(false);

  constructor(private translateService: TranslateService) {}

  toggleSidebarVisible(isVisible: boolean) {
    this.isSidebarVisible.next(isVisible);
  }

  getIsSidebarVisible() {
    return this.isSidebarVisible.asObservable();
  }

  getNavItems(): Observable<INavData[]> {
    return this.navItems.asObservable();
  }

  setNavItems(_navItems: INavData[]) {
    _navItems.forEach((item) => {
      this.translateService
        .get(`${item.name}`)
        .subscribe((translatedText) => {
          item.name = translatedText;
          this.navItems.next(_navItems);
        });

      if (item.children) {
        item.children.forEach((subItem) => {
          this.translateService
            .get(`${subItem.name}`)
            .subscribe((translatedText) => {
              subItem.name = translatedText;
              this.navItems.next(_navItems);
            });
        });
      }
    });
  }
}
