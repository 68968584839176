import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Policy, Action } from "@models/_index";
import { ActionQuery } from "@stores/_index";
import {
  PolicyService,
  ActionService,
  AlertService,
} from "@services/_index";

@Component({
  selector: "app-create-policy",
  templateUrl: "./create-policy.component.html",
  styleUrls: ["./create-policy.component.scss"],
})
export class CreatePolicyComponent implements OnInit {
  public policy: Policy = new Policy();
  public actions: Action[] = [];

  public isLoading: Boolean = false;
  public isSubmitting: Boolean = false;

  constructor(
    private policyService: PolicyService,
    private actionService: ActionService,
    private actionQuery: ActionQuery,
    private alertService: AlertService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadActions();
  }

  loadActions() {
    this.isLoading = true;
    this.actionService.getActions().subscribe(
      () => {
        this.actions = this.actionQuery.getActions;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        console.log(err);
        this.alertService.errors(err?.error?._embedded?.message);
      }
    );
  }

  onSubmit(actionIds) {
    this.isSubmitting = true;
    this.policyService
      .createPolicy({
        payload: {
          policyName: this.policy.policyName,
          actions: actionIds,
        },
      })
      .subscribe(
        () => {
          this.isSubmitting = false;
          this.alertService.success("Policy Successfully Created.");
          this.router.navigate(["/wholesaler/admin/policy"]);
        },
        (err) => {
          this.isSubmitting = false;
          console.log(err);
          this.alertService.errors(err?.error?._embedded?.message);
        }
      );
  }
}
