import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { User } from "@models/_index";

import { UserService, AlertService } from "@services/_index";

@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.scss"],
})
export class EditUserComponent implements OnInit {
  public user: User;
  public isSubmitting: Boolean = false;
  public isLoading: boolean = false;

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.isLoading = true;
      this.userService.getUser(params.id).subscribe(
        (res) => {
          const {
            email,
            firstName,
            lastName,
            roles,
            userGroupIds,
            username,
            id,
            accountId,
            phoneNumber,
          } = res?.data;
          const userGrId = userGroupIds.map((ug) => ug.id);
          const roleId = roles.map((r) => r.id);
          this.user = {
            id,
            firstName,
            lastName,
            email,
            username,
            accountId,
            phoneNumber,
            userGroups: userGrId,
            roles: roleId,
          };
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.alertService.error(err?.error.message);
        }
      );
    });
  }

  onSubmit() {
    this.isSubmitting = true;
    const displayName = `${this.user.firstName} ${this.user.lastName}`;
    const user = {
      ...this.user,
      role: [],
      displayName: displayName,
    };
    const { confirmPassword, id, roles, ...payload } = user;

    this.userService.updateUser(this.user.id, { payload: payload }).subscribe(
      () => {
        this.isSubmitting = false;
        this.alertService.success("User Successfully Updated.");
        this.router.navigate(["/wholesaler/admin/users"]);
      },
      (err) => {
        this.isSubmitting = false;
        this.alertService.error(err?.error.message);
      }
    );
  }
}
