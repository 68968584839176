<div>
  <div class="row justify-content-between mb-3">
    <div class="col-xs-12 col-lg-4 mb-2">
      <app-search-bar
        (handleSearchEmitter)="onSearch($event)"
        [placeholder]="'search' | translate"
      ></app-search-bar>
    </div>
    <div class="col-xs-12 col-lg-6 align-items-end">
      <a
        class="btn btn-primary float-right"
        [routerLink]="'/wholesaler/customer/customers/new'"
        [translate]="'buttonTitles.newCustomer'"
      >
      </a>
    </div>
  </div>
  <div class="card">
    <div class="card-header" translate>customers</div>
    <div class="card-body table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>
              <app-sortable-header
                [title]="'companyName' | translate"
                [isSortedAsc]="isSortedAscOnField('company.name')"
                [isSortedDesc]="isSortedDescOnField('company.name')"
                (handleSort)="onApplySort('company.name')"
                >>
              </app-sortable-header>
            </th>
            <th translate>type</th>
            <th>
              <app-sortable-header
                [title]="'salesContact' | translate"
                [isSortedAsc]="
                  isSortedAscOnField('salesContact.personInCharge')
                "
                [isSortedDesc]="
                  isSortedDescOnField('salesContact.personInCharge')
                "
                (handleSort)="onApplySort('salesContact.personInCharge')"
                >>
              </app-sortable-header>
            </th>
            <th translate>subscription</th>
            <th translate>actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-template [ngIf]="!isLoading">
            <tr *ngFor="let account of accounts">
              <td (click)="onViewDetail(account.id)">{{ account?.company?.name }}</td>
              <td (click)="onViewDetail(account.id)">{{ account?.accountTypes?.join(", ") }}</td>
              <td (click)="onViewDetail(account.id)">{{ account?.salesContact?.personInCharge }}</td>
              <td (click)="onViewDetail(account.id)">
                <span class="badge badge-pill badge-success">Active</span>
              </td>
              <td>
                <a
                  class="text-decoration-none mr-1"
                  [routerLink]="['/wholesaler/customer/customers', account.id]"
                >
                  <i
                    class="fa fa-pencil"
                    tooltip="{{ 'tooltip.edit' | translate }}"
                  ></i>
                </a>
                <a
                  class="text-primary hoverable mr-1"
                  (click)="onClickDeleteBtn(account)"
                >
                  <i
                    class="fa fa-trash"
                    tooltip="{{ 'tooltip.delete' | translate }}"
                  ></i>
                </a>

                <a
                  class="text-primary hoverable"
                  (click)="goToSubscription(account.id)"
                >
                  <i
                    tooltip="{{ 'tooltip.customerSubscriptions' | translate }}"
                    class="fa fa-credit-card-alt"
                  ></i>
                </a>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
      <app-pagination
        [totalItems]="totalItems"
        [currentPage]="currentPage"
        [itemPerPages]="itemPerPage"
        (handleGoToPage)="goToPage($event)"
        (changeItemPerPage)="changeItemPerPage($event)"
      ></app-pagination>
    </div>
  </div>
</div>
