<div>
  <div class="row justify-content-between mb-3">
    <div class="col-xs-12 col-lg-4 mb-2">
      <app-search-bar
        (handleSearchEmitter)="onSearch($event)"
        [placeholder]="'search' | translate"
      ></app-search-bar>
    </div>
    <div class="col-xs-12 col-lg-6 align-items-end">
      <a
        class="btn btn-primary float-right"
        translate
        [routerLink]="'/wholesaler/product/part_pricing/new'"
      >
        buttonTitles.newPartPricing
      </a>
    </div>
  </div>
  <div class="card">
    <div class="card-header" translate>partPricings</div>
    <div class="card-body table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>
              <app-sortable-header
                [title]="'productName' | translate"
                [isSortedAsc]="
                  isSortedAscOnField('productPart.product.productName')
                "
                [isSortedDesc]="
                  isSortedDescOnField('productPart.product.productName')
                "
                (handleSort)="
                  onApplySort('productPart.product.productName')
                "
                >>
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'productPart' | translate"
                [isSortedAsc]="isSortedAscOnField('productPart.name')"
                [isSortedDesc]="isSortedDescOnField('productPart.name')"
                (handleSort)="onApplySort('productPart.name')"
              >
                >
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'partPricing' | translate"
                [isSortedAsc]="isSortedAscOnField('name')"
                [isSortedDesc]="isSortedDescOnField('name')"
                (handleSort)="onApplySort('name')"
              >
              </app-sortable-header>
            </th>
            <th translate>actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-template [ngIf]="!isLoading">
            <tr *ngFor="let item of partPriceItems">
              <td>{{ item.productPart.product.productName }}</td>
              <td>{{ item.productPart.name }}</td>
              <td>{{ item.name }}</td>
              <td>
                <a
                  class="text-primary hoverable mr-1"
                  (click)="onClickEditBtn(item)"
                >
                  <i
                    class="fa fa-pencil"
                    tooltip="{{ 'tooltip.edit' | translate }}"
                  >
                  </i>
                </a>
                <a
                  class="text-primary hoverable"
                  (click)="onClickDeleteBtn(item)"
                >
                  <i
                    class="fa fa-trash"
                    tooltip="{{ 'tooltip.delete' | translate }}"
                  >
                  </i>
                </a>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
      <app-pagination
        [totalItems]="totalItems"
        [currentPage]="currentPage"
        [itemPerPages]="itemPerPage"
        (handleGoToPage)="goToPage($event)"
        (changeItemPerPage)="changeItemPerPage($event)"
      ></app-pagination>
    </div>
  </div>
</div>
