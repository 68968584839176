import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

import { wholesalerNavItems } from "@utils/_wholesaler_nav.util";

import { SidebarService, AuthService } from "@services/_index";
import { AuthQuery } from "@stores/_index";
import { User } from "@models/user.model";

@Component({
  selector: "app-wholesaler",
  templateUrl: "./wholesaler.component.html",
  styleUrls: ["./wholesaler.component.css"],
})
export class WholesalerComponent implements OnInit, OnDestroy {
  public navItems = wholesalerNavItems;
  public isAuthorized: boolean = true;
  private isAuthorizedSubscription: Subscription;

  constructor(
    private sidebarService: SidebarService,
    private authService: AuthService,
    private authQuery: AuthQuery
  ) {
    this.sidebarService.setNavItems(this.navItems);
    this.isAuthorizedSubscription = this.subscribeIsAuthorized();
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.isAuthorizedSubscription.unsubscribe();
  }

  displayUserInfo() {
    const info = this.authQuery.getCurrentUser;
    return `${info.displayName} (${info?.userGroupIds
      ?.map((g: any) => g.name)
      .join(",")})`;
  }

  subscribeIsAuthorized() {
    return this.authService.getIsAuthorized().subscribe((value) => {
      this.isAuthorized = value;
    });
  }
}
