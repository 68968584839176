import { HomeComponent } from "./home/home.component";

/* eslint-disable max-len */
export { WholesalerComponent } from "./wholesaler.component";

// Homes
export { HomeComponent } from "./home/home.component";

// Dashboards
export { DashboardComponent } from "./dashboard/dashboard.component";
export { DashboardWidgetComponent } from "./dashboard/dashboard-widget/dashboard-widget.component";

// Products
export { ProductComponent } from "./product/product.component";
export { EditProductComponent } from "./product/edit-product/edit-product.component";
export { CreateProductComponent } from "./product/create-product/create-product.component";
export { FormProductComponent } from "./product/form-product/form-product.component";

// Customers
export { CustomerComponent } from "./customer/customer.component";
export { CreateCustomerComponent } from "./customer/create-customer/create-customer.component";
export { FormCustomerComponent } from "./customer/form-customer/form-customer.component";
export { EditCustomerComponent } from "./customer/edit-customer/edit-customer.component";
export { CustomerSubscriptionComponent } from './customer/customer-subscription/customer-subscription.component';

// Customer Tax
export { CustomerTaxComponent } from "./customer-tax/customer-tax.component"
export { FormCustomerTaxListComponent } from "./customer-tax/form-customer-tax-list/form-customer-tax-list.component"

// Part Pricings
export { PartPricingComponent } from "./pricing/part-pricing/part-pricing.component";
export { CreatePartPricingComponent } from "./pricing/part-pricing/create-part-pricing/create-part-pricing.component";
export { FormPartPricingComponent } from "./pricing/part-pricing/form-part-pricing/form-part-pricing.component";
export { EditPartPricingComponent } from "./pricing/part-pricing/edit-part-pricing/edit-part-pricing.component";

// Product Parts
export { ProductPartComponent } from "./product/product-part/product-part.component";
export { CreateProductPartComponent } from "./product/product-part/create-product-part/create-product-part.component";
export { FormProductPartComponent } from "./product/product-part/form-product-part/form-product-part.component";
export { EditProductPartComponent } from "./product/product-part/edit-product-part/edit-product-part.component";
export { FilterProductPartComponent } from "./product/product-part/filter-product-part/filter-product-part.component";

// Product Bundle
export { ProductBundleComponent } from "./product-bundle/product-bundle.component"
export { EditProductBundleComponent } from "./product-bundle/edit-product-bundle/edit-product-bundle.component"
export { CreateProductBundleComponent } from "./product-bundle/create-product-bundle/create-product-bundle.component"
export { FormProductBundleComponent } from "./product-bundle/form-product-bundle/form-product-bundle.component"

// Subscriptions
export { SubscriptionComponent } from "./subscription/subscription.component";
export { FormSubscriptionComponent } from "./subscription/form-subscription/form-subscription.component";
export { CreateSubscriptionComponent } from "./subscription/create-subscription/create-subscription.component";
export { EditAttachmentSubscriptionComponent } from "./subscription/edit-attachment-subscription/edit-attachment-subscription.component";
export { VoidSubscriptionComponent } from "./subscription/void-subscription/void-subscription.component";
export { UpdateCustomerReferenceNumberComponent } from "./subscription/update-customer-reference-number/update-customer-reference-number.component";

// Quotations
export { QuotationComponent } from "./quotation/quotation.component";
export { CreateQuotationComponent } from "./quotation/create-quotation/create-quotation.component";
export { EditQuotationComponent } from "./quotation/edit-quotation/edit-quotation.component";
export { FormQuotationComponent } from "./quotation/form/form-quotation/form-quotation.component";
export { FormContactDetailsComponent } from "./quotation/form/form-contact-details/form-contact-details.component";
export { FormQuotationDetailsComponent } from "./quotation/form/form-quotation-details/form-quotation-details.component";
export { FormHeaderComponent } from "./quotation/form/form-header/form-header.component";
export { FormPreviewComponent } from "./quotation/form/form-preview/form-preview.component";
export { ShowQuotationComponent } from "./quotation/show-quotation/show-quotation.component";
export { FormProductDetailsComponent } from "./quotation/form/form-product-details/form-product-details.component";
export { FormProductDetailComponent } from "./quotation/form/form-product-details/form-product-detail/form-product-detail.component";
export { QuotationAmountDetailComponent } from "./quotation/form/quotation-amount-detail/quotation-amount-detail.component";
export { FormQuotationProductBundleComponent } from "./quotation/form/form-product-details/form-quotation-product-bundle/form-quotation-product-bundle.component";

// Billings
export { BillingComponent } from "./billing/billing.component";
export { BillingDetailsComponent } from "./billing/billing-details/billing-details.component";
export { BillingDetailStatusComponent } from "./billing/billing-detail-status/billing-detail-status.component";
export { BillingEditStatusComponent } from "./billing/billing-edit-status/billing-edit-status.component";

// Pending Billings
export { PendingBillingComponent } from "./billing/pending-billing/pending-billing.component";
export { ExportSscComponent } from "./billing/pending-billing/export-ssc/export-ssc.component";
export { ExportSscByMonthComponent } from "./billing/pending-billing/export-ssc-by-month/export-ssc-by-month.component";


// User
export { UserComponent } from "./admin/user/user.component";
export { CreateUserComponent } from "./admin/user/create-user/create-user.component";
export { EditUserComponent } from "./admin/user/edit-user/edit-user.component";
export { FormUserComponent } from "./admin/user/form-user/form-user.component";
export { ModalChangePasswordComponent } from "./admin/user/form-user/modal-change-password/modal-change-password.component";

// User Group
export { UserGroupComponent } from "./admin/user-group/user-group.component";
export { CreateUserGroupComponent } from "./admin/user-group/create-user-group/create-user-group.component";
export { EditUserGroupComponent } from "./admin/user-group/edit-user-group/edit-user-group.component";
export { FormUserGroupComponent } from "./admin/user-group/form-user-group/form-user-group.component";

// Policies
export { PolicyComponent } from "./policy/policy.component";
export { EditPolicyComponent } from "./policy/edit-policy/edit-policy.component";
export { CreatePolicyComponent } from "./policy/create-policy/create-policy.component";
export { FormPolicyComponent } from "./policy/form-policy/form-policy.component";

// Actions
export { ActionComponent } from "./action/action.component";

// Reusable Components
export { SearchBarComponent } from "@shared/components/search-bar/search-bar.component";
export { SortableHeaderComponent } from "@shared/components/sortable-header/sortable-header.component";
export { LoadingSpinnerComponent } from "@shared/components/loading-spinner/loading-spinner.component";
export { PaginationComponent } from "./../shared/components/pagination/pagination.component";
export { ModalComponent } from "@shared/components/modal/modal.component";
