<div>
  <div class="row mb-3">
    <div class="col-xs-12 col-lg-3 mb-2">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"
            ><i class="fa fa-search"></i
          ></span>
        </div>
        <input
          id="search-input"
          type="search"
          class="form-control"
          [placeholder]="'search' | translate"
          (input)="onSearch(search)"
          [(ngModel)]="search"
        />
      </div>
    </div>

    <div class="col-xs-12 col-lg-3 pr-3 mb-2">
      <app-search-bar
        (handleSelectEmitter)="onSelect($event)"
        [showFilter]="true"
        [items]="accounts"
        preSelect="{{ 'allCustomers' | translate }}"
        [defaultValue]="defaultAccountId"
        type="account"
      >
      </app-search-bar>
    </div>
  </div>
  <div class="card">
    <div class="card-header" translate>subscriptions</div>
    <div class="card-body table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>
              <app-sortable-header
                [title]="'subscriptionId' | translate"
                [isSortedAsc]="isSortedAscOnField('created_at')"
                [isSortedDesc]="isSortedDescOnField('created_at')"
                (handleSort)="onApplySort('created_at')"
              >
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'customer' | translate"
                [isSortedAsc]="isSortedAscOnField('company_name')"
                [isSortedDesc]="isSortedDescOnField('company_name')"
                (handleSort)="onApplySort('company_name')"
              >
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'totalPrice' | translate"
                [isSortedAsc]="isSortedAscOnField('total_price')"
                [isSortedDesc]="isSortedDescOnField('total_price')"
                (handleSort)="onApplySort('total_price')"
              >
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'startDate' | translate"
                [isSortedAsc]="isSortedAscOnField('start_date')"
                [isSortedDesc]="isSortedDescOnField('start_date')"
                (handleSort)="onApplySort('start_date')"
              >
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'endDate' | translate"
                [isSortedAsc]="isSortedAscOnField('end_date')"
                [isSortedDesc]="isSortedDescOnField('end_date')"
                (handleSort)="onApplySort('end_date')"
              >
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header
                [title]="'billingCycle' | translate"
                [isSortedAsc]="isSortedAscOnField('billing_type')"
                [isSortedDesc]="isSortedDescOnField('billing_type')"
                (handleSort)="onApplySort('billing_type')"
              >
              </app-sortable-header>
            </th>
            <th translate>actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-template [ngIf]="!isLoading">
            <tr *ngFor="let subscription of subscriptions">
              <td>
                <div>
                  {{ subscription.subscription_code }}
                </div>
                <span *ngIf="subscription.quotation_id">
                  <small>
                    From Quotation ID:
                    <span
                      class="link-to-quotation"
                      (click)="goToQuotation(subscription.quotation_code)"
                    >
                      {{ subscription.quotation_code }}
                    </span>
                  </small>
                </span>
              </td>
              <td>{{ subscription.company_name }}</td>
              <td>
                {{ subscription.total_price | currency: "":"" }}
                {{ subscription.currency }}
              </td>
              <td>{{ subscription.start_date | date: "dd MMM yyyy" }}</td>
              <td>{{ subscription.end_date | date: "dd MMM yyyy" }}</td>
              <td>{{ subscription.billing_type }}</td>
              <td>
                <app-edit-attachment-subscription
                  [subscriptionId]="subscription.id"
                ></app-edit-attachment-subscription>
                <app-update-customer-reference-number
                  [subscription]="subscription"
                ></app-update-customer-reference-number>
                <app-void-subscription
                  [subscription]="subscription"
                  (getSubscriptions)="getSubscriptions()"
                ></app-void-subscription>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
      <app-pagination
        [totalItems]="totalItems"
        [currentPage]="currentPage"
        [itemPerPages]="itemPerPage"
        (handleGoToPage)="goToPage($event)"
        (changeItemPerPage)="changeItemPerPage($event)"
      ></app-pagination>
    </div>
  </div>
</div>
