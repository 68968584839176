import { Query } from "@datorama/akita";
import { QuotationState, QuotationStore } from "./quotation.store";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class QuotationQuery extends Query<QuotationState> {
  quotation$ = this.select("quotation");
  quotations$ = this.select("quotations");

  constructor(protected store: QuotationStore) {
    super(store);
  }

  get quotations() {
    return this.getValue().quotations;
  }

  get quotation() {
    return this.getValue().quotation;
  }

  get selectedQuotation() {
    return this.getValue().selectedQuotation;
  }
}
