import { BaseModel } from "./base.model";

export enum AddressType {
  MAILING = "MAILING",
  BILLING = "BILLING",
}

export class Address extends BaseModel {
  type: AddressType = AddressType.MAILING;
  address: string = "";
  unit?: string = "";
  postalCode?: string= "";
  state?: string = "";
  stateName?: string = "";
  country?: string = "SG";
  countryName?: string = "Singapore";
}
