import { Component, OnInit } from "@angular/core";
import { Login } from "@models/_index";
import { AuthService, AlertService } from "@services/_index";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AuthStore } from "@stores/_index";
import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
// import { addMinutes } from "date-fns";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public login: Login = new Login();
  isLoading: Boolean = false;

  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private authStore: AuthStore,
    private router: Router
  ) {}

  ngOnInit(): void {}

  onSubmit(form: HTMLFormElement) {
    if (form.form.valid) {
      this.isLoading = true;
      this.authService
        .login({ payload: this.login })
        .subscribe(
          (res: any) => {
            this.authStore.update({
              currentUser: res.data,
            });
            this.isLoading = false;
            this.router.navigate(["wholesaler/home"]);
            this.alertService.success(res?.message);
          },
          (err) => {
            if (err.error.message === "User already logged in!") {
              this.handleUserLoggedIn();
            } else {
              this.isLoading = false;
              this.alertService.error(err?.error?.message);
            }
          }
        );
    }
  }

  handleUserLoggedIn() {
    this.authService.getCurrentUser().subscribe(
      (res: any) => {
        this.isLoading = false;
        this.authStore.update({ currentUser: res.data });
        this.router.navigate(["/wholesaler/home"]);
      },
      (err) => {
        this.isLoading = false;
        this.router.navigate(["/auth/login"]);
        this.alertService.error(err?.error?.message);
      }
    );
  }
}
