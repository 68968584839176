<a
  tooltip="{{ 'tooltip.editCustomerRefNum' | translate }}"
  class="text-primary hoverable mr-1"
  (click)="onShowModal()"
>
  <i class="fa fa-address-card-o"> </i>
</a>

<div
  bsModal
  #editRefNumberModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" translate>editCustomerRefNum</h4>
        <button
          type="button"
          class="close"
          (click)="editRefNumberModal.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="padding: 0">
        <div class="card-body table-responsive">
          <div class="row mb-3">
            <div class="col-12 justify-content-end">
              <form
                id="editRefNumberForm"
                #editRefNumberForm="ngForm"
              >
                <div class="form-group">
                  <label translate>customerReferenceNumber</label>
                  <input
                    type="text"
                    class="form-control"
                    name="refNumber"
                    placeholder="{{ 'placeholder.refNumber' | translate }}"
                    [(ngModel)]="subscription.customer_reference_number"
                    #refNumber="ngModel"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            (click)="onSubmit()"
            [ladda]="isSubmitting"
            data-style="zoom-in"
            [translate]="'buttonTitles.save'"
            [disabled]="editRefNumberForm.pristine"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
