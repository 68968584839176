import { BaseModel } from "./base.model";

export class Contact extends BaseModel {
  id: string;
  personInCharge: string;
  contactNumber: string;
  email: string;
}

export class QuotationContact {
  person_in_charge: string;
  contact_number: string;
  email: string;
}
