import { Injectable } from "@angular/core";
import { ServiceUrl } from "./config/service-url";
import { ApiService } from "./config/api.service";
import { Observable, pipe } from "rxjs";
import { tap } from "rxjs/operators";
import { ProductPartStore } from "@stores/_index";
import { Payload, ProductPart } from "@models/_index";
import { HttpClient } from "@angular/common/http";

// import pipe
@Injectable({
  providedIn: "root",
})
export class ProductPartService {
  serviceUrl = new ServiceUrl();
  constructor(
    private apiService: ApiService,
    private store: ProductPartStore,
  ) {}

  getProductPartsByProductId(payload: Payload) {
    return this.apiService
      .get(this.serviceUrl.GET_PRODUCT_PARTS_BY_PRODUCT_ID, payload)
      .pipe(
        tap((res) => {
          this.store.update({ productParts: res.data });
          this.store.setLoading(false);
        })
      );
  }
}
