import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./auth/login/login.component";
import { LogoutComponent } from "./auth/logout/logout.component";
import { P404Component } from "./shared/components/error/404.component";
const routes: Routes = [
  {
    path: "",
    redirectTo: "wholesaler",
    pathMatch: "full",
  },
  {
    path: "",
    loadChildren: () =>
      import("./wholesaler/wholesaler.module").then(
        (m) => m.WholesalerModule
      ),
  },
  {
    path: "auth/login",
    component: LoginComponent
  },
  {
    path: "auth/logout",
    component: LogoutComponent
  },

  {
    path: "**",
    component: P404Component,
    data: {
      title: "Page 404",
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
