import { Injectable } from "@angular/core";
import { ServiceUrl } from "./config/service-url";
import { ApiService } from "./config/api.service";
import { SubscriptionStore } from "@stores/_index";
import { tap } from "rxjs/operators";
import { Payload } from "@models/_index";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  serviceUrl = new ServiceUrl();

  constructor(
    private store: SubscriptionStore,
    private apiService: ApiService
  ) { }

  getSubscriptions(payload?: Payload) {
    return this.apiService
      .get(this.serviceUrl.GET_SUBSCRIPTION, payload)
      .pipe(
        tap((res) => {
          this.store.update({
            subscriptions: res.content,
          });
        })
      );
  }

  getCustomerSubscriptions(customerId: string = "", payload: Payload) {
    return this.apiService
      .get(`${this.serviceUrl.GET_SUBSCRIPTION}/customer/${customerId}`, payload)
      .pipe(
        tap((res) => {
          this.store.update({
            customerSubscriptions: res,
          });
        })
      );
  }

  getSubscriptionPdf(id: string) {
    return this.apiService.get(
      `${this.serviceUrl.GET_SUBSCRIPTION}/${id}/signed_document`,
      {}
    );
  }

  createSubscription(payload: Payload) {
    return this.apiService.postFormData(this.serviceUrl.GET_SUBSCRIPTION, payload);
  }

  updateSubscription(payload: Payload) {
    return this.apiService.put(
      `${this.serviceUrl.GET_SUBSCRIPTION}`, payload
    );
  }

  voidSubscription(id: string, payload: Payload) {
    return this.apiService.put(
      `${this.serviceUrl.GET_SUBSCRIPTION}/${id}/status`, payload
    );
  }

  getSubscriptionAttachments(id: string) {
    return this.apiService.get(
      `${this.serviceUrl.GET_SUBSCRIPTION}/${id}/attachments`,
      {}
    );
  }

  downloadFileAttachment(id: string) {
    return this.apiService.post(
      `${this.serviceUrl.GET_SUBSCRIPTION_ATTACHMENT}/${id}/download`, {}
    );
  }

  deleteFileAttachment(id: string) {
    return this.apiService.delete(
      `${this.serviceUrl.GET_SUBSCRIPTION_ATTACHMENT}/${id}`
    );
  }

  addNewAttachment(id: string, payload: Payload) {
    return this.apiService.postFormData(
      `${this.serviceUrl.GET_SUBSCRIPTION}/${id}/attachments`, payload
    );
  }
}
