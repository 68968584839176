<div>
  <div class="row justify-content-between mb-3">
    <div class="col-xs-12 col-lg-4 mb-2"></div>
    <div class="col-xs-12 col-lg-6 align-items-end">
      <a
        class="btn btn-primary float-right"
        [routerLink]="'/wholesaler/admin/users/new'"
        translate
      >
        buttonTitles.newUser
      </a>
    </div>
  </div>
  <div class="card">
    <div class="card-header" translate>users</div>
    <div class="card-body table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th translate>userName</th>
            <th translate>userGroup</th>
            <th translate>actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-template [ngIf]="!isLoading">
            <tr *ngFor="let user of users; index as i">
              <td>{{ user?.displayName }}</td>
              <td>
                <p *ngFor="let gr of user.userGroupIds">
                  {{ gr.name }}
                </p>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <a
                    class="hoverable text-primary mr-1"
                    [routerLink]="['/wholesaler/admin/users', user.id]"
                  >
                    <i
                      class="fa fa-pencil"
                      tooltip="{{ 'tooltip.edit' | translate }}"
                    >
                    </i>
                  </a>
                  <a class="hoverable text-primary mr-3">
                    <i
                      class="fa fa-trash"
                      (click)="onDelete(user.id)"
                      tooltip="{{ 'tooltip.delete' | translate }}"
                    >
                    </i>
                  </a>
                </div>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
    </div>
  </div>
</div>
