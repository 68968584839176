import { Store, StoreConfig } from "@datorama/akita";
import { Product } from "@models/product.model";
import { ProductPart } from "@models/product-part.model";
import { Injectable } from "@angular/core";

export interface ProductState {
  products: Product[];
  productParts: ProductPart[];
  selectedProduct: string;
}
export function createInitialState(): ProductState {
  return {
    products: [],
    productParts: [],
    selectedProduct: "",
  };
}

@StoreConfig({ name: "products" })
@Injectable({ providedIn: "root" })
export class ProductStore extends Store<ProductState> {
  constructor() {
    super(createInitialState());
  }
}
