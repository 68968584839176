import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
} from "@angular/core";
import { animate, style, transition, trigger } from "@angular/animations";
import { switchMap } from "rxjs/operators";
import {
  Product,
  PartPriceItem,
  Quotation,
  QuotationItem,
  ProductBundleItem,
  ProductBundle,
  CurrencyPriceItem,
} from "@models/_index";
import { QuotationStore } from "@stores/_index";
import { PartPriceItemService, QuotationService } from "@services/_index";
import { PartPriceItemQuery } from "@stores/_index";
import { AlertService } from "@services/alert.service";
import { BillingCycle } from "@models/_index";
import { ProductBundleService } from "@services/product-bundle.service";
import { BundleItem } from "@models/quotation.model";
@Component({
  selector: "app-form-product-details",
  templateUrl: "./form-product-details.component.html",
  styleUrls: ["./form-product-details.component.scss"],
  animations: [
    trigger("inOutPaneAnimation", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateX(-100%)" }),
        animate(
          "400ms ease-in-out",
          style({ opacity: 1, transform: "translateX(0)" })
        ),
      ]),
    ]),
  ],
})
export class FormProductDetailsComponent implements OnInit, OnChanges {
  @Input() isShowing: boolean;
  @Input() isShowingDetails: boolean;
  @Input() quotation: Quotation;

  @Output()
  editProductItemEmitter: EventEmitter<boolean> = new EventEmitter();
  public productBundleList: ProductBundleItem[] = [];
  public products: Product[] = [];
  public partPriceItems: PartPriceItem[] = [];
  public _quotationItems: QuotationItem[] = [];
  public productBundleSelection: ProductBundle[] = []
  public tax: number;
  public billingCycles: string[] = Object.keys(BillingCycle);
  public quotationItemIdInProductBundle: string[] = [];
  // helper to use in View
  public isEditingQuotationItem: boolean = false;
  public isAddingQuotationItem: boolean = false;
  public isLoading: boolean = false;
  public isLoadingPartPricing: boolean = false;
  public editingQuotationItemIndex: number;
  public triggerProductBundleChange: { type: | ""
  | "SUCCESSFULLY_CREATED"
  | "FAILED_TO_CREATE"
  | "SUCCESSFULLY_UPDATED"
  | "FAILED_TO_UPDATE"
  | "SUCCESSFULLY_DELETED"
  | "FAILED_TO_DELETE"}
   =  { type: "" } 
  constructor(
    private partPriceItemService: PartPriceItemService,
    private quotationService: QuotationService,
    private partPriceItemQuery: PartPriceItemQuery,
    private alertService: AlertService,
    private productBundleService: ProductBundleService
  ) {}

  ngOnInit(): void {
    this.loadPartPricing();
    this.updateTempQuotationItems();
    if (this.quotation.bundleItems.length > 0)
      this.validatePartPricingCanBeDeleted(this.quotation, "LOAD");
    this.loadProductBundleSelection()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateProductIdOfQuotationItem();
    this.mappingData();
    this.updateTempQuotationItems();
  }

  loadProductBundleSelection() {
    this.productBundleService.getAllProductBundle({}).subscribe(
      (res) => {
        this.productBundleSelection = res.data.filter(
          (p: ProductBundle) => {
            return (
              p.recurrenceType === this.quotation.billingCycle &&
              p.currency === this.quotation.currency
            );
          }
        );
      },
      (err) => {
        this.alertService.error("Something went wrong!");
        console.log(err);
      }
    );
  }

  loadPartPricing(): void {
    this.isLoadingPartPricing = true;
    this.partPriceItemService.getAllPartPriceItems().subscribe(
      () => {
        this.partPriceItems = this.getAvailablePriceItem();
        this.products = this.getProducts();
        this.updateProductIdOfQuotationItem();
        this.mappingData();
        this.isLoadingPartPricing = false;
      },
      (err) => {
        this.alertService.errors(err?.error?._embedded?.errors);
      }
    );
  }

  mappingData(): void {
    this.quotation.quotationItems.forEach((item) => {
      const priceItem = this.getPartPriceItem(item);
      item.productName = priceItem?.productPart?.product?.productName;
      item.partPricingName = priceItem?.name;
    });
  }

  getProducts(): Product[] {
    const productArray = this.partPriceItems.map(
      (item) => item?.productPart?.product
    );
    const ids = productArray.map((o) => o.id);
    return productArray.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );
  }

  getAvailablePriceItem(): PartPriceItem[] {
    return this.partPriceItemQuery.getAvailablePriceItem(
      this.quotation.currency,
      this.quotation.billingCycle
    );
  }

  updateProductIdOfQuotationItem(): void {
    this.quotation.quotationItems.forEach((item) => {
      const priceItem = this.getPartPriceItem(item);
      const id = priceItem?.productPart?.product?.id;
      item.productId = id;
    });
  }

  updateTempQuotationItems(): void {
    this._quotationItems = JSON.parse(
      JSON.stringify(this.quotation.quotationItems || [])
    );
  }

  isQuotationItemEditing(index) {
    return this.editingQuotationItemIndex === index;
  }

  onEditQuotationItem(index: number) {
    this.editingQuotationItemIndex = index;
    this.isEditingQuotationItem = true;
    this.editProductItemEmitter.emit(true);
  }

  onAddQuotationItem(): void {
    // this.addQuotationItem();
    this.quotation.quotationItems.push(new QuotationItem());
    const index = this.isQuotationItemExist()
      ? this.quotation.quotationItems.length - 1
      : 0;
    this.onEditQuotationItem(index);
    this.isAddingQuotationItem = true;
  }

  isQuotationItemExist(): Boolean {
    return this.quotation.quotationItems.length > 0;
  }

  addQuotationItem(item: QuotationItem): void {
    this.quotationService
      .addQuotationItem({
        id: this.quotation.id,
        payload: this.getQuotationItemPayload(item),
      })
      .subscribe(
        () => {
          this.updateTempQuotationItems();
          this.isAddingQuotationItem = false;
          this.stopEditingQuotationItem();
          this.isLoading = false;
        },
        (err) => {
          this.alertService.errors(err?.error?._embedded?.errors);
        }
      );
  }

  onDeleteQuotationItem(item: QuotationItem): void {
    this.quotationService
      .deleteQuotationItem({ id: this.quotation.id }, item.id)
      .subscribe(() => {
        this.updateTempQuotationItems();
      });
  }

  deleteQuotationItemHandler(): void {
    this.updateTempQuotationItems();
  }

  onSaveQuotationItem(item: QuotationItem) {
    if (
      !this.isDiscountAmountInvalid(item) &&
      this.isQuantityValid(item)
    ) {
      this.isLoading = true;
      if (this.isAddingQuotationItem) {
        this.addQuotationItem(item);
      } else {
        this.updateQuotationItem(item);
      }
    }
  }

  onCancelQuotationItem(index: number): void {
    if (this.isAddingQuotationItem) {
      this.quotation.quotationItems = [...this._quotationItems];
      this.isAddingQuotationItem = false;
    } else {
      this.quotation.quotationItems[index] = {
        ...this._quotationItems[index],
      };
    }
    this.updateProductIdOfQuotationItem();
    this.mappingData();
    this.stopEditingQuotationItem();
  }

  stopEditingQuotationItem(): void {
    this.editingQuotationItemIndex = null;
    this.isEditingQuotationItem = false;
    this.editProductItemEmitter.emit(false);
  }

  updateQuotationItem(item: QuotationItem): void {
    this.quotationService
      .updateQuotationItem(
        {
          id: this.quotation.id,
          payload: this.getQuotationItemPayload(item),
        },
        item.id
      )
      .subscribe(
        () => {
          this.updateTempQuotationItems();
          this.stopEditingQuotationItem();
          this.isLoading = false;
        },
        (err) => {
          this.alertService.errors(err?.error?._embedded?.errors);
        }
      );
  }

  getQuotationItemPayload(item: QuotationItem): {
    part_pricing_id: string;
    quantity: number;
    discount_type: string;
    discount_amount: number;
    recurrence_type: string;
  } {
    return {
      part_pricing_id: item?.partPricingId,
      quantity: item.quantity,
      discount_type: "AMOUNT",
      discount_amount: item.discountAmount,
      recurrence_type: item.recurrenceType,
    };
  }

  isDiscountAmountInvalid(item: QuotationItem): Boolean {
    return (
      this.isDiscountLargerThanSetting(item) ||
      this.isDiscountFormatInvalid(item)
    );
  }

  isQuantityValid(item: QuotationItem): Boolean {
    const minimumQuantity = this.getMinimumQuantity(item);
    return item?.partPricingId ? item?.quantity >= minimumQuantity : true;
  }

  getMinimumQuantity(item: QuotationItem): number {
    const partPriceItem = this.getPartPriceItem(item);
    return partPriceItem?.minimumQuantity;
  }

  getSameCurrencyPrice(item: QuotationItem): CurrencyPriceItem {
    const partPriceItem = this.getPartPriceItem(item);
    return partPriceItem?.priceItems.find(
      (price) =>
        price?.currency === this.quotation?.currency &&
        price?.recurrenceType === item?.recurrenceType
    );
  }

  // Controller Helpers
  isDiscountLargerThanSetting(item: QuotationItem): Boolean {
    const sameCurrencyItem = this.getSameCurrencyPrice(item);
    return item?.discountAmount > sameCurrencyItem?.maxPriceDiscount;
  }

  isDiscountFormatInvalid(item: QuotationItem): Boolean {
    const discount = item.discountAmount.toString();
    const regex = new RegExp("^\\d*(\\.\\d[0]?)?$");
    return !regex.test(discount);
  }

  getPartPriceItem(item: QuotationItem): PartPriceItem {
    return this.partPriceItems.find(
      (_item) => _item.id === item?.partPricingId
    );
  }

  emitProductBundle(emit: {
    data: any;
    type: "CREATE" | "UPDATE" | "DELETE";
  }): void {
    switch (emit.type) {
      case "CREATE":
        this.onAddingProductBundle(emit.data);
        break;
      case "UPDATE":
        this.onUpdatingProductBundle(emit.data);
        break;
      case "DELETE":
        this.onDeletingProductBundle(emit.data);
        break;
    }
  }

  getQuotationItemsFromProductBundle(data: {
    productBundle: ProductBundle;
    payload: any;
  }): any[] {
    const quotationProductBundleItems = [];
    data.productBundle.partPriceItems.forEach((item) => {
      const ppItem = this.partPriceItems.find(
        (i) => i.id === item.partPriceItemId
      );
      const miniPPItem = item.priceItems.find(
        (i) =>
          i.recurrenceType === "UPFRONT" ||
          i.recurrenceType === this.quotation.billingCycle
      );

      quotationProductBundleItems.push({
        part_pricing_id: item.partPriceItemId,
        part_pricing_name: item.partPriceItemName,
        product_part_id: ppItem.productPart.id,
        product_part_name: ppItem.productPart.name,
        product_id: ppItem.productPart.product.id,
        product_name: ppItem.productPart.product.productName,
        quantity: item.minimumQuantity,
        discount_type: "PERCENTAGE",
        discount_amount: data.payload.payload.discountAmount,
        discount_percentage: 0,
        price: ppItem.priceItems[0].price,
        amount: ppItem.priceItems[0].price * item.minimumQuantity,
        recurrence_type: miniPPItem.recurrenceType,
        billing_description: ppItem.billDescription,
        total_price_whole_term:
          ppItem.priceItems[0].price * item.minimumQuantity,
      });
    });

    return quotationProductBundleItems;
  }

  onAddingProductBundle(data: any): void {
    const quotationItem = this.getQuotationItemsFromProductBundle(data);
    data.payload.payload.quotationItems = quotationItem;

    this.quotationService
      .createQuotationProductBundle(data.payload)
      .subscribe(
        () => this.reloadPartPricing("SUCCESSFULLY_CREATED"),
        () => this.triggerProductBundleChange = { type: "FAILED_TO_CREATE" }
      );
  }

  onUpdatingProductBundle(data: any): void {
    const quotationItem = this.getQuotationItemsFromProductBundle(data);
    data.payload.payload.quotationItems = quotationItem;

    this.quotationService
      .updateQuotationProductBundle(data.payload)
      .subscribe(
        () => this.reloadPartPricing("SUCCESSFULLY_UPDATED"),
        () => this.triggerProductBundleChange = { type: "FAILED_TO_UPDATE" }
      );
  }

  onDeletingProductBundle(data: any): void {
    this.quotationService
      .deleteQuotationProductBundle(data.payload)
      .subscribe(
        () => this.reloadPartPricing("SUCCESSFULLY_DELETED"),
        () => this.triggerProductBundleChange = { type: "FAILED_TO_DELETE" }
      );
  }

  reloadPartPricing(
    type:
      | ""
      | "SUCCESSFULLY_CREATED"
      | "FAILED_TO_CREATE"
      | "SUCCESSFULLY_UPDATED"
      | "FAILED_TO_UPDATE"
      | "SUCCESSFULLY_DELETED"
      | "FAILED_TO_DELETE"
  ): void {
    this.quotationService
      .getQuotation({ id: this.quotation.id })
      .subscribe((res) => {
        this.validatePartPricingCanBeDeleted(res, "UPDATE");
        this.triggerProductBundleChange = { type: type }
      });
  }

  validatePartPricingCanBeDeleted(
    quotation: Quotation | any,
    type: "UPDATE" | "LOAD"
  ): void {
    this.quotationItemIdInProductBundle = [];
    if (type === "UPDATE") {
      quotation.bundle_items.forEach((item: any) => {
        item.quotation_items.forEach((qitem: QuotationItem) => {
          this.quotationItemIdInProductBundle.push(qitem.id);
        });
      });
    } else {
      quotation.bundleItems.forEach((item: BundleItem) => {
        item.quotationItems.forEach((qitem: QuotationItem) => {
          this.quotationItemIdInProductBundle.push(qitem.id);
        });
      });
    }
  }
}
