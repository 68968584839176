<a
  tooltip="{{ 'tooltip.changeBillStatus' | translate }}"
  class="text-primary hoverable"
  (click)="onShowModal()"
>
  <i class="fa fa-pencil"></i>
</a>

<div
  bsModal
  #billingEditStatusModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" translate>editBillStatus</h4>
        <button
          type="button"
          class="close"
          (click)="onCloseModal()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-full-width">
          <div class="form-group">
            <label translate>invoiceNumber</label>
            <input
              [placeholder]="'placeholder.invoiceNumber' | translate"
              type="text"
              #invoiceNumber="ngModel"
              class="form-control"
              name="invoiceNumber"
              [(ngModel)]="bill.invoiceNumber"
            />
          </div>
          <div class="form-group">
            <label translate>status</label>
            <select
              class="form-control selector"
              [(ngModel)]="bill.status"
              name="status"
              (change)="onChange()"
            >
              <option value="UNPAID">Unpaid</option>
              <option value="PAID">Paid</option>
              <option value="VOID">Void</option>
            </select>
          </div>
          <div class="form-group" *ngIf="bill.status === 'VOID'">
            <label translate class="required">remarks</label>
            <textarea
              name="remark"
              [(ngModel)]="bill.remark"
              class="form-control"
              rows="4"
              (input)="onChangeRemark()"
            >
            </textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="onCloseModal()"
          translate
        >
          buttonTitles.cancel
        </button>
        <button
          [ladda]="isLoading"
          type="button"
          (click)="onSubmit()"
          class="btn btn-primary"
          [disabled]="isInvalidRemark"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
