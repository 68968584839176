// import { QuotationContact } from "./contact.model";
import { BaseModel } from "./base.model";
import { QuotationItem } from "./quotation-item.model";

export enum SaleMode {
  DIRECT = "DIRECT",
  WHOLESALER = "WHOLESALER",
  RESELLER = "RESELLER",
}

export enum BillingCycle {
  QUARTERLY = "QUARTERLY",
  MONTHLY = "MONTHLY",
  UPFRONT = "UPFRONT",
  ANNUALLY = "ANNUALLY",
}
export class Quotation extends BaseModel {
  id: string;
  quotationDate: string;
  quotationExpiration: string;
  subscriptionDate: string;
  subscriptionEndDate: string;
  startDate: string;
  termLength: number;
  taxAmount: number;
  subtotal: number;
  totalPrice: number;
  discount: number;
  tax: number = 7;
  salesMode: SaleMode;
  paymentTerm: string;
  accountId: string;
  quotationItems: QuotationItem[] = [];
  companyName: string;
  currency: "USD" | "SGD" | "MYR";
  status: string;
  notes: string;
  salesContact: QuotationContact = new QuotationContact();
  billingContact: QuotationContact = new QuotationContact();
  billingCycle: BillingCycle = BillingCycle.MONTHLY;
  salespersonName: string;
  salespersonContactNumber: string;
  salespersonEmail: string;
  reseller: string;
  subscriptionAmount?: string;
  upfrontAmount?: string;
  recurrenceAmount?: string;
  isGenerated: boolean = false;
  quotationCode: string;
  bundleItems: BundleItem[];
}

export class BundleItem{
  id: string
  discountAmount: number
  productBundleId: string
  bundleName: string
  quotationItems: QuotationItem[] = []
}

export class QuotationContact {
  personInCharge: string = "";
  contactNumber: string = "";
  email: string = "";
}
