export class BaseModel {
  static fromArray(array) {
    return array.map((object) => this.fromObject(object));
  }

  static fromObject(object) {
    return Object.assign(new this(), this.convertSnakeToCamel(object));
  }

  static convertSnakeToCamel(object) {
    const resObject = {};
    Object.keys(object).forEach((k) => {
      const newKey = k.replace(/(\_\w)/g, (m) => m[1].toUpperCase());
      if (this.isArray(object[k])) {
        resObject[newKey] = object[k].map((o) =>
          this.convertSnakeToCamel(o)
        );
      } else if (object[k] !== null && this.isObject(object[k])) {
        resObject[newKey] = this.convertSnakeToCamel(object[k]);
      } else {
        resObject[newKey] = object[k];
      }
    });
    return resObject;
  }

  static isArray(array) {
    return array instanceof Array;
  }

  static isObject(object) {
    return object instanceof Object;
  }
}
