import { CanDeactivate } from "@angular/router";
import { EditCustomerComponent } from "../wholesaler/customer/edit-customer/edit-customer.component";
import { Injectable } from "@angular/core";

@Injectable()
export class DeactivateGuard
  implements CanDeactivate<EditCustomerComponent>
{
  canDeact = true;

  canDeactivate() {
    return this.canDeact;
  }
}
