/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */

export class ServiceUrl {
  // Services Name
  PRODUCT_SERVICE = "smp-product-svc";
  CUSTOMER_SERVICE = "smp-customer-svc";
  QUOTATION_SERVICE = "smp-quotation-svc";
  SUBSCRIPTION_SERVICE = "smp-subscription-svc";
  BILLING_SERVICE = "smp-billing-svc";
  IAM_SERVICE = "smp-iam-svc";

  // Namespaces
  PRODUCT = "products";
  PRODUCT_PART = "product_parts";
  PART_PRICE_ITEM = "part_price_items";
  PRICE_PLAN = "price_plans";
  ACCOUNT = "accounts";
  QUOTATION = "quotations";
  QUOTATION_PRODUCT_BUNDLE = "quotation-product-bundle-items";
  SUBSCRIPTION = "subscriptions";
  BILLING = "billings";
  BILLING_ITEM = "billing_items";
  POLICY = "policy";
  ACTION = "action";
  USER = "user";
  ROLE = "role";
  TAX = "tax";
  PRODUCT_BUNDLE = "product-bundles";

  // Products
  GET_PRODUCTS = `${this.PRODUCT_SERVICE}/${this.PRODUCT}`;

  // Product Parts
  GET_PRODUCT_PARTS = `${this.PRODUCT_SERVICE}/${this.PRODUCT_PART}`;
  GET_PRODUCT_PARTS_BY_PRODUCT_ID = `${this.PRODUCT_SERVICE}/${this.PRODUCT_PART}/product`;

  // Part Price Items
  GET_PART_PRICE_ITEMS = `${this.PRODUCT_SERVICE}/${this.PART_PRICE_ITEM}`;
  GET_ALL_PART_PRICE_ITEMS = `${this.PRODUCT_SERVICE}/${this.PART_PRICE_ITEM}/get_all`;
  GET_PART_PRICE_ITEMS_BY_PRODUCT_PART = `${this.PRODUCT_SERVICE}/${this.PART_PRICE_ITEM}/product_part`;

  // Price Plans
  GET_PRICE_PLANS = `${this.PRODUCT_SERVICE}/${this.PRICE_PLAN}`;

  // Accounts
  GET_ACCOUNTS = `${this.CUSTOMER_SERVICE}/${this.ACCOUNT}`;

  // Quotations
  GET_QUOTATIONS = `${this.QUOTATION_SERVICE}/${this.QUOTATION}`;
  GET_QUOTATIONS_PRODUCT_BUNDLE = `${this.QUOTATION_SERVICE}/${this.QUOTATION_PRODUCT_BUNDLE}`;

  // Subscriptions
  GET_SUBSCRIPTION = `${this.SUBSCRIPTION_SERVICE}/${this.SUBSCRIPTION}`;
  GET_SUBSCRIPTION_ATTACHMENT = `${this.SUBSCRIPTION_SERVICE}/attachments`;

  // Billings
  GET_BILLINGS = `${this.BILLING_SERVICE}/${this.BILLING}`;
  GET_BILLING_EXCEL = `${this.BILLING_SERVICE}/${this.BILLING}/excel`;

  // Billing Items
  GET_BILLING_ITEMS = `${this.BILLING_SERVICE}/${this.BILLING_ITEM}`;
  EXPORT_BILLING_ITEMS = `${this.BILLING_SERVICE}/${this.BILLING_ITEM}/excel`;
  EXPORT_BILLING_ITEMS_BY_MONTH = `${this.BILLING_SERVICE}/${this.BILLING_ITEM}/excel_by_month`;

  // Policies
  GET_POLICIES = `${this.IAM_SERVICE}/${this.POLICY}`;

  // Actions
  GET_ACTIONS = `${this.IAM_SERVICE}/${this.ACTION}`;

  // Authentication
  LOGIN = `${this.IAM_SERVICE}/login`;

  // User
  GET_USERS = `${this.IAM_SERVICE}/${this.USER}`;

  // Role
  GET_ROLES = `${this.IAM_SERVICE}/${this.ROLE}`;

  // Tax
  GET_TAX = `${this.CUSTOMER_SERVICE}/${this.TAX}`;

  // Product Bundle
  GET_PRODUCT_BUNDLE = `${this.PRODUCT_SERVICE}/${this.PRODUCT_BUNDLE}`;
}
