<div>
  <div class="card">
    <div class="card-header" translate>actions</div>
    <div class="card-body table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th translate>service</th>
            <th translate>operation</th>
            <th translate>attributes</th>
          </tr>
        </thead>
        <tbody>
          <ng-template [ngIf]="!isLoading">
            <tr *ngFor="let action of actions">
              <td>{{ action?.service }}</td>
              <td>{{ action?.operation }}</td>
              <td>{{ action?.attributes }}</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
      <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
    </div>
  </div>
</div>
